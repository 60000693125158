// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  // {
  //   title: 'Worksheet',
  //   path: '/worksheet',
  //   icon: icon('worksheet'),
  // },
  {
    title: 'Database',
    path: '/resumedatabase',
    icon: icon('database'),
  },
  {
    title: 'Jobs',
    path: '/jobs',
    icon: icon('job'),
  },
  {
    title: 'Clients',
    path: '/clients',
    icon: icon('client'),
  },

  // {
  //   title: 'Tasks',
  //   path: '/companymanegement',
  //   icon: icon('ic_analytics'),
  // },

  // {
  //   title: 'Dashboard',
  //   path: '/companymanegement',
  //   icon: icon('dashboard'),
  // },
  {
    title: 'Setting',
    // path: '/companymanegement',
    icon: icon('gear'),
  },
];

export default navConfig;
