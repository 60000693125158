import React, { useEffect, useRef, useState } from 'react';
import {
  Grid,
  Stack,
  Typography,
  Box,
  Button,
  Modal,
  Divider,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  Tab,
  Tabs,
} from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import moment from 'moment';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar/Scrollbar';
import CustomComplete from '../components/customComplete/CustomComplete';
import { Api, UploadFile } from '../webservices';

function JobDetail() {
  const styleClients = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '60%', sm: '80%' },
    bgcolor: 'background.paper',
    border: '3px solid #3C6BA5',
    boxShadow: 24,
    p: 3,
    borderRadius: 3,
  };
  const navigate = useNavigate();
  const locationData = useLocation();
  const row = locationData.state?.row;
  const [currentTab, setCurrentTab] = useState('1');
  const suru = 'https://docs.google.com/viewer?url=';
  const lll = '&embedded=true';

  const [openN, setOpenN] = useState(false);
  const openNote = () => {
    setOpenN(true);
  };
  const closeNote = () => {
    setOpenN(false);
  };

  const [openS, setOpenS] = useState(false);
  const openScreening = () => {
    setOpenS(true);
  };
  const closeScreening = () => {
    setOpenS(false);
  };

  const [openC, setOpenC] = useState(false);
  const openCompensation = () => {
    setOpenC(true);
  };
  const closeCompensation = () => {
    setOpenC(false);
  };

  const [openR, setOpenR] = useState(false);
  const openResume = () => {
    setOpenR(true);
  };
  const closeResume = () => {
    setOpenR(false);
  };

  return (
    <>
     <Iconify icon="ion:arrow-back" fontSize={25} sx={{ cursor: 'pointer', color: 'text.secondary',fontSize:'0.9rem',fontWeight:'600',mr:1 }} onClick={() => navigate(-1)} />
      
    <Stack sx={{ border: 2, borderColor: 'text.secondary', borderRadius: 2, p: 2, py: 4, mx: 4 }}>
      <Stack sx={{ flexDirection: 'row', width: '100%', mb: 2, gap: 2, justifyContent: 'center' }}>
        <Button
          variant="contained"
          size="small"
          color="custom"
          sx={{ width: 'fit-content', height: 30, mt: 0.3 }}
          onClick={() => openNote()}
        >
          Recruiting Notes
        </Button>
        <Button
          variant="contained"
          size="small"
          color="custom"
          sx={{ width: 'fit-content', height: 30, mt: 0.3 }}
          onClick={() => openScreening()}
        >
          Screening Questions
        </Button>
        <Button
          variant="contained"
          size="medium"
          color="custom"
          sx={{ width: 'fit-content', height: 35 }}
          // onClick={() => setCurrentTab('1')}
        >
          Submit Candidate
        </Button>
        <Button
          variant="contained"
          size="small"
          color="custom"
          sx={{ width: 'fit-content', height: 30, mt: 0.3 }}
          onClick={() => openCompensation()}
        >
          Compensation
        </Button>
        <Button
          variant="contained"
          size="small"
          color="custom"
          sx={{ width: 'fit-content', height: 30, mt: 0.3 }}
          onClick={() => openResume()}
        >
          Sample Resume
        </Button>
        {/* <Tabs
          value={currentTab}
          onChange={(event, newValue) => {setCurrentTab(newValue)}}
          aria-label="wrapped label tabs example"
        >
          <Tab
            value="1"
            label={
              <Stack flexDirection={'row'} gap={1} alignItems={'center'}>
                <Icon icon="fluent-mdl2:all-apps" fontSize={20} />
                <Typography>Job Detail</Typography>
              </Stack>
            }
          />
          <Tab
            value="2" 
             label={
              <Stack flexDirection={'row'} gap={1} alignItems={'center'}>
                <Icon icon="iconamoon:star-thin" fontSize={20} />
                <Typography>Screening Questions</Typography>
              </Stack>
            }
          />
          
          <Tab
            value="3"
            label={
              <Stack flexDirection={'row'} gap={1} alignItems={'center'}>
                <Icon icon="prime:dollar" fontSize={20} />
                <Typography>Compensation</Typography>
              </Stack>
            }
          />
          <Tab
            value="4"
            label={
              <Stack flexDirection={'row'} gap={1} alignItems={'center'}>
                <Icon icon="jam:triangle-danger" fontSize={20} />
                <Typography>Sample Resume</Typography>
              </Stack>
            }
          />
        </Tabs> */}
      </Stack>
      {currentTab === '1' && (
        <Stack sx={{ border: 2, borderColor: 'text.secondary', borderRadius: 2, p: 2 }}>
          <Typography sx={{fontWeight: '600', textTransform: 'capitalize' }}>{row.endClientOrImpPartner}</Typography>
          <Stack direction={'row'} gap={1} alignItems={'center'}>
            <Typography
              sx={{ fontWeight: '600', cursor: 'pointer', textTransform: 'capitalize',fontSize:'0.9rem' }}
              color={'text.secondary'}
            >
              {row.jobTitle} - {row.jobId}
            </Typography>
            <Typography color={'text.textG'} sx={{ textTransform: 'capitalize' }}>
              [ {row.altJobTitle.join(' | ')} ]
            </Typography>
          </Stack>
          <Typography sx={{ textTransform: 'capitalize' }}>
            {row.openings} Openings : {row.jobType}
          </Typography>
          <Stack direction={'row'} gap={1} alignItems={'center'}>
            <Typography>Updated : </Typography>
            <Typography sx={{ color: 'text.secondary' }}>{moment(row.createdAT).format('ll')}</Typography>
          </Stack>
          <Stack direction={'row'} gap={3} alignItems={'center'}>
            <Typography sx={{ fontWeight: '600' }}>{row.location}</Typography>
            <Stack direction={'row'} gap={1} alignItems={'center'}>
              <Typography sx={{ fontWeight: '600' }}>Out Of Area Candidate : </Typography>
              <Typography  sx={{textTransform:'capitalize'}}>{row.outOfAreaCandidate}</Typography>
            </Stack>
            <Stack direction={'row'} gap={1} alignItems={'center'}>
              <Typography sx={{ fontWeight: '600' }}>Relocation Assistance :</Typography>
              <Typography sx={{textTransform:'capitalize'}}>{row.relocationAssistance}</Typography>
            </Stack>
            <Stack direction={'row'} gap={1} alignItems={'center'}>
              <Typography sx={{ fontWeight: '600' }}>Amount :</Typography>
              <Typography>${row.relocationExpenses}K</Typography>
            </Stack>
          </Stack>
          <Typography sx={{textTransform:'capitalize'}}>{row.remote}</Typography>
          <Typography sx={{fontWeight: '600',textTransform:'capitalize'}}>Compensation</Typography>
          {row.compensationType === 'annual' && (
            <Typography>
               ${row.annualMinCompensation}K - ${row.annualMaxCompensation}K
            </Typography>
          )}
          {row.compensationType === 'per hour' && (
            <>
            <Typography>
            Pay Rate on  W2 / T4 : ${row.payRateOn.minPayRate} P/H - ${row.payRateOn.maxPayRate} P/H
            </Typography>
            <Typography>
            Pay Rate on C2C/1099/Inc : ${row.onC2c1099Inc.minC2c1099Inc} P/H - ${row.onC2c1099Inc.maxC2c1099Inc} P/H
            </Typography>
            </>
          )}
          {row.benefits.length ? (
            <>
            <Typography sx={{fontWeight: '600',textTransform:'capitalize'}}>Benefits</Typography>
            <Typography sx={{textTransform:'capitalize'}}>{row.benefits}</Typography>
            </>
             ):null}
          <Typography sx={{ fontWeight: '600', mt: 2 }} color={'text.secondary'}>
            Job Description:
          </Typography>
          {/* <Typography>{row.jobDescription}</Typography> */}
          <div dangerouslySetInnerHTML={{ __html: row.jobDescription }} />
          {/* <Typography sx={{ fontWeight: '600', mt: 2 }} color={''}>
            Duties/Responsibilities:
          </Typography>
          <Stack sx={{ p: 1 }}>
            <Typography>{row.recruitingInst}</Typography>
          </Stack>
          <Typography sx={{ fontWeight: '600', mt: 2 }} color={''}>
            Required Skills/Abilities::
          </Typography>
          <Stack sx={{ p: 1 }}>
            <Typography>
              Excellent Attention to Detail Excellent written and verbal communication skills. Excellent organizational
              and time management skills. Accounting background. Proficient in Microsoft Office Suite or similar
              software. Strong Excel skills Insurance Agency/Broker accounting Preferred Abilities: Knowledge of Applied
              TAM or EPIC a plus! Education and Experience: Bachelor’s degree in Accounting or Business Administration
              Five years or more of related experience work in accounting on the Broker/Agency Insurance industry in the
              absence of a related degree. Physical Requirements: Prolonged periods sitting at a desk and working on a
              computer.
            </Typography>
          </Stack> */}
          <Typography sx={{ fontWeight: '600', mt: 1 }} color={'text.secondary'}>
          Min/Max Years of Experience
          </Typography>
          <Stack direction={'row'} gap={2}>
            <Typography sx={{ textTransform: 'capitalize' }}>
            Min : {row.minExperience} to Max : {row.maxExperience}
            </Typography>
            {/* <Typography sx={{ textTransform: 'capitalize' }}></Typography> */}
          </Stack>
          {row.skillWithExp.length && (
            <Stack sx={{ }}>
              <Typography sx={{ fontWeight: '600' }}>Must Have Skills:</Typography>
              {row.skillWithExp.map((item, index) => (
                <Stack direction={'row'} gap={2}>
                  <Typography sx={{ textTransform: 'capitalize' }}>{item.mustHaveSkill}</Typography>
                  <Typography sx={{ textTransform: 'capitalize' }}>{item.minExperience}</Typography>
                </Stack>
              ))}
            </Stack>
          )}
          {row.desiredSkillsWithExp.length && (
            <Stack sx={{ }}>
              <Typography sx={{ fontWeight: '600' }}>Desired Skills:</Typography>
              {row.desiredSkillsWithExp.map((item, index) => (
                <Stack direction={'row'} gap={2}>
                  <Typography sx={{ textTransform: 'capitalize' }}>{item.desiredSkill}</Typography>
                  <Typography sx={{ textTransform: 'capitalize' }}>{item.minExperience}</Typography>
                </Stack>
              ))}
            </Stack>
          )}
          <Typography sx={{ fontWeight: '600', mt: 1 }} color={'text.secondary'}>
            Education:
          </Typography>
          <Stack sx={{ }}>
            <Stack>
              <Typography sx={{ fontWeight: '600', textTransform: 'capitalize' }}>degree</Typography>
              {row.degree.length
                ? row.degree.map((item) => {
                    return (
                      <Stack direction={'row'} gap={2}>
                        <Typography sx={{ textTransform: 'capitalize' }}>{item.degree}</Typography>
                        <Typography sx={{ textTransform: 'capitalize' }}>{item.degreeRequired}</Typography>
                      </Stack>
                    );
                  })
                : null}
              {/* <Typography sx={{ textTransform: 'capitalize' }}>{row.degree ? row.degree : '-'}</Typography>
              <Typography sx={{ textTransform: 'capitalize' }}>{row.degreeRequired}</Typography> */}
            </Stack>
            <Stack>
              <Typography sx={{ fontWeight: '600', textTransform: 'capitalize', mt: 0.5 }}>certification</Typography>
              {row.certifications.length
                ? row.certifications.map((item) => {
                    return (
                      <Stack direction={'row'} gap={2}>
                        <Typography sx={{ textTransform: 'capitalize' }}>{item.certification}</Typography>
                        <Typography sx={{ textTransform: 'capitalize' }}>{item.certificationRequired}</Typography>
                      </Stack>
                    );
                  })
                : null}
              {/* <Typography sx={{ textTransform: 'capitalize' }}>
                {row.certifications ? row.certifications : '-'}
              </Typography>
              <Typography sx={{ textTransform: 'capitalize' }}>{row.certificationRequired}</Typography> */}
            </Stack>
          </Stack>
          <Stack sx={{ justifyContent: 'center', width: '100%', flexDirection: 'row' }}>
            <CopyToClipboard
              text={`
              ${row.endClientOrImpPartner}
              ${row.jobTitle} - ${row.jobId} [ ${row.altJobTitle.join(', ')} ]
              ${row.openings} Vacancy : ${row.jobType}
              Updated : ${moment(row.createdAT).format('ll')}
              ${row.location} Out Of Area Candidate : ${row.outOfAreaCandidate} Relocation Assistance : ${row.relocationAssistance} Amount : ${row.relocationExpenses}
              ${row.remote}
              Compensation : ${row.compensationType === 'annual' && (
                 `$ ${row.annualMinCompensation} K - $ ${row.annualMaxCompensation} K`
              )} 
              ${row.compensationType === 'per hour' && (
                `$ ${row.payRateOn.minPayRate} P/H - $ ${row.payRateOn.maxPayRate} P/H`
              )}
              Job Description: ${row.jobDescription}
              Minimum Years of Experience:  ${row.minExperience} to ${row.maxExperience}
              Must Have Skills: ${row.skillWithExp.length?row.skillWithExp.map((item, index) =>{
                return `${item.mustHaveSkill} ${item.minExperience}`
              }):null}
              Desired Skills: ${row.desiredSkillsWithExp.length?row.desiredSkillsWithExp.map((item, index) => {
                return `${item.desiredSkill} ${item.minExperience}`
              }):null}
              Education: ${row.degree.length
                ? row.degree.map((item) => {
                    return `${item.degree} ${item.degreeRequired}`
                  }):null}
                  Certification:  ${row.certifications.length
                    ? row.certifications.map((item) => {
                        return `${item.certification} ${item.certificationRequired}`
                      }
                    ):null}`}
            >
              <Button variant="contained" size="small" color="custom" sx={{ width: 'fit-content' }}>
                Copy Description
              </Button>
            </CopyToClipboard>
          </Stack>
        </Stack>
      )}
      {currentTab === '2' && (
        <Stack sx={{ border: 1, borderColor: 'text.secondary', borderRadius: 1, p: 1 }}>
          <Typography variant="h6" sx={{ textAlign: 'center', color: 'text.secondary' }}>
            Screening Questions
          </Typography>
          <Typography>{row.screeningQuestions ? row.screeningQuestions : '-'}</Typography>
        </Stack>
      )}
      {currentTab === '3' && (
        <Stack sx={{ border: 1, borderColor: 'text.secondary', borderRadius: 1, p: 1 }}>
          <Typography variant="h6" sx={{ textAlign: 'center', color: 'text.secondary' }}>
            Compensation
          </Typography>
          {row.compensationType === 'annual' && (
            <>
              <Typography sx={{ fontWeight: '600' }}>Annual Compensation</Typography>

              <Typography>
                ${row.annualMinCompensation}K - ${row.annualMaxCompensation}K
              </Typography>
            </>
          )}
          {row.compensationType === 'per hour' && (
            <>
              <Typography sx={{ fontWeight: '600' }}>Pay Rate On</Typography>
              <Typography sx={{ p: 1 }}>
                ${row.payRateOn.minPayRate} P/H - ${row.payRateOn.maxPayRate} P/H
              </Typography>
              <Typography sx={{ fontWeight: '600' }}>On C2C 1099 Inc</Typography>
              <Typography sx={{ p: 1 }}>
                ${row.onC2c1099Inc.minC2c1099Inc} P/H - ${row.onC2c1099Inc.maxPayRate} P/H
              </Typography>
              <Typography sx={{ fontWeight: '600' }}>Bill Rate</Typography>
              <Typography sx={{ p: 1 }}>
                ${row.billRate.minBillRate} P/H - ${row.billRate.maxBillRate} P/H
              </Typography>
            </>
          )}
        </Stack>
      )}
      {currentTab === '4' && (
        <Stack sx={{ border: 1, borderColor: 'text.secondary', borderRadius: 1, p: 1 }}>
          <Typography variant="h6" sx={{ textAlign: 'center', color: 'text.secondary' }}>
            Sample Resume
          </Typography>
          <iframe title="resume" src={suru + row.sampleResume + lll} width="100%" height="650px" />
        </Stack>
      )}
      {currentTab === '5' && (
        <Stack sx={{ border: 1, borderColor: 'text.secondary', borderRadius: 1, p: 1 }}>
          <Typography variant="h6" sx={{ textAlign: 'center', color: 'text.secondary' }}>
            Recruiting Notes
          </Typography>
          {/* <Typography>{row.notes ? row.notes : ''}</Typography> */}
          <div dangerouslySetInnerHTML={{ __html: row.recruitingInst }} />
        </Stack>
      )}
      <Modal open={openN} onClose={closeNote} style={{ borderRadius: 10 }}>
        <Box sx={styleClients}>
          <Typography variant="h6" sx={{ textAlign: 'center', color: 'text.secondary' }}>
            Recruiting Notes
          </Typography>
            <Divider />
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
            <Stack sx={{ border: 1, borderColor: 'text.secondary', borderRadius: 2, p: 2,mt:2 }}>
              <Typography sx={{fontWeight:'600'}}>Note</Typography>
              <div dangerouslySetInnerHTML={{ __html: row.recruitingInst }} />
              <Typography sx={{fontWeight:'600'}}>Benefits</Typography>
              <Typography sx={{}}>{row.benefits || '-'}</Typography>
              <Typography sx={{fontWeight:'600'}}>Gurantee Period</Typography>
              <Typography sx={{}}>{row.guaranteePeriod}</Typography>
            </Stack>
          </Scrollbar>
        </Box>
      </Modal>
      <Modal open={openS} onClose={closeScreening} style={{ borderRadius: 10 }}>
        <Box sx={styleClients}>
          <Typography variant="h6" sx={{ textAlign: 'center', color: 'text.secondary' }}>
            Screening Questions
          </Typography>
            <Divider />
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
            <Stack sx={{ border: 1, borderColor: 'text.secondary', borderRadius: 2, p: 2 ,mt:2}}>
              {/* <Typography>{row.screeningQuestions ? row.screeningQuestions : '-'}</Typography> */}
              <div dangerouslySetInnerHTML={{ __html: row.screeningQuestions }} />
            </Stack>
          </Scrollbar>
        </Box>
      </Modal>
      <Modal open={openC} onClose={closeCompensation} style={{ borderRadius: 10 }}>
        <Box sx={styleClients}>
          <Typography variant="h6" sx={{ textAlign: 'center', color: 'text.secondary' }}>
            Compensation
          </Typography>
            <Divider />
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
            <Stack sx={{ border: 1, borderColor: 'text.secondary', borderRadius: 2, p: 2 ,mb:1,mt:2}}>
              {row.compensationType === 'annual' && (
                <>
                  <Typography sx={{ fontWeight: '600' }}>Annual Compensation</Typography>

                  <Typography>
                    ${row.annualMinCompensation} K - ${row.annualMaxCompensation} K
                  </Typography>
                </>
              )}
              {row.compensationType === 'per hour' && (
                <>
                <Stack sx={{flexDirection:'row',gap:1}}>
                  <Typography sx={{ fontWeight: '600' }}>Bill Rate :</Typography>
                  <Typography sx={{ }}>
                    ${row.billRate.minBillRate} P/H - ${row.billRate.maxBillRate} P/H
                  </Typography></Stack>
                <Stack sx={{flexDirection:'row',gap:1}}>
                  <Typography sx={{ fontWeight: '600' }}>Pay Rate On  W2 / T4 :</Typography>
                  <Typography sx={{ }}>
                    ${row.payRateOn.minPayRate} P/H - ${row.payRateOn.maxPayRate} P/H
                  </Typography></Stack>
                  <Stack sx={{flexDirection:'row',gap:1}}>
                  <Typography sx={{ fontWeight: '600' }}>On C2C / 1099 / Inc :</Typography>
                  <Typography sx={{ }}>
                    ${row.onC2c1099Inc.minC2c1099Inc} P/H - ${row.onC2c1099Inc.maxC2c1099Inc} P/H
                  </Typography></Stack>
                  
                </>
              )}
              <Typography sx={{textTransform:'capitalize'}}>Relocation : {row.relocationAssistance}</Typography>
              <Typography>Relocation Amount : ${row.relocationExpenses}K</Typography>
              <Typography>Interview Expenses : {row.interviwExpenses?"Yes":'No'}</Typography>
              <Typography>Interview Amount : ${row.interviwExpenses}K</Typography>
            </Stack>
          </Scrollbar>
        </Box>
      </Modal>
      <Modal open={openR} onClose={closeResume} style={{ borderRadius: 10 }}>
        <Box sx={[styleClients]}>
          <Typography variant="h6" sx={{ textAlign: 'center', color: 'text.secondary' }}>
            Sample Resume
          </Typography>
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
            <Divider />
            <Stack sx={{ border: 1, borderColor: 'text.secondary', borderRadius: 2, p: 2 ,mt:2}}>
              <iframe title="resume" src={suru + row.sampleResume + lll} width="100%" height="650px" />
            </Stack>
          </Scrollbar>
        </Box>
      </Modal>
    </Stack>
    </>
  );
}

export default JobDetail;
