import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Box,
  Link,
  TextField,
  Pagination,
  Modal,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  InputAdornment,
  CircularProgress,
  Divider,
  OutlinedInput,
  ListItemText,
  Checkbox,
  Chip,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import moment from 'moment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ToastContainer, toast } from 'react-toastify';
import { Icon } from '@iconify/react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Navigate, useNavigate, useNavigation } from 'react-router-dom';
import CustomComplete from '../components/customComplete/CustomComplete';
import { Api, UploadFile } from '../webservices';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar/Scrollbar';

const styleClients = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: '60%', sm: '80%' },
  bgcolor: 'background.paper',
  border: '3px solid #3C6BA5',
  boxShadow: 24,
  p: 3,
  borderRadius: 3,
};
function Clients() {
  const role = useSelector((state) => state.userData.data.role);
  // const navigate = useNavigate();
  const [pages, setPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const handlePage = (event, value) => {
    console.log(value);
    setPageNumber(value);
    getClient(currentTab, value);
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setFormValues({});
    setDocfile('');
  };
  const [uploadLoader, setUploadLoader] = useState(false);
  const [contactErr, setContactErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [clientErr, setClientErr] = useState(false);
  const [docfile, setDocfile] = useState('');
  const [isClientUpdate, setIsClientUpdate] = useState(false);
  const [currentTab, setCurrentTab] = React.useState('Active');

  const [cityAndZip, setCityAndZip] = useState([]);
  const [cityy, setCityy] = useState('');
  const [clientList, setClientList] = useState([]);
  const [startDatee, setStartDate] = useState(dayjs());
  const [formValues, setFormValues] = useState({
    _id: '',
    addedBy: '',
    addedById: '',
    email: '',
    additionalEmail: '',
    linkedinUrl: '',
    mainContactName: '',
    websiteUrl: '',
    phone: '',
    completeAddress: '',
    salesPerson: '',
    employeeNo: '',
    location: '',
    currentStatus: '',
    domain: '',
    industry: '',
    RPO_Staffing: 'RPO',
    status: 'Active',
    amountType: '',
    contractDate: '',
    amountValue: '',
    clientSource: '',
    contractUrl: '',
    direct: '',
    createdAt: '',
    companyID: '',
    ClientName: '',
    subPlan: 'month',
    country: 'USA',
  });

  useEffect(() => {
    getClient('Active');
  }, []);

  const getCity = async (val) => {
    setCityAndZip([]);
    const token = localStorage.getItem('token');
    const body = {
      country: formValues.country,
      query: val,
    };
    Api(`resume/get_city_states`, 'POST', body, token)
      .then((response) => {
        if (response.status === 200) {
          console.log('=========status 200======>>>>>>>', response);
          if (response.data.code === 200) {
            setCityAndZip(response.data.data);
            // setCityandstate(response.data.data[0]);
            console.log('=======city and State', response.data.data[0].zip);
          } else {
            // setCityAndZip(response.data.data);
            // toast.error(response.data.message, +'!');
          }
        }
      })
      .catch((err) => {
        console.log('===========', err);
        // toast.error(err, +'!');
      });
  };
  const childFuc = (e) => {
    console.log('e', e);
    setCityy(e);
    // setValue({ ...value, location: e });
    if (e.length >= 3) getCity(e);
  };
  const fullVal = (val) => {
    setCityy(`${val.city} ${val.stateCode} ${val.zip}`);
    // setCityy(`${val.city} (${val.state}) (${val.zip})`);
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { md: '50%', xs: '95%' },
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 3,
  };

  const addClient = () => {
    const token = localStorage.getItem('token');
    if (formValues.ClientName !== '') {
    if (formValues.email !== '') {
    if (formValues.mainContactName !== '') {
        Api('company/generate/id', 'GET', '', token).then((Response) => {
          if (Response.status === 200) {
            if (Response.data.code === 200) {
              console.log('Response', Response.data.clientCompanyId);
              addClientt(Response.data.clientCompanyId);
            }
          }
        });
      }else{
        setContactErr(true);
        toast.warn('Please Enter Main Contact Name', +'!');
      }
      } else {
        setEmailErr(true);
        toast.warn('Please Enter Email', +'!');
      }
      } else {
        setClientErr(true);
        toast.warn('Please EnterClient Name', +'!');
      }
   
  };
  const addClientt = (ccId) => {
    const token = localStorage.getItem('token');
    const body = {
      clientCompanyId: ccId,
      companyID: formValues.companyID,
      email: formValues.email,
      startDate: startDatee,
      additionalEmail: formValues.additionalEmail,
      linkedinUrl: formValues.linkedinUrl,
      contactPersonName: formValues.mainContactName,
      websiteUrl: formValues.websiteUrl,
      domain: formValues.domain,
      contactNoWithISD: formValues.phone,
      industry: formValues.industry,
      RPO_Staffing: formValues.RPO_Staffing,
      status: formValues.status,
      amountType: formValues.amountType,
      contractDate: formValues.contractDate,
      amountValue: formValues.amountValue,
      clientSource: formValues.clientSource,
      contractUrl: formValues.contractUrl,
      address: formValues.completeAddress,
      salesPerson: formValues.salesPerson,
      employeeNo: formValues.employeeNo,
      location: cityy,
      country: formValues.country,
      clientName: formValues.ClientName,
      companyName: formValues.ClientName,
      subPlan: formValues.amountType === 'Subscription' ? formValues.subPlan : '',
      billingDate: formValues.amountType === 'Subscription' ? formValues.billingDate : '',
    };
    Api('company/add_new_client', 'POST', body, token).then((Response) => {
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          handleClose();
          setFormValues({...formValues,status:'Active'})
          toast.success(Response.data.message);
          getClient('Active');
        }
      } else {
        toast.warn(Response.data.error, +'!');
      }
    });
  };

  const pdfUpload = async (file) => {
    setUploadLoader(true);
    setDocfile(file.name);
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('document', file);
    formData.append('directoryName', 'contract');
    UploadFile(`resume/upload_resume`, formData, token).then((Response) => {
      console.log('=====upload Response========>', Response.data);
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          setUploadLoader(false);
          toast.success(Response.data.status, +'!');
          setFormValues({ ...formValues, contractUrl: Response.data.filePath });
        } else {
          console.log('=====404=gst===', Response.data.message);
          toast.warn(Response.data.status, +'!');
        }
      } else {
        toast.warn(Response.data.message, +'!');
      }
    });
  };

  const getClient = (val, value) => {
    setCurrentTab(val || '');
    const token = localStorage.getItem('token');
    const body = {
      status: val,
      page: value || 1,
      limit: '35',
    };
    Api('company/direct_converted', 'POST', body, token).then((Response) => {
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          setClientList(Response.data.data);
          const totalPage = Math.ceil(Response.data.count / 35);
          setPages(totalPage);
        }
      } else {
        toast.warn(Response.data.error, +'!');
      }
    });
  };

  const editClientDetail = (defaultVal) => {
    setFormValues(defaultVal);
    setIsClientUpdate(true);
    handleOpen();
  };

  const handleTabs = (val) => {
    // setCurrentTab(val);
    getClient(val);
  };

  return (
    <>
      <ToastContainer />
      <Stack flexDirection={'row'} alignItems={'flex-end'} justifyContent={'space-between'}>
        <Typography variant="h5" color={'text.secondary'}>
          Clients
        </Typography>
        {role === 'admin' || role === 'salesHead' || role === "salesManager" || role === "salesTeamLead"|| role === "salesExecutive"|| role ===  "salesTrainee" || role === 'recruiterHead' ||role === 'recruiterManager' ? (
          <Button color="custom" variant="contained" size="small" onClick={handleOpen}>
            Add Client
          </Button>
        ) : null}
      </Stack>

      <Stack sx={{ flexDirection: 'row', gap: 2, mb: 0.5, cursor: 'pointer', alignItems: 'center' }}>
        <Stack
          sx={[
            { flexDirection: 'row' },
            currentTab === '' ? { borderBottom: 3, color: 'text.secondary' } : { color: 'text.textG' },
          ]}
          onClick={() => {
            handleTabs('');
          }}
        >
          <Iconify icon="mingcute:list-check-line" fontSize={17} />
          <Typography variant="tabs" sx={{ ml: 1 }}>
            All
          </Typography>
        </Stack>
        <Stack
          sx={[
            { flexDirection: 'row' },
            currentTab === 'Active' ? { borderBottom: 3, color: 'text.secondary' } : { color: 'text.textG' },
          ]}
          onClick={() => {
            handleTabs('Active');
          }}
        >
          <Iconify
            icon="iconamoon:star-thin"
            fontSize={17}
            color={currentTab === 'Active' ? '#2ECC71' : 'text.textG'}
          />
          <Typography variant="tabs" sx={{ ml: 1 }}>
            Active Clients
          </Typography>
        </Stack>
        <Stack
          sx={[
            { flexDirection: 'row' },
            currentTab === 'On Hold' ? { borderBottom: 3, color: 'text.secondary' } : { color: 'text.textG' },
          ]}
          onClick={() => {
            handleTabs('On Hold');
          }}
        >
          <Iconify icon="mynaui:pause" fontSize={17} color={currentTab === 'On Hold' ? '#F4D03F' : 'text.textG'} />
          <Typography variant="tabs" sx={{ ml: 1 }}>
            On Hold
          </Typography>
        </Stack>
        <Stack
          sx={[
            { flexDirection: 'row' },
            currentTab === 'Inactive' ? { borderBottom: 3, color: 'text.secondary' } : { color: 'text.textG' },
          ]}
          onClick={() => {
            handleTabs('Inactive');
          }}
        >
          <Iconify
            icon="jam:triangle-danger"
            fontSize={17}
            color={currentTab === 'Inactive' ? '#CB4335' : 'text.textG'}
          />
          <Typography variant="tabs" sx={{ ml: 1 }}>
            Inactive Clients
          </Typography>
        </Stack>
      </Stack>

      <TableContainer component={Paper} sx={{}}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ justifyContent: 'flex-start', backgroundColor: 'text.secondary' }}>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white', width: '12%' }}>Date</TableCell>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white', width: '18%' }}>Client Info</TableCell>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white', width: '18%' }}>Contact</TableCell>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white', width: '12%' }}>Client Info</TableCell>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white', width: '10%' }}>Jobs</TableCell>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white', width: '10%' }}>Activity</TableCell>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white', width: '10%' }}>Status</TableCell>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white', width: '10%' }}>Invoice</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clientList.map((item, index) => (
              <ClientRow
                key={item._id}
                row={item}
                index={index}
                editClient={editClientDetail}
                hitClient={getClient}
                role={role}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {clientList.length < 1 && (
        <Stack sx={{ mt: 2, p: 2 }}>
          <Typography sx={{ alignSelf: 'center', color: 'red' }}>no data found</Typography>
        </Stack>
      )}
      {clientList.length ? (
        <Stack spacing={2} sx={{ alignItems: 'flex-end', width: '100%', mt: 2 }}>
          <Pagination
            variant="outlined"
            shape="rounded"
            color="secondary"
            count={pages}
            page={pageNumber}
            onChange={handlePage}
          />
        </Stack>
      ) : null}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={[styleClients, { width: { xs: '95%', sm: '75%' } }]}>
          <Typography variant="h5" textAlign={'center'} color={'text.secondary'}>
            Add Client
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
            <Grid display={'grid'} gridTemplateColumns={{ sm: 'repeat(2, 1fr)', xs: 'repeat(1, 1fr)' }} py={1} gap={2}>
              <Stack gap={2}>
                <TextField
                  id="outlined-basic"
                  label="Client ID"
                  placeholder="Client ID"
                  variant="outlined"
                  value={formValues?.companyID}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setFormValues({ ...formValues, companyID: e.target.value })}
                />
                <TextField
                error={clientErr}
                required
                  id="outlined-basic"
                  label="Client Name"
                  placeholder="Client Name"
                  variant="outlined"
                  value={formValues?.ClientName}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) =>{ setFormValues({ ...formValues, ClientName: e.target.value })
                  if(formValues.ClientName!==""){
                    setClientErr(false)
                  }else{
                    setClientErr(true)
                  } }}
                />

                <TextField
                  id="outlined-basic"
                  label="Domain"
                  placeholder="Domain"
                  variant="outlined"
                  value={formValues?.domain}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setFormValues({ ...formValues, domain: e.target.value })}
                />

                <TextField
                  id="outlined-basic"
                  label="Phone"
                  placeholder="Phone"
                  variant="outlined"
                  value={formValues.phone}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setFormValues({ ...formValues, phone: e.target.value })}
                />
                <TextField
                  id="outlined-basic"
                  label="Linkedin URL"
                  placeholder="Linkedin URL"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={formValues?.linkedinUrl}
                  onChange={(e) => setFormValues({ ...formValues, linkedinUrl: e.target.value })}
                />
                {/* <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Industry</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Industry"
                    placeholder="Industry"
                    value={formValues?.industry}
                    onChange={(e) => setFormValues({ ...formValues, industry: e.target.value })}
                  >
                    <MenuItem value="IT">IT</MenuItem>
                    <MenuItem value="Healthcare">Healthcare</MenuItem>
                    <MenuItem value="Engineering">Engineering</MenuItem>
                    <MenuItem value="Manufacturing">Manufacturing</MenuItem>
                    <MenuItem value="non IT">non IT</MenuItem>
                    <MenuItem value="Finance">Finance</MenuItem>
                    <MenuItem value="Pharma">Pharma</MenuItem>
                    <MenuItem value="Hospitality">Hospitality</MenuItem>
                    <MenuItem value="All">All</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                </FormControl> */}
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Country</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Country"
                    placeholder="Country"
                    value={formValues.country}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setFormValues({ ...formValues, country: e.target.value })}
                  >
                    <MenuItem value={'USA'}>USA</MenuItem>;<MenuItem value={'CANADA'}>CANADA</MenuItem>;
                  </Select>
                </FormControl>
                <FormControl>
                  <CustomComplete
                    placeholder={'City'}
                    option={cityAndZip}
                    propVal={cityy}
                    childFuc={childFuc}
                    fullVal={fullVal}
                    fetch={'state'}
                  />
                </FormControl>
                {/* <TextField
                  id="outlined-basic"
                  label="location"
                  placeholder="location"
                  variant="outlined"
                  value={formValues?.location}
                  onChange={(e) => setFormValues({ ...formValues, location: e.target.value })}
                /> */}
                <TextField
                  id="outlined-basic"
                  label="Industry"
                  placeholder="Industry"
                  variant="outlined"
                  value={formValues?.industry}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setFormValues({ ...formValues, industry: e.target.value })}
                />

                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Client Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Client type"
                    placeholder="Client type"
                    value={formValues.RPO_Staffing}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setFormValues({ ...formValues, RPO_Staffing: e.target.value })}
                  >
                    <MenuItem value="RPO">RPO</MenuItem>
                    <MenuItem value="Staffing">Staffing</MenuItem>
                    <MenuItem value="Consulting">Consulting</MenuItem>
                    <MenuItem value="MSP">MSP</MenuItem>
                    <MenuItem value="Contingent">Contingent</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                </FormControl>

                <FormControl>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{ width: '100%' }}
                      label={'Contract Signed Date'}
                      value={dayjs(formValues?.contractDate)}
                      onChange={(e) => setFormValues({ ...formValues, contractDate: e })}
                    />
                  </LocalizationProvider>
                </FormControl>

                <TextField
                  id="outlined-basic"
                  label="Client Source"
                  placeholder="Client Source"
                  variant="outlined"
                  value={formValues?.clientSource}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setFormValues({ ...formValues, clientSource: e.target.value })}
                />

                <Stack sx={{ position: 'relative' }}>
                  <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Stack
                      sx={{
                        cursor: 'pointer',
                        m: 1,
                        border: 1,
                        p: 1,
                        borderRadius: 2,
                        width: 'fit-content',
                        borderColor: 'text.secondary',
                      }}
                    >
                      <Typography sx={{ alignSelf: 'center' }} color={'text.secondary'}>
                        Upload Contract
                      </Typography>
                    </Stack>
                    <input
                      style={{ opacity: 0, position: 'absolute', margin: 5, width: 100 }}
                      type="file"
                      onChange={(e) => {
                        pdfUpload(e.target.files[0]);
                      }}
                    />
                    {uploadLoader && <CircularProgress />}
                  </Stack>
                  {formValues.contractUrl || docfile ? (
                    <Typography>{docfile || 'Contract already Uploaded'}</Typography>
                  ) : null}
                </Stack>
              </Stack>
              <Stack gap={2}>
                <TextField
                  error={emailErr}
                  required
                  id="outlined-basic"
                  label="Email"
                  placeholder="Email"
                  variant="outlined"
                  value={formValues?.email}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => {setFormValues({ ...formValues, email: e.target.value });
                  if(formValues.email!==""){
                    setEmailErr(false)
                  }else{
                    setEmailErr(true)
                  }   
                }}
                />
                {/* <TextField
                  id="outlined-basic"
                  label="Start Date"
                  multiline
                  placeholder="Start Date"
                  variant="outlined"
                  value={formValues?.additionalEmail}
                  onChange={(e) => setFormValues({ ...formValues, additionalEmail: e.target.value })}
                /> */}
                <FormControl>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer sx={{ mt: -0.8 }} components={['DatePicker', 'DatePicker']}>
                      <DatePicker
                        sx={{ width: '100%' }}
                        label={'Start Date'}
                        value={startDatee}
                        onChange={(e) => {
                          setStartDate(e);
                          console.log('e', e);
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </FormControl>
                <TextField
                  id="outlined-basic"
                  label="Number of Employees"
                  placeholder="Number of Employees"
                  variant="outlined"
                  value={formValues?.employeeNo}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setFormValues({ ...formValues, employeeNo: e.target.value })}
                />
                <TextField
                  error={contactErr}
                  required
                  id="outlined-basic"
                  label=" Main Contact Name"
                  placeholder="Main Contact Name"
                  variant="outlined"
                  value={formValues.mainContactName}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => {setFormValues({ ...formValues, mainContactName: e.target.value })
                  if(formValues.mainContactName!==""){
                    setContactErr(false)
                  }else{
                    setContactErr(true)
                  } }}
                />

                <TextField
                  id="outlined-basic"
                  label="Website URL"
                  placeholder="Website URL"
                  variant="outlined"
                  value={formValues?.websiteUrl}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setFormValues({ ...formValues, websiteUrl: e.target.value })}
                />
                <TextField
                  id="outlined-basic"
                  label="Complete  Address"
                  placeholder="Complete Address"
                  variant="outlined"
                  value={formValues?.completeAddress}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setFormValues({ ...formValues, completeAddress: e.target.value })}
                />

                <FormControl size="small" fullWidth sx={{ mt: -0.6 }}>
                  <FormLabel id="demo-radio-buttons-group-label">Fees Type</FormLabel>
                  <RadioGroup
                    size="small"
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="Amount Type"
                    name="radio-buttons-group"
                    sx={{ flexDirection: 'row' }}
                    value={formValues?.amountType}
                    onChange={(e) => setFormValues({ ...formValues, amountType: e.target.value })}
                  >
                    <FormControlLabel value="Percentage Basis" label="Percentage" control={<Radio size="small" />} />
                    <FormControlLabel value="Flat Fee" label="Flat Fee" control={<Radio size="small" />} />
                    <FormControlLabel value="Margin Basis" label="Margin" control={<Radio size="small" />} />
                    <FormControlLabel value="Subscription" label="Subscription" control={<Radio size="small" />} />
                  </RadioGroup>
                </FormControl>
                {formValues.amountType === 'Subscription' ? (
                  <>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Subscription Plan</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Subscription Plan"
                        placeholder="Subscription Plan"
                        value={formValues.subPlan}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setFormValues({ ...formValues, subPlan: e.target.value })}
                      >
                        <MenuItem value="month">Month</MenuItem>
                        <MenuItem value="quater">Quater</MenuItem>
                        <MenuItem value="year">Year</MenuItem>
                      </Select>
                    </FormControl>
                    <TextField
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        endAdornment: <InputAdornment position="end">per/{formValues?.subPlan}</InputAdornment>,
                      }}
                      value={formValues?.amountValue}
                      onChange={(e) => setFormValues({ ...formValues, amountValue: e.target.value })}
                    />
                    <FormControl>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          sx={{ width: '100%' }}
                          label={'Billing Date'}
                          value={dayjs(formValues.billingDate)}
                          onChange={(e) => setFormValues({ ...formValues, billingDate: e })}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </>
                ) : (
                  <TextField
                    id="outlined-basic"
                    label={'Fee Type'}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {formValues?.amountType !== 'Percentage Basis' ? '$' : null}
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          {formValues?.amountType === 'Percentage Basis'
                            ? '%'
                            : formValues?.amountType === 'Margin Basis'
                            ? 'P/H'
                            : 'K'}
                        </InputAdornment>
                      ),
                    }}
                    value={formValues?.amountValue}
                    onChange={(e) => setFormValues({ ...formValues, amountValue: e.target.value })}
                  />
                )}
                <TextField
                  id="outlined-basic"
                  label="Sales Persons"
                  placeholder="Sales Persons"
                  variant="outlined"
                  value={formValues?.salesPerson}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setFormValues({ ...formValues, salesPerson: e.target.value })}
                />
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Status"
                    placeholder="Status"
                    value={formValues.status}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setFormValues({ ...formValues, status: e.target.value })}
                  >
                    <MenuItem value="Active">Active</MenuItem>
                    <MenuItem value="Inactive">Inactive</MenuItem>
                    <MenuItem value="on Hold">on Hold</MenuItem>
                  </Select>
                </FormControl>
                {/* {formValues?.status && (
                      <FormControl>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            sx={{ width: '100%' }}
                            label={formValues?.RPO_Staffing === 'RPO' ? 'Billing Date' : 'Contract Signed Date'}
                            value={dayjs(formValues?.contractDate)}
                            onChange={(e) =>
                              setFormValues({ ...formValues, contractDate: new Date(e.$d).toLocaleDateString() })
                            }
                          />
                        </LocalizationProvider>
                      </FormControl>
                    )} */}
              </Stack>
            </Grid>
            <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
              <Button variant="contained" color="custom" onClick={() => addClient()} sx={{ alignSelf: 'center' }}>
                Add Client
              </Button>
            </Stack>
          </Scrollbar>
        </Box>
      </Modal>
    </>
  );
}

const ClientRow = ({ row, editClient, index, hitClient, role }) => {
  const tablebg = 'rgba(82, 130, 190, 0.08)';
  const navigate = useNavigate();
  const [roleUsers, setRoleUsers] = useState([]);
  const [underRole, setUnderRole] = useState([]);
  const [selectUser, setSelectUser] = useState([]);
  const [selectUserId, setSelectUserId] = useState([]);

  const [docfile, setDocfile] = useState('');
  const [startDatee, setStartDate] = useState(dayjs(row.startDate));

  const [actionData, setActionData] = useState([]);
  const [assignee, setAssignee] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openAss, setOpenAss] = useState(false);
  const assCloses = () => setOpenAss(false);
  const assOpen = () => setOpenAss(true);

  const [member, setMember] = useState('individual');
  const [tempRole, setTempRole] = useState('');
  const [personName, setPersonName] = useState([]);

  const [openD, setOpenD] = useState(false);
  const openDetail = () => {
    getContract();
    setOpenD(true);
  };
  const closeDetail = () => {
    setOpenD(false);
  };

  const [openA, setOpenA] = useState(false);
  const openActivity = () => setOpenA(true);
  const closeActivity = () => setOpenA(false);

  const [openstatus, setOpenstatus] = useState(false);
  const statusCloses = () => setOpenstatus(false);
  const statusOpen = () => setOpenstatus(true);

  const [changeJobstatus, setChangeJobstatus] = useState(false);
  const jobStatusOpen = () => {
    setChangeJobstatus(true);
  };
  const jobStatusCloses = () => {
    setChangeJobstatus(false);
  };

  // const [popupOpen, setPopupOpen] = useState(false);

  const [openS, setOpenS] = useState(false);
  const openSub = () => {
    statusJobs('submitted');
    setOpenS(true);
  };
  const closeSubmission = () => setOpenS(false);

  const [openI, setOpenI] = useState(false);
  const openInterview = () => {
    statusJobs('interview');
    setOpenI(true);
  };
  const closeInterview = () => setOpenI(false);

  const [openP, setOpenP] = useState(false);
  const openPlacement = () => {
    statusJobs('placement');
    setOpenP(true);
  };
  const closePlacement = () => setOpenP(false);

  const [openT, setOpenT] = useState(false);
  const openTotalJob = () => {
    setOpenT(true);
    totalJobs();
  };
  const closeTotalJob = () => setOpenT(false);

  const [tempData, setTempData] = useState([]);

  const [openJ, setOpenJ] = useState(false);
  const openJob = () => {
    openJobs();
    setOpenJ(true);
  };
  const closeJob = () => setOpenJ(false);
  const [openData, setOpenData] = useState([]);
  const [totalData, setTotalData] = useState([]);

  const [openC, setOpenC] = useState(false);
  const openCandidateDetails = () => setOpenC(true);
  const closeCandidateDetails = () => setOpenC(false);

  const [openClient, setOpenClient] = useState(false);
  const openClientD = () => setOpenClient(true);
  const closeClientD = () => setOpenClient(false);
  const [tabs, setTabs] = useState('Agreement');
  const [upload, setUpload] = useState(false);
  const [resumeArry, setResumeArry] = useState([]);

  const [note, setNote] = useState('');
  const [openEdit, setOpenEdit] = useState(false);
  const editClose = () => setOpenEdit(false);
  const editOpen = () => setOpenEdit(true);
  const [clientUserList, setClientUserList] = useState([]);
  const [openClientlist, setOpenClientlist] = useState(false);
  const openCList = () => setOpenClientlist(true);
  const closeCList = () => setOpenClientlist(false);
  const [allAssign, setAllAssign] = useState(false);
  const [contractUrl, setContractUrl] = useState(row.contractUrl);

  const [statuss, setStatuss] = useState('');
  const [statusDate, setStatusDate] = useState(dayjs());
  const [statusNote, setStatusNote] = useState('');

  const [cityAndZip, setCityAndZip] = useState([]);
  const [cityy, setCityy] = useState(row.location || '');

  const suru = 'https://docs.google.com/viewer?url=';
  const lll = '&embedded=true';
  const [smsValue, setSmsValue] = useState('');
  const [uploadLoader, setUploadLoader] = useState(false);
  const [editAssign, setEditAssign] = useState(false);
  const [unAssignee, setUnAssignee] = useState([]);
  const setEditorSmsValue = (val) => {
    setSmsValue(val);
    console.log('val', val);
  };

  const [email, setEmail] = useState({
    editor: '',
    Subject: '',
    date: '',
    clientId: '',
    superAdminId: '',
  });
  const [formValues, setFormValues] = useState({
    _id: '',
    addedBy: '',
    addedById: '',
    email: row.email || '',
    additionalEmail: '',
    linkedinUrl: row.linkedinUrl || '',
    mainContactName: row.contactPersonName || '',
    websiteUrl: row.websiteUrl || '',
    phone: row.contactNoWithISD || '',
    completeAddress: row.address || '',
    salesPerson: row.salesPerson || row.addedBy,
    employeeNo: row.employeeNo || '',
    location: row.location || '',
    currentStatus: row.currentStatus || '',
    domain: row.domain || '',
    industry: row.industry || '',
    RPO_Staffing: row.RPO_Staffing || '',
    status: row.status || '',
    amountType: row.amountType || '',
    contractDate: dayjs(row.contractDate) || '',
    billingDate: dayjs(row.billingDate) || '',
    amountValue: row.amountValue || '',
    clientSource: row.clientSource || '',
    contractUrl: row.contractUrl || '',
    direct: row.direct || '',
    createdAt: '',
    companyID: row.clientCompanyId || '',
    ClientName: row.clientName || row.companyName,
    subPlan: row.subPlan || 'month',
    country: row.country || 'USA',
  });

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { md: '50%', xs: '95%' },
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 3,
  };
  const getCity = async (val) => {
    setCityAndZip([]);
    const token = localStorage.getItem('token');
    const body = {
      country: formValues.country,
      query: val,
    };
    Api(`resume/get_city_states`, 'POST', body, token)
      .then((response) => {
        if (response.status === 200) {
          console.log('=========status 200======>>>>>>>', response);
          if (response.data.code === 200) {
            setCityAndZip(response.data.data);
            // setCityandstate(response.data.data[0]);
            console.log('=======city and State', response.data.data[0].zip);
          } else {
            // setCityAndZip(response.data.data);
            // toast.error(response.data.message, +'!');
          }
        }
      })
      .catch((err) => {
        console.log('===========', err);
        // toast.error(err, +'!');
      });
  };
  const childFuc = (e) => {
    console.log('e', e);
    setCityy(e);
    // setValue({ ...value, location: e });
    if (e.length >= 3) getCity(e);
  };
  const fullVal = (val) => {
    setCityy(`${val.city} ${val.stateCode} ${val.zip}`);
    // setCityy(`${val.city} (${val.state}) (${val.zip})`);
  };
  const UpdateClient = () => {
    const token = localStorage.getItem('token');
    const body = {
      clientId: row._id,
      companyID: formValues.companyID,
      email: formValues.email,
      startDate: startDatee,
      linkedinUrl: formValues.linkedinUrl,
      contactPersonName: formValues.mainContactName,
      websiteUrl: formValues.websiteUrl,
      domain: formValues.domain,
      contactNoWithISD: formValues.phone,
      RPO_Staffing: formValues.RPO_Staffing,
      status: formValues.status,
      amountType: formValues.amountType,
      contractDate: formValues.contractDate,
      amountValue: formValues.amountValue,
      clientSource: formValues.clientSource,
      contractUrl: formValues.contractUrl,
      address: formValues.completeAddress,
      salesPerson: formValues.salesPerson,
      employeeNo: formValues.employeeNo,
      location: cityy,
      country: formValues.country,
      industry: formValues.industry,
      currentStatus: '',
      companyName: formValues.ClientName,
      clientName: formValues.ClientName,
      subPlan: formValues.amountType === 'Subscription' ? formValues.subPlan : '',
      billingDate: formValues.amountType === 'Subscription' ? formValues.billingDate : '',
    };
    Api('company/edit_converted_client', 'POST', body, token).then((Response) => {
      console.log(Response);
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          hitClient();
          editClose();
          toast.success(Response.data.message);
        } else {
          toast.warn(Response.data.error);
        }
      } else {
        toast.warn(Response.data.error, +'!');
      }
    });
  };
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      ['blockquote', 'code-block'],
      [{ header: 1 }, { header: 2 }, 'blockquote', 'code-block'], // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
      [{ indent: '-1' }, { indent: '+1' }, { indent: '-1' }, { indent: '+1' }], // outdent/indent
      [{ direction: 'rtl' }], // text direction
      [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],
      ['link', 'image', 'video', 'formula'],
      ['clean'],
    ],
  };

  const pdfUpload = async (file) => {
    setUploadLoader(true);
    setDocfile(file.name);
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('document', file);
    formData.append('directoryName', 'contract');
    UploadFile(`resume/upload_resume`, formData, token).then((Response) => {
      console.log('=====upload Response========>', Response.data);
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          setUploadLoader(false);
          toast.success(Response.data.status, +'!');
          setFormValues({ ...formValues, contractUrl: Response.data.filePath });
        } else {
          console.log('=====404=gst===', Response.data.message);
          toast.warn(Response.data.status, +'!');
        }
      } else {
        toast.warn(Response.data.message, +'!');
      }
    });
  };
  const getContract = async () => {
    const token = localStorage.getItem('token');
    Api(`company/contracts_by_candidate/${row._id}`, 'GET', '', token).then((Response) => {
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          // toast.success(Response.data.message);
          setResumeArry(Response.data.data);
        }
      } else {
        toast.warn(Response.data.error, +'!');
      }
    });
  };
  const deleteContract = async (cId) => {
    const token = localStorage.getItem('token');
    const body = {
      clientId: row._id,
      contractUrlId: cId,
    };
    Api(`company/del_contract`, 'POST', body, token).then((Response) => {
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          getContract();
          // toast.success(Response.data.message);
          // setResumeArry(Response.data.data);
        }
      } else {
        toast.warn(Response.data.error, +'!');
      }
    });
  };
  const getAllUser = async () => {
    const token = localStorage.getItem('token');
    Api('company/all_users', 'GET', '', token).then((Response) => {
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          // toast.success(Response.data.message);
          setAllUsers(Response.data.data);
        }
      } else {
        toast.warn(Response.data.error, +'!');
      }
    });
  };
  const totalJobs = (val) => {
    const token = localStorage.getItem('token');
    Api(`company/jobsforclient/${row._id}`, 'GET', '', token).then((Response) => {
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          if (Response.data.totalJobs.length > 0) {
            setTotalData(Response.data.totalJobs);
          }
          // toast.success(Response.data.message);
        }
      } else {
        toast.warn(Response.data.error, +'!');
      }
    });
  };
  const openJobs = (val) => {
    const token = localStorage.getItem('token');
    Api(`company/jobsforclient/${row._id}`, 'GET', '', token).then((Response) => {
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          if (Response.data.totalJobs.length > 0) {
            setOpenData(Response.data.openJobs);
          }
          // toast.success(Response.data.message);
        }
      } else {
        toast.warn(Response.data.error, +'!');
      }
    });
  };

  const changeJob = () => {
    const token = localStorage.getItem('token');
    const jobIdd = localStorage.getItem('tempJobId');
    const body = {
      clientId: row._id,
      jobId: jobIdd,
      status: statuss,
    };
    Api(`company/update_job_status`, 'POST', body, token).then((Response) => {
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          if (openT === true) {
            totalJobs();
          } else {
            openJobs();
          }
          jobStatusCloses();
          closeJob();
          closeTotalJob();
          hitClient();
          // toast.success(Response.data.message);
          // setTempData(Response.data.data);
        }
      } else {
        toast.warn(Response.data.error, +'!');
      }
    });
  };
  const statusJobs = (val) => {
    const token = localStorage.getItem('token');
    const body = {
      clientId: row._id,
      status: val,
    };
    Api(`company/actions/client`, 'POST', body, token).then((Response) => {
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          // toast.success(Response.data.message);
          setTempData(Response.data.data);
        }
      } else {
        toast.warn(Response.data.error, +'!');
      }
    });
  };

  const changeClientStatus = (val) => {
    const token = localStorage.getItem('token');
    const body = {
      clientId: row._id,
      status: statuss,
      note: statusNote,
      date: statusDate,
    };

    Api(`company/update_client_status`, 'POST', body, token).then((Response) => {
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          hitClient(statuss);
          // toast.success(Response.data.message);
          // setTempData(Response.data.data);
        }
        setOpenstatus(false);
      } else {
        toast.warn(Response.data.error, +'!');
      }
    });
  };
  const handleChange = (event) => {
    console.log('event', event.target.value);
    setPersonName(event.target.value);
    setSelectUser([...selectUser, roleUsers[event.target.value]]);
    setSelectUserId([...selectUserId, roleUsers[event.target.value]._id]);
    userUnderRole(roleUsers[event.target.value]._id);
    // const {target: { value }, } = event.target.value[0].contactPersonName;
    // setPersonName(
    //   // On autofill we get a stringified value.
    //   typeof value === 'string' ? value.split(',') : value,
    // );
  };
  const handleRole = (val) => {
    setPersonName([]);
    const token = localStorage.getItem('token');
    Api(`company/userbyrole/${val}`, 'GET', '', token).then((Response) => {
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          if (Response.data.data.length) {
            setRoleUsers(Response.data.data);
          } else {
            toast.warn(Response.data.message);
          }
        }
      } else {
        toast.error(Response.data.error, +'!');
      }
    });
  };
  const userUnderRole = (val) => {
    const token = localStorage.getItem('token');
    Api(`company/underusers/${val}`, 'GET', '', token).then((Response) => {
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          if (Response.data.data.length) {
            setUnderRole([...underRole, Response.data.data]);
          } else {
            toast.warn(Response.data.message);
          }
        }
      } else {
        toast.error(Response.data.error, +'!');
      }
    });
  };
  const handleRoleUser = (index) => {
    selectUser.splice(index, 1);
    setSelectUser([...selectUser]);
    selectUserId.splice(index, 1);
    setSelectUserId([...selectUserId]);
    console.log('selectUserId', selectUserId);
  };
  const asignData = (val) => {
    if (val.target.checked) {
      setAssignee([...assignee, val.target.value]);
    } else {
      assignee.forEach((item, index) => {
        if (item === val.target.value) {
          assignee.splice(index, 1);
          setAssignee(assignee);
        }
      });
    }
  };
  const unAsignData = (val) => {
    if (val.target.checked) {
      setUnAssignee([...unAssignee, val.target.value]);
    } else {
      unAssignee.forEach((item, index) => {
        if (item === val.target.value) {
          unAssignee.splice(index, 1);
          setUnAssignee(unAssignee);
        }
      });
    }
  };
  const AllAsignData = (val) => {
    const tempData = [];

    setAllAssign(true);
    allUsers.forEach((item) => {
      tempData.push(item._id);
      console.log('all-', item._id);
    });
    setAssignee(tempData);
  };

  const assignClient = async () => {
    const token = localStorage.getItem('token');
    const body = {
      clientId: row._id,
      clientAssignedTo: member === 'team' ? selectUserId : assignee,
      assignType: member === 'team' ? 'team' : 'individual',
    };
    Api('company/assign_client', 'POST', body, token).then((Response) => {
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          assCloses();
          toast.success(Response.data.message);
          // setActionData(Response.data.data);
        }
      } else {
        toast.warn(Response.data.error, +'!');
      }
    });
  };
  const unAssignClient = async () => {
    const token = localStorage.getItem('token');
    const body = {
      clientId: row._id,
      usersToUnassign: unAssignee,
    };
    Api('company/remove_client', 'POST', body, token).then((Response) => {
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          setEditAssign(false);
          closeCList();
          toast.success(Response.data.message);
          // setActionData(Response.data.data);
        }
      } else {
        toast.warn(Response.data.error, +'!');
      }
    });
  };

  const clientUser = async () => {
    openCList();
    const token = localStorage.getItem('token');
    Api(`company/users_for_client/${row._id}`, 'GET', '', token).then((Response) => {
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          if (Response.data.data.length) {
            setClientUserList(Response.data.data);
          } else {
            toast.warn(Response.data.message);
          }
        }
      } else {
        toast.error(Response.data.error, +'!');
      }
    });
  };

  return (
    <>
      <TableRow
        key={row._id}
        sx={[
          { background: index % 2 === 0 ? 'text.background' : tablebg },
          { '&:last-child td, &:last-child th': { border: 0 } },
          { '&:hover': { boxShadow: 5 } },
        ]}
      >
        <TableCell sx={{}}>
          <Typography>Started {moment(row.startDate).format('ll')}</Typography>
          <Typography>Since {moment().diff(row.createdAt, 'months')} months</Typography>
          <Stack sx={{ flexDirection: 'row', gap: 1 }}>
            {role === 'admin' || role === 'salesHead' || role === "salesManager" || role === "salesTeamLead"|| role === "salesExecutive"|| role ===  "salesTrainee" || role === 'recruiterHead' ||role === 'recruiterManager' ? (
              <Typography sx={{ color: 'text.secondary', cursor: 'pointer' }} onClick={() => editOpen()}>
                Edit
              </Typography>
            ) : (
              <Typography>&nbsp;</Typography>
            )}
            <Stack sx={{ flexDirection: 'row' }} onClick={() => openActivity()}>
              <Iconify icon="tabler:activity" fontSize={20} color={'text.secondary'} />
              <Typography sx={{ color: 'text.secondary', cursor: 'pointer' }}>
                {' +'}
                {row.notes?.length}
              </Typography>
            </Stack>
          </Stack>
        </TableCell>

        <TableCell sx={{}}>
          <Stack flexDirection={'row'} alignItems={'center'}>
            <Typography
              sx={{
                fontSize: '0.90rem',
                color: 'text.secondary',
                fontWeight: '600',
                cursor: 'pointer',
                maxWidth: 160,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              onClick={() => navigate('/clientLeads', { state: row })}
            >
              {row.companyName || '-'}
            </Typography>
            <Iconify
              icon="mdi:web"
              color={'text.secondary'}
              sx={{ m: 0.3, height: 15, width: 15, cursor: 'pointer' }}
              onClick={() => window.open(row.websiteUrl, '_blank', 'noopener', 'noreferrer')}
            />
          </Stack>
          <Typography
            onClick={() => navigate('/clientDetail', { state: { row } })}
            sx={{ cursor: 'pointer', color: 'text.secondary' }}
          >
            {' '}
            Details{' '}
          </Typography>
          <Typography sx={{ color: 'text.textG', width: 160, overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {row.location || '-'}
          </Typography>
        </TableCell>

        <TableCell sx={{}}>
          <Stack flexDirection={'row'} alignItems={'center'}>
            <Typography
              sx={{
                fontWeight: 600,
                color: 'text.secondary',
                maxWidth: 160,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {row.contactPersonName || '-'}
            </Typography>
            <Iconify
              icon="devicon:linkedin"
              color={'text.textG'}
              sx={{ m: 0.3, height: 15, width: 15, cursor: 'pointer' }}
              onClick={() => window.open(row.linkedinUrl, '_blank')}
            />
          </Stack>
          <Typography sx={{ textTransform: 'none', maxWidth: 160, overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {row.email || '-'}
          </Typography>
          <Typography sx={{ color: 'text.textG' }}>{row.contactNoWithISD || '-'}</Typography>
        </TableCell>

        <TableCell sx={{}}>
          <Stack flexDirection={'row'} gap={1} alignItems={'center'}>
            <Typography sx={{ fontWeight: 600, color: 'text.secondary' }}>{row.clientCompanyId}</Typography>
          </Stack>
          <Typography>{row.RPO_Staffing || '-'}</Typography>
          <Typography sx={{ color: 'text.textG', maxWidth: 120, overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {row.domain || '-'}
          </Typography>
        </TableCell>

        <TableCell sx={{}}>
          <Typography sx={{ fontWeight: '600', cursor: 'pointer' }} onClick={openTotalJob}>
            Total Jobs : {row.totalJobs}
          </Typography>
          <Typography sx={{ color: '#4BB03B', fontWeight: '600', cursor: 'pointer' }} onClick={openJob}>
            Open Jobs : {row.openJobs}
          </Typography>
          <Typography
            sx={{ color: 'text.secondary', fontWeight: '600', cursor: 'pointer' }}
            onClick={() =>
              navigate('/addjob', {
                state: {
                  rowData: {
                    companyName: row.companyName,
                    client: row.contactPersonName,
                    _id: row._id,
                    email: row.email,
                    web: row.websiteUrl,
                  },
                },
              })
            }
          >
            Add Job
          </Typography>
        </TableCell>

        <TableCell sx={{ color: 'text.secondary', fontWeight: '600', cursor: 'pointer' }}>
          <Typography sx={{ fontWeight: '600', cursor: 'pointer' }} onClick={openSub}>
            Submissions : {row.submittedCount}
          </Typography>
          <Typography sx={{ color: '#AE8D18', fontWeight: '600', cursor: 'pointer' }} onClick={openInterview}>
            Interviews : {row.interviewCount}
          </Typography>
          <Typography sx={{ color: '#399648', fontWeight: '600', cursor: 'pointer' }} onClick={openPlacement}>
            Placements : {row.placementCount}
          </Typography>
        </TableCell>

        <TableCell sx={{}}>
          <Stack sx={{ flexDirection: 'row' }}>
            <Typography sx={{}}>{row.status || 'Status'}</Typography>
            {role === 'admin' || role === 'salesHead' || role === 'recruiterHead'|| role === 'recruiterManager' ? (
              <Iconify
                icon="bx:edit"
                // color={'text.textB'}
                sx={{ height: 15, width: 15, cursor: 'pointer' }}
                onClick={() => statusOpen()}
              />
            ) : null}
          </Stack>
          <Stack sx={{ flexDirection: 'row', cursor: 'pointer' }}>
            <Typography
              sx={{ color: '#4671A1' }}
              onClick={() => {
                clientUser();
              }}
            >
              Assignees
            </Typography>
            {role === 'admin' || role === 'salesHead' || role === 'recruiterHead' || role === 'recruiterManager' ? (
              <Iconify
                icon="bx:edit"
                // color={'text.textB'}
                sx={{ height: 15, width: 15 }}
                onClick={() => {
                  getAllUser();
                  assOpen();
                }}
              />
            ) : null}
          </Stack>
          {/* <Typography onClick={openActivity} sx={{color:'text.secondary',cursor: 'pointer'}}>{row.designation}</Typography> */}
          <Typography sx={{ color: 'text.textG' }}>{row.salesPerson || row.addedBy || '-'}</Typography>
        </TableCell>

        <TableCell sx={{}}>
          <Typography sx={{ color: 'text.secondary' }}>Invoice</Typography>
          <Typography>{row.amountType || '-'}</Typography>
          {row.amountType === 'Percentage Basis' ? (
            <Typography sx={{ color: 'text.textG' }}>{row.amountValue || '-'} %</Typography>
          ) : row.amountType === 'Subscription' ? (
            <Typography sx={{ color: 'text.textG' }}>
              ${row.amountValue || '-'}/{row.subPlan}
            </Typography>
          ) : row.amountType === 'Margin Basis' ? (
            <Typography sx={{ color: 'text.textG' }}>${row.amountValue || '-'} P/H</Typography>
          ) : (
            <Typography sx={{ color: 'text.textG' }}>${row.amountValue || '-'} K</Typography>
          )}
        </TableCell>
      </TableRow>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h4" textAlign={'center'} color={'text.secondary'}>
            Email Box
          </Typography>
          <Scrollbar sx={{ height: { sm: 500, xs: 500 } }}>
            <Grid display={'grid'} gap={2}>
              <TextField
                id="outlined-basic"
                label="Subject"
                placeholder="Subject"
                variant="outlined"
                value={email?.Subject}
                onChange={(e) => setEmail({ ...email, Subject: e.target.value })}
              />
              <ReactQuill
                modules={modules}
                theme="snow"
                value={smsValue}
                onChange={(e) => setEditorSmsValue(e)}
                style={{ height: '80%', display: 'block' }}
              />
            </Grid>
          </Scrollbar>
          <Button onClick={() => console.log(email)}>test</Button>
        </Box>
      </Modal>
      {/* <Modal open={openD} onClose={closeDetail} style={{ borderRadius: 10 }}>
        <Box sx={[styleClients,{height:'90%'}]}>
          <Typography variant="h6" sx={{ textAlign: 'center', color: 'text.secondary' }}>
            Detail
          </Typography>
          <Scrollbar sx={{ maxHeight: 450, Scrollbar: 'thin' }}>
            <Divider />
            <Stack sx={{ p: 2, py: 4, mx: 4, flexDirection: 'row' }}>
              <Stack sx={{ p: 1, width: '50%' }}>
                <iframe title="resume" src={suru +contractUrl+ lll} width="100%" height="650px" />
              </Stack>
              <Stack sx={{ p: 1, width: '50%' }}>
              <Stack sx={{ flexDirection: 'row', gap: 2, mb: 2, cursor: 'pointer' }}>
            <Stack
              sx={[
                { flexDirection: 'row' },
                tabs === 'Agreement' ? { borderBottom: 2, color: 'text.secondary' } : { color: 'text.textG' },
              ]}
              onClick={() => {
                setTabs('Agreement');
              }}
            >
              <Iconify icon="bi:filetype-pdf" sx={{ mb: 0.5 }} fontSize={20} />
              <Typography  sx={{ ml: 0.5, mb: 0.5,fontSize:'0.9rem',fontWeight:'600' }}>
                Agreement
              </Typography>
            </Stack>
            <Stack
              sx={[
                { flexDirection: 'row' },
                tabs === 'Detail' ? { borderBottom: 2, color: 'text.secondary' } : { color: 'text.textG' },
              ]}
              onClick={() => {
                setTabs('Detail');
              }}
            >
              <Iconify icon="mingcute:user-4-fill" sx={{ mb: 0.5 }} fontSize={20} />
              <Typography  sx={{ ml: 0.5, mb: 0.5,fontSize:'0.9rem',fontWeight:'600' }} onClick={()=> navigate('/clientDetail', { state: { row } })}>
                Detail
              </Typography>
            </Stack>
            </Stack>
                 {tabs === 'Agreement' ? (
                  <Stack sx={{ backgroundColor: 'text.background', borderRadius: 2, boxShadow: 3, p: 2, gap: 2 }}>
                    <Stack sx={{ flexDirection: 'row', gap: 1 }}>
                      <Stack>
                        <Button
                          type="file"
                          variant="contained"
                          size="small"
                          color="custom"
                          sx={{ width: 'fit-content' }}
                          // onClick={(e) => updateNote()}
                        >
                          Add New Agreement
                        </Button>
                        <input
                          style={{ opacity: 0, position: 'absolute', width: 120 }}
                          type="file"
                          accept=".doc,.docx, application/pdf"
                          onChange={(e) => {
                            pdfUpload(e.target.files[0]);
                          }}
                        />
                      </Stack>
                      {upload && <Typography sx={{ color: 'text.secondary', p: 0.5 }}>Success</Typography>}
                    </Stack>
                    <Scrollbar sx={{ maxHeight: 300, Scrollbar: 'thin' }}>
                      {resumeArry.length ? resumeArry.map((item,index)=>{
                       return <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                      <Stack sx={{flexDirection:'row'}}> 
                      <Typography
                        color={'text.secondary'}
                        sx={{ textDecoration: 'underline',cursor: 'pointer'  }}
                        onClick={() => setContractUrl(item.contractUrl)}
                      >
                        Agreement #{index+1}
                      </Typography>
                      <Iconify
                        icon="mdi:open-in-new"
                        fontSize={20}
                        sx={{ cursor: 'pointer',ml:3 }}
                        onClick={() => window.open(suru+item.contractUrl+lll, '_blank')}
                        color={'text.secondary'}
                      />
                      </Stack>
                      <Typography sx={{}}>Uploaded on : {moment(item.createdAt).format('ll')}</Typography>
                      
                      <Stack sx={{flexDirection:'row'}}>
                      <Typography color={'text.secondary'} sx={{ cursor: 'pointer' }} onClick={()=>deleteContract(item._id)}>
                        Delete
                      </Typography>
                      <Typography
                        color={'text.secondary'}
                        sx={{ cursor: 'pointer',ml:3}}
                        onClick={() => window.open(item.contractUrl, '_blank')}
                      >
                        Download
                      </Typography>
                      </Stack>
                    </Stack>
                    }):null}
                    </Scrollbar>
                    <TextField
                  sx={{ mt: 2 }}
                  id="outlined-multiline-static"
                  label="Note... (Max 2000 char)"
                  placeholder="Note..."
                  multiline
                  rows={5}
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                />
                <Button
                  variant="contained"
                  size="small"
                  color="custom"
                  sx={{ width: 'fit-content', height: 30, mt: 2 }}
                  //   onClick={() => openScreening()}
                >
                  Save
                </Button>
                  </Stack>
                ) : null}
              
              </Stack>
            </Stack>
          </Scrollbar>
        </Box>
      </Modal> */}
      <Modal open={openA} onClose={closeActivity} style={{ borderRadius: 10 }}>
        <Box sx={styleClients}>
          <Typography variant="h5" color={'#3C6BA5'} textAlign={'center'}>
            Activities
          </Typography>
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
            <Divider />
            <Stack sx={{ alignItems: 'center' }}>
              <Stack
                sx={{ backgroundColor: 'text.secondary', width: '100%', alignItems: 'center', p: 0.5, borderRadius: 1 }}
              >
                <Stack sx={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Typography sx={{ width: '10%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Date
                  </Typography>
                  <Typography sx={{ width: '20%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    User Name
                  </Typography>
                  <Typography sx={{ width: '15%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Status
                  </Typography>
                  <Typography sx={{ width: '50%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Note
                  </Typography>
                </Stack>
              </Stack>
              {row.notes?.length
                ? row.notes
                    .slice(0)
                    .reverse()
                    .map((item, index) => {
                      return (
                        <Stack
                          sx={[
                            { background: index % 2 === 0 ? 'text.background' : tablebg },
                            { width: '100%', flexDirection: 'row', justifyContent: 'space-between', p: 1 },
                          ]}
                        >
                          <Typography sx={{ width: '10%' }}>{moment(row.date).format('ll')}</Typography>
                          <Typography
                            sx={{
                              width: '20%',
                              textTransform: 'capitalize',
                              fontWeight: '600',
                              color: 'text.secondary',
                            }}
                          >
                            {item.noteBy}
                          </Typography>
                          <Typography sx={{ width: '15%', textTransform: 'capitalize' }}>
                            Marked {item.status}
                          </Typography>
                          <Typography sx={{ width: '50%', textTransform: 'capitalize' }}>{item.note}</Typography>
                        </Stack>
                      );
                    })
                : null}
            </Stack>
          </Scrollbar>
        </Box>
      </Modal>

      <Modal open={openC} onClose={closeCandidateDetails} style={{ borderRadius: 10 }}>
        <Box sx={styleClients}>
          {/* <Typography variant="h5" color={'#3C6BA5'}>
            Client's Leads
          </Typography> */}

          <Stack flexDirection={'row'} justifyContent={'space-between'}>
            <Typography variant="h5" color={'text.secondary'}>
              Client's Leads
            </Typography>
            <Button variant="contained">Add Lead</Button>
          </Stack>

          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
            <Divider />
            <Stack sx={{ alignItems: 'center' }}>
              <Stack
                sx={{ backgroundColor: 'text.secondary', width: '100%', alignItems: 'center', p: 0.5, borderRadius: 1 }}
              >
                <Stack sx={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Typography sx={{ color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>Date</Typography>
                  <Typography sx={{ color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>Name</Typography>
                  <Typography sx={{ color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>Designation</Typography>
                  <Typography sx={{ color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>Phone</Typography>
                  <Typography sx={{ color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>Email</Typography>
                  <Typography sx={{ color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>Jobs</Typography>
                  <Typography sx={{ color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>Open Jobs</Typography>
                  <Typography sx={{ color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>Placements</Typography>
                  <Typography sx={{ color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>Status</Typography>
                </Stack>
              </Stack>
            </Stack>
          </Scrollbar>
        </Box>
      </Modal>

      <Modal open={openT} onClose={closeTotalJob} style={{ borderRadius: 10 }}>
        <Box sx={[styleClients, { width: { xs: '60%', sm: '90%' } }]}>
          <Typography variant="h5" color={'#3C6BA5'} textAlign={'center'}>
            Total Jobs
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
            <Stack sx={{ alignItems: 'center' }}>
              <Stack
                sx={{ backgroundColor: 'text.secondary', width: '100%', alignItems: 'center', p: 0.5, borderRadius: 1 }}
              >
                <Stack sx={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Typography sx={{ color: 'white', fontSize: '0.976rem', fontWeight: '600', width: '10%' }}>
                    Date
                  </Typography>
                  <Typography sx={{ color: 'white', fontSize: '0.976rem', fontWeight: '600', width: '15%' }}>
                    Role
                  </Typography>
                  <Typography sx={{ color: 'white', fontSize: '0.976rem', fontWeight: '600', width: '15%' }}>
                    Location
                  </Typography>
                  <Typography sx={{ color: 'white', fontSize: '0.976rem', fontWeight: '600', width: '10%' }}>
                    Job Type
                  </Typography>
                  <Typography sx={{ color: 'white', fontSize: '0.976rem', fontWeight: '600', width: '10%' }}>
                    Submission
                  </Typography>
                  <Typography sx={{ color: 'white', fontSize: '0.976rem', fontWeight: '600', width: '10%' }}>
                    Interview
                  </Typography>
                  <Typography sx={{ color: 'white', fontSize: '0.976rem', fontWeight: '600', width: '10%' }}>
                    Placement
                  </Typography>
                  <Typography sx={{ color: 'white', fontSize: '0.976rem', fontWeight: '600', width: '10%' }}>
                    Lead
                  </Typography>
                  <Typography sx={{ color: 'white', fontSize: '0.976rem', fontWeight: '600', width: '10%' }}>
                    Status
                  </Typography>
                </Stack>
              </Stack>
              {totalData.length
                ? totalData.map((item, index) => {
                    return (
                      <Stack
                        sx={[
                          { background: index % 2 === 0 ? 'text.background' : tablebg },
                          { width: '100%', p: 0.5, flexDirection: 'row', justifyContent: 'space-between' },
                        ]}
                      >
                        <Typography sx={{ width: '10%' }}>{moment(item.createdAT).format('ll')}</Typography>
                        <Typography
                          sx={{
                            color: 'text.secondary',
                            width: '15%',
                            fontSize: '0.90rem',
                            fontWeight: '600',
                            overflow: 'auto',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {item.jobTitle || '-'}
                        </Typography>
                        <Typography sx={{ width: '15%', overflow: 'auto', whiteSpace: 'nowrap' }}>
                          {item.location || '-'}
                        </Typography>
                        <Typography sx={{ width: '10%', textTransform: 'capitalize' }}>
                          {item.jobType || '-'}
                        </Typography>
                        <Typography sx={{ width: '10%' }}>{item.submittedCount}</Typography>
                        <Typography sx={{ width: '10%' }}>{item.interviewCount}</Typography>
                        <Typography sx={{ width: '10%' }}>{item.placementCount}</Typography>
                        <Typography sx={{ width: '10%', overflow: 'auto', whiteSpace: 'nowrap' }}>
                          {item.lead || '-'}
                        </Typography>
                        <Stack sx={{ width: '10%', flexDirection: 'row' }}>
                          <Typography>{item.status || '-'}</Typography>
                          <Iconify
                            icon="bx:edit"
                            // color={'text.textB'}
                            sx={{ height: 18, width: 18, ml: 1 }}
                            onClick={() => {
                              localStorage.setItem('tempJobId', item._id);
                              jobStatusOpen();
                            }}
                          />
                        </Stack>
                      </Stack>
                    );
                  })
                : null}
            </Stack>
          </Scrollbar>
        </Box>
      </Modal>

      <Modal open={openJ} onClose={closeJob} style={{ borderRadius: 10 }}>
        <Box sx={[styleClients, { width: { xs: '60%', sm: '95%' } }]}>
          <Typography variant="h5" color={'#3C6BA5'} textAlign={'center'}>
            Open Jobs
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
            <Stack sx={{ alignItems: 'center' }}>
              <Stack
                sx={{ backgroundColor: 'text.secondary', width: '100%', alignItems: 'center', p: 0.5, borderRadius: 1 }}
              >
                <Stack sx={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Typography sx={{ color: 'white', fontSize: '0.976rem', fontWeight: '600', width: '8%' }}>
                    Date
                  </Typography>
                  <Typography sx={{ color: 'white', fontSize: '0.976rem', fontWeight: '600', width: '15%' }}>
                    Role
                  </Typography>
                  <Typography sx={{ color: 'white', fontSize: '0.976rem', fontWeight: '600', width: '15%' }}>
                    Location
                  </Typography>
                  <Typography sx={{ color: 'white', fontSize: '0.976rem', fontWeight: '600', width: '10%' }}>
                    Job Type
                  </Typography>
                  <Typography sx={{ color: 'white', fontSize: '0.976rem', fontWeight: '600', width: '10%' }}>
                    Submission
                  </Typography>
                  <Typography sx={{ color: 'white', fontSize: '0.976rem', fontWeight: '600', width: '10%' }}>
                    Interview
                  </Typography>
                  <Typography sx={{ color: 'white', fontSize: '0.976rem', fontWeight: '600', width: '10%' }}>
                    Placement
                  </Typography>
                  <Typography sx={{ color: 'white', fontSize: '0.976rem', fontWeight: '600', width: '10%' }}>
                    Lead
                  </Typography>
                  <Typography sx={{ color: 'white', fontSize: '0.976rem', fontWeight: '600', width: '10%' }}>
                    Status
                  </Typography>
                </Stack>
              </Stack>
              {openData.length
                ? openData.map((item, index) => {
                    return (
                      <Stack
                        sx={[
                          { background: index % 2 === 0 ? 'text.background' : tablebg },
                          { width: '100%', p: 0.5, flexDirection: 'row', justifyContent: 'space-between' },
                        ]}
                      >
                        <Typography sx={{ width: '8%' }}>{moment(item.createdAT).format('ll')}</Typography>
                        <Typography
                          sx={{
                            color: 'text.secondary',
                            width: '15%',
                            overflow: 'auto',
                            whiteSpace: 'nowrap',
                            fontWeight: '600',
                            fontSize: '0.90rem',
                          }}
                        >
                          {item.jobTitle || '-'}
                        </Typography>
                        <Typography sx={{ width: '15%', overflow: 'auto', whiteSpace: 'nowrap' }}>
                          {item.location || '-'}
                        </Typography>
                        <Typography sx={{ width: '10%', textTransform: 'capitalize' }}>
                          {item.jobType || '-'}
                        </Typography>
                        <Typography sx={{ width: '10%' }}>{item.submittedCount}</Typography>
                        <Typography sx={{ width: '10%' }}>{item.interviewCount}</Typography>
                        <Typography sx={{ width: '10%' }}>{item.placementCount}</Typography>
                        <Typography sx={{ width: '10%', overflow: 'auto', whiteSpace: 'nowrap' }}>
                          {item.lead || '-'}
                        </Typography>
                        <Stack sx={{ width: '10%', flexDirection: 'row' }}>
                          <Typography>{item.status || '-'}</Typography>
                          <Iconify
                            icon="bx:edit"
                            // color={'text.textB'}
                            sx={{ height: 18, width: 18, ml: 1 }}
                            onClick={() => {
                              localStorage.setItem('tempJobId', item._id);
                              jobStatusOpen();
                            }}
                          />
                        </Stack>
                      </Stack>
                    );
                  })
                : null}
            </Stack>
          </Scrollbar>
        </Box>
      </Modal>

      <Modal open={openS} onClose={closeSubmission} style={{ borderRadius: 10 }}>
        <Box sx={[styleClients, { width: { xs: '60%', sm: '95%' } }]}>
          <Typography variant="h5" color={'#3C6BA5'} textAlign={'center'}>
            Submissions
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
            <Stack sx={{ alignItems: 'center' }}>
              <Stack
                sx={{ backgroundColor: 'text.secondary', width: '100%', alignItems: 'center', p: 0.5, borderRadius: 1 }}
              >
                <Stack sx={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Typography sx={{ color: 'white', fontSize: '0.976rem', fontWeight: '600', width: '8%' }}>
                    Date
                  </Typography>
                  <Typography sx={{ color: 'white', fontSize: '0.976rem', fontWeight: '600', width: '15%' }}>
                    Role
                  </Typography>
                  <Typography sx={{ color: 'white', fontSize: '0.976rem', fontWeight: '600', width: '15%' }}>
                    Name
                  </Typography>
                  <Typography sx={{ color: 'white', fontSize: '0.976rem', fontWeight: '600', width: '15%' }}>
                    Email
                  </Typography>
                  <Typography sx={{ color: 'white', fontSize: '0.976rem', fontWeight: '600', width: '10%' }}>
                    Compensation
                  </Typography>
                  <Typography sx={{ color: 'white', fontSize: '0.976rem', fontWeight: '600', width: '10%' }}>
                    Tax Term
                  </Typography>
                  <Typography sx={{ color: 'white', fontSize: '0.976rem', fontWeight: '600', width: '10%' }}>
                    Status
                  </Typography>
                  <Typography sx={{ color: 'white', fontSize: '0.976rem', fontWeight: '600', width: '10%' }}>
                    Presented By
                  </Typography>
                </Stack>
              </Stack>
              {tempData.length
                ? tempData.map((item, index) => {
                    return (
                      <Stack
                        sx={[
                          { background: index % 2 === 0 ? 'text.background' : tablebg },
                          { width: '100%', p: 0.5, flexDirection: 'row', justifyContent: 'space-between' },
                        ]}
                      >
                        <Typography sx={{ width: '8%' }}>
                          {moment(item.submissionData.updatedAt).format('ll')}
                        </Typography>
                        <Typography
                          sx={{
                            color: 'text.secondary',
                            fontSize: '0.90rem',
                            fontWeight: '600',
                            width: '15%',
                            overflow: 'auto',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {item.submissionData.type === 'position'
                            ? item.submissionData.jobIdTitle
                            : item.submissionData.positionName}
                        </Typography>
                        <Typography sx={{ width: '15%', overflow: 'auto', whiteSpace: 'nowrap' }}>
                          {item.fullName || '-'}
                        </Typography>
                        <Typography sx={{ width: '15%', overflow: 'auto', whiteSpace: 'nowrap' }}>
                          {item.prospectEmail || '-'}
                        </Typography>
                        <Typography sx={{ width: '10%' }}>
                          $ {item.submissionData.expectedCompensation || '-'}{' '}
                          {item.submissionData.chargeType === 'Annually' ? 'K' : 'P/H'}
                        </Typography>
                        <Typography sx={{ width: '10%' }}>{item.submissionData.taxTerm || '-'}</Typography>
                        <Typography sx={{ width: '10%', textTransform: 'capitalize' }}>
                          {item.submissionData.tempSubmitStatus || 'Submitted'}
                        </Typography>
                        <Typography sx={{ width: '10%', overflow: 'auto', whiteSpace: 'nowrap' }}>
                          {item.presentedBy || '-'}
                        </Typography>
                      </Stack>
                    );
                  })
                : null}
            </Stack>
          </Scrollbar>
        </Box>
      </Modal>
      <Modal open={openP} onClose={closePlacement} style={{ borderRadius: 10 }}>
        <Box sx={[styleClients, { width: { xs: '60%', sm: '99%' } }]}>
          <Typography variant="h5" color={'#3C6BA5'} textAlign={'center'}>
            Placements
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
            <Stack sx={{ alignItems: 'center' }}>
              <Stack
                sx={{ backgroundColor: 'text.secondary', width: '100%', alignItems: 'center', p: 0.5, borderRadius: 1 }}
              >
                <Stack sx={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Typography sx={{ color: 'white', fontSize: '0.976rem', fontWeight: '600', width: '10%' }}>
                    Date
                  </Typography>
                  <Typography sx={{ color: 'white', fontSize: '0.976rem', fontWeight: '600', width: '10%' }}>
                    Role
                  </Typography>
                  <Typography sx={{ color: 'white', fontSize: '0.976rem', fontWeight: '600', width: '10%' }}>
                    Name
                  </Typography>
                  <Typography
                    sx={{
                      color: 'white',
                      fontSize: '0.976rem',
                      fontWeight: '600',
                      width: '10%',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    Employement Type
                  </Typography>
                  <Typography sx={{ color: 'white', fontSize: '0.976rem', fontWeight: '600', width: '10%' }}>
                    Tax Term
                  </Typography>
                  <Typography
                    sx={{
                      color: 'white',
                      fontSize: '0.976rem',
                      fontWeight: '600',
                      width: '10%',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    Compensation
                  </Typography>
                  <Typography sx={{ color: 'white', fontSize: '0.976rem', fontWeight: '600', width: '10%' }}>
                    Start Date
                  </Typography>
                  <Typography sx={{ color: 'white', fontSize: '0.976rem', fontWeight: '600', width: '10%' }}>
                    End Date
                  </Typography>
                  <Typography sx={{ color: 'white', fontSize: '0.976rem', fontWeight: '600', width: '10%' }}>
                    Status
                  </Typography>
                  <Typography sx={{ color: 'white', fontSize: '0.976rem', fontWeight: '600', width: '10%' }}>
                    Presented By
                  </Typography>
                </Stack>
              </Stack>
              {tempData.length
                ? tempData.map((item, index) => {
                    return (
                      <Stack
                        sx={[
                          { background: index % 2 === 0 ? 'text.background' : tablebg },
                          { width: '100%', p: 0.5, flexDirection: 'row', justifyContent: 'space-between' },
                        ]}
                      >
                        <Typography sx={{ width: '10%' }}>
                          {moment(item.submissionData.updatedAt).format('ll')}
                        </Typography>
                        <Typography
                          sx={{
                            color: 'text.secondary',
                            fontSize: '0.90rem',
                            fontWeight: '600',
                            width: '10%',
                            textTransform: 'capitalize',
                            overflow: 'auto',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {item.submissionData.type === 'position'
                            ? item.submissionData.jobIdTitle
                            : item.submissionData.positionName}
                        </Typography>
                        <Typography
                          sx={{ width: '10%', textTransform: 'capitalize', overflow: 'auto', whiteSpace: 'nowrap' }}
                        >
                          {item.fullName || '-'}
                        </Typography>
                        <Typography sx={{ width: '10%', textTransform: 'capitalize' }}>
                          {item.submissionData.offeredJob || '-'}
                        </Typography>
                        <Typography sx={{ width: '10%' }}>{item.submissionData.taxTerm || '-'}</Typography>
                        <Typography sx={{ width: '10%' }}>
                          ${item.submissionData.expectedCompensation}{' '}
                          {item.submissionData.chargeType === 'Annually' ? 'K' : 'P/H'}
                        </Typography>
                        <Typography sx={{ width: '10%' }}>
                          {moment(item.submissionData.tentativeStartDate).format('ll')}
                        </Typography>
                        <Typography sx={{ width: '10%' }}>NA</Typography>
                        <Typography sx={{ width: '10%', textTransform: 'capitalize' }}>
                          {item.submissionData.status || '-'}
                        </Typography>
                        <Typography sx={{ width: '10%', overflow: 'auto', whiteSpace: 'nowrap' }}>
                          {item.presentedBy || '-'}
                        </Typography>
                      </Stack>
                    );
                  })
                : null}
            </Stack>
          </Scrollbar>
        </Box>
      </Modal>

      <Modal open={openI} onClose={closeInterview} style={{ borderRadius: 10 }}>
        <Box sx={[styleClients, { width: { xs: '60%', sm: '95%' } }]}>
          <Typography variant="h5" color={'#3C6BA5'} textAlign={'center'}>
            Interviews
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
            <Stack
              sx={{ backgroundColor: 'text.secondary', width: '100%', alignItems: 'center', p: 0.5, borderRadius: 1 }}
            >
              <Stack sx={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Typography sx={{ color: 'white', fontSize: '0.976rem', fontWeight: '600', width: '8%' }}>
                  Date
                </Typography>
                <Typography sx={{ color: 'white', fontSize: '0.976rem', fontWeight: '600', width: '15%' }}>
                  Role
                </Typography>
                <Typography sx={{ color: 'white', fontSize: '0.976rem', fontWeight: '600', width: '15%' }}>
                  Name
                </Typography>
                <Typography sx={{ color: 'white', fontSize: '0.976rem', fontWeight: '600', width: '11%' }}>
                  Email
                </Typography>
                <Typography sx={{ color: 'white', fontSize: '0.976rem', fontWeight: '600', width: '11%' }}>
                  Mode
                </Typography>
                <Typography sx={{ color: 'white', fontSize: '0.976rem', fontWeight: '600', width: '11%' }}>
                  Location
                </Typography>
                <Typography sx={{ color: 'white', fontSize: '0.976rem', fontWeight: '600', width: '11%' }}>
                  Hiring Manager
                </Typography>
                <Typography sx={{ color: 'white', fontSize: '0.976rem', fontWeight: '600', width: '10%' }}>
                  Status
                </Typography>
                <Typography sx={{ color: 'white', fontSize: '0.976rem', fontWeight: '600', width: '10%' }}>
                  Presented By
                </Typography>
              </Stack>
            </Stack>
            {tempData.length
              ? tempData.map((item, index) => {
                  return (
                    <Stack
                      sx={[
                        { background: index % 2 === 0 ? 'text.background' : tablebg },
                        { p: 0.5, flexDirection: 'row', justifyContent: 'space-between' },
                      ]}
                    >
                      <Typography sx={{ width: '8%' }}>{moment(item.submissionData.updatedAt).format('ll')}</Typography>

                      <Typography
                        sx={{
                          color: 'text.secondary',
                          fontSize: '0.90rem',
                          fontWeight: '600',
                          width: '15%',
                          overflow: 'auto',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {item.submissionData.type === 'position'
                          ? item.submissionData.jobIdTitle
                          : item.submissionData.positionName}
                      </Typography>

                      <Typography sx={{ overflow: 'auto', whiteSpace: 'nowrap', width: '15%' }}>
                        {item.fullName || '-'}
                      </Typography>
                      <Typography sx={{ overflow: 'auto', whiteSpace: 'nowrap', width: '11%' }}>
                        {item.prospectEmail || '-'}
                      </Typography>
                      <Typography sx={{ textTransform: 'capitalize', width: '11%' }}>
                        {item.submissionData.interviewMode || '-'}
                      </Typography>
                      {item.submissionData.interviewMode === 'phoneic' && (
                        <Typography sx={{ width: '11%' }}>{item.submissionData.contactNo || '-'}</Typography>
                      )}
                      {item.submissionData.interviewMode === 'video' && (
                        <Typography
                          sx={{ color: 'text.secondary', width: '11%' }}
                          onClick={() => window.open(item.submissionData.meetingLink, '_blank')}
                        >
                          {item.videoPlatform}
                        </Typography>
                      )}
                      {item.submissionData.interviewMode === 'onsite' && (
                        <Typography sx={{ width: '11%', overflow: 'auto', whiteSpace: 'nowrap' }}>
                          {item.submissionData.companyAddress || '-'}
                        </Typography>
                      )}
                      <Typography sx={{ width: '11%', overflow: 'auto', whiteSpace: 'nowrap' }}>
                        {item.submissionData.hiringManagerName || '-'}
                      </Typography>
                      <Typography sx={{ width: '10%', textTransform: 'capitalize' }}>
                        {item.submissionData.stepWiseStatus || '-'} Interview
                      </Typography>
                      <Typography sx={{ width: '10%', overflow: 'auto', whiteSpace: 'nowrap' }}>
                        {item.presentedBy || '-'}
                      </Typography>
                    </Stack>
                  );
                })
              : null}
          </Scrollbar>
        </Box>
      </Modal>

      <Modal open={openClient} onClose={closeClientD} style={{ borderRadius: 1 }}>
        <Box sx={styleClients}>
          <Typography variant="h5" color={'#3C6BA5'} textAlign={'center'}>
            Details
          </Typography>
          {/* <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
            <Divider />
            <Stack sx={{ alignItems: 'center' }}>
              <Stack sx={{ backgroundColor: 'text.secondary', width: '100%', alignItems: 'center', p: 0.5,borderRadius: 1}}>
                <Stack
                  sx={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}
                >
                  <Typography sx={{  color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Date
                  </Typography>
                  <Typography sx={{  color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Role
                  </Typography>
                  <Typography sx={{  color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Name
                  </Typography>
                  <Typography sx={{  color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Email
                  </Typography>
                  <Typography sx={{  color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Compensation
                  </Typography>
                  <Typography sx={{  color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Tax Term
                  </Typography>
                  <Typography sx={{  color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                   Status
                  </Typography>
                  <Typography sx={{  color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Presented By
                  </Typography>
                  
                </Stack>
              </Stack>
            </Stack>
          </Scrollbar> */}
        </Box>
      </Modal>

      <Modal open={openstatus} onClose={statusCloses} style={{ borderRadius: 10 }}>
        <Box sx={[styleClients, { width: { xs: '60%', sm: '50%' } }]}>
          <Typography variant="h5" color={'#3C6BA5'} textAlign={'center'}>
            Change Status
          </Typography>
          <Divider sx={{ mb: 2 }} />

          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
            <Grid container sx={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} gap={2}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Select Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Select New Status"
                  value={statuss}
                  onChange={(e) => setStatuss(e.target.value)}
                >
                  <MenuItem value={'Active'}>Active</MenuItem>;<MenuItem value={'Inactive'}>Inactive</MenuItem>;
                  <MenuItem value={'On Hold'}>On Hold</MenuItem>;
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer sx={{ mt: -0.8 }} components={['DatePicker', 'DatePicker']}>
                    <DatePicker
                      disablePast
                      label="Date"
                      sx={{ width: '100%' }}
                      value={statusDate}
                      onChange={(newValue) => setStatusDate(newValue)}
                      //   slotProps={{
                      //     textField: {
                      //         readOnly: true,
                      //     },
                      // }}
                      // console.log("uncontrol date",newValue)}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  multiline
                  id="outlined-multiline-static"
                  label="Note"
                  value={statusNote}
                  onChange={(e) => setStatusNote(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
              <Button
                color="custom"
                variant="contained"
                sx={{ width: '10%', mt: 2 }}
                onClick={() => changeClientStatus()}
              >
                Submit
              </Button>
            </Stack>
          </Scrollbar>
        </Box>
      </Modal>

      <Modal open={changeJobstatus} onClose={jobStatusCloses} style={{ borderRadius: 10 }}>
        <Box sx={[styleClients, { width: { xs: '60%', sm: '50%' } }]}>
          <Typography variant="h5" color={'#3C6BA5'} textAlign={'center'}>
            Change Job Status
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
            <Grid
              container
              sx={{ mt: 2, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
              gap={2}
            >
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Select Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Select New Status"
                  value={statuss}
                  onChange={(e) => setStatuss(e.target.value)}
                >
                  <MenuItem value={'Active'}>Active</MenuItem>
                  <MenuItem value={'On Hold'}>On Hold</MenuItem>
                  <MenuItem value={'Closed'}>Closed</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
              <Button color="custom" variant="contained" sx={{ width: '10%', mt: 2 }} onClick={() => changeJob()}>
                Submit
              </Button>
            </Stack>
          </Scrollbar>
        </Box>
      </Modal>

      <Modal open={openAss} onClose={assCloses} style={{ borderRadius: 10 }}>
        <Box sx={[styleClients, { p: 1, width: '60%' }]}>
          <Typography variant="h5" color={'#3C6BA5'} textAlign={'center'}>
            Assign Professionals
          </Typography>
          <Divider sx={{}} />
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin', p: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Select Resources</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select Resources"
                placeholder="Select Resources"
                value={member}
                onChange={(e) => {
                  setMember(e.target.value);
                  if (e.target.value === 'all') {
                    AllAsignData();
                  } else {
                    setAllAssign(false);
                    setAssignee([]);
                  }
                }}
              >
                <MenuItem value={'all'}>All</MenuItem>
                <MenuItem value={'individual'}>Individual</MenuItem>
                <MenuItem value={'team'}>Team</MenuItem>
              </Select>
            </FormControl>
            {member === 'team' && (
              <>
                <FormControl fullWidth sx={{ mt: 1 }}>
                  <InputLabel id="demo-simple-select-label">Select Role</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Select Resources"
                    placeholder="Select Resources"
                    value={tempRole}
                    onChange={(e) => {
                      setTempRole(e.target.value);
                      handleRole(e.target.value);
                    }}
                  >
                    <MenuItem value={'recruiterHead'}>RH</MenuItem>
                    <MenuItem value={'recruiterManager'}>RM</MenuItem>
                    <MenuItem value={'recruiterTeamLead'}>RL</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth sx={{ mt: 1 }}>
                  <InputLabel id="demo-multiple-checkbox-label">Users</InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    // multiple
                    value={personName}
                    onChange={handleChange}
                    input={<OutlinedInput label="Tag" />}
                    // renderValue={(selected) => selected.join(', ')}
                    // MenuProps={MenuProps}
                  >
                    {roleUsers.map((name, index) => (
                      <MenuItem key={name._id} value={index}>
                        {/* <Checkbox checked={personName.indexOf(name.contactPersonName) > -1} value={name._id}  /> */}
                        <ListItemText primary={name.contactPersonName} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {selectUser.length > 0 && (
                  <Grid
                    container
                    gridTemplateColumns={{ sm: 'repeat(8, 1fr)', xs: 'repeat(8, 1fr)' }}
                    sx={{ mt: 1, gap: 1 }}
                  >
                    {selectUser.map((item, index) => {
                      return (
                        <Stack
                          sx={{
                            flexDirection: 'row',
                            border: 1,
                            borderRadius: 1,
                            p: 0.5,
                            borderColor: 'text.secondary',
                          }}
                        >
                          <Typography sx={{ color: 'text.secondary', textAlign: 'center' }}>
                            {item.contactPersonName}
                          </Typography>
                          <Icon
                            icon="jam:close"
                            color={'red'}
                            fontSize={16}
                            sx={{ cursor: 'pointer', mt: -1 }}
                            onClick={() => handleRoleUser(index)}
                          />
                        </Stack>
                      );
                    })}
                  </Grid>
                )}

                <Grid display={'grid'} gridTemplateColumns={{ sm: 'repeat(4, 1fr)', xs: 'repeat(4, 1fr)' }}>
                  {underRole.map((item, index) => {
                    return (
                      <Stack key={index} sx={{ flexDirection: 'row', alignItems: 'center' }}>
                        {/* return<Grid display={'grid'} gridTemplateColumns={{ sm: 'repeat(2, 1fr)', xs: 'repeat(2, 1fr)' }} py={1} gap={2}> */}
                        <FormControlLabel
                          control={<Checkbox defaultChecked disabled />}
                          value={item._id}
                          // onChange={(e) => asignData(e)}
                        />
                        <Typography sx={{ ml: -3 }}>
                          {item.contactPersonName} {item.USERID}
                        </Typography>
                        {/* <Typography sx={{ml:1}}>{item.USERID}</Typography> */}
                      </Stack>
                    );
                  })}
                </Grid>
              </>
            )}
            {member === 'individual' && (
              <Grid display={'grid'} gridTemplateColumns={{ sm: 'repeat(4, 1fr)', xs: 'repeat(4, 1fr)' }}>
                {allUsers.map((item, index) => {
                  return (
                    <Stack key={index} sx={{ flexDirection: 'row', alignItems: 'center' }}>
                      {/* return<Grid display={'grid'} gridTemplateColumns={{ sm: 'repeat(2, 1fr)', xs: 'repeat(2, 1fr)' }} py={1} gap={2}> */}
                      <FormControlLabel control={<Checkbox />} value={item._id} onChange={(e) => asignData(e)} />
                      <Typography sx={{ ml: -3 }}>
                        {item.contactPersonName} {item.USERID}
                      </Typography>
                      {/* <Typography sx={{ml:1}}>{item.USERID}</Typography> */}
                    </Stack>
                  );
                })}
              </Grid>
            )}
            {member === 'all' && (
              <Grid display={'grid'} gridTemplateColumns={{ sm: 'repeat(4, 1fr)', xs: 'repeat(4, 1fr)' }}>
                {allUsers.map((item, index) => {
                  return (
                    <Stack key={index} sx={{ flexDirection: 'row', alignItems: 'center' }}>
                      {/* return<Grid display={'grid'} gridTemplateColumns={{ sm: 'repeat(2, 1fr)', xs: 'repeat(2, 1fr)' }} py={1} gap={2}> */}
                      <FormControlLabel
                        control={<Checkbox checked={allAssign} />}
                        value={item._id}
                        // onChange={(e) => asignData(e)}
                      />
                      <Typography sx={{ ml: -3 }}>
                        {item.contactPersonName} {item.USERID}
                      </Typography>
                      {/* <Typography sx={{ml:1}}>{item.USERID}</Typography> */}
                    </Stack>
                  );
                })}
              </Grid>
            )}
            {/* <Grid container sx={{ mt: 2 ,flexDirection:'column',justifyContent:'center',alignItems:'center'}}  gap={2}>
            
            
          </Grid> */}
            <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
              <Button color="custom" variant="contained" sx={{ width: '10%', mt: 2 }} onClick={() => assignClient()}>
                Submit
              </Button>
            </Stack>
          </Scrollbar>
        </Box>
      </Modal>
      <Modal
        open={openEdit}
        onClose={editClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={[styleClients, { width: { xs: '95%', sm: '75%' } }]}>
          <Typography variant="h5" textAlign={'center'} color={'text.secondary'}>
            Edit Client
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
            <Grid display={'grid'} gridTemplateColumns={{ sm: 'repeat(2, 1fr)', xs: 'repeat(1, 1fr)' }} py={1} gap={2}>
              <Stack gap={2}>
                <TextField
                  id="outlined-basic"
                  disabled
                  label="Client ID"
                  placeholder="Client ID"
                  variant="outlined"
                  value={formValues?.companyID}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setFormValues({ ...formValues, companyID: e.target.value })}
                />
                <TextField
              
                  id="outlined-basic"
                  label="Client Name"
                  placeholder="Client Name"
                  variant="outlined"
                  value={formValues?.ClientName}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setFormValues({ ...formValues, ClientName: e.target.value })}
                />

                <TextField
                  id="outlined-basic"
                  label="Domain"
                  placeholder="Domain"
                  variant="outlined"
                  value={formValues?.domain}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setFormValues({ ...formValues, domain: e.target.value })}
                />

                <TextField
                  id="outlined-basic"
                  label="Phone"
                  placeholder="Phone"
                  variant="outlined"
                  value={formValues.phone}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setFormValues({ ...formValues, phone: e.target.value })}
                />
                <TextField
                  id="outlined-basic"
                  label="Linkedin URL"
                  placeholder="Linkedin URL"
                  variant="outlined"
                  value={formValues?.linkedinUrl}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setFormValues({ ...formValues, linkedinUrl: e.target.value })}
                />
                {/* <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Industry</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Industry"
                    placeholder="Industry"
                    value={formValues?.industry}
                    onChange={(e) => setFormValues({ ...formValues, industry: e.target.value })}
                  >
                    <MenuItem value="IT">IT</MenuItem>
                    <MenuItem value="Healthcare">Healthcare</MenuItem>
                    <MenuItem value="Engineering">Engineering</MenuItem>
                    <MenuItem value="Manufacturing">Manufacturing</MenuItem>
                    <MenuItem value="non IT">non IT</MenuItem>
                    <MenuItem value="Finance">Finance</MenuItem>
                    <MenuItem value="Pharma">Pharma</MenuItem>
                    <MenuItem value="Hospitality">Hospitality</MenuItem>
                    <MenuItem value="All">All</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                </FormControl> */}
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Country</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Country"
                    placeholder="Country"
                    value={formValues.country}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setFormValues({ ...formValues, country: e.target.value })}
                  >
                    <MenuItem value={'USA'}>USA</MenuItem>;<MenuItem value={'CANADA'}>CANADA</MenuItem>;
                  </Select>
                </FormControl>
                <FormControl>
                  <CustomComplete
                    placeholder={'City'}
                    option={cityAndZip}
                    propVal={cityy}
                    childFuc={childFuc}
                    fullVal={fullVal}
                    fetch={'state'}
                  />
                </FormControl>
                <TextField
                  id="outlined-basic"
                  label="Industry"
                  placeholder="Industry"
                  variant="outlined"
                  value={formValues?.industry}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setFormValues({ ...formValues, industry: e.target.value })}
                />

                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Client Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Client type"
                    placeholder="Client type"
                    value={formValues?.RPO_Staffing}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setFormValues({ ...formValues, RPO_Staffing: e.target.value })}
                  >
                    <MenuItem value="RPO">RPO</MenuItem>
                    <MenuItem value="Staffing">Staffing</MenuItem>
                    <MenuItem value="Consulting">Consulting</MenuItem>
                    <MenuItem value="MSP">MSP</MenuItem>
                    <MenuItem value="Contingent">Contingent</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                </FormControl>

                <FormControl>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{ width: '100%' }}
                      label={'Contract Signed Date'}
                      value={dayjs(formValues?.contractDate)}
                      onChange={(e) => setFormValues({ ...formValues, contractDate: e })}
                    />
                  </LocalizationProvider>
                </FormControl>

                <TextField
                  id="outlined-basic"
                  label="Client Source"
                  placeholder="Client Source"
                  variant="outlined"
                  value={formValues?.clientSource}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setFormValues({ ...formValues, clientSource: e.target.value })}
                />

                <Stack sx={{ position: 'relative' }}>
                  <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Stack
                      sx={{
                        cursor: 'pointer',
                        m: 1,
                        border: 1,
                        p: 1,
                        borderRadius: 2,
                        width: 'fit-content',
                        borderColor: 'text.secondary',
                      }}
                    >
                      <Typography sx={{ alignSelf: 'center' }} color={'text.secondary'}>
                        Upload Contract
                      </Typography>
                    </Stack>
                    <input
                      style={{ opacity: 0, position: 'absolute', margin: 5, width: 100 }}
                      type="file"
                      onChange={(e) => {
                        pdfUpload(e.target.files[0]);
                      }}
                    />
                    {uploadLoader && <CircularProgress />}
                  </Stack>
                  {formValues.contractUrl || docfile ? (
                    <Typography>{docfile || 'Contract already Uploaded'}</Typography>
                  ) : null}
                </Stack>
              </Stack>
              <Stack gap={2}>
                <TextField
                  id="outlined-basic"
                  label="Email"
                  placeholder="Email"
                  variant="outlined"
                  value={formValues?.email}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setFormValues({ ...formValues, email: e.target.value })}
                />
                <FormControl>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{ width: '100%' }}
                      label={'Start Date'}
                      value={startDatee}
                      onChange={(e) => {
                        setStartDate(e);
                        console.log('e', e);
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>

                <TextField
                  id="outlined-basic"
                  label="Number of Employees"
                  placeholder="Number of Employees"
                  variant="outlined"
                  value={formValues?.employeeNo}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setFormValues({ ...formValues, employeeNo: e.target.value })}
                />
                <TextField
                  id="outlined-basic"
                  label=" Main Contact Name"
                  placeholder="Main Contact Name"
                  variant="outlined"
                  value={formValues?.mainContactName}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setFormValues({ ...formValues, mainContactName: e.target.value })}
                />

                <TextField
                  id="outlined-basic"
                  label="Website URL"
                  placeholder="Website URL"
                  variant="outlined"
                  value={formValues?.websiteUrl}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setFormValues({ ...formValues, websiteUrl: e.target.value })}
                />
                <TextField
                  id="outlined-basic"
                  label="Complete  Address"
                  placeholder="Complete Address"
                  variant="outlined"
                  value={formValues?.completeAddress}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setFormValues({ ...formValues, completeAddress: e.target.value })}
                />

                <FormControl size="small" fullWidth sx={{ mt: -0.6 }}>
                  <FormLabel id="demo-radio-buttons-group-label">Fees Type</FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    size="small"
                    name="radio-buttons-group"
                    sx={{ flexDirection: 'row' }}
                    value={formValues.amountType}
                    onChange={(e) => setFormValues({ ...formValues, amountType: e.target.value })}
                  >
                    <FormControlLabel value="Percentage Basis" label="Percentage" control={<Radio size="small" />} />
                    <FormControlLabel value="Flat Fee" label="Flat Fee" control={<Radio size="small" />} />
                    <FormControlLabel value="Margin Basis" label="Margin" control={<Radio size="small" />} />
                    <FormControlLabel value="Subscription" label="Subscription" control={<Radio size="small" />} />
                  </RadioGroup>
                </FormControl>
                {formValues.amountType === 'Subscription' ? (
                  <>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Subscription Plan</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Subscription Plan"
                        placeholder="Subscription Plan"
                        value={formValues.subPlan}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setFormValues({ ...formValues, subPlan: e.target.value })}
                      >
                        <MenuItem value="month">Month</MenuItem>
                        <MenuItem value="quater">Quater</MenuItem>
                        <MenuItem value="year">Year</MenuItem>
                      </Select>
                    </FormControl>
                    <TextField
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        endAdornment: <InputAdornment position="end">per/{formValues?.subPlan}</InputAdornment>,
                      }}
                      value={formValues.amountValue}
                      onChange={(e) => setFormValues({ ...formValues, amountValue: e.target.value })}
                    />
                    <FormControl>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          sx={{ width: '100%' }}
                          label={'Billing Date'}
                          value={dayjs(formValues.billingDate)}
                          onChange={(e) => setFormValues({ ...formValues, billingDate: e })}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </>
                ) : (
                  <TextField
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {formValues?.amountType !== 'Percentage Basis' ? '$' : null}
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          {formValues?.amountType === 'Percentage Basis'
                            ? '%'
                            : formValues?.amountType === 'Margin Basis'
                            ? 'P/H'
                            : 'K'}
                        </InputAdornment>
                      ),
                    }}
                    value={formValues?.amountValue}
                    onChange={(e) => setFormValues({ ...formValues, amountValue: e.target.value })}
                  />
                )}
                <TextField
                  id="outlined-basic"
                  label="Sales Persons"
                  placeholder="Sales Persons"
                  variant="outlined"
                  value={formValues?.salesPerson}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setFormValues({ ...formValues, salesPerson: e.target.value })}
                />
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Status"
                    placeholder="Status"
                    value={formValues?.status}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setFormValues({ ...formValues, status: e.target.value })}
                  >
                    <MenuItem value="Active">Active</MenuItem>
                    <MenuItem value="Inactive">Inactive</MenuItem>
                    <MenuItem value="on Hold">on Hold</MenuItem>
                  </Select>
                </FormControl>
                {/* {formValues?.status && (
                      <FormControl>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            sx={{ width: '100%' }}
                            label={formValues?.RPO_Staffing === 'RPO' ? 'Billing Date' : 'Contract Signed Date'}
                            value={dayjs(formValues?.contractDate)}
                            onChange={(e) =>
                              setFormValues({ ...formValues, contractDate: new Date(e.$d).toLocaleDateString() })
                            }
                          />
                        </LocalizationProvider>
                      </FormControl>
                    )} */}
              </Stack>
            </Grid>
            <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
              <Button variant="contained" color="custom" onClick={() => UpdateClient()}>
                Update Client
              </Button>
            </Stack>
          </Scrollbar>
        </Box>
      </Modal>

      <Modal open={openClientlist} onClose={closeCList} style={{ borderRadius: 10 }}>
        <Box sx={[styleClients, { p: 1, width: '90%' }]}>
          <Stack sx={{ width: '100%', alignItems: 'flex-end' }}>
            <Icon icon="bx:edit" fontSize={16} sx={{ cursor: 'pointer', mt: -1 }} onClick={() => setEditAssign(true)} />
          </Stack>
          <Typography variant="h5" color={'#3C6BA5'} textAlign={'center'} sx={{ mt: -3 }}>
            {!editAssign ? 'Assigned' : 'Unassigned'} Professionals
          </Typography>

          <Divider sx={{}} />
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin', p: 2 }}>
            <>
              {!editAssign ? (
                <>
                  <Grid display={'grid'} gap={1} gridTemplateColumns={{ sm: 'repeat(3, 1fr)', xs: 'repeat(3, 1fr)' }}>
                    <Stack
                      sx={{
                        flexDirection: 'row',
                        backgroundColor: 'text.secondary',
                        width: '100%',
                        alignItems: 'center',
                        p: 0.5,
                        borderRadius: 1,
                      }}
                    >
                      <Typography sx={{ width: '50%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                        User ID
                      </Typography>
                      <Typography sx={{ width: '50%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                        Name
                      </Typography>
                    </Stack>
                    <Stack
                      sx={{
                        flexDirection: 'row',
                        backgroundColor: 'text.secondary',
                        width: '100%',
                        alignItems: 'center',
                        p: 0.5,
                        borderRadius: 1,
                      }}
                    >
                      <Typography sx={{ width: '50%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                        User ID
                      </Typography>
                      <Typography sx={{ width: '50%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                        Name
                      </Typography>
                    </Stack>
                    <Stack
                      sx={{
                        flexDirection: 'row',
                        backgroundColor: 'text.secondary',
                        width: '100%',
                        alignItems: 'center',
                        p: 0.5,
                        borderRadius: 1,
                      }}
                    >
                      <Typography sx={{ width: '50%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                        User ID
                      </Typography>
                      <Typography sx={{ width: '50%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                        Name
                      </Typography>
                    </Stack>
                  </Grid>

                  <Grid display={'grid'} gap={1} gridTemplateColumns={{ sm: 'repeat(3, 1fr)', xs: 'repeat(3, 1fr)' }}>
                    {clientUserList.map((item, index) => {
                      return (
                        <Stack
                          sx={[
                            { background: index % 2 === 0 ? 'text.background' : tablebg },
                            { width: '100%', flexDirection: 'row', justifyContent: 'space-between', p: 1 },
                          ]}
                        >
                          <Typography sx={{ width: '50%' }}>{item.USERID}</Typography>
                          <Typography
                            sx={{
                              width: '50%',
                              textTransform: 'capitalize',
                              fontWeight: '600',
                              color: 'text.secondary',
                            }}
                          >
                            {item.contactPersonName}
                          </Typography>
                        </Stack>
                      );
                    })}
                  </Grid>
                </>
              ) : (
                <>
                  <Grid display={'grid'} gridTemplateColumns={{ sm: 'repeat(4, 1fr)', xs: 'repeat(4, 1fr)' }}>
                    {clientUserList.map((item, index) => {
                      return (
                        <Stack key={index} sx={{ flexDirection: 'row', alignItems: 'center' }}>
                          <FormControlLabel control={<Checkbox />} value={item._id} onChange={(e) => unAsignData(e)} />
                          <Typography sx={{ ml: -3 }}>
                            {item.contactPersonName} {item.USERID}
                          </Typography>
                        </Stack>
                      );
                    })}
                  </Grid>
                  <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
                    <Button
                      color="custom"
                      variant="contained"
                      sx={{ width: '10%', mt: 2 }}
                      onClick={() => unAssignClient()}
                    >
                      Unassign
                    </Button>
                  </Stack>
                </>
              )}
            </>
          </Scrollbar>
        </Box>
      </Modal>
    </>
  );
};

export default Clients;
