import React, { useState, useEffect, useCallback } from 'react';
// import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useNavigate, useLocation, Link } from 'react-router-dom';

import moment from 'moment';
import {
  Typography,
  Table,
  Button,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Modal,
  Box,
  TextField,
  Divider,
  Grid,
  MenuItem,
  InputLabel,
  Select,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  Pagination,
} from '@mui/material';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';
import { Document, Page, pdfjs } from 'react-pdf';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Iconify from '../components/iconify';
// import Upload from '../components/upload';
import Scrollbar from '../components/scrollbar/Scrollbar';
import CustomComplete from '../components/customComplete/CustomComplete';
import { Api, UploadFile } from '../webservices';

pdfjs.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.7.570/pdf_viewer.js.map';
const tablebg = 'rgba(82, 130, 190, 0.08)';
const filter = createFilterOptions();
function SalesWorksheet() {
  const location = useLocation();
  const navigate = useNavigate();
  function MyFormHelperText() {
    const { onFilled } = useFormControl() || {};
    const helperText = React.useMemo(() => {
      if (onFilled) {
        return 'This field is being onFilled';
      }

      return 'Helper text11';
    }, [onFilled]);

    return <FormHelperText>{helperText}</FormHelperText>;
  }
  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [pages, setPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedEmail, setSelectedEmail] = useState('');
  const [askill, setAskill] = React.useState(null);
  const [finallst, setFinallst] = React.useState([]);
  const [tableData, setTableData] = useState([]);
  const [autocompleteValues, setAutocompleteValues] = useState([]);
  const [evalid, setEvalid] = useState(true);
  const [dupemail, setDupemail] = useState(false);
  const [tempskill, setTempskill] = useState([]);
  const [cityerr, setCityerr] = useState(false);
  const [joblst, setJoblst] = useState([]);
  const [cityAndZip, setCityAndZip] = useState([]);
  const [cityandstate, setCityandstate] = useState({});
  const [emails, getEmails] = useState([]);

  const userId = useSelector((state) => state.userData.data._id);

  const [value, setValue] = useState({
    email: '',
    companyName: '',
    linkedinUrl: '',
    contactPersonName: '',
    websiteUrl: '',
    contactNo: '',
    domain: '',
    designation: '',
    employeeNo: '',
    location: '',
    city: '',
    status: 'new',
    rsc: '',
    country: 'USA',
  });

  const [cityy, setCityy] = useState('');
  const [statee, setStatee] = useState('');
  const [skill, setSkill] = useState([{ title: 'zashi' }]);
  const [pdffile, setPdffile] = useState(null);
  const [docfile, setDocfile] = useState();
  const [zipcode, setZipcode] = useState('');
  const [mainTab, setMainTab] = useState('recruiter');
  const [emailerr, setEmailerr] = useState(false);
  const [companyerr, setCompanyerr] = useState(false);
 
  const handlePage = (event, value) => {
    console.log(value)
    setPageNumber(value);
    const pg = value
    handleChange(valuee,pg)
  };

  const [valuee, setValuee] = React.useState('new');
  // console.log("===========location worksheet", location.state);
  const handleChangeAutoComplete = (event, value1) => {
    setValue({ ...value, skill: value1 });
  };

  useEffect(() => {
    getSkills();
    handleChange('new');
  }, []);

  const [openConfirm, setOpenConfirm] = useState(false);
  const confirmClose = () => {
    setOpenConfirm(false)
  }
  const handleChange = (newValue,pg) => {
    setValuee(newValue);
    const id = localStorage.getItem('userid');
    const body = {
      currentStatus: newValue,
      userId: userId || id,
      "page":pg || 1,
      "limit":"35"
    };
    setTableData([]);
    const token = localStorage.getItem('token');
    Api(`company/get_clients_by_role`, 'POST', body, token)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.result.length) {
            // toast.success(response.data.message, +'!');
            setTableData(response.data.result);
            const totalPage = Math.ceil(response.data.count/35)
            setPages(totalPage)
          } else {
            setTableData([]);
            // toast.warn('Data Not Found');
          }
        } else {
          setTableData([]);
          // toast.warn(response.data.message);
        }
      })
      .catch((err) => {
        console.log('===========', err);
        toast.error(err, +'!');
      });
  };

  const adminrole = useSelector((state) => state.userData.data.role);
  const cities = [];

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '95%', sm: '70%' },
    bgcolor: 'background.paper',
    border: '3px solid #3C6BA5',
    boxShadow: 24,
    p: 3,
    borderRadius: 3,
  };
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#3366FF',
    // backgroundColor: 'secondary',
    ...theme.typography.button,
    padding: theme.spacing(0.5),
    // textAlign: 'center',
    color: 'white',
  }));

  const getResume = async (val) => {
    const id = localStorage.getItem('userid');
    const body = {
      currentStatus: 'new',
      userId: val || id,
    };
    const token = localStorage.getItem('token');
    Api(`company/get_clients_by_role`, 'POST', body, token)
      .then((response) => {
        console.log('=========get_sales_client_list======>>>>>>>', response.data);

        if (response.status === 200) {
          if (response.data.length) {
            // toast.success(response.data.message, +'!');
            setTableData(response.data);
            // setValuee('worksheet')
            console.log('======ashish======>>>>>>>', response);
          } else {
            setTableData([]);
            // toast.warn('Data Not Found');
          }
        } else {
          setTableData([]);
          toast.warn(response.data.message);
        }
      })
      .catch((err) => {
        console.log('===========', err);
        toast.error(err, +'!');
      });
  };

  const pdfUpload = async () => {
    console.log('=====pdf====>>>', docfile);
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('document', docfile);
    formData.append('directoryName', 'resume');
    UploadFile(`resume/upload_resume`, formData, token).then((Response) => {
      console.log('=====upload Response========>', Response.data);

      if (Response.status === 200) {
        if (Response.data.code === 200) {
          toast.success(Response.data.status, +'!');
          //  console.log('=====1004=gst===', Response.data.message);
          setPdffile(Response.data.filePath);
        } else {
          console.log('=====404=gst===', Response.data.message);
          toast.warn(Response.data.status, +'!');
        }
      } else {
        toast.warn('Error !');
      }
    });
  };
  const addcv = async (e) => {
    setValuee({ ...valuee, status: 'new' });
    // if (value.email !== '') {
      if (value.companyName !== '') {
        const token = localStorage.getItem('token');
        const body = {
          email: selectedEmail,
          additionalEmail: value.additionalEmail,
          companyName: value.companyName,
          clientName: value.companyName,
          linkedinUrl: value.linkedinUrl,
          contactPersonName: value.contactPersonName,
          websiteUrl: value.websiteUrl,
          contactNoWithISD: value.contactNo,
          domain: value.domain,
          designation: value.designation,
          employeeNo: value.employeeNo,
          location: cityy,
          currentStatus: value.status,
          RPO_Staffing: value.rsc,
        };
        console.log('=====create_resume==========>>>>>>>', finallst);
        Api(`company/add_sales_client`, 'POST', body, token)
          .then((response) => {
            console.log('=====create_resume==========>>>>>>>', response);
            if (response.status === 200) {
              if (response.data.code === 200) {
               handleChange(value.status?value.status:"new");
              toast.success(response.data.message, +'!');
              handleClose1();
              setValue({
                email: '',
                companyName: '',
                linkedinUrl: '',
                contactPersonName: '',
                websiteUrl: '',
                contactNo: '',
                domain: '',
                additionalEmail: '',
                designation: '',
                employeeNo: '',
                location: '',
                rsc: '',
              });
              setSelectedEmail('');
              getEmails([])
              setCityy('');
            }else if (response.data.code === 400) {
              toast.warn(response.data.error, +'!');
              handleClose1();
              setValuee(value.status)
              handleChange(value.status);
              setValue({
                email: '',
                companyName: '',
                linkedinUrl: '',
                contactPersonName: '',
                websiteUrl: '',
                contactNo: '',
                domain: '',
                additionalEmail: '',
                designation: '',
                employeeNo: '',
                location: '',
                rsc: '',
              });
              setSelectedEmail('');
              getEmails([])
              setCityy('');
              
              
            }
           }
          })
          .catch((err) => {
            console.log('===========', err);
          });
      } else {
        setCompanyerr('true');
      }
    // } else {
    //   setEmailerr('true');
    
    // }
  };
  const funclick = (val) => {
    handleChange(valuee);
  };
  const funclick1 = (val) => {
    handleChange(valuee);
  };

  const changeS = (val) => {  
    // alert(val)
    tableData.splice(val, 1);
    setTableData([...tableData]);
  }
  const handleEmail = (e) => {
    console.log('======================true=====1=============>>>>>>>>>', evalid);
    emails.forEach((item) => {
      if (item === e) {
        getDataByEmail(item);
      }
    });
    setValue({ ...value, email: e });
    const reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEvalid(reg.test(e));
    // setTimeout(() => {
    if (evalid === true) {
      setEmailerr(false);
      console.log('=======email valid', evalid);
    } else {
      console.log('=======email unvalid', evalid);
    }
    // }, 1000);
    console.log('======================true==================>>>>>>>>>', evalid);
  };
  
  // const chkmail = async (e) => {
  //   const token = localStorage.getItem('token');
  //   const body = {
  //     email: value.email,
  //   };
  //   Api(`resume/duplicate_check`, 'POST', body, token)
  //     .then((response) => {
  //       console.log('===============>>>>>>>', response);
  //       if (response.status === 200) {
  //         if (response.data.code === 400) {
  //           setDupemail(true);
  //         }
  //       } else {
  //         toast.warn(response.data.message, +'!');
  //       }
  //     })
  //     .catch((err) => {
  //       console.log('===========', err);
  //     });
  // };

  const getSkills = async () => {
    const token = localStorage.getItem('token');
    Api(`resume/get_skills`, 'GET', '', token)
      .then((response) => {
        // console.log('=========getSkills======>>>>>>>', response.data);
        if (response.data.code === 200) {
          if (response.data.result.length !== 0) {
            response.data.result.map((item) => {
              return tempskill.push({ title: item.skillName });
            });
            setSkill(tempskill);
            console.log('===value skilll========', JSON.stringify(skill));
          } else {
            setSkill([{ title: '' }]);
          }
        } else {
          toast.warn(response.data.message, +'!');
        }
      })
      .catch((err) => {
        console.log('===========', err);
        toast.error(err, +'!');
      });
  };
  const getCity = async (val) => {
    setCityAndZip([])
    const token = localStorage.getItem('token');
    const body = {
      country: value.country,
      query: val,
    };
    Api(`resume/get_city_states`, 'POST', body, token)
      .then((response) => {
        if (response.status === 200) {
          console.log('=========status 200======>>>>>>>', response);
          if (response.data.code === 200) {
            setCityAndZip(response.data.data);
            setCityandstate(response.data.data[0]);
            if (response.data.data[0].zip) {
              setValue({ ...value, zip: response.data.data[0].zip });
            } else {
              setValue({ ...value, zip: '' });
            }
            console.log('=======city and State', response.data.data[0].zip);
          } else {
            // setCityAndZip(response.data.data);
            // toast.error(response.data.message, +'!');
          }
        }
      })
      .catch((err) => {
        console.log('===========', err);
        // toast.error(err, +'!');
      });
  };
  const clearFormData = (e) => {
    e.preventDefault();
    getEmails([]);
    setSelectedEmail('')
    setCityAndZip([])
    setCityy('');
    setValue({
      email:"",
      companyName: '',
      linkedinUrl: '',
      contactPersonName: '',
      websiteUrl: '',
      contactNo: '',
      domain: '',
      designation: '',
      employeeNo: '',
      location: '',
      additionalEmail: '',
      status: '',
      rsc:"",

    });
  };
  const getDataByEmail = async (val) => {
    const token = localStorage.getItem('token');
    Api(`company/get_client_by_email/${val}`, 'GET', '', token)
      .then((response) => {
        if (response.status === 200) {
          console.log('=========get_client_by_email======>>>>>>>', response);
          if (response.data.code === 200) {
            console.log('getDataByemail========', response.data.result);
            setValue({
              ...value,
              email: response.data.result.email,
              companyName: response.data.result.companyName,
              linkedinUrl: response.data.result.linkedinUrl,
              contactPersonName: response.data.result.contactPersonName,
              websiteUrl: response.data.result.websiteUrl,
              contactNo: response.data.result.contactNoWithISD,
              domain: response.data.result.domain,
              designation: response.data.result.designation,
              employeeNo: response.data.result.employeeNo,
              location: response.data.result.location,
              status: response.data.result.currentStatus,
              additionalEmail: response.data.result.additionalEmail,
              rsc: response.data.result.RPO_Staffing,
            });
            setCityy(response.data.result.location);
            setSelectedEmail(response.data.result.email);
          } else {
            console.log('getDataByemail========', response.data);
          }
        }
      })
      .catch((err) => {
        console.log('===========', err);
        // toast.error(err, +'!');
      });
  };

  const getEmail = async (val) => {
    const token = localStorage.getItem('token');
    Api(`company/check_email/${val}`, 'GET', '', token)
      .then((response) => {
        if (response.status === 200) {
          console.log('====check_email=====status 200======>>>>>>>', response);
          if (response.data.code === 200) {
            console.log('city========', JSON.stringify(response.data));
            if(response.data.data !== null){
              getEmails(response.data.data);
            }
          } else {
            getEmails([]);
            // toast.error(response.data.message, +'!');
          }
        }
      })
      .catch((err) => {
        console.log('===========', err);
        // toast.error(err, +'!');
      });
  };
  function addskill(val) {
    if (val !== '') {
      console.log('add', finallst);
      finallst.push(val);
      setFinallst([...finallst]);
      setAskill('');
    }
    console.log('------finallist', finallst);
  }
  function del(item) {
    // setSkill("");
    const idx = finallst.indexOf(item);
    finallst.splice(idx, 1);
    setFinallst([...finallst]);
    console.log('delete', finallst);
    setAskill(null);
  }

  const childFuc = (e) => {
    console.log('e', e);
    setCityy(e);

    // setValue({ ...value, location: e });
    if (e.length >= 3) getCity(e);
  };

  const fullVal = (val) => {
    setCityy(`${val.city} ${val.stateCode}`);
    // setCityy(`${val.city} (${val.state}) (${val.zip})`);
  };

  const childFucEmail = (e) => {
    handleEmail(e);
    setSelectedEmail(e);
    const arr = e.match(/@/);
    if (arr != null) {
      // Did it match?
      getEmail(e);
    }
    // if (e.length >= 3) getEmail(e);
  };

  const fullEmail = (val) => {
    console.log('email', val);
    handleEmail(val);
    setSelectedEmail(val);
  };
  const styleSkillset = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: { xs: '60%', sm: '50%' },
    width: '50%',
    bgcolor: 'background.paper',
    border: '3px solid #3C6BA5',
    boxShadow: 24,
    p: 3,
    borderRadius: 3,
  };
 
  const setContact = (e) => {
    const reg = /^[a-zA-Z]*$/
    if(e.length){
      const lst = e[e.length - 1]  
      if(!lst.match(reg)){
        setValue({
          ...value,
          contactNo: e,
        })
      }
    } else{
      setValue({
        ...value,
        contactNo: e,
      })
    }
    
  }
  return (
    <>
      <ToastContainer />
      <Stack flexDirection={'row'} alignItems={'flex-end'} justifyContent={'space-between'} m={1}>
        <Typography variant="h5" color={'text.secondary'}>
          Sales Worksheet
        </Typography>
        <Button color="custom" variant="contained" size="small" onClick={handleOpen1}>
          Add Client
        </Button>
      </Stack>
      <Stack sx={{ flexDirection: 'row', gap: 2, mb: 0.5, cursor: 'pointer', alignContent: 'center' }}>
        <Stack
          sx={[
            { flexDirection: 'row' },
            valuee === 'new' ? { borderBottom: 3, color: 'text.secondary' } : { color: 'text.textG' },
          ]}
          onClick={() => {
            handleChange('new')
          }}
        >
          <Iconify icon="mingcute:list-check-line" fontSize={20} />
          <Typography variant="tabs" fontWeight={500} sx={{ ml: 1 }}>
            New
          </Typography>
        </Stack>
        <Stack
          sx={[
            { flexDirection: 'row', ml: 1 },
            valuee === 'favourites' ? { borderBottom: 3, color: 'text.secondary' } : { color: 'text.textG' },
          ]}
          onClick={() => handleChange('favourites')}
        >
          <Iconify icon="tdesign:heart" fontSize={20} color={valuee === 'favourites' ? '#E74C3C' : 'text.textG'} />
          <Typography variant="tabs" fontWeight={500} sx={{ ml: 1 }}>
            Favourites
          </Typography>
        </Stack>

        <Stack
          sx={[
            { flexDirection: 'row', ml: 1 },
            valuee === 'pipeline' ? { borderBottom: 3, color: 'text.secondary' } : { color: 'text.textG' },
          ]}
          onClick={() => handleChange('pipeline')}
        >
          <Iconify icon="carbon:user-multiple" fontSize={20} color={valuee === 'pipeline' ? '#F4D03F' : 'text.textG'} />
          <Typography variant="tabs" fontWeight={500} sx={{ ml: 1 }}>
            Pipeline
          </Typography>
        </Stack>
        <Stack
          sx={[
            { flexDirection: 'row', ml: 1 },
            valuee === 'meetings' ? { borderBottom: 3, color: 'text.secondary' } : { color: 'text.textG' },
          ]}
          onClick={() => handleChange('meetings')}
        >
          <Iconify icon="fluent-mdl2:date-time" fontSize={20} />
          <Typography variant="tabs" fontWeight={500} sx={{ ml: 1 }}>
            Meetings
          </Typography>
        </Stack>
        <Stack
          sx={[
            { flexDirection: 'row', ml: 1 },
            valuee === 'converted' ? { borderBottom: 3, color: 'text.secondary' } : { color: 'text.textG' },
          ]}
          onClick={() => handleChange('converted')}
        >
          <Iconify icon="bi:check-circle" fontSize={20} color={valuee === 'converted' ? '#2ECC71' : 'text.textG'} />
          <Typography variant="tabs" fontWeight={500} sx={{ ml: 1 }}>
            Converted
          </Typography>
        </Stack>
        <Stack
          sx={[
            { flexDirection: 'row', ml: 1 },
            valuee === 'passiveclient' ? { borderBottom: 3, color: 'text.secondary' } : { color: 'text.textG' },
          ]}
          onClick={() => handleChange('passiveclient')}
        >
          <Iconify icon="ps:check-box" fontSize={20} color={valuee === 'passiveclient' ? '#F4D03F' : 'text.textG'} />
          <Typography variant="tabs" fontWeight={500} sx={{ ml: 1 }}>
            Passive Client
          </Typography>
        </Stack>
      
        <Stack
          sx={[
            { flexDirection: 'row', ml: 1 },
            valuee === 'dnc' ? { borderBottom: 3, color: 'text.secondary' } : { color: 'text.textG' },
          ]}
          onClick={() => handleChange('dnc')}
        >
          <Iconify icon="bx:error" fontSize={20} color={valuee === 'dnc' ? '#CB4335' : 'text.textG'} />
          <Typography variant="tabs" fontWeight={500} sx={{ ml: 1 }}>
            DNC
          </Typography>
        </Stack>
      </Stack>
      <TableContainer component={Paper}>    
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ justifyContent: 'flex-start', backgroundColor: 'text.secondary' }}>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white',width:'16%' }}>Date</TableCell>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white',width:'18%' }}>Detail</TableCell>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white',width:'20%' }}>Contact Details</TableCell>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white' ,width:'15%'}}>Location</TableCell>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white',width:'10%' }}>Activities</TableCell>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white',width:'11%' }}>Status</TableCell>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white',width:'10%' }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((item, index) => (
              <ResumeRow
                index={index}
                key={item._id}
                row={item}
                fun={funclick}
                fun1={funclick1}
                // fun2={pushNote}
                changeS={changeS}
                
                // skillsett={skill}
                // joblst1={joblst}
                filterTab={valuee}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {tableData.length < 1 && (
        <Stack sx={{ mt: 2, p: 2 }}>
          <Typography sx={{ alignSelf: 'center', color: 'red' }}>no data found</Typography>
        </Stack>
      )}

      {tableData.length ? (
        <Stack spacing={2} sx={{ alignItems: 'flex-end', width: '100%', mt: 2 }}>
          <Pagination  variant="outlined" shape="rounded" color="secondary" count={pages} page={pageNumber} onChange={handlePage}/>
        </Stack>
      ) : null}

      <Modal open={open1} onClose={handleClose1} style={{ borderRadius: 10 }}>
        <Box sx={style}>
          <Typography variant="h4" color={'#3C6BA5'} textAlign={'center'}>
            New Prospect
          </Typography>
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
            <Divider />
            <Grid display={'grid'} gridTemplateColumns={{ md: 'repeat(2, 1fr)', sm: 'repeat(1, 1fr)' }} py={2} gap={4}>
              <Stack gap={2}>
                <CustomComplete
                  err={emailerr}
                  // req={'true'}
                  placeholder={'Email'}
                  option={emails}
                  propVal={value.email}
                  childFuc={childFucEmail}
                  fullVal={fullEmail}
                  fetch={'email'}
                  // helperText={"Please enter valid email address"}
                />

                {evalid === false ? (
                  <Stack sx={{ ml: 1 }}>
                    <Typography color={'red'} variant="body">
                      This is not vaild mail
                    </Typography>
                  </Stack>
                ) : null}
                {dupemail === true ? (
                  <Stack sx={{ ml: 1, mt: 1, flexDirection: 'row' }}>
                    <Typography color={'red'} variant="body">
                      Duplicate Record Found
                    </Typography>
                    <Typography color={'red'} variant="body" sx={{ ml: 2 }}>
                      -
                    </Typography>
                    <Typography
                      color={'text.secondary'}
                      variant="body"
                      sx={{ ml: 2, cursor: 'pointer', textDecoration: 'underline' }}
                    >
                      Click here to view
                    </Typography>
                  </Stack>
                ) : null}

                <TextField
                  error={companyerr}
                  required
                  id="outlined-basic"
                  label="Client Name"
                  placeholder="Client Name"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={value.companyName}
                  // sx={{mt:-2}}
                  onChange={(e) => {
                    setValue({ ...value, companyName: e.target.value });
                    if (value.companyName.length > 4) {
                      setCompanyerr(false);
                    } else {
                      setCompanyerr('true');
                    }
                  }}
                />
                <TextField
                  id="outlined-basic"
                  label="Website"
                  placeholder="Website"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={value.websiteUrl}
                  // inputProps={{ type: 'numeric'}}
                  // type='number'
                  onChange={(e) => setValue({ ...value, websiteUrl: e.target.value })}
                />
                <TextField
                  id="outlined-basic"
                  label="Contact Name"
                  placeholder="Contact Name"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={value.contactPersonName}
                  // inputProps={{ type: 'numeric'}}
                  // type='number'
                  onChange={(e) => setValue({ ...value, contactPersonName: e.target.value })}
                />

                <TextField
                  id="outlined-basic"
                  label="Designation"
                  placeholder="Designation"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={value.designation}
                  // inputProps={{ type: 'numeric'}}

                  onChange={(e) => setValue({ ...value, designation: e.target.value })}
                />

                <TextField
                  id="outlined-basic"
                  label="Linkedin URL"
                  placeholder="Linkedin URL"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={value.linkedinUrl}
                  onChange={(e) => setValue({ ...value, linkedinUrl: e.target.value })}
                />

                <TextField
                  id="outlined-basic"
                  label="Contact Number"
                  placeholder="Contact Number"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={value.contactNo}
                  // inputProps={{ type: 'numeric'}}
                  onChange={(e) => setContact(e.target.value)}
                />
              </Stack>
              <Stack gap={2} sx={{}}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Select Country</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value.country}
                    label="Select Country"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setValue({ ...value, country: e.target.value })}
                  >
                    <MenuItem value="USA">USA</MenuItem>;<MenuItem value="CANADA">CANADA</MenuItem>;
                  </Select>
                </FormControl>
                <CustomComplete
                  placeholder={'City'}
                  option={cityAndZip}
                  propVal={cityy}
                  childFuc={childFuc}
                  fullVal={fullVal}
                  fetch={'state'}
                />
                <TextField
                  id="outlined-basic"
                  label="Domain"
                  placeholder="Domain"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={value.domain}
                  // inputProps={{ type: 'numeric'}}

                  onChange={(e) => setValue({ ...value, domain: e.target.value })}
                />

                {/* <TextField
                  id="outlined-basic"
                  multiline
                  label="Additional Contact"
                  placeholder="Additional Contact"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={value.additionalEmail}
                  onChange={(e) => setValue({ ...value, additionalEmail: e.target.value })}
                /> */}
                <FormControl sx={{}}>
                  <InputLabel id="demo-simple-select-label">RPO/Staffing/Consulting</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    InputLabelProps={{ shrink: true }}
                    label="RPO/Staffing/Consulting/MSP/Contingent/Other"
                    placeholder="RPO/Staffing/Consulting/MSP/Contingent/Other"
                    value={value.rsc}
                    onChange={(e) => setValue({ ...value, rsc: e.target.value })}
                  >
                    <MenuItem value={'rpo'}>RPO</MenuItem>
                    <MenuItem value={'staffing'}>Staffing</MenuItem>
                    <MenuItem value={'consulting'}>Consulting</MenuItem>
                    <MenuItem value={'MSP'}>MSP</MenuItem>
                    <MenuItem value={'contingent'}>Contingent</MenuItem>
                    <MenuItem value={'other'}>Other</MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">No of Employees</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    InputLabelProps={{ shrink: true }}
                    label="No of Employees"
                    placeholder="No of Employees"
                    value={value.employeeNo}
                    onChange={(e) => setValue({ ...value, employeeNo: e.target.value })}
                  >
                    <MenuItem value={'0-10'}>0-10</MenuItem>
                    <MenuItem value={'10-25'}>10-25</MenuItem>
                    <MenuItem value={'25-50'}>25-50</MenuItem>
                    <MenuItem value={'50-100'}>50-100</MenuItem>
                    <MenuItem value={'100-250'}>100-250</MenuItem>
                    <MenuItem value={'250-500'}>250-500</MenuItem>
                    <MenuItem value={'more than 500'}>more than 500</MenuItem>
                  </Select>
                </FormControl>
                <FormControl sx={{}}>
                  <InputLabel id="demo-simple-select-label">Current Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    InputLabelProps={{ shrink: true }}
                    label="Current Status"
                    placeholder="Current Status"
                    value={value.status}
                    onChange={(e) => setValue({ ...value, status: e.target.value })}
                  >
                    <MenuItem value={'new'}>New</MenuItem>
                    <MenuItem value={'meetings'}>Meetings</MenuItem>
                    <MenuItem value={'pipeline'}>Pipeline</MenuItem>
                    <MenuItem value={'passiveclient'}>Passive Client</MenuItem>
                    <MenuItem value={'favourites'}>Favourites</MenuItem>
                    <MenuItem value={'converted'}>Converted</MenuItem>
                    <MenuItem value={'dnc'}>DNC</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
            </Grid>
            <Stack sx={{ flexDirection: 'row', gap: 1, mt: 1, justifyContent: 'center' }}>
              <Button color="custom" sx={{ width: 100 }} variant="contained" onClick={(e) => {if(value.email === ""){
                setOpenConfirm(true)}
                else{addcv()}}}>
                Add
              </Button>
              <Button color="custom" variant="contained" onClick={(e)=>clearFormData(e)}>
                Clear data
              </Button>
            </Stack>
          </Scrollbar>
        </Box>
      </Modal>
      <Modal open={openConfirm} onClose={confirmClose} style={{ borderRadius: 10 }}>
        <Box sx={[styleSkillset, { width: '30%'}]}>  
        <Stack sx={{justifyContent:"center",alignItems:'center',width:'100%'}}>
              <Stack sx={{  width:"100%" }}>
                <Typography sx={{ }} variant='h6' textAlign={"center"} color={'red'}>
                  Proceed without an Email ?
               </Typography>
                </Stack>
                <Stack sx={{flexDirection:'row',justifyContent:'space-between',mt:3,alignSelf:'center',gap:2}}>
                <Button color="custom" sx={{  }} variant="contained" size='small' onClick={()=>{confirmClose();addcv()}}>
                  Yes
                  </Button>
                <Button color="custom" sx={{  }} variant="contained" size='small' onClick={confirmClose}>
                  No
                </Button>
                </Stack>
                </Stack>
  
        </Box>
      </Modal>
      
    </>
  );
}

export default SalesWorksheet;

function ResumeRow({ row, fun, fun1, skillsett, joblst1, filterTab, index,changeS,fun2 }) {
  useEffect(() => {
    console.log('=================rowwww===>>>', row);
  }, []);

  const navigate = useNavigate();
  const [tmpskl, setTmpskl] = useState([]);

  const [status, setStatus] = useState('Pending');
  const [edit, setEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openpdf, setOpenpdf] = useState(false);

  const [openskill, setOpenskill] = useState(true);
  const skillclose = () => setOpenskill(false);
  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [totalDays, setTotalDays] = useState(0);
  const [opens, setOpens] = useState(false);
  const handleOpens = () => setOpens(true);
  const handleCloses = () => setOpens(false);

  const [openn, setOpenn] = useState(false);
  const handleOpenn = () => setOpenn(true);
  const handleClosen = () => setOpenn(false);

  const [openmeeting,setMeeting]= useState(false)
  const handleOpenM = () => setMeeting(true);
  const handleCloseM = () => setMeeting(false);
  const[editM,setEditM]=useState(false)
  const openEditM =()=>setEditM(true)
  const closeM =()=>setEditM(false)

  const [openclient, setOpenclient] = useState(false);
  const handleOpenclient = () => setOpenclient(true);
  const handleCloseclient = () => setOpenclient(false);

  const [upnote,setUpnote] = useState(false)
  const noteCloses =()=>setUpnote(false)
  const noteOpen =()=>setUpnote(true)
  const [newNote, setNewNote] = useState('')

  const handleOpenpdf = () => setOpenpdf(true);
  const Closepdf = () => setOpenpdf(false);
  const [pdffile, setPdffile] = useState(row.resumeUrl);
  const [docfile, setDocfile] = useState();
  const [commentt, setCommentt] = useState('');
  const [companyerr, setCompanyerr] = useState(false);
  const [value, setValue] = useState({
    email: row.email,
    companyName: row.companyName,
    linkedinUrl: row.linkedinUrl,
    contactPersonName: row.contactPersonName,
    websiteUrl: row.websiteUrl,
    contactNo: row.contactNoWithISD,
    domain: row.domain,
    additionalEmail: row.additionalEmail,
    designation: row.designation,
    employeeNo: row.employeeNo,
    location: row.location,
    status: row.currentStatus,
    rsc: row.RPO_Staffing,
    country: '',
  });
  const [tempskill, setTempskill] = useState([]);
  const [skill, setSkill] = useState('');
  const [finallst, setFinallst] = useState(row.skill);
  const [evalid, setEvalid] = useState(true);
  const [cityy, setCityy] = useState(row.location || '');
  // const [ city , setCity] = useState('')
  const [askill, setAskill] = React.useState(null);
  const [statee, setStatee] = useState('');
  const [cityAndZip, setCityAndZip] = useState([]);
  const cities = [];
  const [skillset, setSkillset] = useState([{ title: 'item.skillName' }]);
  const [client, setClients] = useState([]);
  const [cityandstate, setCityandstate] = useState({});
  const [statusnotes, setStatusnotes] = useState('');
  const [statusch, setStatusch] = useState(row.currentStatus);
  const [changeStatus, setChangeStatus] = useState({
    status: '',
    note: '',
    date: '',
    datee: '',
    day: '',
    month: '',
    year: '',
    sl: '/',
  });

  const [datee, setDatee] = useState(dayjs());
  const [ConvertedDate, setConvertedDate] = useState(dayjs());
  // const [cmntarr, setCmntarr] = useState([]);
  const[gemail,setGemail]=useState(row.email)
  const [ccc,setCcc]=useState('')
  const [subline,setSubline]=useState('')
  const [meetingDate, setMeetingDate] = useState(dayjs());
  const [startTimee, setStartTimee] = useState(dayjs("2023-12-11T18:30:11.625Z"));
  const [endTimee, setEndTimee] = useState(dayjs("2023-12-11T18:30:11.625Z"));
  const [timez, setTimez] = useState('est');
  const [meetingMode, setMeetingMode] = useState(''); 
  const [pNumber, setPNumber] = useState('');
  const [vPlatform, setVPlatform] = useState('');
  const [mUrl, setMurl] = useState('');
  const [address, setAddress] = useState('');
  const [meetingNote, setMeetingNote] = useState('');
  const [meetingData, setMeetingData] = useState({});

  const [editgemail,setEditgemail]=useState("")
  const [editccc,setEditccc]=useState("")
  const [editsubline,setEditsubline]=useState("")
  const [editmeetingDate, setEditmeetingDate] = useState(dayjs());
  const [editstartTimee, setEditstartTimee] = useState(dayjs());
  const [editendTimee, setEditendTimee] = useState(dayjs());
  const [edittimez, setEdittimez] = useState('est');
  const [editmeetingMode, setEditmeetingMode] = useState(''); 
  const [editpNumber, setEditpNumber] = useState('');
  const [editvPlatform, setEditvPlatform] = useState('');
  const [editMurl, setEditMurl] = useState('');
  const [editaddress, setEditaddress] = useState('');
  const [editmeetingNote, setEditmeetingNote] = useState('');
  const [addEmails,setAddEmails] =useState("")
  const [convertedDis,setConvertedDis]= useState(statusch==="converted"?"none":"") 

  const [emailCopy,setEmailCopy]=useState(false)
  const [phoneCopy,setPhoneCopy]=useState(false)


  const getAddEmail=async(cname)=>{
    const token = localStorage.getItem('token')
    Api(`company/get_mails/${cname}`, 'GET', "", token)
      .then((Response) => {
        if (Response.status === 200) {
          if(Response.data.code===200){
            if(Response.data.data){
              const tempAdd = Response.data.data.toString()
              const tempAdd1 = tempAdd.replace(/,/g, ' | ')
              console.log('======get_add_email=====>', tempAdd1);
              setAddEmails(tempAdd1)
            }
          }
        }
        // console.log('======get_add_email=====>', Response);  
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const getMeeting = async (id) => {
    // console.log('======get_meeting=====>', id,"======get_meeting=====>", row._id );
    const token = localStorage.getItem('token'); 
    Api(`company/meeting/${id}`, 'GET', "", token)
      .then((Response) => {
        console.log('======get_meeting=====>', Response);
        if (Response.status === 200) {
          if(Response.data.code===200){
            setMeetingData(Response.data.data)
            handleOpenM()
            setEditgemail(Response.data.data.guestEmail)
            setEditccc(Response.data.data.cc)
            setEditsubline(Response.data.data.subject)
            setEditmeetingDate(dayjs(Response.data.data.dateOfMeeting))
            setEditstartTimee(dayjs(Response.data.data.startTime))
            setEditendTimee(dayjs(Response.data.data.endTime))
            setEdittimez(Response.data.data.timeZone)
            setEditmeetingMode(Response.data.data.modeOfMeeting)
            setEditpNumber(Response.data.data.phone)  
            setEditvPlatform(Response.data.data.meetingPlatform)
            setEditMurl(Response.data.data.meetingLink)
            setEditaddress(Response.data.data.meetingAddress)
            setEditmeetingNote(Response.data.data.additionalNotes)
          // handleClose1();
          // toast.success(Response.data.message);
          // fun();
          console.log('======getUser Meetings===data.data pdata====>', Response);
        }else{
          toast.error(Response.data.message);
        }
      }
      })
      .catch((err) => {
        toast.error(err, +'!');
        console.log('===========', err);
      });
  }
  
  const editmeetingData=(id)=>{
    const token = localStorage.getItem('token');
    const body = {
      clientId:id,
      guestEmail: editgemail,
      cc: editccc,
      subject: editsubline,
      dateOfMeeting: editmeetingDate,
      startTime: editstartTimee,
      endTime: editendTimee,
      timeZone: edittimez,
      modeOfMeeting: editmeetingMode,
      phone: editpNumber,
      meetingPlatform: editvPlatform,
      meetingLink: editMurl,
      meetingAddress:editaddress,
      currentStatus: 'meetings',
      date: editmeetingDate,
      additionalNotes: editmeetingNote,
      status:"Meeting Rescheduled"
    };
    Api(`company/edit_meeting`, 'POST', body, token)
      .then((Response) => {
        if (Response.status === 200) {
          if(Response.data.code===200){
            toast.success(Response.data.message);
            closeM()
            fun1("meetings")
          }
          console.log('======edit_meeting=====>', Response);
       
        
        }
      })
      .catch((err) => {
        toast.error(err, +'!');
        console.log('===========', err);
      }); 
  }


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: '500px',
    // height: '50%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    // flexDirection: 'row',
    display: 'flex',
    gap: 2,
  };
  const style2 = {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  };
  const style3 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 3,
  };
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#3366FF',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: 'white',
  }));

  const editSales = async (row) => {
    const id = row._id;
    const token = localStorage.getItem('token');
    if (value.companyName !== '') {
      const body = {
        clientId: id,
        companyName: value.companyName,
        clientName: value.companyName,
        email: value.email,
        linkedinUrl: value.linkedinUrl,
        contactPersonName: value.contactPersonName,
        websiteUrl: value.websiteUrl,
        contactNoWithISD: value.contactNo,
        domain: value.domain,
        designation: value.designation,
        employeeNo: value.employeeNo,
        location: cityy,
        // additionalEmail: value.additionalEmail,
        currentStatus: value.status,
        RPO_Staffing: value.rsc,
      };
      Api(`company/edit_sales_client`, 'POST', body, token)
        .then((Response) => {
          console.log('======edit_sales_client==response=====>', Response);
          if (Response.status === 200) {
            handleClose1();
            toast.success(Response.data.message);
            fun();
            console.log('======getUser===data.data pdata====>', Response);
          }
        })
        .catch((err) => {
          toast.error(err, +'!');
          console.log('===========', err);
        });
    } else {
      setCompanyerr('true');
    }
  };
  const schMeeting = async () => {
    if(statusch==="converted"){
     const token = localStorage.getItem('token'); 
    Api('company/generate/id', 'GET', '', token).then((Response) => {
      if (Response.status === 200) {
        if (Response.data.code === 200) {
       console.log('Response',Response.data.clientCompanyId)
       schMeetingg(Response.data.clientCompanyId)
        }
      }})
   }else{
    schMeetingg()
   }
   
  }
  const schMeetingg = async (ccId) => {
    const token = localStorage.getItem('token');
    const body = {
      clientCompanyId:ccId,
      clientId: row._id,
      guestEmail: statusch==="meetings"?gemail:"",
      cc: statusch==="meetings"?ccc:"",
      subject: statusch==="meetings"?subline:"",
      dateOfMeeting: statusch==="meetings"?meetingDate:"",
      startTime: statusch==="meetings"?startTimee:"",
      endTime: statusch==="meetings"?endTimee:"",
      timeZone: statusch==="meetings"?timez:"",
      modeOfMeeting: statusch==="meetings"?meetingMode:"",
      phone: statusch==="meetings" && meetingMode === "phone"?pNumber:"",
      meetingPlatform: statusch==="meetings" && meetingMode === "conference"?vPlatform:"",
      meetingLink: statusch==="meetings" && meetingMode === "conference"?mUrl:"",
      meetingAddress:statusch==="meetings" && meetingMode === "face to face"?address:"",
      currentStatus: statusch,
      status:statusch==="new"?"new":"" ||statusch==="favourites"?"Moved To Favourites":"" ||statusch==="pipeline"?"Moved To Pipeline":"" ||statusch==="meetings"?"Meeting Scheduled":"" ||statusch==="meetings"?"Meeting Scheduled":"" ||statusch=== "converted"? "Client Engaged" :""||statusch==="passiveclient"?"Moved To Passive Client":"" ||statusch==="dnc"?"Moved To DNC":"",
      date: statusch!=="meetings"?datee:"",
      connectDate: statusch==="passiveclient"?datee:"",
      additionalNotes: statusch==="meetings" && meetingNote,
      note: statusch!=="meetings"?statusnotes:"",
      startDate: statusch==="converted"?ConvertedDate:"",
    };
    Api(`company/add_meeting`, 'POST', body, token)
      .then((Response) => {
        console.log('======company/add_meeting=====>', Response);
        if (Response.status === 200) {
          handleCloses();
          // setValuee(statusch)
          setStatusch('');
          setStatusnotes('');
          // toast.success(Response.data.message);
          // fun1();
          changeS(index)

          console.log('======getUser===data.data pdata====>', Response);
        }
      })
      .catch((err) => {
        toast.error(err, +'!');
        console.log('===========', err);
      });
  };

  const handleEmail = (e) => {
    console.log('======================true=====1=============>>>>>>>>>', evalid);

    setValue({ ...value, email: e.target.value });
    const reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEvalid(reg.test(e.target.value));
    // setTimeout(() => {
    if (evalid === true) {
      console.log('=======email valid', evalid);
    } else {
      console.log('=======email unvalid', evalid);
    }
    console.log('======================true==================>>>>>>>>>', evalid);
  };
  // const chkmail = async (e) => {
  //   const token = localStorage.getItem('token');
  //   const body = {
  //     email: value.email,
  //   };
  //   Api(`resume/duplicate_check`, 'POST', body, token)
  //     .then((response) => {
  //       console.log('===============>>>>>>>', response);
  //       if (response.status === 200) {
  //       } else {
  //         toast.warn(response.data.message, +'!');
  //       }
  //     })
  //     .catch((err) => {
  //       console.log('===========', err);
  //     });
  // };

  const getCity = async (val) => {
    const token = localStorage.getItem('token');
    setCityAndZip([])
    const body = {
      country: value.country,
      query: val,
    };
    Api(`resume/get_city_states`, 'POST', body, token)
      .then((response) => {
        if (response.status === 200) {
          console.log('=========status 200======>>>>>>>', response);
          if (response.data.code === 200) {
            setCityAndZip(response.data.data);
            setCityandstate(response.data.data[0]);
            if (response.data.data[0].zip) {
              setValue({ ...value, zip: response.data.data[0].zip });
            } else {
              setValue({ ...value, zip: '' });
            }
            console.log('=======city and State', response.data.data[0].zip);
          } else {
            // setCityAndZip(response.data.data);
            // toast.error(response.data.message, +'!');
          }
        }
      })
      .catch((err) => {
        console.log('===========', err);
        // toast.error(err, +'!');
      });
  };

  const childFuc = (e) => {
    console.log('e', e);
    setCityy(e);

    // setValue({ ...value, location: e });
    if (e.length >= 3) getCity(e);
  };

  const fullVal = (val) => {
    console.log(val);
    setCityy(`${val.city} (${val.state}) (${val.zip})`);
  };

  const styleSkill = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '95%', sm: '70%' },
    bgcolor: 'background.paper',
    border: '3px solid #3C6BA5',
    boxShadow: 24,
    p: 3,
    borderRadius: 3,
  };
  const styleSkillset = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: { xs: '60%', sm: '50%' },
    width: '50%',
    bgcolor: 'background.paper',
    border: '3px solid #3C6BA5',
    boxShadow: 24,
    p: 3,
    borderRadius: 3,
  };
  const styleClients = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '60%', sm: '50%' },
    bgcolor: 'background.paper',
    border: '3px solid #3C6BA5',
    boxShadow: 24,
    p: 3,
    borderRadius: 3,
  };
 
  const setContact = (e) => {
    const reg = /^[a-zA-Z]*$/
    if(e.length){
      const lst = e[e.length - 1]  
      if(!lst.match(reg)){
        setValue({
          ...value,
          contactNo: e,
        })
      }
    } else{
      setValue({
        ...value,
        contactNo: e,
      })
    }
    
  }

  const changeClientStatus = (val) => {
    const token = localStorage.getItem('token');
    const body={
      "clientId": row._id,
      "status": row.currentStatus,
      "note": newNote,
      }
    
    Api(`company/update_client_status`, 'POST', body, token).then((Response) => {
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          const obj ={
            "status":row.currentStatus,
            "note":newNote,
            "noteBy":localStorage.getItem('username'),
            "createdAt": new Date()

          }
          row.notes.push(obj)
          // fun2(obj)
          noteCloses()
          toast.success(Response.data.message);
          // setTempData(Response.data.data);
        }
      } else {
        toast.warn(Response.data.error, +'!');
      }
    });
  };

  const handleCopy = () => {
    toast.success('Copied to clipboard!', {
      position: toast.POSITION.TOP_CENTER
    });
  };

  return (
    <>
      <TableRow
        key={row._id}
        sx={[
          { background: index % 2 === 0 ? 'text.background' : tablebg },
          { '&:last-child td, &:last-child th': { border: 0 } },
          { '&:hover': { boxShadow: 5 } },
        ]}
      >
        <TableCell sx={{ py: 0 }}>
        { filterTab ==="new"&&(
            <>
          <Typography sx={{}}>
          updated on - {moment(row.createdAt).format('ll')}
          </Typography>
          <Typography sx={{}}>
          Since - {moment().diff(row.createdAt, 'months')} months
          </Typography>
           <Typography
           sx={{ color: 'text.secondary', textDecoration: 'underline', cursor: 'pointer' }}
           onClick={()=>{handleOpen1();getAddEmail(row.companyName)}}
         >
           Edit
         </Typography>
         </>
        )}
        { filterTab ==="favourites"&&(
            <>
          <Typography sx={{}}>
          updated on - {moment(`${row?.notes[row.notes.length - 1]?.date}`).format('ll')}
          </Typography>
          <Typography sx={{}}>
          Since - {moment().diff(row.createdAt, 'months')} months
          </Typography>
           <Typography
           sx={{ color: 'text.secondary', textDecoration: 'underline', cursor: 'pointer' }}
           onClick={()=>{handleOpen1();getAddEmail(row.companyName)}}
         >
           Edit
         </Typography>
         </>
        )}
        { filterTab ==="pipeline"&&(
            <>
          <Typography sx={{}}>
          updated on - {moment(`${row?.notes[row.notes.length - 1]?.date}`).format('ll')}
          </Typography>
          <Typography sx={{}}>
          Since - {moment().diff(row.createdAt, 'months')} months
          </Typography>
           <Typography
           sx={{ color: 'text.secondary', textDecoration: 'underline', cursor: 'pointer' }}
           onClick={()=>{handleOpen1();getAddEmail(row.companyName)}}
         >
           Edit
         </Typography>
         </>
        )}
        { filterTab ==="passiveclient"&&(
            <>
          <Typography sx={{}}>
          Connect On - {moment(`${row.connectDate}`).format('ll')}
          </Typography>
          <Typography sx={{}}>
          Updated - {moment(`${row?.notes[row.notes.length - 1]?.createdAt}`).format('ll')}
          </Typography>
           <Typography
           sx={{ color: 'text.secondary', textDecoration: 'underline', cursor: 'pointer' }}
           onClick={()=>{handleOpen1();getAddEmail(row.companyName)}}
         >
           Edit 
         </Typography>
         </>
        )}
          
          
          { filterTab ==="meetings"&&(
          <>
          <Typography sx={{cursor:'pointer',color:"text.secondary"}} onClick={()=>getMeeting(row._id)}>
              Meeting On - {moment(`${row.meetingDetails?.dateOfMeeting}`).format('ll')}
            </Typography>
          <Typography sx={{cursor:'pointer',textTransform:'uppercase'}} onClick={()=>getMeeting(row._id)}>
             {moment(`${row.meetingDetails?.startTime}`).format('LT')} - {moment(`${row.meetingDetails?.endTime}`).format('LT')} {row.meetingDetails?.timeZone}
            </Typography>
            <Typography
            sx={{ color: 'text.secondary', textDecoration: 'underline', cursor: 'pointer' }}
            onClick={()=> openEditM(row._id)}
          >
            Edit Meeting
          </Typography>
           
            </>
        )}
          { filterTab ==="converted"&&(
            <>
          <Typography sx={{}}>
          Converted On - {moment(`${row?.notes[row.notes.length - 1]?.date}`).format('ll')}
          </Typography>
          <Typography sx={{}}>
          Start on - {moment(`${row.meetingDetails?.startDate}`).format('ll')}
          </Typography>
           <Typography
           sx={{ color: 'text.secondary', textDecoration: 'underline', cursor: 'pointer' }}
           onClick={()=>{handleOpen1();getAddEmail(row.companyName)}}
         >
           Edit
         </Typography>
         </>
        )}
         
          { filterTab ==="dnc"&&(
            <>
          <Typography sx={{}}>
          Updated - {moment(`${row?.notes[row.notes.length - 1]?.date}`).format('ll')}
          </Typography>
          <Typography sx={{}}>
          Since {moment().diff(row.createdAt, 'months')} months
          </Typography>
           <Typography
           sx={{ color: 'text.secondary', textDecoration: 'underline', cursor: 'pointer' }}
           onClick={()=>{handleOpen1();getAddEmail(row.companyName)}}
         >
           Edit
         </Typography>
         </>
        )}
         
         
        </TableCell>
        <TableCell
          //  onClick={() => getComment(row._id)}
          sx={{ py: 0 }}
        >
          <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
            {row.currentStatus === 'dnc' ? (
              <Typography sx={{ fontWeight: '600', cursor: 'pointer',maxWidth: 170, overflow: 'hidden', textOverflow: 'ellipsis' }} color={'red'}>
                {row.companyName}
              </Typography>
            ) : (
              <Typography
                sx={{ fontWeight: '600', cursor: 'pointer',maxWidth: 170, overflow: 'hidden', textOverflow: 'ellipsis' }}
                color={'text.secondary'}
                onClick={(e) => navigate('/salesDetail', { state: row })}
              >
                {row.companyName}
              </Typography>
            )}
            <Iconify
              icon="mdi:web"
              color={'text.textB'}
              sx={{ m: 0.3, height: 15, width: 15, cursor: 'pointer' }}
              hover
              onClick={() => window.open(row.websiteUrl, '_blank')}
            />
          </Stack>
          {row.contactPersonName ? (
            <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
              <Typography sx={{maxWidth:170,overflow:'hidden',textOverflow:'ellipsis'}}>{row.contactPersonName}</Typography>
              <Iconify
                icon="devicon:linkedin"
                color={'text.textG'}
                sx={{ m: 0.3, height: 15, width: 15, cursor: 'pointer' }}
                hover
                onClick={() => window.open(row.linkedinUrl, '_blank')}
              />
            </Stack>
          ) : (
            <Typography sx={{}} color={'text.textG'}>
              -
            </Typography>
          )}

          <Typography sx={{ textTransform: 'uppercase' }} color={'text.textG'}>
            {row.RPO_Staffing || '-'}
          </Typography>
          {/* <Typography sx={{  }} color={'text.textG'}>
            {row.currentCompany}
          </Typography> */}
        </TableCell>
        <TableCell sx={{ py: 0 }}>
          <Typography sx={{ textTransform: 'none' }}>{row.designation || '-'}</Typography>
          {row.email ? (
            <Stack sx={{ flexDirection: 'row' }}>
              <Typography sx={{ textTransform: 'lowercase',maxWidth: 170, overflow: 'hidden', textOverflow: 'ellipsis' }}>{row.email}</Typography>
              <CopyToClipboard text={row.email} onCopy={()=>setEmailCopy(true)}>
                <Iconify
                  icon="radix-icons:copy"
                  color={emailCopy?'text.secondary':'text.textG'}
                  sx={{ ml: 1, height: 17, width: 17, cursor: 'pointer' }}
                />
              </CopyToClipboard>
            </Stack>
          ) : (
            <Typography sx={{ textTransform: 'lowercase' }} color={'text.textG'}>
              -
            </Typography>
          )}
          {/* <Typography sx={{ textTransform:'lowercase' }} color={'text.textG'}>
            {row.additionalEmail}
          </Typography> */}
          {row.contactNoWithISD ? (
            <Stack sx={{ flexDirection: 'row' }}>
              <Typography sx={{maxwidth:170,overflow:'hidden',textOverflow:'ellipsis'}} color={'text.textG'}>
                {row.contactNoWithISD}
              </Typography>
              <CopyToClipboard text={row.contactNoWithISD} onCopy={()=>setPhoneCopy(true)}>
                <Iconify
                  icon="radix-icons:copy"
                  color={phoneCopy?'text.secondary':'text.textG'}
                  sx={{ ml: 1, height: 17, width: 17, cursor: 'pointer' }}
                  hover
                />
              </CopyToClipboard>
            </Stack>
          ) : (
            <Typography sx={{ textTransform: 'lowercase' }} color={'text.textG'}>
              -
            </Typography>
          )}
        </TableCell>

        <TableCell sx={{ py: 0 }}>
          <Typography sx={{maxwidth:170,overflow:'hidden',textOverflow:'ellipsis'}}>{row.location || '-'}</Typography>
          <Typography sx={{maxwidth:170,overflow:'hidden',textOverflow:'ellipsis'}}>{row.domain || '-'}</Typography>
          <Typography sx={{}} color={'text.textG'}>
            {row.employeeNo || '-'}
          </Typography>
        </TableCell>
        <TableCell >
          <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
        <Iconify icon="tabler:activity" fontSize={20} color={'text.secondary'} />
          <Typography
            sx={{ color: 'text.secondary', cursor: 'pointer', py: 0 }}
            onClick={(e) => handleOpenn()}
          >
           + {row.notes ? row.notes.length : ''}
          </Typography>
          </Stack>
        </TableCell>
        <TableCell sx={{ py: 0 }}>
          <Stack sx={{ flexDirection: 'row' }}>
            <Typography sx={{ }}>{row.currentStatus}</Typography>
            
            {/* <Box sx={{ paddingTop: 1.5 }}> */}
              <Iconify
                icon="bx:edit"
                sx={{ width: 15, height: 15 }}
                style={{ cursor: 'pointer' }}
                onClick={(e) => setOpens(true)}
              />
            {/* </Box> */}

          </Stack>

          <Typography sx={{}}>-</Typography>
          <Typography sx={{}} color={'text.textG'}>
            {row.addedBy?row.addedBy:"-"}
          </Typography>
        </TableCell>
        {/* <TableCell sx={{ , maxWidth: 120, overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <Typography variant="body" sx={{ maxWidth: 100 }}>
            {row.notes.length ? row.notes[0].note : ''}
          </Typography>
          {row.notes.length ? (
            <Typography
              sx={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer',  }}
              onClick={handleOpenclient}
            >
              more
            </Typography>
          ) : null}
        </TableCell> */}
        <TableCell sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
          {/* <Button color="custom" type="submit" variant="contained" size="small" onClick={() => handleOpen()}>
            Edit
          </Button> */}
          <Stack sx={{ color: 'text.secondary', flexDirection: 'row', cursor: 'pointer' }}>
            <Iconify icon="ic:baseline-email" fontSize={22} />
            <Typography sx={{ ml: 0.5 }}>Email</Typography>
          </Stack>
          <Stack sx={{ color: 'text.secondary', flexDirection: 'row', cursor: 'pointer' }}>
            <Iconify icon="bx:support" fontSize={22} />
            <Typography sx={{ ml: 0.5 }}>Call / Text</Typography>
          </Stack>
          <Stack sx={{ color: 'text.secondary', flexDirection: 'row', cursor: 'pointer' }}>
            <Iconify icon="fluent:note-16-filled" fontSize={22} />
            <Typography sx={{ ml: 0.5 }} onClick={()=>{setNewNote('');noteOpen()}}>Notes</Typography>
          </Stack>
        </TableCell>
      </TableRow>
      <Modal open={open1} onClose={handleClose1} style={{ borderRadius: 10 }}>
        <Box sx={styleSkill}>
          <Typography variant="h4" color={'#3C6BA5'} textAlign={'center'}>
            Edit Prospect
          </Typography>
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
            <Divider />
            <Grid display={'grid'} gridTemplateColumns={{ md: 'repeat(2, 1fr)', sm: 'repeat(1, 1fr)' }} py={2} gap={4}>
              <Stack gap={2}>
                <FormControl fullWidth>
                  <TextField
                    disabled
                    type="email"
                    id="outlined-basic"
                    label="Email"
                    placeholder="Email"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    value={value.email}
                  />
                </FormControl>

                <TextField
                  error={companyerr}
                  required
                  id="outlined-basic"
                  label="Client Name"
                  placeholder="Client Name"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={value.companyName}
                  onChange={(e) => {
                    setValue({ ...value, companyName: e.target.value });
                    if (value.companyName.length > 4) {
                      setCompanyerr(false);
                    } else {
                      setCompanyerr('true');
                    }
                  }}
                />
                <TextField
                  id="outlined-basic"
                  label="Website"
                  placeholder="Website"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={value.websiteUrl}
                  onChange={(e) => setValue({ ...value, websiteUrl: e.target.value })}
                />
                <FormControl fullWidth>
                  <TextField
                    id="outlined-basic"
                    label="Contact Name"
                    placeholder="Contact Name"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    value={value.contactPersonName}
                    // type='number'
                    onChange={(e) => setValue({ ...value, contactPersonName: e.target.value })}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="outlined-basic"
                    label="Designation"
                    placeholder="Designation"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    value={value.designation}
                    // type='number'
                    onChange={(e) => setValue({ ...value, designation: e.target.value })}
                  />
                </FormControl>
                <TextField
                  id="outlined-basic"
                  label="Linkedin URL"
                  placeholder="Linkedin URL"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={value.linkedinUrl}
                  onChange={(e) => setValue({ ...value, linkedinUrl: e.target.value })}
                />
                <FormControl fullWidth>
                  <TextField
                    id="outlined-basic"
                    label="Contact Number"
                    placeholder="Contact Number"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    value={value.contactNo}
                    // type="number"
                    // onChange={(e) => setValue({ ...value, contactNo: e.target.value })}
                    onChange={(e) => setContact(e.target.value)}
                  />
                </FormControl>
              </Stack>
              <Stack gap={2}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Select Country</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value.country}
                    label="Select Country"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setValue({ ...value, country: e.target.value })}
                  >
                    <MenuItem value="USA">USA</MenuItem>;<MenuItem value="CANADA">CANADA</MenuItem>;
                  </Select>
                </FormControl>
                <CustomComplete
                  placeholder={'City'}
                  option={cityAndZip}
                  propVal={cityy}
                  childFuc={childFuc}
                  fullVal={fullVal}
                  fetch={'state'}
                />
                <TextField
                  id="outlined-basic"
                  label="Domain"
                  placeholder="Domain"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={value.domain}
                  // inputProps={{ type: 'numeric'}}
                  // type='number'
                  onChange={(e) => setValue({ ...value, domain: e.target.value })}
                />
               
                <FormControl sx={{}}>
                  <InputLabel id="demo-simple-select-label">RPO/Staffing/Consulting</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    InputLabelProps={{ shrink: true }}
                    label="RPO/Staffing/Consulting/MSP/Contingent/Other"
                    placeholder="RPO/Staffing/Consulting/MSP/Contingent/Other"
                    value={value.rsc}
                    onChange={(e) => setValue({ ...value, rsc: e.target.value })}
                  >
                    <MenuItem value={'rpo'}>RPO</MenuItem>
                    <MenuItem value={'staffing'}>Staffing</MenuItem>
                    <MenuItem value={'consulting'}>Consulting</MenuItem>
                    <MenuItem value={'MSP'}>MSP</MenuItem>
                    <MenuItem value={'contingent'}>Contingent</MenuItem>
                    <MenuItem value={'other'}>Other</MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">No of Employees</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    InputLabelProps={{ shrink: true }}
                    label="Start Typing Domain"
                    placeholder="Start Typing Domain"
                    value={value.employeeNo}
                    onChange={(e) => setValue({ ...value, employeeNo: e.target.value })}
                  >
                    <MenuItem value={'0-10'}>0-10</MenuItem>
                    <MenuItem value={'10-25'}>10-25</MenuItem>
                    <MenuItem value={'25-50'}>25-50</MenuItem>
                    <MenuItem value={'50-100'}>50-100</MenuItem>
                    <MenuItem value={'100-250'}>100-250</MenuItem>
                    <MenuItem value={'250-500'}>250-500</MenuItem>
                    <MenuItem value={'more than 500'}>more than 500</MenuItem>
                  </Select>
                </FormControl>
                <FormControl sx={{}}>
                  <InputLabel id="demo-simple-select-label">Current Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    InputLabelProps={{ shrink: true }}
                    label="Current Status"
                    placeholder="Current Status"
                    value={value.status}
                    onChange={(e) => setValue({ ...value, status: e.target.value })}
                  >
                    <MenuItem value={'new'}>New</MenuItem>
                    <MenuItem value={'meetings'}>Meetings</MenuItem>
                    <MenuItem value={'pipeline'}>Pipeline</MenuItem>
                    <MenuItem value={'passiveclient'}>Passive Client</MenuItem>
                    <MenuItem value={'favourites'}>Favourites</MenuItem>
                    <MenuItem value={'converted'}>Converted</MenuItem>
                    <MenuItem value={'dnc'}>DNC</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
            </Grid>
             <FormControl fullWidth>
                  <TextField
                    disabled
                    id="outlined-basic"
                    label="Additional Contact"
                    placeholder="Additional Contact"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    value={addEmails}
                    // onChange={(e) => setValue({ ...value, additionalEmail: e.target.value })}
                  />
                </FormControl>
            <Stack flexDirection={'row'} gap={1} mt={1} sx={{ justifyContent: 'center' }}>
              <Button color="custom" variant="contained" onClick={(e) => editSales(row)}>
                Save
              </Button>
            </Stack>
          </Scrollbar>
        </Box>
      </Modal>

      <Modal open={opens} onClose={handleCloses} style={{ borderRadius: 10 }}>
        <Box sx={[styleSkillset]}>
          <Typography variant="h5" color={'#3C6BA5'} textAlign={'center'}>
            Change Status
          </Typography>

          <Divider />
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
          <Grid container sx={{ mt: 2 ,flexDirection:'column',justifyContent:'center',alignItems:'center'}}  gap={2}>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-label">Select New Status</InputLabel>
              <Select
                disabled={convertedDis}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select New Status"
                InputLabelProps={{ shrink: true }}
                value={statusch}
                onChange={(e) => setStatusch(e.target.value)}
              >
                <MenuItem value={'new'}>New</MenuItem>
                <MenuItem value={'meetings'}>Meetings</MenuItem>
                <MenuItem value={'pipeline'}>Pipeline</MenuItem>
                <MenuItem value={'passiveclient'}>Passive Client</MenuItem>
                <MenuItem value={'favourites'}>Favourites</MenuItem>
                <MenuItem value={'converted'}>Converted</MenuItem>
                <MenuItem value={'dnc'}>DNC</MenuItem>
              </Select>
            </FormControl>
            {statusch === 'passiveclient' && (
              <FormControl  sx={{ width: '100%' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker', 'DatePicker']}>
                    <DatePicker
                    disablePast
                      sx={{ width:'100%'}}
                      label="Select Next Connect Date"
                      value={datee}
                      onChange={(e) => {
                        setDatee(e);
                        console.log('date----------', e);
                        // setDatee(e.$D+ e.$M + 1  e.$y)
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </FormControl>
            )}
            {statusch === 'converted' && (
              <FormControl  sx={{ width: '100%' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker', 'DatePicker']}>
                    <DatePicker
                    disablePast
                      sx={{ width:'100%'}}
                      label="Start Date"
                      value={ConvertedDate}
                      onChange={(e) => {
                        setConvertedDate(e);
                        console.log('date----------', e);
                        // setDatee(e.$D+ e.$M + 1  e.$y)
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </FormControl>
            )}
           {statusch === 'meetings' &&(
            <>
            <Stack sx={{ width: '100%',flexDirection:'row',justifyContent:'space-between',gap:1}}>
            <FormControl sx={{ width: '100%'}}>
              <TextField
                id="outlined-multiline-static"
                label="Guest Email"
                placeholder="Guest Email"
                value={gemail}
                onChange={(e) => setGemail(e.target.value)}
              />
            </FormControl>
            <FormControl sx={{ width: '100%'}}>
              <TextField
                id="outlined-multiline-static"
                label="CC"
                placeholder="CC (optional)"
                value={ccc}
                onChange={(e) => setCcc(e.target.value)}
              />
            </FormControl>
            </Stack>
            <FormControl sx={{ width: '100%'}}>
              <TextField
                id="outlined-multiline-static"
                label="Subject Line"
                placeholder="Subject Line"
                value={subline}
                onChange={(e) => setSubline(e.target.value)}
              />
            </FormControl>
            <Stack sx={{ width: '100%',flexDirection:'row',justifyContent:'space-between',gap:1}}>
            <FormControl sx={{ width: '100%'}}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker', 'DatePicker']}>
                <DatePicker
                disablePast
                  sx={{ width: '100%' }}
                  label="Meeting Date"
                  value={meetingDate}
                  onChange={(e) => {
                    setMeetingDate(e);
                    console.log('date----------', e);
                    // setDatee(e.$D+ e.$M + 1  e.$y)
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
            </FormControl>
            <FormControl sx={{ width: '100%'}}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['TimePicker', 'TimePicker']}>
                <TimePicker
                sx={{ width: '100%' }}
                  label="Start Time"
                  value={startTimee}
                  onChange={(e) => {
                    setStartTimee(e);
                    // setItime({ ...itime, hrs: e.$H, min: e.$m });
                    // console.log('===============time===', e.$H, ':', e.$m);
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
            </FormControl>
            </Stack>
            <Stack sx={{ width: '100%',flexDirection:'row',justifyContent:'space-between',gap:1}}>
           
            <FormControl sx={{ width: '100%'}}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['TimePicker', 'TimePicker']}>
                <TimePicker
                sx={{ width: '100%' }}
                  label="End Time"
                  value={endTimee}
                  onChange={(e) => {
                    setEndTimee(e);
                    // setItime({ ...itime, hrs: e.$H, min: e.$m });
                    // console.log('===============time===', e.$H, ':', e.$m);
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
            </FormControl>
            <FormControl sx={{ m: 1,width: '100%' }}>
                      <InputLabel id="demo-simple-select-autowidth-label">Time zone</InputLabel>
                      <Select
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        label="Time Zone"
                        InputLabelProps={{ shrink: true }}
                        placeholder="Time zone"
                        value={timez}
                        onChange={(e) => setTimez(e.target.value)}
                      >
                        <MenuItem value={'est'}>EST</MenuItem>
                        <MenuItem value={'pst'}>PST</MenuItem>
                        <MenuItem value={'mst'}>MST</MenuItem>
                        <MenuItem value={'cst'}>CST</MenuItem>
                      </Select>
            </FormControl>
           
            </Stack>
            <Grid container gap={1} sx={{width:'100%'}} columns={2}>

            <FormControl sx={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-label">Meeting Mode</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                InputLabelProps={{ shrink: true }}
                label="Meeting Mode"
                placeholder="Meeting Mode"
                value={meetingMode}
                onChange={(e) => setMeetingMode(e.target.value)}
              >
                <MenuItem value={'phone'}>Phone</MenuItem>
                <MenuItem value={'conference'}>Video Conference</MenuItem>
                <MenuItem value={'face to face'}>Inperson Meeting</MenuItem>
               
              </Select>
            </FormControl>
            {meetingMode === 'phone' &&(
            <FormControl sx={{ width: '100%'}}>
              <TextField
                id="outlined-multiline-static"
                label="Phone Number"
                placeholder="Note..."
                value={pNumber}
                onChange={(e) => setPNumber(e.target.value)}
              />
            </FormControl>
              )}
              {meetingMode === 'conference' &&(
                <>
            {/* <FormControl sx={{ width: '100%'}}>
                        <InputLabel id="demo-simple-select-label">IMs / Video Platform</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="IMs / Video Platform"
                          value={vPlatform}
                          onChange={(e) => setVPlatform(e.target.value)}
                        >
                          <MenuItem value="microsoftTeam">Microsoft Team</MenuItem>
                          <MenuItem value="googlemeet">Google Meet</MenuItem>
                          <MenuItem value="zoom">Zoom</MenuItem>
                          <MenuItem value="slack">Slack</MenuItem>
                          <MenuItem value="dialpadMeetings">Dialpad Meetings</MenuItem>
                          <MenuItem value="trueConfOnline">TrueConf Online</MenuItem>
                          <MenuItem value="freeConference">Free Conference</MenuItem>
                          <MenuItem value="other">Other</MenuItem>
                        </Select>
             </FormControl> */}
            
            <FormControl sx={{ width: '100%'}}>
              <TextField
                id="outlined-multiline-static"
                label="Meeting URL"
                placeholder="Meeting URL"
                value={mUrl}
                onChange={(e) => setMurl(e.target.value)}
              />
            </FormControl>
            </>
              )}
              {meetingMode === 'face to face' &&(
            <FormControl sx={{ width: '100%'}}>
              <TextField
                id="outlined-multiline-static"
                label="Address"
                placeholder="Note..."
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </FormControl>
              )}
               <FormControl sx={{ width: '100%'}}>
              <TextField
                id="outlined-multiline-static"
                label="Additional Note..."
                placeholder="Additional Note..."
                multiline
                rows={5}
                value={meetingNote}
                onChange={(e) => setMeetingNote(e.target.value)}
              />
            </FormControl>
            </Grid>
            </>
          )}
          {statusch !== 'meetings' &&(
            <FormControl sx={{ width: '100%'}}>
              <TextField
                id="outlined-multiline-static"
                label="Note... (Max 2000 char)"
                placeholder="Note..."
                multiline
                rows={5}
                value={statusnotes}
                onChange={(e) => setStatusnotes(e.target.value)}
              />
            </FormControl>
          )}
          </Grid>
          <Stack sx={{justifyContent:'center',alignItems:'center'}}>
            <Button color="custom" variant="contained" sx={{ width: '10%',mt:2 }} onClick={(e) => schMeeting(row)}>
              Submit
            </Button>

          </Stack>
          </Scrollbar>
        </Box>
      </Modal>
      <Modal open={openn} onClose={handleClosen} style={{ borderRadius: 10 }}>
        <Box sx={[styleSkillset, { width: '70%' }]}>
          <Typography variant="h5" color={'#3C6BA5'} textAlign={'center'}>
            Activities
          </Typography>
            <Divider sx={{mb:2}} />
          <Scrollbar sx={{ maxHeight: 600, Scrollbar: 'thin' }}>
            <Stack sx={{ backgroundColor: 'text.secondary', width: '100%', alignItems: 'center', p: 0.5,borderRadius: 1}}>
              <Stack sx={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between'  }}>
                <Typography sx={{ width: '15%', color: 'white', fontSize: '0.976rem', fontWeight: '600'  }} color={'text.secondary'}>
                  Date
                </Typography>
                <Typography sx={{width: '15%' , color: 'white', fontSize: '0.976rem', fontWeight: '600'  }} color={'text.secondary'}>
                  User
                </Typography>
                <Typography sx={{ width: '25%' , color: 'white', fontSize: '0.976rem', fontWeight: '600'  }} color={'text.secondary'}>
                  Status
                </Typography>
                <Typography sx={{ width: '55%' , color: 'white',fontSize: '0.976rem', fontWeight: '600' }} color={'text.secondary'}>
                  Note
                </Typography>
              </Stack>
            </Stack>
            {row.notes.length &&
              row.notes.slice(0).reverse().map((item, index) => {
                return (
                  <Stack
                    sx={[
                      { background: index % 2 === 0 ? 'text.background' : tablebg },
                      { flexDirection: 'row', justifyContent: 'space-between', p: 1 },
                    ]}
                  >
                    <Typography sx={{ width: '15%' }}>{moment(`${item.createdAt}`).format('ll')}</Typography>
                    <Typography sx={{ width: '15%',color:'text.secondary', textTransform: 'capitalize' ,fontWeight:'600'}}>{item.noteBy}</Typography>
                    {item.status === 'dnc' ? (
                      <Typography sx={{ width: '25%' }}>DNC</Typography>
                    ):null}
                    {item.status === 'passiveclient' ? (
                      <Typography sx={{ width: '25%' }}>Passive Client</Typography>
                    ):null}
                    {item.status !== 'dnc' && item.status !== 'passiveclient' ? (
                      <Typography sx={{ width: '25%' }}>{item.status}</Typography>
                    ):null}
                    <Typography sx={{ width: '55%', textTransform: 'capitalize' }}>
                      {/* <div dangerouslySetInnerHTML={{ __html: item.note }}/>
                       */}

                      {item.note}
                    </Typography>
                  </Stack>
                );
              })}
          </Scrollbar>
        </Box>
      </Modal>
      <Modal open={openclient} onClose={handleCloseclient} style={{ borderRadius: 10 }}>
        <Box sx={styleClients}>
          <Typography sx={{ fontWeight: 'bold', fontSize: 20 }} color={'#3C6BA5'} textAlign={'center'}>
            Note
          </Typography>
          <Scrollbar sx={{ maxHeight: 600, Scrollbar: 'thin' }}>
            <Divider />
            <Stack sx={{ alignItems: 'center' }}>
              {/* <Stack sx={{ backgroundColor: 'text.hover', width: '100%', alignItems: 'center' }}>
                <Stack sx={{ width: '50%', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Typography variant="h5" textAlign={'center'} sx={{ p: 2 }}>
                    Client
                  </Typography>
                  <Typography variant="h5" textAlign={'center'} sx={{ p: 2 }}>
                    Status
                  </Typography>
                </Stack>
              </Stack> */}
              <Typography textAlign={'center'} sx={{ p: 2 }}>
                {row.notes.length && row.notes[0].note}
              </Typography>
            </Stack>
          </Scrollbar>
        </Box>
      </Modal>
      <Modal open={openmeeting} onClose={handleCloseM} style={{ borderRadius: 10 }}>
        <Box sx={[styleSkillset, { width: '70%' }]}>
        <Stack sx={{width:'100%',alignItems:'flex-end'}}>
            <Button          
            color="custom"
            variant="contained" 
            size='small'
            onClick={()=>{handleCloseM();openEditM(row._id)}}
           >
            Edit Meeting
            </Button>
          </Stack>
          <Typography variant="h5" color={'#3C6BA5'} sx={{ textTransform: 'capitalize',mt:-3}} textAlign={'center'}>
          Meeting Detail
            </Typography>
          
          <Scrollbar sx={{ maxHeight: 600, Scrollbar: 'thin' }}>
            <Divider />
            <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', backgroundColor: 'text.hover', p: 1 }}>
              <Stack sx={{ alignItems: 'flex-start', width: '30%' }}>
                <Typography sx={{ fontSize: '0.976rem', fontWeight: '600' }} color={'text.secondary'}>
                  Date
                </Typography>
              </Stack>
              <Stack sx={{ alignItems: 'flex-start', width: '30%' }}>
                <Typography sx={{ fontSize: '0.976rem', fontWeight: '600' }} color={'text.secondary'}>
                  Time
                </Typography>
              </Stack>
              <Stack sx={{ alignItems: 'flex-start', width: '30%' }}>
                <Typography sx={{ fontSize: '0.976rem', fontWeight: '600' }} color={'text.secondary'}>
                  Mode
                </Typography>
              </Stack>
             
            </Stack>
            
                  <Stack
                    sx={[
                      { background: index % 2 === 0 ? 'text.background' : tablebg },
                      { flexDirection: 'row', justifyContent: 'space-between', p: 1 },
                    ]}
                  >
                    <Typography sx={{ width: '30%' }}>{moment(meetingData?.dateOfMeeting).format('ll')}</Typography>
                    <Typography sx={{ width: '30%', textTransform: 'uppercase' }}>{moment(meetingData?.startTime).format('LT')} - {moment(meetingData?.endTime).format('LT')} {meetingData?.timeZone}</Typography>
                    <Stack sx={{flexDirection:"row", width: '30%', textTransform: 'capitalize',overflow:'hidden' }}>
                      {meetingData?.modeOfMeeting === 'phone'&&(
                        <>
                        <Typography sx={{ textTransform: 'capitalize' }}>Phone - ({meetingData?.phone})</Typography>
                        <CopyToClipboard text={`
                          Subject : ${meetingData.subject}
                          Date: ${moment(meetingData.dateOfMeeting).format('ll')}
                          Time:  ${moment(meetingData.startTime).format('LT')} - ${moment(meetingData.endTime).format('LT')} ${meetingData.timeZone}                       
                          Mode: ${"Phone"} - ${meetingData.phone},
                          Additional Info Notes : ${meetingData.additionalNotes}                                                                  
                          `}>
                        <Iconify
                          icon="radix-icons:copy"
                          color={'text.textG'}
                          sx={{  ml: 1, height: 17, width: 17, cursor: 'pointer' }}
                          hover
                        />
                      </CopyToClipboard>
                      </>
                      )}
                      {meetingData?.modeOfMeeting === 'conference'&&(
                        <>
                        <Typography sx={{ textTransform: 'capitalize' }}>Video Conference - </Typography>
                       
                       
                      <Typography sx={{ textTransform: 'capitalize' ,color:'text.secondary'}} 
                        onClick={()=>{window.location.href=meetingData.meetingLink}}
                        >
                          &nbsp; meeting URL
                          </Typography>
                          {/* <a href={meetingData.meetingLink} target="_blank" rel="noreferrer">
                          &nbsp; Meeting link
                          </a> */}
                       
                        <CopyToClipboard text={`
                          Subject : ${meetingData.subject}
                          Date: ${moment(meetingData.dateOfMeeting).format('ll')}
                          Time:  ${moment(meetingData.startTime).format('LT')} - ${moment(meetingData.endTime).format('LT')} ${meetingData.timeZone}                     
                          Mode: ${"Video Conference"} - ${meetingData.meetingLink},
                          Additional Info Notes : ${meetingData.additionalNotes}                                                                  
                          `}>
                        <Iconify
                          icon="radix-icons:copy"
                          color={'text.textG'}
                          sx={{  ml: 1, height: 17, width: 17, cursor: 'pointer' }}
                          hover
                        />
                        </CopyToClipboard>
                        </>
                      )}
                      {meetingData?.modeOfMeeting === 'face to face'&&(
                         <>
                         <Typography sx={{ textTransform: 'capitalize',maxWidth:'90%' }}>Inperson Meeting - {meetingData.meetingAddress}</Typography>
                         <CopyToClipboard text={`
                          Subject : ${meetingData.subject}
                          Date: ${moment(meetingData.dateOfMeeting).format('ll')}
                          Time:  ${moment(meetingData.startTime).format('LT')} - ${moment(meetingData.endTime).format('LT')} ${meetingData.timeZone}                       
                          Mode: ${"Inperson Meeting"} - ${meetingData.meetingAddress},
                          Additional Info Notes : ${meetingData.additionalNotes}                                                                  
                          `}>
                         <Iconify
                           icon="radix-icons:copy"
                           color={'text.textG'}
                           sx={{  ml: 1, height: 17, width: 17, cursor: 'pointer' }}
                           hover
                         />
                         </CopyToClipboard>
                         </>
                      )}
                   
                    </Stack>
                  </Stack>
               
              
          </Scrollbar>
        </Box>
      </Modal>
      
      <Modal open={editM} onClose={closeM} style={{ borderRadius: 10 }}>
        <Box sx={[styleSkillset]}>
          <Typography variant="h5" color={'#3C6BA5'} textAlign={'center'}>
            Edit Meetings
          </Typography>
          <Divider />
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
          <Grid container sx={{ mt: 2 ,flexDirection:'column',justifyContent:'center',alignItems:'center'}}  gap={2}>  
          <Stack sx={{flexDirection:'row',gap:1,width:'100%',justifyContent:'space-between'}}>     
            <FormControl sx={{ width: '100%'}}>
              <TextField
                id="outlined-multiline-static"
                label="Guest Email"
                placeholder="Guest Email"
                value={editgemail}
                onChange={(e) => setEditgemail(e.target.value)}
              />
            </FormControl>
            <FormControl sx={{ width: '100%'}}>
              <TextField
                id="outlined-multiline-static"
                label="CC"
                placeholder="CC (optional)"
                value={editccc}
                onChange={(e) => setEditccc(e.target.value)}
              />
            </FormControl>
            </Stack>
            <FormControl sx={{ width: '100%'}}>
              <TextField
                id="outlined-multiline-static"
                label="Subject Line"
                placeholder="Subject Line"
                value={editsubline}
                onChange={(e) => setEditsubline(e.target.value)}
              />
            </FormControl>
            <Stack sx={{flexDirection:'row',gap:1,width:'100%',justifyContent:'space-between'}}>
            <FormControl sx={{ width: '100%'}}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker', 'DatePicker']}>
                <DatePicker
                disablePast
                  sx={{ width: '100%' }}
                  label="Meeting Date"
                  value={editmeetingDate}
                  onChange={(e) => {
                    setEditmeetingDate(e);
                    console.log('date----------', e);
                    // setDatee(e.$D+ e.$M + 1  e.$y)
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
            </FormControl>
            <FormControl sx={{ width: '100%'}}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['TimePicker', 'TimePicker']}>
                <TimePicker
                sx={{ width: '100%' }}
                  label="Start Time"
                  value={editstartTimee}
                  onChange={(e) => {
                    setEditstartTimee(e);
                    // setItime({ ...itime, hrs: e.$H, min: e.$m });
                    // console.log('===============time===', e.$H, ':', e.$m);
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
            </FormControl>
           
           
            </Stack>
            <Stack sx={{flexDirection:'row',gap:1,width:'100%',justifyContent:'space-between'}}>
            
            <FormControl sx={{ width: '100%'}}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['TimePicker', 'TimePicker']}>
                <TimePicker
                sx={{ width: '100%' }}
                  label="End Time"
                  value={editendTimee}
                  onChange={(e) => {
                    setEditendTimee(e);
                    // setItime({ ...itime, hrs: e.$H, min: e.$m });
                    // console.log('===============time===', e.$H, ':', e.$m);
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
            </FormControl>
            <FormControl sx={{ m: 1,width: '100%' }}>
                      <InputLabel id="demo-simple-select-autowidth-label">Time zone</InputLabel>
                      <Select
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        label="Time Zone"
                        InputLabelProps={{ shrink: true }}
                        placeholder="Time zone"
                        value={edittimez}
                        onChange={(e) => setEdittimez(e.target.value)}
                      >
                        <MenuItem value={'est'}>EST</MenuItem>
                        <MenuItem value={'pst'}>PST</MenuItem>
                        <MenuItem value={'mst'}>MST</MenuItem>
                        <MenuItem value={'cst'}>CST</MenuItem>
                      </Select>
            </FormControl>
            </Stack>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-label">Meeting Mode</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                InputLabelProps={{ shrink: true }}
                label="Meeting Mode"
                placeholder="Meeting Mode"
                value={editmeetingMode}
                onChange={(e) => setEditmeetingMode(e.target.value)}
              >
                <MenuItem value={'phone'}>Phone</MenuItem>
                <MenuItem value={'conference'}>Video Conference</MenuItem>
                <MenuItem value={'face to face'}>Inperson Meeting</MenuItem>
               
              </Select>
            </FormControl>
            {editmeetingMode === 'phone' &&(
            <FormControl sx={{ width: '100%'}}>
              <TextField
                id="outlined-multiline-static"
                label="Phone Number"
                placeholder="Note..."
                value={editpNumber}
                onChange={(e) => setEditpNumber(e.target.value)}
              />
            </FormControl>
              )}
              {editmeetingMode === 'conference' &&(
                <>
            {/* <FormControl sx={{ width: '100%'}}>
                        <InputLabel id="demo-simple-select-label">IMs / Video Platform</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="IMs / Video Platform"
                          value={editvPlatform}
                          onChange={(e) => setEditvPlatform(e.target.value)}
                        >
                          <MenuItem value="microsoftTeam">Microsoft Team</MenuItem>
                          <MenuItem value="googlemeet">Google Meet</MenuItem>
                          <MenuItem value="zoom">Zoom</MenuItem>
                          <MenuItem value="slack">Slack</MenuItem>
                          <MenuItem value="dialpadMeetings">Dialpad Meetings</MenuItem>
                          <MenuItem value="trueConfOnline">TrueConf Online</MenuItem>
                          <MenuItem value="freeConference">Free Conference</MenuItem>
                          <MenuItem value="other">Other</MenuItem>
                        </Select>
             </FormControl> */}
            
            <FormControl sx={{ width: '100%'}}>
              <TextField
                id="outlined-multiline-static"
                label="Meeting URL"
                placeholder="Meeting URL"
                value={editMurl}
                onChange={(e) => setEditMurl(e.target.value)}
              />
            </FormControl>
            </>
              )}
              {editmeetingMode === 'face to face' &&(
            <FormControl sx={{ width: '100%'}}>
              <TextField
                id="outlined-multiline-static"
                label="Address"
                placeholder="Note..."
                value={editaddress}
                onChange={(e) => setEditaddress(e.target.value)}
              />
            </FormControl>
              )}
               <FormControl sx={{ width: '100%'}}>
              <TextField
                id="outlined-multiline-static"
                label="Additional Note..."
                placeholder="Additional Note..."
                multiline
                rows={5}
                value={editmeetingNote}
                onChange={(e) => setEditmeetingNote(e.target.value)}
              />
            </FormControl>
           
         
          </Grid>
          <Stack sx={{justifyContent:'center',alignItems:'center'}}>
            <Button color="custom" variant="contained" sx={{ width: '10%',mt:2 }} onClick={(e) => editmeetingData(row._id)}>
              Submit
            </Button>
          </Stack>
          </Scrollbar>
        </Box>
      </Modal>

      <Modal open={upnote} onClose={noteCloses} style={{ borderRadius: 10 }}>
        <Box sx={[styleClients, { width: { xs: '60%', sm: '50%' } }]}>
          <Typography variant="h5" color={'#3C6BA5'} textAlign={'center'}>
            Update Note
          </Typography>
          <Divider sx={{mb:2}}/>
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
            <Grid
              container
              sx={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
              gap={2}
            >
              <FormControl fullWidth mt={1}>
              <TextField
                id="outlined-multiline-static"
                label="Note... (Max 2000 char)"
                placeholder="Note..."
                multiline
                rows={5}
                value={newNote}
                onChange={(e) => setNewNote(e.target.value)}
              />
              </FormControl>
            </Grid>
            <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
              <Button
                color="custom"
                variant="contained"
                sx={{ width: '10%', mt: 2 }}
                 onClick={() => changeClientStatus()}
                
              >
                Submit
              </Button>
            </Stack>
          </Scrollbar>
        </Box>
      </Modal>
    </>
  );
}
