import { useState } from 'react';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { useNavigate } from 'react-router-dom';
import './style.css';
// @mui
import { Link, Stack, Button, InputAdornment, TextField, Checkbox, Typography, Divider } from '@mui/material';
// import Visibility from '@mui/icons-material/Visibility';
// import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { LoadingButton } from '@mui/lab';
// components
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Iconify from '../../../components/iconify';
import { Api } from '../../../webservices';

// ----------------------------------------------------------------------

export default function ForgotForm(props) {
  const { func1 } = props;
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [value, setValue] = useState({ email: '', pass1: '', pass2: '', otp1: '', otp2: '', otp3: '', otp4: '' });
  const [send, setSend] = useState(true);
  const [otp, setOtp] = useState(false);
  const [pass, setPass] = useState(false);
  const [suid, setSuid] = useState('');
  const [newpass, setNewpass] = useState('');
  const [valueo, setValueo] = useState('');
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleChange = (newValue) => {
    if (Number(newValue)) {
      setValueo(newValue);
    } else {
      setValueo('');
    }
  };

  const handleComplete = (finalValue) => {
    fetch('...');
  };

  const handleClick = () => {
    navigate('/usermanagement', { replace: true });
  };

  const forgot = (e) => {
    // alert('ashish')
    e.preventDefault();
    const body = {
      email: value.email,
    };
    Api(`company/forgot_super_password`, 'POST', body, '')
      .then((response) => {
        console.log('===============>>>>>>>', response);
        if (response.status === 200) {
          setSend(false);
          setOtp(true);
          setSuid(response.data.userID);
          console.log(response.data.superAdminId);
          toast.success(response.data.message, +'!');
          // setTableData(response.data.data)
        } else {
          toast.warn(response.data.message, +'!');
        }
      })
      .catch((err) => {
        console.log('===========', err);
        toast.warn(err);
      });
  };
  const sendotp = (e) => {
    // alert('ashish')
    e.preventDefault();
    const body = {
      superAdminId: suid,
      otp: valueo,
      // "otp": value.otp1+value.otp2+value.otp3+value.otp4
    };
    Api(`company/verify_super_otp`, 'POST', body, '')
      .then((response) => {
        console.log('===============>>>>>>>', response);
        if (response.status === 200) {
          setSend(false);
          setOtp(false);
          setPass(true);
          toast.success(response.data.message, +'!');
          // setTableData(response.data.data)
        } else {
          toast.warn(response.data.message, +'!');
        }
      })
      .catch((err) => {
        console.log('===========', err);
        toast.warn(err);
      });
  };
  function handlePass() {
    // let reg = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
    // let reg = ^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,16}$ ;

    if (value.pass1 !== '' && value.pass1.length > 7) {
      if (value.pass1 !== value.pass2) {
        toast.warn('Password Fields Do Not Match');
      } else {
        createPass();
      }
    } else {
      // toast.warn('Password must be at least 8 characters');
      // toast.warn(value.pass1.length)
    }
  }
  const createPass = (e) => {
    // alert(suid)
    // e.preventDefault();
    const body = {
      superAdminId: suid,
      newPassword: value.pass1,
    };
    Api(`company/change_super_password`, 'POST', body, '')
      .then((response) => {
        console.log('===============>>>>>>>', response);
        if (response.status === 200) {
          toast.success(response.message);
          func1();
          setSend(false);
          setOtp(false);
          setPass(true);

          // setTableData(response.data.data)
        } else {
          toast.warn(response.data.message, +'!');
        }
      })
      .catch((err) => {
        console.log('===========', err);
        toast.warn(err);
      });
  };
  return (
    <Stack bgcolor={'white'} padding={'30px'} border={'1px solid #dadada'} borderRadius={3}>
      <ToastContainer />
      {send === true ? (
        <Stack>
          <Typography variant="h4" color="#2E3C49" gutterBottom mb={5} whiteSpace={'nowrap'}>
            Forgot Your Account
          </Typography>
          <Stack spacing={3} className="email_field" mb={2}>
            <input
              label="Email Id"
              color="secondary"
              placeholder="Email Id"
              onChange={(e) => setValue({ ...value, email: e.target.value })}
            />

            <Stack flexDirection={'row'} alignItems={'center'} gap={4}>
              <button className="login_btn" onClick={(e) => forgot(e)}>
                Send
              </button>
            </Stack>
          </Stack>
        </Stack>
      ) : null}
      {otp === true ? (
        <Stack>
          <Typography variant="h4" color="#2E3C49" gutterBottom mb={5} whiteSpace={'nowrap'}>
            Enter Your OTP
          </Typography>
          <Stack spacing={3} className="email_field" mb={2}>
            <Stack gap={2} flexDirection={'row'}>
              {/* <TextField label="" color="secondary" placeholder="*" onChange={(e)=>setValue({...value,otp1: e.target.value})} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*',maxLength:1,minLength:1 }}  style={{width:'15%'}} />
          <TextField label="" color="secondary" placeholder="*" onChange={(e)=>setValue({...value,otp2: e.target.value})} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*',maxLength:1,minLength:1 }} style={{width:'15%'}} />
          <TextField label="" color="secondary" placeholder="*" onChange={(e)=>setValue({...value,otp3: e.target.value})} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*',maxLength:1,minLength:1 }}  style={{width:'15%'}} />
          <TextField label="" color="secondary" placeholder="*" onChange={(e)=>setValue({...value,otp4: e.target.value})} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*',maxLength:1,minLength:1 }} style={{width:'15%'}} /> */}
              <MuiOtpInput
                value={valueo}
                onChange={handleChange}
                onComplete={handleComplete}
                length={6}
                inputMode="numeric"

                // validateChar={(character: string, index: number) => true}
              />
            </Stack>

            <Stack flexDirection={'row'} alignItems={'center'} gap={4}>
              <button className="login_btn" onClick={(e) => sendotp(e)}>
                Send
              </button>
            </Stack>
          </Stack>
        </Stack>
      ) : null}
      {pass === true ? (
        <Stack>
          <Typography variant="h4" color="#2E3C49" gutterBottom mb={5} whiteSpace={'nowrap'}>
            Create Your Password
          </Typography>
          <Stack spacing={3} mb={2}>
            <FormControl sx={{ width: '35ch' }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <Iconify
                      icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  </InputAdornment>
                }
                label="Password"
                onChange={(e) => setValue({ ...value, pass1: e.target.value })}
                // inputProps={{ maxLength: 8, minLength: 8 }}
              />
            </FormControl>
            <FormControl sx={{ width: '35ch' }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <Iconify
                      icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  </InputAdornment>
                }
                label="Confirm Password"
                onChange={(e) => setValue({ ...value, pass2: e.target.value })}
                // inputProps={{ maxLength: 8, minLength: 8 }}
              />
            </FormControl>
            {/* <TextField
                type={showPassword ? 'text' : 'password'}
                label="Confirm Password"
                 color="secondary" 
                 placeholder="Confirm Password" 
                 onChange={(e)=>setValue({...value,pass2: e.target.value})}
                
              />
              <Iconify
                icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                onClick={() => setShowPassword(!showPassword)}
              /> */}

            <Stack flexDirection={'row'} alignItems={'center'} gap={4}>
              <button className="login_btn" onClick={(e) => handlePass(e)}>
                Create
              </button>
              {/* <Button variant="contained" color='red'  onClick={(e)=>createPass(e)}>
             Create New Password
             </Button> */}
            </Stack>
          </Stack>
        </Stack>
      ) : null}
      <Divider />
      <Button variant="contained" className="newacc_btn">
        CREATE NEW ACCOUNT
      </Button>

      {/* <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Login
      </LoadingButton> */}
    </Stack>
  );
}
