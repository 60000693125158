import React, { useEffect, useRef, useState } from 'react';
import {
  Grid,
  Stack,
  Typography,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  Checkbox,
  FormGroup,
  styled,
  Switch,
  Button,
  TableCell,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  InputAdornment,
} from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { Icon } from '@iconify/react';
import { ToastContainer, toast } from 'react-toastify';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar/Scrollbar';
import CustomComplete from '../components/customComplete/CustomComplete';
import { Api,UploadFile } from '../webservices';

const filter = createFilterOptions();
function AddJob() {
  const inputRef = useRef('');
  const navigate = useNavigate();
  const locationData = useLocation(); 
  const [editJobDes,setEditJobDes] =useState(false) 
  const [editScreen,setEditScreen] =useState(false) 
  const [editRec,setEditRec] =useState(false) 
  const [job,setJob] = useState('')
  const [clientList, setClientList] = useState([]);
  const [cId,setCId] = useState('');
  const [leadId,setLeadId] = useState('');
  const [leadlist, setLeadlist] = useState([]); 
  const [cityAndZip, setCityAndZip] = useState([]);
  const [cityy, setCityy] = useState('');
  const [visa, setVisa] = useState([]);
  const [skill, setSkill] = useState([]);
  const [dskill, setDskill] = useState([]);
  const [degree, setDegree] = useState([]);
  const [certificate, setCertificate] = useState([]);
  const [pdffile, setPdffile] = useState('');
  const [upload, setUpload] = useState(false);
  const [tempId, setTempId] = useState();
  const [finallst,setFinallst] = useState([])
  const [formValues, setFormValues] = useState({
      jobId: '',
      client: '',    
      clientMailExist: false,
      submissionEmailTo: '',    
      submissionEmailCc: '',                      
      country:'USA',
      title:'',
      location: '',
      relocation: false,
      relocationAssistance: '',
      contractDuration: '',
      compensation: '',
      hourlyRate: '',
      taxTerm: 'c2c',
      minExperience:"",
      maxExperience:"",
      mustHaveSkill: '',
      maxExp: '',
      minExp: '',
      dmustHaveSkill: '',
      dmaxExp: '',
      dminExp: '',
      hiringManagerNotes: '',
      jobDescription: '',
      endClientWebLink:'',
      endClient:  '',
      isEnd: '',
      lead: 0,
      jobOpening: '',
      altJobTitle:'',
      outofArea:   '',
      jobType: '',
      remote:'',
      duration:  '',
      sponsorship: '',
      status: 'Active',
      compensationType:'annual',
      relocationExpenses: 'yes',
      relocationAmount:'',
      rInstructions:'',
      interviewExpenses:'yes',
      interviewAmount:'',
      benefits:'yes',
      benefitsAmount:'',
      annualMinCompensation:'', 
      annualMaxCompensation:'',
      c2cMin:"",
      c2cMax:'',
      PayMax:'',
      PayMin:'',
      billMax:'',
      billMin:'',
      screeningQuestion:'',   
      feeType: 'K',
      fee:'',
      guaranteePeriod:'',
      degreeRequired:'Required',
      degree:'',
      certifications:'',
      certificationRequired:'Required',
      notes:'',
    });
   
    const [jobDescription,setJobDes] = useState('')
    const [screeningQuestions,setScreening] = useState('')
    const [recruitingInst,setRec] = useState('')
    
  useEffect(() => {     
      console.log('row data-  - --- ',locationData ) 
      if (locationData.state.rowData!==undefined && locationData.state.rowData!==null && locationData.state.rowData!==''){
        getClient(); 
        const obj = {'contactPersonName':locationData.state.rowData.client,
        '_id':locationData.state.rowData._id,
        'companyName':locationData.state.rowData.companyName,
         'websiteUrl':locationData.state.rowData.web}
        // setClientList([obj])
        setTempId([obj])
        setFormValues({...formValues, client: locationData.state.rowData._id,submissionEmailTo:locationData.state.rowData.email }) 
        getLeadFor(locationData.state.rowData.companyName)
        
      }else{
        getClient();
      
      }
      
    }, [locationData.state.rowData]);
  
  useEffect(() => {
    if (locationData.state.row!==undefined && locationData.state.row!==null && locationData.state.row!=='') {
   
      setFormValues({...formValues,
        jobId: locationData.state.row.jobId,
        client: locationData.state.row.clientId,    
        clientMailExist: false,
        submissionEmailTo: locationData.state.row.submissionEmail ,    
        submissionEmailCc: locationData.state.row.submissionEmailCc ,                      
        country: locationData.state.row.country,
        title: locationData.state.row.jobTitle ,
        location: '',
        relocation: false,
        relocationAssistance: '',
        contractDuration: '',
        compensation: '',
        hourlyRate: '',
        taxTerm: locationData.state.row.taxTerm,
        mustHaveSkill: '',
        maxExp: '',
        minExp: '',
        dmustHaveSkill: '',
        dmaxExp: '',
        dminExp: '',
        hiringManagerNotes: '' ,
        endClientWebLink: locationData.state.row.website ,
        endClient: locationData.state.row.endClientOrImpPartner,
        isEnd: locationData.state.row.isEndClient ,
        lead:0,
        jobOpening: locationData.state.row.openings ,
        altJobTitle: locationData.state.row.altJobTitle ,
        outofArea:  locationData.state.row.outOfAreaCandidate,
        jobType:locationData.state.row.jobType,
        remote: locationData.state.row.remote ,
        duration: locationData.state.row.duration,
        sponsorship: locationData.state.row.visaSponsorship ,
        status: locationData.state.row.status ,
        compensationType:locationData.state.row.compensationType,
        relocationExpenses: locationData.state.row.relocationAssistance,
        relocationAmount: locationData.state.row.relocationExpenses ,
        interviewExpenses:'yes',
        interviewAmount:locationData.state.row.interviwExpenses ,
        benefits:'yes',
        benefitsAmount:locationData.state.row.benefits ,
        annualMinCompensation:locationData.state.row.annualMinCompensation , 
        annualMaxCompensation:locationData.state.row.annualMaxCompensation ,
        c2cMin:locationData.state.row.onC2c1099Inc.minC2c1099Inc,
        c2cMax:locationData.state.row.onC2c1099Inc.maxC2c1099Inc,
        PayMax:locationData.state.row.payRateOn.maxPayRate,
        PayMin:locationData.state.row.payRateOn.minPayRate,
        billMax:locationData.state.row.billRate.maxBillRate,
        billMin:locationData.state.row.billRate.minBillRate,
        feeType:locationData.state.row.feeType ,
        fee:locationData.state.row.fee ,
        guaranteePeriod:locationData.state.row.guaranteePeriod ,
        degreeRequired:locationData.state.row.degreeRequired ,
        degree:locationData.state.row.degree ,
        certifications:locationData.state.row.certifications ,
        certificationRequired:locationData.state.row.certificationRequired,
        notes:locationData.state.row.notes ,
        minExperience: locationData.state.row.minExperience,
        maxExperience: locationData.state.row.maxExperience,
      })  
      
      // setClientList([{companyName:locationData.state.row.companyName,_id:locationData.state.row.clientId}])
       console.log("usueff")
      // setClientList([temp])
      const obj = {'contactPersonName':locationData.state.row.client,
        '_id':locationData.state.row.clientId,
        'companyName':locationData.state.row.client,
         'websiteUrl':locationData.state.row.website}
        // setClientList([obj])
        setTempId([obj])
      console.log("bill ratye",locationData.state.row.onC2c1099Inc.minC2c1099Inc)
      setLeadlist([{contactPersonName:locationData.state.row.lead,_id:locationData.state.row.leadId}])
      setVisa(locationData.state.row.taxTerm)                    
      setJob(locationData.state.row._id)
      setCityy(locationData.state.row.location);
      setSkill(locationData.state.row.skillWithExp);
      setDskill(locationData.state.row.desiredSkillsWithExp)
      setDegree(locationData.state.row.degree)
      setCertificate(locationData.state.row.certifications)
      if(locationData.state.row.altJobTitle.length){
        const tempArr = []
        locationData.state.row.altJobTitle.map((item) => {
          return tempArr.push({title:item})
        })
        setFinallst(tempArr)
      }   
      setJobDes(locationData.state.row.jobDescription)
      setScreening(locationData.state.row.screeningQuestion)
      setRec(locationData.state.row.recruitingInst)
    }
  }, [locationData.state.row]);    
 
 
  const getJobID=async()=>{
    const token = localStorage.getItem('token');
      Api(`company/jobid`, 'GET', '', token)
        .then((res) => {
          // alert(res.data.jobId)
          if (res.status === 200) {
          if (res.data.code === 200) {
            console.log("jobid----------------`----",JSON.stringify(res));
            const _id =res.data.id
            const jobId =res.data.jobId
            addJob(_id,jobId)
              setJob(res.data.id);
              setFormValues({...formValues,jobId:res.data.jobId})
          } else {   
              
              toast.warn(res.error)
              // setLeadlist(res.data.data);
          } 
          }
        })
        .catch((err) => {
          toast.warn(err)
          console.log(err);
        });
  }

  const getCity = async (val) => {
    setCityAndZip([])
    const token = localStorage.getItem('token');
    const body = {
      country: formValues.country,
      query: val,
    };
    Api(`resume/get_city_states`, 'POST', body, token)
      .then((response) => {
        if (response.status === 200) {
          console.log('=========status 200======>>>>>>>', response);
          if (response.data.code === 200) {
            setCityAndZip(response.data.data);
            // setCityandstate(response.data.data[0]);
            console.log('=======city and State', response.data.data[0].zip);
          } else {
            // setCityAndZip(response.data.data);
            // toast.error(response.data.message, +'!');
          }
        }
      })
      .catch((err) => {
        console.log('===========', err);
        // toast.error(err, +'!');
      });
  };
  const childFuc = (e) => {
    console.log('e', e);
    setCityy(e);
    // setValue({ ...value, location: e });
    if (e.length >= 3) getCity(e);
  };
  const fullVal = (val) => {
    setCityy(`${val.city} ${val.stateCode} ${val.zip}`);
    // setCityy(`${val.city} (${val.state}) (${val.zip})`);
  };
 
  const getClient = async () => {
    const id = localStorage.getItem('userid');
    const token = localStorage.getItem('token');
    const body = {
      status:''
    };
    // Api(`company/get_clients_by_role`, 'POST', body, token)
    Api(`company/active_clients`, 'GET', '', token)
      .then((response) => {
        console.log('=========get_sales_client_list======>>>>>>>', response.data);
        if (response.status === 200) {
          if (response.data.data.length) {
            // toast.success(response.data.message, +'!');
            console.log("--------------",clientList)
            setClientList(response.data.data);
          } else {
            setClientList([]);
          }
        } else {
          setClientList([]);
        }
      })
      .catch((err) => {
        console.log('===========', err);
        toast.error(err, +'!');
      });
  };
  const getLeads=async(e)=>{
    const cName = clientList.filter((item) => item._id === e);
    setFormValues({...formValues,submissionEmailTo:cName[0].email,client:e})
    setTempId(cName)
    const token = localStorage.getItem('token');
  Api(`company/get_comp_details/${cName[0].companyName}`, 'GET', '', token)
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            if (res.data.code === 200) {
              setLeadlist(res.data.data);
              // setFormValues({ ...formValues, lead: 0 })
            } else {
              toast.success(res.data.message);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
  }
  const getLeadFor=async(e)=>{
    const token = localStorage.getItem('token');
  Api(`company/get_comp_details/${e}`, 'GET', '', token)
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            if (res.data.code === 200) {
              setLeadlist(res.data.data);
              // setFormValues({ ...formValues, lead: 0 })
            } else {
              toast.success(res.data.message);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
  }

  const addJob = (_id , jobId) => {
    const altList = [];
    finallst.map((item) => {
      return altList.push(item.title);
    });
    const token = localStorage.getItem('token');
    const body =
      {
        "id": _id || job,
        "client": tempId[0].companyName,
        "clientId":formValues.client,
        "isEndClient": formValues.isEnd,
        "submissionEmail": formValues.submissionEmailTo,
        "submissionEmailCc": formValues.submissionEmailCc ,
        "endClientOrImpPartner": formValues.endClient,
        "website": formValues.endClientWebLink,
        "lead":leadlist[formValues.lead].contactPersonName,
        "leadId":leadlist[formValues.lead]._id,
        "recruitingInst": formValues.rInstructions,
        "jobId": jobId || formValues.jobId,
        "jobTitle": formValues.title,
        "openings": formValues.jobOpening,
        "altJobTitle": altList,
        "country": formValues.country,
        "location":cityy,
        "outOfAreaCandidate": formValues.outofArea,
        "jobType": formValues.jobType,
        "remote": formValues.remote,
        "visaSponsorship": formValues.sponsorship,
        "duration": formValues.duration,
        "taxTerm": visa,
        "status": formValues.status,
        "jobDescription": formValues.jobDescription,
        "skillWithExp": skill,
        "desiredSkillsWithExp": dskill,
        "compensationType": formValues.compensationType,
        "annualMinCompensation": formValues.annualMinCompensation,
        "annualMaxCompensation": formValues.annualMaxCompensation,
        "billRate": { "minBillRate": formValues.billMin, "maxBillRate": formValues.billMax },
        "payRateOn": { "minPayRate": formValues.PayMin, "maxPayRate": formValues.PayMax },
        "onC2c1099Inc": { "minC2c1099Inc": formValues.c2cMin, "maxC2c1099Inc": formValues.c2cMax },
        "fee": formValues.fee,
        "feeType": formValues.feeType,
        "relocationAssistance":formValues.relocationExpenses,
        "relocationExpenses": formValues.relocationExpenses ==="yes"?formValues.relocationAmount:"",
        "interviwExpenses": formValues.interviewExpenses ==="yes"?formValues.interviewAmount:"", 
        "benefits": formValues.benefits ==="yes"?formValues.benefitsAmount:"",
        "guaranteePeriod": formValues.guaranteePeriod,
        "notes": formValues.notes,
        "screeningQuestions": formValues.screeningQuestion,
        "degree": degree,
        "certifications": certificate,
        "sampleResume": pdffile,
        "priority":'Top Priority',
        "minExperience": formValues.minExperience,
        "maxExperience": formValues.maxExperience,
    };
    console.log('altList', altList);  
    Api('company/newjob', 'POST', body, token).then((Response) => {
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          console.log('job res',Response.data.message)
          toast.success(Response.data.message);
          navigate('/jobs', { required: true });
          setFormValues( {
            isEnd:'',
            submissionEmailTo:'',
            submissionEmailCc:'' ,
            endClient:'',
            endClientWebLink:'',
            rInstructions:'',
            jobId:'',
            title:'',
            jobOpening:'',
            country:'USA',
            outofArea:'',
            jobType:'',
            remote:'',
            sponsorship:'',
            duration:'',
            status:'',
            jobDescription:'',
            compensationType:'',
            annualMinCompensation:'',
            annualMaxCompensation:'',
            billMin:'',
            billMax:'',
            PayMin:'',
            PayMax:'',
            c2cMin:'',
            c2cMax:'',
            fee:'',
            feeType:'',
            relocationExpenses:'',
            relocationAmount:"",
            interviewAmount:"", 
            benefitsAmount:"",
            guaranteePeriod:'',
            notes:'',
            screeningQuestion:'',
            minExperience:'',
            maxExperience:'',
        })
        setFinallst([])
        setCityy()
        setVisa([])
        setSkill([])
        setDskill([])
        setPdffile('')
        setCertificate([])
        setDegree([])
        }
      } else {
        toast.warn(Response.data.error, +'!');
      }
    });
  };

 

  const handleVisa = (e) => {
    if (e.target.checked && e.target.value !== 'ALL') {
      setVisa([...visa, e.target.value]);
    } else if(!e.target.checked && e.target.value !== 'ALL') {
      const idx = visa.indexOf(e.target.value) 
      console.log("idx",idx)                                                                          
      visa.splice(idx, 1)
      setVisa([...visa])
      console.log("taxTerm",visa)
    }else if(e.target.checked && e.target.value === 'ALL') {
      setVisa(['C2C','W2','1099','T4','INC','ALL']);
      console.log("all",visa)
    }else if(!e.target.checked && e.target.value === 'ALL') {
      setVisa([]);
      console.log("empty",visa)
    }
  };

  const handleSkill = (type, val) => {
    if (type === 'add') {
      setSkill([...skill, { mustHaveSkill: formValues.mustHaveSkill, minExperience: formValues.minExp,maxExperience: formValues.maxExp }]);
      setFormValues({ ...formValues, mustHaveSkill: '', maxExp: '',minExp:'' });
      inputRef.current.children[1].children[0].focus();
    } else {
      skill.splice(val, 1);
      setSkill([...skill]); 
      // setSkill(skill.filter((item) => item.skill !== val)); 
    }
  };
  const handleDesiredSkill = (type, val) => {
    if (type === 'add') {
      setDskill([...dskill, { desiredSkill: formValues.dmustHaveSkill,
         minExperience: formValues.dminExp,
         maxExperience: formValues.dmaxExp }]);
      setFormValues({ ...formValues, dmustHaveSkill: '', dmaxExp: '',dminExp:'' });
      inputRef.current.children[1].children[0].focus();
    } else {
      dskill.splice(val, 1);
      setDskill([...dskill]); 
      // setSkill(skill.filter((item) => item.skill !== val)); 
    }
  };
  const handleDegree = (type, val) => {
    if (type === 'add') {
      setDegree([...degree, { degree: formValues.degree,degreeRequired: formValues.degreeRequired}]);
      setFormValues({ ...formValues, degreeRequired: 'Required', degree: '' });
      // inputRef.current.children[1].children[0].focus();
    } else {
      degree.splice(val, 1);
      setDegree([...degree]);                      
      // setSkill(skill.filter((item) => item.skill !== val)); 
    }
  };
  const handleCertificate = (type, val) => {
    if (type === 'add') {
      setCertificate([...certificate, { certification: formValues.certifications,certificationRequired: formValues.certificationRequired}]);
      setFormValues({ ...formValues, certificationRequired: 'Required', certifications: '' });
      // inputRef.current.children[1].children[0].focus();
    } else {
      certificate.splice(val, 1);
      setCertificate([...certificate]);                      
      // setSkill(skill.filter((item) => item.skill !== val)); 
    }
  };

  const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
      borderRadius: 22 / 2,
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 16,
        height: 16,
      },
      '&:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      '&:after': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      width: 16,
      height: 16,
      margin: 2,
    },
  }));
  const pdfUpload = async (pdf) => {
    // console.log('=====pdf====>>>', docfile);
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('document', pdf);
    formData.append('directoryName', 'resume');
    UploadFile(`resume/upload_resume`, formData, token).then((Response) => {
      console.log('=====upload Response========>', Response.data);
      if (Response.status === 200) {
        if (Response.data.code === 200) {   
          // toast.success(Response.data.status, +'!');
          //  console.log('=====404=gst===', Response.data.message);
          setPdffile(Response.data.filePath);
          setUpload(true)
          // uploadPdf(Response.data.filePath)
        } else {
          console.log('=====404=gst===', Response.data.message);
          toast.warn(Response.data.status, +'!');
        }
      } else {
        toast.warn('Error !');
      }
    });
  };  
  return (
    <>
    <ToastContainer/>
    <Stack>
      <Stack flexDirection={'row'} alignItems={'center'} gap={1} sx={{ml:4}}>
      <Iconify icon="ion:arrow-back" fontSize={25} sx={{ cursor: 'pointer', color: 'text.secondary',fontSize:'0.9rem',fontWeight:'600',mr:1 }} onClick={() => navigate(-1)} />
        
        {/* <Link to={'/'}> */}
        <Typography sx={{ color: 'text.secondary',fontSize:'0.9rem',fontWeight:'600' }}>Home</Typography>
        {/* </Link> */}
        <Typography sx={{color:'text.secondary',fontSize:'0.9rem',fontWeight:'600' }}>{'>'}</Typography>
        <Link to={'/jobs'}>
          <Typography sx={{ color: 'text.secondary', textDecoration: 'underline', cursor: 'pointer',fontSize:'0.9rem',fontWeight:'600' }}>
            All Jobs
          </Typography>
        </Link>
        <Typography sx={{color:'text.secondary',fontSize:'0.9rem',fontWeight:'600'}}>{'>'}</Typography>
        <Typography sx={{ color: 'text.textG',fontSize:'0.9rem',fontWeight:'600' }}>Add New Job/Position</Typography>
      </Stack>
      <Typography variant="h5" color={'text.secondary'} sx={{ml:4,textAlign:'center'}}>
        {locationData.state.row?"Edit": "New"} Job
      </Typography>

      <Grid display={'grid'} gridTemplateColumns={{ sm: 'repeat(2, 1fr)', xs: 'repeat(2, 1fr)' }} px={8} gap={2} mt={1}>
        <Stack sx={{ border: 1 ,borderColor:'text.secondary',borderRadius:1,p:1 }}>
          <Typography variant="h6" color={'text.secondary'} sx={{ textAlign: 'center' }}>
            Client info
          </Typography>
          <Grid display={'grid'} gridTemplateColumns={{ sm: 'repeat(2, 1fr)', xs: 'repeat(2, 1fr)' }} py={1} gap={2}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Select Client</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select Client"
                placeholder="Select Client"
                value={formValues.client}             
                onChange={(e) => {setFormValues({ ...formValues, client: e.target.value })
                getLeads(e.target.value)}}
              >
                {clientList
                  ? clientList.map((item,index) => {
                      return <MenuItem key={index} value={item._id}>{item.companyName}</MenuItem>;

                    })
                  : null}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">Is it end Client?</FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="yes"
                name="radio-buttons-group"
                sx={{ flexDirection: 'row' }}
                value={formValues.isEnd}
                onChange={(e) => {
                  if(e.target.value==='yes'){
                    setFormValues({ ...formValues,isEnd: e.target.value, 
                      endClient:tempId[0].companyName,
                      endClientWebLink:tempId[0].websiteUrl })
                  }else{
                    setFormValues({ ...formValues,isEnd: e.target.value,
                      endClient:'',
                      endClientWebLink:'' })
                  }}}
              >
                <FormControlLabel value="yes" label="Yes" control={<Radio />} />
                <FormControlLabel value="no" label="No" control={<Radio />} />
              </RadioGroup>
            </FormControl>
            <TextField
              id="outlined-basic"
              label="Submission Email"
              placeholder="Submission Email"
              variant="outlined"
              value={formValues.submissionEmailTo}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setFormValues({ ...formValues, submissionEmailTo: e.target.value })}
            />
            <TextField
              id="outlined-basic"
              label="CC Email"
              placeholder="CC Email"
              variant="outlined"
              value={formValues.submissionEmailCc}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setFormValues({ ...formValues, submissionEmailCc: e.target.value })}
            />
            <TextField
              id="outlined-basic"
              label="End Client / Implementation Partner"
              placeholder="End Client / Implementation Partner"
              variant="outlined"
              value={formValues.endClient}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setFormValues({ ...formValues, endClient: e.target.value })}
            />
            <TextField
              id="outlined-basic"
              label="Website"
              placeholder="Website"
              variant="outlined"
              value={formValues.endClientWebLink}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setFormValues({ ...formValues, endClientWebLink: e.target.value })}
            />
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Select Lead</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select Lead"
                placeholder="Select Lead"
                value={formValues.lead}
                onChange={(e) => setFormValues({ ...formValues, lead: e.target.value })}
              >
                {leadlist.map((item,index) => {
                  return <MenuItem value={index}>{item.contactPersonName}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Grid>
        </Stack>
        <Stack gap={2} sx={{border: 1 ,borderColor:'text.secondary',borderRadius:1,p:1}}>
         {locationData.state.row?(
          <>
          <Stack sx={{ width: '100%', alignItems: 'flex-end' }}>
          <Iconify icon='bx:edit' sx={{cursor:'pointer',height:25,width:25}} onClick={()=>setEditRec(true)}/> 
          </Stack>
        <Typography variant="h6" color={'text.secondary'} sx={{ textAlign: 'center',mt:-4 }}>
        Recruiting Instructions
          </Typography>
          </>
         ):(
          <Typography variant="h6" color={'text.secondary'} sx={{ textAlign: 'center'}}>
          Recruiting Instructions
            </Typography>
         )}           
          {/* <TextField
            id="outlined-basic"
            multiline
            rows={12}
            label="Recruiting Instructions"
            placeholder="Recruiting Instructions"
            variant="outlined"
            value={formValues.rInstructions}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => setFormValues({ ...formValues, rInstructions: e.target.value })}
          />                 */}
            <Scrollbar sx={{ height: 300, Scrollbar: 'thin' }}>
            {locationData.state.row && editRec===false?(
              <div dangerouslySetInnerHTML={{ __html:recruitingInst }} />
            ):null}
              {locationData.state.row  && editRec===true?( 
                <CKEditor
                  editor={ClassicEditor}                     
                  data={recruitingInst}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    // console.log('Editor is ready to use!', editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setFormValues({ ...formValues, rInstructions: data})
                    // console.log('ckeditor------data', { event, editor, data });
                  }}
                  onBlur={(event, editor) => {
                    // console.log('Blur.', editor);
                  }}
                  onFocus={(event, editor) => {
                    // console.log('Focus.', editor);
                  }}
                /> 
                
            ):null}
            {!locationData.state.row?(
              <CKEditor
              editor={ClassicEditor}                     
              // data={recruitingInst}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
                // console.log('Editor is ready to use!', editor);
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setFormValues({ ...formValues, rInstructions: data})
                // console.log('ckeditor------data', { event, editor, data });
              }}
              onBlur={(event, editor) => {
                // console.log('Blur.', editor);
              }}
              onFocus={(event, editor) => {
                // console.log('Focus.', editor);
              }}
            /> 
            ):null}
                
            </Scrollbar>  
        </Stack>
        <Stack sx={{border: 1 ,borderColor:'text.secondary',borderRadius:1,p:1}}  >
          <Typography variant="h6" color={'text.secondary'} sx={{ textAlign: 'center' }}>
            Job info
          </Typography>
          <Grid display={'grid'} gridTemplateColumns={{ sm: 'repeat(2, 1fr)', xs: 'repeat(2, 1fr)' }} py={1} gap={2}>
            <FormControl fullWidth>
            <TextField
              id="outlined-basic"
              label="Job ID"
              placeholder="Job ID"
              variant="outlined"
              value={formValues.jobId}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setFormValues({ ...formValues, jobId: e.target.value })}
            /> 
            </FormControl>
            <TextField
              id="outlined-basic"
              label="Job Title"
              placeholder="Job Title"
              variant="outlined"
              value={formValues.title}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setFormValues({ ...formValues, title: e.target.value })}
            />
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Job Opening</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Job Opening"
                placeholder="Job Opening"
                value={formValues.jobOpening}
                onChange={(e) => setFormValues({ ...formValues, jobOpening: e.target.value })}
              >
                <MenuItem value={'1'}>1</MenuItem>;<MenuItem value={'2'}>2</MenuItem>;<MenuItem value={'3'}>3</MenuItem>
                ;<MenuItem value={'4'}>4</MenuItem>;<MenuItem value={'5'}>5</MenuItem>;
                <MenuItem value={'6'}>6</MenuItem>;<MenuItem value={'7'}>7</MenuItem>;<MenuItem value={'8'}>8</MenuItem>
                ;<MenuItem value={'9'}>9</MenuItem>;<MenuItem value={'10+'}>10+</MenuItem>;
              </Select>
            </FormControl>
            {/* <TextField
              id="outlined-basic"
              label="Alternate Job Title"
              placeholder="title1 | title2 | title3"
              variant="outlined"
              value={formValues.altJobTitle}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setFormValues({ ...formValues, altJobTitle: e.target.value })}
            /> */}
            <Autocomplete
                  multiple
                  id="tags-outlined"
                  onChange={(event, newValue) => {
                    setFinallst([...newValue]);
                  }}
                  options={[]}
                  getOptionLabel={(option) => option.title}
                  value={finallst.map((item) => item)}
                  filterSelectedOptions
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some((option) => inputValue === option.title);
                    if (inputValue !== '' && !isExisting) {
                      filtered.push({
                        inputValue,
                        title: `${inputValue}`,
                      });
                    }
                    return filtered;
                  }}
                  renderInput={(params) => <TextField {...params} label="Alternate Job Title" placeholder="Alternate Job Title" />}
                />
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Country</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Country"
                placeholder="Country"
                value={formValues.country}
                onChange={(e) => setFormValues({ ...formValues, country: e.target.value })}
              >
                <MenuItem value={'USA'}>USA</MenuItem>;<MenuItem value={'CANADA'}>CANADA</MenuItem>;
              </Select>
            </FormControl>
            <FormControl>
            <CustomComplete
                  placeholder={'City'}
                  option={cityAndZip}
                  propVal={cityy}
                  childFuc={childFuc}
                  fullVal={fullVal}
                  fetch={'state'}
                />
                </FormControl>
            {/* <TextField
              id="outlined-basic"
              label="Location"
              placeholder="Location"
              variant="outlined"
              value={formValues.location}
              onChange={(e) => setFormValues({ ...formValues, location: e.target.value })}
            /> */}
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">Out of area Candidates?</FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="yes"
                name="radio-buttons-group"
                sx={{ flexDirection: 'row' }}
                value={formValues.outofArea}
                onChange={(e) => setFormValues({ ...formValues, outofArea: e.target.value })}
              >
                <FormControlLabel value="Yes" label="Yes" control={<Radio />} />
                <FormControlLabel value="No" label="No" control={<Radio />} />
              </RadioGroup>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Job Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Job Type"
                placeholder="Job Type"
                value={formValues.jobType}
                onChange={(e) => setFormValues({ ...formValues, jobType: e.target.value })}
              >
                <MenuItem value={'fulltime'}>Fulltime</MenuItem>;<MenuItem value={'permanent'}>Permanent</MenuItem>;
                <MenuItem value={'contract'}>Contract</MenuItem>;<MenuItem value={'CTH'}>CTH</MenuItem>;<MenuItem value={'temporary'}>Temporary</MenuItem>;<MenuItem value={'internship'}>Internship</MenuItem>;
                <MenuItem value={'parttime'}>Part Time</MenuItem>;
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Remote</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Remote"
                placeholder="Remote"
                value={formValues.remote}
                onChange={(e) => setFormValues({ ...formValues, remote: e.target.value })}
              >
                <MenuItem value={'onsite Job'}>Onsite Job</MenuItem>;<MenuItem value={'remote'}>Remote</MenuItem>;
                <MenuItem value={'hybrid'}>Hybrid</MenuItem>;
              </Select>
            </FormControl>
            
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">Visa Sponsorship</FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                // defaultValue="Country"
                name="radio-buttons-group"
                sx={{ flexDirection: 'row' }}
                value={formValues.sponsorship}
                onChange={(e) => setFormValues({ ...formValues, sponsorship: e.target.value })}
              >
                <FormControlLabel value="Yes" label="Yes" control={<Radio />} />
                <FormControlLabel value="no" label="No" control={<Radio />} />
              </RadioGroup>
            </FormControl>
            {formValues.jobType  !=='fulltime' && formValues.jobType  !== 'permanent' ?(
            <TextField
                  id="outlined-basic"
                  label="Duration"
                  placeholder="Duration"
                  variant="outlined"
                  value={formValues.duration}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setFormValues({ ...formValues, duration: e.target.value })}
                />):null }
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Status"
                placeholder="Status"
                value={formValues.status}
                onChange={(e) => setFormValues({ ...formValues, status: e.target.value })}
              >
                <MenuItem value={'Active'}>Active</MenuItem>;<MenuItem value={'Closed'}>Closed</MenuItem>;
                <MenuItem value={'On Hold'}>On Hold</MenuItem>;<MenuItem value={'Cancelled'}>Cancelled</MenuItem>;
              </Select>
            </FormControl>
           

            {/* <TextField
              id="outlined-basic"
              label="Location"
              placeholder="Location"
              variant="outlined"
              value={formValues.location}
              onChange={(e) => setFormValues({ ...formValues, location: e.target.value })}
            />
            <FormControlLabel
              control={
                <Android12Switch
                  defaultChecked={formValues.relocation}
                  onChange={(e) => setFormValues({ ...formValues, relocation: e.target.checked })}
                />
              }
              labelPlacement="start"
              label="Relocation"
            />
            {formValues.relocation && (
              <TextField
                id="outlined-basic"
                label="Relocation Assistance Amount"
                placeholder="Relocation Assistance Amount"
                variant="outlined"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                value={formValues.relocationAssistance}
                onChange={(e) => setFormValues({ ...formValues, relocationAssistance: e.target.value })}
              />
            )}
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">Job Type</FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                sx={{ flexDirection: 'row' }}
                value={formValues.jobType}
                onChange={(e) => setFormValues({ ...formValues, jobType: e.target.value })}
              >
                <FormControlLabel value="Full Time" label="Full Time" control={<Radio />} />
                <FormControlLabel value="Part Time" label="part Time" control={<Radio />} />
                <FormControlLabel value="Contact" label="Contract" control={<Radio />} />
                <FormControlLabel value="Contact to hire" label="Contract to hire" control={<Radio />} />
              </RadioGroup>
            </FormControl>
            {formValues.jobType && formValues.jobType !== 'Full Time' && (
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Contract Duration</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Contract Duration"
                  placeholder="Contract Duration"
                  value={formValues.contractDuration}
                  onChange={(e) => setFormValues({ ...formValues, contractDuration: e.target.value })}
                >
                  <MenuItem value="1 Month">1 Month</MenuItem>
                  <MenuItem value="2 Month">2 Month</MenuItem>
                  <MenuItem value="3 Month">3 Month</MenuItem>
                  <MenuItem value="6 Month">6 Month</MenuItem>
                  <MenuItem value="1 Year">1 Year</MenuItem>
                  <MenuItem value="2 Year">2 Year</MenuItem>
                  <MenuItem value="3 Year">3 Year</MenuItem>
                </Select>
              </FormControl>
            )}
            {formValues.jobType &&
              (formValues.jobType === 'Full Time' ? (
                <TextField
                  id="outlined-basic"
                  label="Annual Compensation Amount"
                  placeholder="Annual Compensation Amount"
                  variant="outlined"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  value={formValues.compensation}
                  onChange={(e) => setFormValues({ ...formValues, compensation: e.target.value })}
                />
              ) : (
                <TextField
                  id="outlined-basic"
                  label="Hourly Rate"
                  placeholder="Hourly Rate"
                  variant="outlined"
                  value={formValues.hourlyRate}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    endAdornment: <InputAdornment position="end">/hour</InputAdornment>,
                  }}
                  onChange={(e) => setFormValues({ ...formValues, hourlyRate: e.target.value })}
                />
              ))} */}
          </Grid>
          {/* <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">Tax Term</FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                sx={{ flexDirection: 'row' }}
                value={formValues.taxTerm}
                onChange={(e) => setFormValues({ ...formValues, taxTerm: e.target.value })}
              >
                <FormControlLabel value="c2c" control={<Radio />} label="C2C" />
                <FormControlLabel value="w2" control={<Radio />} label="W2" />
                <FormControlLabel value="1099" control={<Radio />} label="1099" />
                <FormControlLabel value="T4" control={<Radio />} label="T4" />
                <FormControlLabel value="INC" control={<Radio />} label="INC" />
                <FormControlLabel value="na" control={<Radio />} label="NA" />
              </RadioGroup>
            </FormControl> */}
            {formValues.jobType !== 'permanent' && formValues.jobType !== 'fulltime' ?(
              
            <FormControl>
                 <FormLabel id="demo-radio-buttons-group-label">Tax Term</FormLabel>
                  <FormGroup
                    sx={{ flexDirection: 'row' }}
                    // value={visa}
                    onChange={handleVisa}
                  >
                    <FormControlLabel
                      control={<Checkbox  checked={visa?.includes('C2C')} />}
                      label="C2C"
                      value={'C2C'}
                    />
                    <FormControlLabel
                      control={<Checkbox checked={visa?.includes('W2')}  />}
                      label="W2"
                      value={'W2'}
                    />
                    <FormControlLabel
                      control={<Checkbox checked={visa?.includes('1099')} />}
                      label="1099"
                      value={'1099'}
                    />
                    <FormControlLabel
                      control={<Checkbox checked={visa?.includes('T4')}  />}
                      label="T4"
                      value={'T4'}
                    />
                    <FormControlLabel
                      control={<Checkbox checked={visa?.includes('INC')}  />}
                      label="INC"
                      value={'INC'}
                    />
                    <FormControlLabel
                      control={<Checkbox checked={visa?.includes('All Tax Term')} />}
                      label="All Tax Term"
                      value={'All Tax Term'}
                    />
                  </FormGroup>
            </FormControl>
            ):null}
        </Stack>
        <Stack gap={2}>
        <Stack gap={2} sx={{border: 1 ,borderColor:'text.secondary',borderRadius:1,p:1}}>
        {locationData.state.row?(
          <>
          <Stack sx={{ width: '100%', alignItems: 'flex-end' }}>
          <Iconify icon='bx:edit' sx={{cursor:'pointer',height:25,width:25}} onClick={()=>setEditJobDes(true)}/> 
          </Stack>
        <Typography variant="h6" color={'text.secondary'} sx={{ textAlign: 'center',mt:-4 }}>
        Job Description
          </Typography>
          </>
         ):(
          <Typography variant="h6" color={'text.secondary'} sx={{ textAlign: 'center'}}>
          Job Description
            </Typography>
         )} 
          <Scrollbar sx={{ height: 300, Scrollbar: 'thin' }}>
          {locationData.state.row && editJobDes===false?(
              <div dangerouslySetInnerHTML={{ __html:jobDescription }} />
            ):null}
              {locationData.state.row  && editJobDes?( 
          <CKEditor
                      editor={ClassicEditor}

                      data={jobDescription}

                      onReady={(editor) => {
                    
                        // You can store the "editor" and use when it is needed.
                        console.log('Editor is ready to use!', editor);
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setFormValues({ ...formValues, jobDescription: data})
                        // console.log('ckeditor------data',  editor);
                      }}
                      onBlur={(event, editor) => {
                        // console.log('Blur.', editor);
                      }}
                      onFocus={(event, editor) => {
                        // console.log('Focus.', editor);
                      }}
                    />
                ):null}
                {!locationData.state.row?(
                  <CKEditor
                  editor={ClassicEditor}

                  // data={jobDescription}

                  onReady={(editor) => {
                
                    // You can store the "editor" and use when it is needed.
                    console.log('Editor is ready to use!', editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setFormValues({ ...formValues, jobDescription: data})
                    // console.log('ckeditor------data',  editor);
                  }}
                  onBlur={(event, editor) => {
                    // console.log('Blur.', editor);
                  }}
                  onFocus={(event, editor) => {
                    // console.log('Focus.', editor);
                  }}
                />
                ):null }
                 </Scrollbar>    
          {/* <TextField
            id="outlined-basic"
            multiline
            rows={12}
            label="Complete Description"
            placeholder="Complete Description"
            variant="outlined"
            value={formValues.jobDescription}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => setFormValues({ ...formValues, jobDescription: e.target.value })}
          /> */}
         
        </Stack>
         
        <Stack sx={{border: 1 ,borderColor:'text.secondary',borderRadius:1,p:1}}>
        <Typography variant="h6" color={'text.secondary'} sx={{ textAlign: 'center' }}>
          Experience
        </Typography>
        <Scrollbar sx={{ maxHeight: 180, Scrollbar: 'thin' }}>
        <Typography  color={'text.secondary'} sx={{  }}>
          Min/Maximum Years of Experience:
        </Typography>
        <Stack sx={{flexDirection:'row',mt:1}} gap={1} >
        <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label">Min Year</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Min Year"
              placeholder="Min Year"
              value={formValues.minExperience}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setFormValues({ ...formValues, minExperience: e.target.value })}
            >
              <MenuItem value="1 Year">1 Year</MenuItem>
              <MenuItem value="2 Years">2 Years</MenuItem>
              <MenuItem value="3 Years">3 Years</MenuItem>
              <MenuItem value="4 Years">4 Years</MenuItem>
              <MenuItem value="5 Years">5 Years</MenuItem>
              <MenuItem value="6 Years">6 Years</MenuItem>
              <MenuItem value="7 Years">7 Years</MenuItem>
              <MenuItem value="8 Years">8 Years</MenuItem>
              <MenuItem value="9 Years">9 Years</MenuItem>
              <MenuItem value="10+ Years">10+ Years</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label">Max Year</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Max Year"
              placeholder="Max Year"
              value={formValues.maxExperience}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setFormValues({ ...formValues, maxExperience: e.target.value })}
            >
              <MenuItem value="1 Year">1 Year</MenuItem>
              <MenuItem value="2 Years">2 Years</MenuItem>
              <MenuItem value="3 Years">3 Years</MenuItem>
              <MenuItem value="4 Years">4 Years</MenuItem>
              <MenuItem value="5 Years">5 Years</MenuItem>
              <MenuItem value="6 Years">6 Years</MenuItem>
              <MenuItem value="7 Years">7 Years</MenuItem>
              <MenuItem value="8 Years">8 Years</MenuItem>
              <MenuItem value="9 Years">9 Years</MenuItem>
              <MenuItem value="10 Years">10 Years</MenuItem>
              <MenuItem value="11 Years">11 Years</MenuItem>
              <MenuItem value="12 Years">12 Years</MenuItem>
              <MenuItem value="13 Years">13 Years</MenuItem>
              <MenuItem value="14 Years">14 Years</MenuItem>
              <MenuItem value="15+ Years">15+ Years</MenuItem>
            </Select>
          </FormControl>
          </Stack>
        <Typography  color={'text.secondary'} sx={{ mt:1 }}>
          Must Have Skills :
        </Typography>
        <Stack sx={{flexDirection:'row',mt:1}} gap={1} >
         <FormControl fullWidth>                                                    
        <TextField
            id="outlined-basic"
            label="Must have Skill"
            size="small"
            placeholder="Must have Skill"
            variant="outlined"
            ref={inputRef}
            value={formValues.mustHaveSkill}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => setFormValues({ ...formValues, mustHaveSkill: e.target.value })}
          />
          </FormControl>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label">Min Year</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Min Year"
              placeholder="Min Year"
              value={formValues.minExp}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setFormValues({ ...formValues, minExp: e.target.value })}
            >
              <MenuItem value="1 Year">1 Year</MenuItem>
              <MenuItem value="2 Years">2 Years</MenuItem>
              <MenuItem value="3 Years">3 Years</MenuItem>
              <MenuItem value="4 Years">4 Years</MenuItem>
              <MenuItem value="5 Years">5 Years</MenuItem>
              <MenuItem value="6 Years">6 Years</MenuItem>
              <MenuItem value="7 Years">7 Years</MenuItem>
              <MenuItem value="8 Years">8 Years</MenuItem>
              <MenuItem value="9 Years">9 Years</MenuItem>
              <MenuItem value="10+ Years">10+ Years</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label">Max Year</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Max Year"
              placeholder="Max Year"
              value={formValues.maxExp}
              onChange={(e) => setFormValues({ ...formValues, maxExp: e.target.value })}
            >
              <MenuItem value="1 Year">1 Year</MenuItem>
              <MenuItem value="2 Years">2 Years</MenuItem>
              <MenuItem value="3 Years">3 Years</MenuItem>
              <MenuItem value="4 Years">4 Years</MenuItem>
              <MenuItem value="5 Years">5 Years</MenuItem>
              <MenuItem value="6 Years">6 Years</MenuItem>
              <MenuItem value="7 Years">7 Years</MenuItem>
              <MenuItem value="8 Years">8 Years</MenuItem>
              <MenuItem value="9 Years">9 Years</MenuItem>
              <MenuItem value="10 Years">10 Years</MenuItem>
              <MenuItem value="11 Years">11 Years</MenuItem>
              <MenuItem value="12 Years">12 Years</MenuItem>
              <MenuItem value="13 Years">13 Years</MenuItem>
              <MenuItem value="14 Years">14 Years</MenuItem>
              <MenuItem value="15+ Years">15+ Years</MenuItem>
            </Select>
          </FormControl>
          
          <Button variant="contained" size="small" color="custom" sx={{width:'fit-content'}} onClick={() => handleSkill('add', '')}>
          Add
            </Button>
          </Stack>
          {/* <Stack flexDirection={'row'} justifyContent={'end'}>
            <Button variant="contained" onClick={() => handleSkill('add', '')}>
              Add Skill
            </Button>
          </Stack> */}
          <Stack sx={{px:1}}>
          {skill.length > 0 && (
            <TableContainer >
              <Table aria-label="customized table" m={1}>
                {/* <TableHead>
                  <TableRow>
                    <TableCell>Skill</TableCell>
                    <TableCell align="center">Experience</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead> */}
                <TableBody>
                  {skill.map((row,index) => (
                    <TableRow key={row.index} hover>
                      <TableCell>{row.mustHaveSkill}</TableCell>
                      <TableCell align="center">{row.minExperience}</TableCell>
                      <TableCell align="center">{row.maxExperience}</TableCell>
                      <TableCell sx={{ display: 'grid', placeItems: 'center' }}>
                        <Icon
                          icon="line-md:remove"
                          color={'red'}
                          fontSize={30}
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleSkill('remove', index)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          </Stack>

<Typography  color={'text.secondary'} sx={{ mt:1 }}>
          Desired Skills :
        </Typography>
        <Stack sx={{flexDirection:'row',mt:1}} gap={1}>
         <FormControl fullWidth>                                                    
        <TextField
            id="outlined-basic"
            label="Desired Skill"
            placeholder="Desired Skill"
            variant="outlined"
            ref={inputRef}
            size="small"
            value={formValues.dmustHaveSkill}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => setFormValues({ ...formValues, dmustHaveSkill: e.target.value })}
          />
          </FormControl>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-select-small-label">Min Year</InputLabel>
            <Select
             labelId="demo-select-small-label"
             id="demo-select-small"              
              label="Min Year"
              placeholder="Min Year"
              value={formValues.dminExp}
              onChange={(e) => setFormValues({ ...formValues, dminExp: e.target.value })}
            >
              <MenuItem value="1 Year">1 Year</MenuItem>
              <MenuItem value="2 Years">2 Years</MenuItem>
              <MenuItem value="3 Years">3 Years</MenuItem>
              <MenuItem value="4 Years">4 Years</MenuItem>
               <MenuItem value="5 Years">5 Years</MenuItem>
              <MenuItem value="6 Years">6 Years</MenuItem>
              <MenuItem value="7 Years">7 Years</MenuItem>
              <MenuItem value="8 Years">8 Years</MenuItem>
              <MenuItem value="9 Years">9 Years</MenuItem>
              <MenuItem value="10+ Years">10+ Years</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-select-small-label">Max Year</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small" 
              label="Max Year"
              placeholder="Max Year"
              value={formValues.dmaxExp}
              onChange={(e) => setFormValues({ ...formValues, dmaxExp: e.target.value })}
            >
              <MenuItem value="1 Year">1 Year</MenuItem>
              <MenuItem value="2 Years">2 Years</MenuItem>
              <MenuItem value="3 Years">3 Years</MenuItem>
              <MenuItem value="4 Years">4 Years</MenuItem>
               <MenuItem value="5 Years">5 Years</MenuItem>
              <MenuItem value="6 Years">6 Years</MenuItem>
              <MenuItem value="7 Years">7 Years</MenuItem>
              <MenuItem value="8 Years">8 Years</MenuItem>
              <MenuItem value="9 Years">9 Years</MenuItem>
              <MenuItem value="10 Years">10 Years</MenuItem>
              <MenuItem value="11 Years">11 Years</MenuItem>
              <MenuItem value="12 Years">12 Years</MenuItem>
              <MenuItem value="13 Years">13 Years</MenuItem>
              <MenuItem value="14 Years">14 Years</MenuItem>
              <MenuItem value="15+ Years">15+ Years</MenuItem>
            </Select>
          </FormControl>
          
          <Button variant="contained" size="small" color="custom" sx={{width:'fit-content'}} onClick={() => handleDesiredSkill('add', '')}>
          Add
            </Button>
          </Stack>
          <Stack sx={{px:1}}>
          {dskill.length > 0 && (
            <TableContainer >
              <Table aria-label="customized table" m={1}>
                <TableHead>
                  {/* <TableRow>
                    <TableCell>Skill</TableCell>
                    <TableCell align="center">Experience</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow> */}
                </TableHead>
                <TableBody>
                  {dskill.map((row,index) => (
                    <TableRow key={row.index} hover>
                      <TableCell>{row.desiredSkill}</TableCell>
                      <TableCell align="center">{row.minExperience}</TableCell>
                      <TableCell align="center">{row.maxExperience}</TableCell>
                      <TableCell sx={{ display: 'grid', placeItems: 'center' }}>
                        <Icon
                          icon="line-md:remove"
                          color={'red'}
                          fontSize={30}
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleDesiredSkill('remove', index)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )} 
          </Stack>     
          {/* <TextField
            id="outlined-basic"
            multiline
            rows={12}
            label="Recruiting Instructions"
            placeholder="Recruiting Instructions"
            variant="outlined"
            value={formValues.rInstructions}
            onChange={(e) => setFormValues({ ...formValues, rInstructions: e.target.value })}
          /> */}
         
        </Scrollbar></Stack>
        </Stack> 
        <Stack sx={{border: 1 ,borderColor:'text.secondary',borderRadius:1,p:1}}  >
          <Typography variant="h6" color={'text.secondary'} sx={{ textAlign: 'center' }}>
            Compensation
          </Typography>
          <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">Compensation Type</FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                // defaultValue="Country"
                name="radio-buttons-group"          
                sx={{ flexDirection: 'row' }}
                value={formValues.compensationType}
                onChange={(e) => setFormValues({ ...formValues, compensationType: e.target.value })}
              >
                <FormControlLabel value="annual" label="Annual" control={<Radio />} />
                <FormControlLabel value="per hour" label="Per hours" control={<Radio />} />
              </RadioGroup>
            </FormControl>
          <Stack>
             {/* <Grid display={'grid'} gridTemplateColumns={{ sm: 'repeat(2, 1fr)', xs: 'repeat(2, 1fr)' }} py={1} gap={2}> */}
          {formValues.compensationType === 'annual' ? (
          <Stack sx={{flexDirection:'row',gap:2}}>
            <FormControl fullWidth>
          <TextField
                  id="outlined-basic"
                  label="Min"
                  placeholder="Min"
                  variant="outlined"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,      
                    endAdornment: <InputAdornment position="end">K</InputAdornment>,
                  }}
                  value={formValues.annualMinCompensation}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setFormValues({ ...formValues, annualMinCompensation: e.target.value })}
                />
                </FormControl>
                <FormControl fullWidth>
                 <TextField
                  id="outlined-basic"
                  label="Max"
                  placeholder="Max"
                  variant="outlined"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    endAdornment: <InputAdornment position="end">K</InputAdornment>,
                  }}
                  value={formValues.annualMaxCompensation}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setFormValues({ ...formValues, annualMaxCompensation: e.target.value })}
                />
                </FormControl>
                </Stack>
          ):(<>
          <Typography sx={{color:'text.secondary'}}>Bill Rate</Typography>
          <Stack sx={{flexDirection:'row',gap:2,mt:2}}>
          <FormControl fullWidth>
          <TextField
                  id="outlined-basic"
                  label="Min"
                  placeholder="Min"
                  variant="outlined"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    endAdornment:<InputAdornment position="end">P/H</InputAdornment>,
                  }}
                  value={formValues.billMin}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setFormValues({ ...formValues, billMin: e.target.value })}
                />
                </FormControl>
                <FormControl fullWidth>
                <TextField
                  id="outlined-basic"
                  label="Max"
                  placeholder="Max"
                  variant="outlined"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    endAdornment:<InputAdornment position="end">P/H</InputAdornment>,
                  }}
                  value={formValues.billMax}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setFormValues({ ...formValues, billMax: e.target.value })}
                />
                </FormControl>
          </Stack>
          <Typography sx={{color:'text.secondary',mt:2}}>Pay Rate on W2 / T4</Typography>
          <Stack sx={{flexDirection:'row',gap:2,mt:2}}>
          <FormControl fullWidth>
            <TextField
                  id="outlined-basic"
                  label="Min"
                  placeholder="Min"
                  variant="outlined"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    endAdornment:<InputAdornment position="end">P/H</InputAdornment>,
                  }}
                  value={formValues.PayMin}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setFormValues({ ...formValues, PayMin: e.target.value })}
                />
                </FormControl>
          <FormControl fullWidth>
            <TextField
                  id="outlined-basic"
                  label="Max"
                  placeholder="Max"
                  variant="outlined"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    endAdornment:<InputAdornment position="end">P/H</InputAdornment>,
                  }}
                  value={formValues.PayMax}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setFormValues({ ...formValues, PayMax: e.target.value })}
                />
                </FormControl>
               
          </Stack>
          <Typography sx={{color:'text.secondary',mt:2}}>On C2C / 1099 / Inc</Typography>
          <Stack sx={{flexDirection:'row',gap:2,mt:2}}>
          <FormControl fullWidth>
            <TextField
                  id="outlined-basic"
                  label="Min"
                  placeholder="Min"
                  variant="outlined"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    endAdornment:<InputAdornment position="end">P/H</InputAdornment>,
                  }}
                  value={formValues.c2cMin}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setFormValues({ ...formValues, c2cMin: e.target.value })}
                />
                </FormControl>
          <FormControl fullWidth>
            <TextField
                  id="outlined-basic"
                  label="Max"
                  placeholder="Max"
                  variant="outlined"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    endAdornment:<InputAdornment position="end">P/H</InputAdornment>,
                  }}
                  value={formValues.c2cMax}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setFormValues({ ...formValues, c2cMax: e.target.value })}
                />
                </FormControl>
               
          </Stack>
         </>
        )}
            <Stack sx={{flexDirection:'row',gap:2,mt:2}}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Fee Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Fee Type"
                placeholder="Fee Type"
                value={formValues.feeType}
                onChange={(e) => setFormValues({ ...formValues, feeType: e.target.value })}
              >
                <MenuItem value={'K'}>Flat Fee</MenuItem>;
                <MenuItem value={'%'}>%</MenuItem>;
                <MenuItem value={'P/H'}>Hourly</MenuItem>;
              </Select>
            </FormControl>
            <FormControl fullWidth> 
            <TextField
              id="outlined-basic"
              label="Fee"
              placeholder="Fee"
              variant="outlined"
              value={formValues.fee}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                endAdornment:<InputAdornment position="end">{formValues.feeType}</InputAdornment>,
              }}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setFormValues({ ...formValues, fee: e.target.value })}
            /> 
            </FormControl>
            </Stack>
            <Stack sx={{flexDirection:'row',gap:2,mt:2}}>
            <FormControl fullWidth>
              <FormLabel id="demo-radio-buttons-group-label">Relocation Expenses?</FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="yes"
                name="radio-buttons-group"
                sx={{ flexDirection: 'row' }}
                value={formValues.relocationExpenses}
                onChange={(e) => setFormValues({ ...formValues, relocationExpenses: e.target.value })}
              >
                <FormControlLabel value="yes" label="Yes" control={<Radio />} />
                <FormControlLabel value="no" label="No" control={<Radio />} />
              </RadioGroup>
            </FormControl>
            {formValues.relocationExpenses ==="yes" &&(
               <FormControl fullWidth>
            <TextField
              id="outlined-basic"
              label="Relocation Amount"
              placeholder="Relocation Amount"
              variant="outlined"
              value={formValues.relocationAmount}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                endAdornment:<InputAdornment position="end">K</InputAdornment>,
              }}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setFormValues({ ...formValues, relocationAmount: e.target.value })}
            />
              </FormControl>
            )}
            </Stack>
            <Stack sx={{flexDirection:'row',gap:2,mt:2}}>
            <FormControl fullWidth>
              <FormLabel id="demo-radio-buttons-group-label">Interview Expenses?</FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="yes"
                name="radio-buttons-group"
                sx={{ flexDirection: 'row' }}
                value={formValues.interviewExpenses}
                onChange={(e) => setFormValues({ ...formValues, interviewExpenses: e.target.value })}
              >
                <FormControlLabel value="yes" label="Yes" control={<Radio />} />
                <FormControlLabel value="no" label="No" control={<Radio />} />
              </RadioGroup>
            </FormControl>
            {formValues.interviewExpenses ==="yes" &&(
               <FormControl fullWidth>
            <TextField
              id="outlined-basic"
              label="Interview Amount"
              placeholder="Interview Amount"
              variant="outlined"
              value={formValues.interviewAmount}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                endAdornment:<InputAdornment position="end">K</InputAdornment>,
              }}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setFormValues({ ...formValues, interviewAmount: e.target.value })}
            />
              </FormControl>
            )}
            </Stack>
            <Stack sx={{flexDirection:'row',gap:2,mt:2}}>
            <FormControl fullWidth>
              <FormLabel id="demo-radio-buttons-group-label">Benefits ?</FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="yes"
                name="radio-buttons-group"
                sx={{ flexDirection: 'row' }}
                value={formValues.benefits}
                onChange={(e) => setFormValues({ ...formValues, benefits: e.target.value })}
              >
                <FormControlLabel value="yes" label="Yes" control={<Radio />} />
                <FormControlLabel value="no" label="No" control={<Radio />} />
              </RadioGroup>
            </FormControl>
            {formValues.benefits ==="yes" &&(
               <FormControl fullWidth sx={{mb:1}}>
            <TextField
              id="outlined-basic"
              label="Benefits"
              placeholder="Benefits"
              variant="outlined"
              multiline
              value={formValues.benefitsAmount}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setFormValues({ ...formValues, benefitsAmount: e.target.value })}
            />
              </FormControl>
            )}
            </Stack>
            <FormControl fullWidth>
            <TextField
              id="outlined-basic"
              label="Guarantee Period"
              placeholder="Guarantee Period"
              variant="outlined"
              value={formValues.guaranteePeriod}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setFormValues({ ...formValues, guaranteePeriod: e.target.value })}
            />
              </FormControl>
            <FormControl fullWidth sx={{mt:2}}>
            <TextField
              id="outlined-basic"
              label="Note"
              placeholder="Note"
              variant="outlined"
              value={formValues.notes}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setFormValues({ ...formValues, notes: e.target.value })}
            />
              </FormControl>
          
            {/* <FormControlLabel
              control={
                <Android12Switch
                  defaultChecked={formValues.relocation}
                  onChange={(e) => setFormValues({ ...formValues, relocation: e.target.checked })}
                />
              }
              labelPlacement="start"
              label="Relocation"
            /> */}
            {/* {formValues.relocation && (
              <TextField
                id="outlined-basic"
                label="Relocation Assistance Amount"
                placeholder="Relocation Assistance Amount"
                variant="outlined"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                value={formValues.relocationAssistance}
                onChange={(e) => setFormValues({ ...formValues, relocationAssistance: e.target.value })}
              />
            )} */}
           
          </Stack>
        </Stack>
        <Stack gap={2}>
        <Stack gap={2} sx={{border: 1 ,borderColor:'text.secondary',borderRadius:1,p:1}}>
        {locationData.state.row?(
          <>
          <Stack sx={{ width: '100%', alignItems: 'flex-end' }}>
          <Iconify icon='bx:edit' sx={{cursor:'pointer',height:25,width:25}} onClick={()=>setEditScreen(true)}/> 
          </Stack>
        <Typography variant="h6" color={'text.secondary'} sx={{ textAlign: 'center',mt:-4 }}>
        Screening Question
          </Typography>
          </>
         ):(
          <Typography variant="h6" color={'text.secondary'} sx={{ textAlign: 'center'}}>
          Screening Question
            </Typography>
         )} 
          {/* <TextField
            id="outlined-basic"
            multiline
            rows={10}
            label="Screening Question"
            placeholder="Screening Question"
            variant="outlined"
            value={formValues.screeningQuestion}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => setFormValues({ ...formValues, screeningQuestion: e.target.value })}
          /> */}
            <Scrollbar sx={{ height: 300, Scrollbar: 'thin' }}>
            {locationData.state.row && editScreen === false?(
              <div dangerouslySetInnerHTML={{ __html:screeningQuestions }} />
            ):null}
              {locationData.state.row  && editScreen?( 
              <CKEditor

                      editor={ClassicEditor}
                      data={screeningQuestions}
                      onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                        // console.log('Editor is ready to use!', editor);
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setFormValues({ ...formValues, screeningQuestion: data})
                        // console.log('ckeditor------data', { event, editor, data });
                      }}
                      onBlur={(event, editor) => {
                        // console.log('Blur.', editor);
                      }}
                      onFocus={(event, editor) => {
                        // console.log('Focus.', editor);
                      }}
                    /> 
              ):null}
              {!locationData.state.row?(
                <CKEditor

                editor={ClassicEditor}
                data={''}
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  // console.log('Editor is ready to use!', editor);
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setFormValues({ ...formValues, screeningQuestion: data})
                  // console.log('ckeditor------data', { event, editor, data });
                }}
                onBlur={(event, editor) => {
                  // console.log('Blur.', editor);
                }}
                onFocus={(event, editor) => {
                  // console.log('Focus.', editor);
                }}
              /> 
              ):null}
                     </Scrollbar>  
          </Stack>
        <Stack gap={2} sx={{border: 1 ,borderColor:'text.secondary',borderRadius:1,p:1}}>
         <Typography variant="h6" color={'text.secondary'} sx={{ textAlign: 'center' }}>
         Education
          </Typography>
          <Scrollbar sx={{ maxHeight: 200, Scrollbar: 'thin' }}>  
              <Stack sx={{ flexDirection: 'row', gap: 2, mt: 1 }}>
          <FormControl fullWidth size="small">
          <TextField
            id="outlined-basic"
            label="Degree"
            size="small"
            placeholder="Degree"
            variant="outlined"
            value={formValues.degree}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => setFormValues({ ...formValues, degree: e.target.value })}
          />
          </FormControl>
           <FormControl fullWidth size="small">
              {/* <FormLabel id="demo-radio-buttons-group-label">Benefits ?</FormLabel> */}
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="yes"
                size="small"
                name="radio-buttons-group"
                sx={{ flexDirection: 'row' }}
                value={formValues.degreeRequired}
                onChange={(e) => setFormValues({ ...formValues, degreeRequired: e.target.value })}
              >
                <FormControlLabel value="Required" label="Required" control={<Radio  size="small" />} />
                <FormControlLabel value="Preffered" label="Preffered" control={<Radio  size="small" />} />
              </RadioGroup>
            </FormControl>
            <Button variant="contained" size="small" color="custom" sx={{width:'fit-content'}} onClick={() => handleDegree('add', '')}>
              add
            </Button>
          </Stack>
          <Stack sx={{px:1}}>
          {degree.length > 0 && (
            <TableContainer >
              <Table aria-label="customized table" m={1}>
                <TableBody>
                  {degree.map((row,index) => (
                    <TableRow key={row.index} hover>
                      <TableCell>{row.degree}</TableCell>
                      <TableCell align="center">{row.degreeRequired}</TableCell>
                      <TableCell sx={{ display: 'grid', placeItems: 'center' }}>
                        <Icon
                          icon="line-md:remove"
                          color={'red'}
                          fontSize={30}
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleDegree('remove', index)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          </Stack>
          <Stack sx={{flexDirection:'row',gap:2,mt:1}}>
          <FormControl fullWidth size="small">
          <TextField
            id="outlined-basic"
            label="Certifications"
            placeholder="Certifications"
            variant="outlined"
            size='small'
            value={formValues.certifications}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => setFormValues({ ...formValues, certifications: e.target.value })}
          />
          </FormControl>
           <FormControl fullWidth size="small">
              {/* <FormLabel id="demo-radio-buttons-group-label">Benefits ?</FormLabel> */}
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="yes"
                size="small"
                name="radio-buttons-group"
                sx={{ flexDirection: 'row' }}
                value={formValues.certificationRequired}
                 onChange={(e) => setFormValues({ ...formValues, certificationRequired: e.target.value })}
              >
                <FormControlLabel value="Required" label="Required" control={<Radio  size="small" />} />
                <FormControlLabel value="Preffered" label="Preffered" control={<Radio  size="small" />} />
              </RadioGroup>
            </FormControl>
            <Button variant="contained" size="small" color="custom" sx={{width:'fit-content'}} onClick={() => handleCertificate('add', '')}>
              add
            </Button>
          </Stack>
          <Stack sx={{px:1}}>
          {certificate.length > 0 && (
            <TableContainer >
              <Table aria-label="customized table" m={1}>
                <TableBody>
                  {certificate.map((row,index) => (
                    <TableRow key={row.index} hover>
                      <TableCell>{row.certification}</TableCell>
                      <TableCell align="center">{row.certificationRequired}</TableCell>
                      <TableCell sx={{ display: 'grid', placeItems: 'center' }}>
                        <Icon
                          icon="line-md:remove"
                          color={'red'}
                          fontSize={30}
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleCertificate('remove', index)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          </Stack>
          </Scrollbar> 
        </Stack>
       
        <Stack sx={{ flexDirection: 'row' }}>
                <Stack sx={{ flexDirection: 'row',justifyContent:'center',width:'100%',mt:1 }}>
                  <Button
                    type="file"
                    variant="outlined"
                    size="medium"
                    color="custom"
                    sx={{ width: 'fit-content' }}
                    // onClick={(e) => updateNote()}
                  >
                    Add Sample Resume
                  </Button>
                  <input
                    style={{ opacity: 0, position: 'absolute', width: 120 }}
                    type="file"
                    accept=".doc,.docx, application/pdf"
                    onChange={(e) => {
                      pdfUpload(e.target.files[0]);
                    }}
                  />
                {upload && <Typography sx={{ color: 'text.secondary', p: 0.5,ml:1 }}>Success</Typography>}
                </Stack>
              </Stack>
        
        </Stack>
      </Grid>
      <Stack sx={{justifyContent:'center',alignItems:'center'}}>
      <Button variant="contained"
                    size="medium"
                    color="custom"  sx={{width:'fit-content',mt:2}} onClick={()=>{
                      if(locationData.state.row){addJob()}else{getJobID()}}
                      }>
        add Job
      </Button>
      </Stack>
      
    </Stack>
    </>
  );
}

export default AddJob;
