import { Typography, Button } from '@mui/material';
import React from 'react';

function ResumeDAtabase() {
  return (
    <>
      <Typography variant="h5">Resume Database</Typography>
    </>
  );
}

export default ResumeDAtabase;
