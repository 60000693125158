import React, { useEffect, useRef } from 'react';
import {
  Typography,
  Table,
  Button,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Modal,
  Box,
  TextField,
  Divider,
  Grid,
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Scrollbar from '../components/scrollbar/Scrollbar';

function CompanyManagement() {
    
  const [editTrue, setEditTrue] = React.useState(true);

  // modal 0
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // modal 1
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const rows = [];

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { md: '50%', xs: '95%' },
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 3,
  };

  function handlePopUp(val) {
    setEditTrue(val);
    if (val === true) {
      handleOpen1();
    } else {
      handleClose1();
    }
  }

  return (
    <>
      <Stack flexDirection={'row'} alignItems={'flex-end'} justifyContent={'space-between'} m={1}>
        <Typography variant="h5" mb={2} color={'text.secondary'}>
          Sales Management
        </Typography>
        <Stack flexDirection={'row'} gap={1}>
          {/* <Button color="custom" variant="contained" size="small" onClick={handleOpen}>
            Add Company
          </Button> */}
        </Stack>
      </Stack>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{backgroundColor:'text.secondary'}}>
            <TableCell sx={{p:1,py:0.5,color:'white'}}>Contact Person Name</TableCell>
              <TableCell sx={{p:1,py:0.5,color:'white'}}>Role</TableCell>
              <TableCell sx={{p:1,py:0.5,color:'white'}}>Email Id</TableCell>
              <TableCell sx={{p:1,py:0.5,color:'white'}}>Contact Number</TableCell>
              <TableCell sx={{p:1,py:0.5,color:'white'}}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} hover>
                <TableCell>Nobillio Technologies</TableCell>
                <TableCell>sales@nobilliotechnologies.com</TableCell>
                <TableCell>+91 1234567890</TableCell>
                <TableCell>flat M-001, Raj Empire, Ghaziabad</TableCell>
                <TableCell>
                  <Stack gap={1} flexDirection={'row'}>
                    <Button color="custom" variant="contained" onClick={() => handlePopUp(true)}>
                      edit
                    </Button>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Scrollbar sx={{ maxHeight: { sm: 500, xs: 400 } }}>
            <Stack py={1} gap={2}>
              <TextField id="outlined-basic" label="Company Name" placeholder="Company Name" variant="outlined" />
              <TextField id="outlined-basic" label="Email" placeholder="Email" variant="outlined" />
              <TextField id="outlined-basic" label="Password" placeholder="Password" variant="outlined" />
              <TextField id="outlined-multiline-static" label="Address" placeholder="Address" multiline rows={4} />
            </Stack>
          </Scrollbar>
          <Stack flexDirection={'row'} gap={1} mt={2}>
            <Button color="custom" variant="contained">
              Add Company
            </Button>
          </Stack>
        </Box>
      </Modal>

      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Scrollbar sx={{ maxHeight: { sm: 500, xs: 400 } }}>
            <Grid display={'grid'} gridTemplateColumns={{ sm: 'repeat(2, 1fr)', xs: 'repeat(1, 1fr)' }} py={1} gap={2}>
              <TextField id="outlined-basic" label="First Name" placeholder="First Name" variant="outlined" />
              <TextField id="outlined-basic" label="Last Name" placeholder="Last Name" variant="outlined" />
              <TextField id="outlined-basic" label="Email" placeholder="Email" variant="outlined" />
              <TextField id="outlined-basic" label="Password" placeholder="Password" variant="outlined" />
              <TextField id="outlined-basic" label="Phone" placeholder="Phone" variant="outlined" />
              <TextField id="outlined-basic" label="Status" placeholder="Status" variant="outlined" />
              {/* <TextField id="outlined-basic" label="Location" placeholder="Location" variant="outlined" /> */}
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Role</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Role"
                  placeholder="Role"
                  // value={age}
                  // onChange={handleChange}
                >
                  <MenuItem value="Admin">Admin</MenuItem>
                  <MenuItem value="Recruiter">Recruiter</MenuItem>
                  <MenuItem value="salesTrainee">Trainee</MenuItem>
                </Select>
              </FormControl>
              {/* <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Status"
                  placeholder="Status"
                  // value={age}
                  // onChange={handleChange}
                >
                  <MenuItem value={'Active'}>Active</MenuItem>
                  <MenuItem value={'In-Active'}>In-Active</MenuItem>
                </Select>
              </FormControl> */}
            </Grid>
            <div>
              <input type="file" accept="application/pdf" style={{ fontSize: 18 }} />
            </div>
          </Scrollbar>
          <Stack flexDirection={'row'} gap={1} mt={2}>
            <Button color="custom" variant="contained">
              Add CV
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
}

export default CompanyManagement;
