import React, { useState, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useNavigate, useLocation, Link } from 'react-router-dom';

import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import moment from 'moment';
import {
  Typography,
  Table,
  Button,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Modal,
  Box,
  TextField,
  Divider,
  Grid,
  MenuItem,
  InputLabel,
  Select,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  InputAdornment,
  useTheme,
} from '@mui/material';

import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';
import { Document, Page, pdfjs } from 'react-pdf';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CustomComplete from '../components/customComplete/CustomComplete';
import Iconify from '../components/iconify';
// import Upload from '../components/upload';
import Scrollbar from '../components/scrollbar/Scrollbar';

import { Api, UploadFile } from '../webservices';

pdfjs.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.7.570/pdf_viewer.js.map';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}
const tablebg = 'rgba(82, 130, 190, 0.08)';
const filter = createFilterOptions();
function Worksheet() {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  function MyFormHelperText() {
    const { onFilled } = useFormControl() || {};
    const helperText = React.useMemo(() => {
      if (onFilled) {
        return 'This field is being onFilled';
      }

      return 'Helper text11';
    }, [onFilled]);

    return <FormHelperText>{helperText}</FormHelperText>;
  }

  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [pages, setPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [askill, setAskill] = React.useState(null);
  const [finallst, setFinallst] = React.useState([]);
  const [tableData, setTableData] = useState([]);
  const [autocompleteValues, setAutocompleteValues] = useState([]);
  const [evalid, setEvalid] = useState(true);
  const [dupemail, setDupemail] = useState(false);
  const [tempskill, setTempskill] = useState([]);
  const [cityerr, setCityerr] = useState(false);
  const [joblst, setJoblst] = useState([]);
  const [cityAndZip, setCityAndZip] = useState([]);
  const [cityandstate, setCityandstate] = useState({});
  
  const [emails, getEmails] = useState([]);

  const [value, setValue] = useState({
    email: '',
    name: '',
    jobtitle: '',
    currCompany: '',
    phone: '',
    visa: '',
    tax: '',
    linkedinUrl: '',
    comType: '',
    expectedCom: '',
    jobid: '',
    city: '',
    zip: '',
    reloc: '',
    notes: '',
    fcity: '',
    scode: '',
    country: 'USA',
    userStatus: '',
  });
  const [newcv ,setNewcv] = useState('no')
  const [cityy, setCityy] = useState('');
  const [statee, setStatee] = useState('');
  const [skill, setSkill] = useState([{ title: 'zashi' }]);
  const [pdffile, setPdffile] = useState(null);
  const [docfile, setDocfile] = useState('');
  const [docval, setDocval] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [choosefile, setChoosefile] = useState(false);
  const [mainTab, setMainTab] = useState('recruiter');
  const visalist = [
    { label: 'USC' },
    { label: 'GC' },
    { label: 'GCEAD' },
    { label: 'TN' },
    { label: 'H1B' },
    { label: 'H1b I 140 ' },
    { label: 'H4 EAD' },
    { label: 'OPT EAD' },
    { label: 'CPT EAD' },
    { label: 'E2 Visa' },
    { label: 'B 1 Visa' },
    { label: 'Canadian citizen' },
    { label: 'Open work permit' },
  ];

  const [emailerr, setEmailerr] = useState(false);
  const [nameerr, setNameerr] = useState(false);
  const [jobterr, setJobterr] = useState(false);

  const handlePage = (event, value) => {
    console.log(value)
    setPageNumber(value);
    if(valuee ==='worksheet'){
      getResume(value)
    } else if(valuee === 'favourites'){
      getFav(value)
    }else {
      handleChange(valuee,value)
    }
  };

  const [valuee, setValuee] = React.useState('worksheet');

  useEffect(() => {
    // console.log('dependency', location.state);
    if (location.state) {
      // setValuee(location.state);
      handleChange(location.state);
    } else {
      setValuee('worksheet');
      getResume();
    }
  }, [location.state]);

  useEffect(() => {
    // setValuee('worksheet')
    // setValuee('worksheet');
    // getResume();
    getSkills();
    GetJobId();
  }, []);


  const handleChange = (newValue,value) => {
    setValuee(newValue);
    setTableData([]);
    const body={
        "status": newValue,
        "page": value || 1,
        "limit": "35"
    }
    const token = localStorage.getItem('token');
    Api(`resume/resume_by_status`, 'POST',body, token)
      .then((response) => {
        // console.log('=========getCompany======>>>>>>>', response.data.message)
        if (response.status === 200) {
          if (response.data.code === 200) {
            if (response.data.data.length !== 0) {
              setTableData(response.data.data);
              const totalPage = Math.ceil(response.data.count/35)
              setPages(totalPage)
              console.log('======resume_by_status======>>>>>>>', response.data.data);
            } else {
              setTableData([]);
            }
          } else {
            setTableData([]);
          }
        } else {
          toast.warn(response.data.message, +'!');
        }
      })
      .catch((err) => {
        console.log('===========', err);
        toast.error(err, +'!');
      });
  };

  const adminrole = useSelector((state) => state.userData.data.role);
  const cities = [];

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '95%', sm: '70%' },
    // width: { xs: '85%', sm: '60%' },
    bgcolor: 'background.paper',
    border: '3px solid #3C6BA5',
    boxShadow: 24,
    p: 3,
    borderRadius: 3,
  };
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#3366FF',
    // backgroundColor: 'secondary',
    ...theme.typography.button,
    padding: theme.spacing(0.5),
    // textAlign: 'center',
    color: 'white',
  }));
  
  const delfile = () => {
    console.log('doc file', docfile);
    setDocfile('');
    // setDocfile('')
    setPdffile('');
    console.log('doc file------', docfile);
    console.log('doc value------', docval);
  };

  const GetJobId = () => {
    const id = localStorage.getItem('userid');
    const token = localStorage.getItem('token');
    Api(`company/active_jobs`, 'GET', '', token)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            setJoblst(response.data.data);
          }
        }
      })
      .catch((err) => {});
  };

  const getResume = async (value) => {
    const token = localStorage.getItem('token');
    const body={
      "page":value || 1,
      "limit":"35"
    }
    Api(`resume/get_all_resumes`, 'POST', body, token)
      .then((response) => {
        console.log('=========get all resume======>>>>>>>', response);
        if (response.status === 200) {
          if (response.data.code === 200) {
            if (response.data.result.length !== 0) {
              // toast.success(response.data.message, +'!');
              setTableData(response.data.result);
              const totalPage = Math.ceil(response.data.count/35)
              setPages(totalPage)
              // setValuee('worksheet')
              console.log('============>>>>>>>', Math.ceil(response.data.count/30));
            } else {
              setTableData([]);
              toast.warn('Data Not Found');
            }
          } else {
            setTableData([]);
            toast.warn(response.data.message);
          }
        } else {
          toast.warn(response.data.message, +'!');
        }
      })
      .catch((err) => {
        console.log('===========', err);
        toast.error(err, +'!');
      });
  };
  const getFav = async () => {
    const token = localStorage.getItem('token');
    const body={
      "page":pageNumber,
      "limit":"35"
    }
    Api(`resume/get_favourites`, 'POST', body, token)
      .then((response) => {
        console.log('=========get all resume======>>>>>>>', response);
        if (response.status === 200) {
          if (response.data.code === 200) {
            if (response.data.result.length !== 0) {
              // toast.success(response.data.message, +'!');
              setTableData(response.data.result);
               const totalPage = Math.ceil(response.data.count/35)
              setPages(totalPage)
              // setValuee('worksheet')
              console.log('============>>>>>>>', response);
            } else {
              setTableData([]);
              toast.warn('Data Not Found');
            }
          } else {
            setTableData([]);
            toast.warn(response.data.message);
          }
        } else {
          toast.warn(response.data.message, +'!');
        }
      })
      .catch((err) => {
        console.log('===========', err);
        toast.error(err, +'!');
      });
  };

  const pdfUpload = async (pdf) => {
    console.log('=====pdf====>>>', docfile);
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('document', pdf);
    formData.append('directoryName', 'resume');
    UploadFile(`resume/upload_resume`, formData, token).then((Response) => {
      console.log('=====upload Response========>', Response.data);

      if (Response.status === 200) {
        if (Response.data.code === 200) {
          setNewcv('yes')
          setChoosefile(true);
          setDocfile('');
          toast.success(Response.data.status, +'!');
          //  console.log('=====404=gst===', Response.data.message);
          setPdffile(Response.data.filePath);
        } else {
          console.log('=====404=gst===', Response.data.message);
          toast.warn(Response.data.status, +'!');
        }
      } else {
        toast.warn('Error !');
      }
    });
  };
  const addcv = async (e) => {
    const skillLst = [];
    finallst.map((item) => {
      return skillLst.push(item.title);
    });
    console.log('lst', skillLst);
    if (value.email !== '') {
      if (value.name !== '') {
        if (value.jobtitle !== '') {
          const token = localStorage.getItem('token');
          const body = {
            "newCV": newcv,
            email: value.email,
            fullName: value.name,
            jobTitle: value.jobtitle,
            phone: value.phone,
            currentCompany: value.currCompany,
            skill: skillLst,
            visaType: value.visa,
            taxTerm: value.tax,
            chargeType: value.comType,
            expectedCompensation: value.expectedCom,
            cityOrZipcode: cityandstate.city,
            linkedinUrl: value.linkedinUrl,
            reallocate: value.reloc,
            zipcode: value.zip,
            stateCode: value.scode,
            resumeUrl: pdffile,
            jobId: value.jobid,
            note: value.notes,
            status: 'prospect',
            userStatus: value.userStatus,
            country: value.country,
          };
          console.log('=====create_resume==========>>>>>>>', finallst);
          Api(`resume/create_resume`, 'POST', body, token)
            .then((response) => {
              console.log('=====create_resume==========>>>>>>>', response);
              if (response.status === 200) {
                if (response.data.code === 200) {
                  setChoosefile(false);
                  handleClose1();
                  toast.success(response.data.message, +'!');
                  setValuee('worksheet');
                  getResume();
                  setValue({
                    email: '',
                    name: '',
                    jobtitle: '',
                    currCompany: '',
                    phone: '',
                    visa: '',
                    tax: '',
                    comType: '',
                    expectedCom: '',
                    jobid: '',
                    city: '',
                    reloc: '',
                    notes: '',
                    zip: '',
                  });
                  getEmails([]);
                  setFinallst([]);
                  setCityandstate('');
                  setCityAndZip([]);
                  setDocfile();

                  setPdffile('');
                  getResume();
                  // setcityData([]);
                  setCityy('');
                  // getCompanyDetail()
                  // setTableData(response.data.data)
                } else if (response.data.code === 400) {
                  setChoosefile(false);
                  toast.warn(response.data.error, +'!');
                  setValuee('worksheet');
                  // toast.success(response.data.message, +'!');
                  handleClose1();
                  getResume();
                  setValue({
                    email: '',
                    name: '',
                    jobtitle: '',
                    currCompany: '',
                    phone: '',
                    visa: '',
                    tax: '',
                    comType: '',
                    expectedCom: '',
                    jobid: '',
                    city: '',
                    reloc: '',
                    notes: '',
                    zip: '',
                  });
                  getEmails([]);
                  setFinallst([]);
                  setCityandstate('');
                  setCityAndZip([]);
                  setDocfile();

                  setPdffile('');
                }
              } else {
                toast.warn(response.data.message, +'!');
              }
            })
            .catch((err) => {
              console.log('===========', err);
            });
        } else {
          setJobterr('true');
        }
      } else {
        setNameerr('true');
      }
    } else {
      setEmailerr('true');
    }
  };
  const funclick = () => {
    getResume();
  };
  const funclick2 = () => {
    setValuee('submitted');
    handleChange('submitted');
  };

  const fun3 = () => {
    setValuee('favourites');
    getFav();
  };

  const handleEmail = (e) => {
    console.log('======================true=====1=============>>>>>>>>>', evalid);
    emails.forEach((item) => {
      if (item === e) {
        getDataByEmail(item);
      }
    });
    setValue({ ...value, email: e });
    const reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEvalid(reg.test(e));
    // setTimeout(() => {
    if (evalid === true) {
      setEmailerr(false);
      console.log('=======email valid', evalid);
    } else {
      console.log('=======email unvalid', evalid);
    }
    // }, 1000);
    console.log('======================true==================>>>>>>>>>', evalid);
  };

  // const chkmail = async (e) => {
  //   const token = localStorage.getItem('token');
  //   const body = {
  //     email: value.email,
  //   };
  //   Api(`resume/duplicate_check`, 'POST', body, token)
  //     .then((response) => {
  //       console.log('===============>>>>>>>', response);
  //       if (response.status === 200) {
  //         if (response.data.code === 400) {
  //           setDupemail(true);
  //         }
          
  //       } else {
  //         toast.warn(response.data.message, +'!');
  //       }
  //     })
  //     .catch((err) => {
  //       console.log('===========', err);
  //     });
  // };

  const getSkills = async () => {
    const token = localStorage.getItem('token');
    Api(`resume/get_skills`, 'GET', '', token)
      .then((response) => {
        // console.log('=========getSkills======>>>>>>>', response.data);
        if (response.data.code === 200) {
          if (response.data.result.length !== 0) {
            response.data.result.map((item) => {
              return tempskill.push({ title: item.skillName });
            });
            setSkill(tempskill);
            console.log('===value skilll========', JSON.stringify(skill));
          } else {
            setSkill([{ title: '' }]);
          }
        } else {
          toast.warn(response.data.message, +'!');
        }
      })
      .catch((err) => {
        console.log('===========', err);
        toast.error(err, +'!');
      });
  };
  const getCity = async (val) => {
    setCityAndZip([])
    const token = localStorage.getItem('token');
    const body = {
      country: value.country,
      query: val,
    };
    Api(`resume/get_city_states`, 'POST', body, token)
      .then((response) => {
        if (response.status === 200) {
          console.log('=========status 200======>>>>>>>', response);
          if (response.data.code === 200) {
            setCityAndZip(response.data.data);
            // setCityandstate(response.data.data[0]);

            // if (response.data.data[0].zip) {
            //   setValue({ ...value, zip: response.data.data[0].zip, scode: response.data.data[0].stateCode });
            // } else {
            //   setValue({ ...value, zip: '' });
            // }
            console.log('=======city and State', response.data.data[0].zip);
          } else {
            // setCityAndZip(response.data.data);
            // toast.error(response.data.message, +'!');
          }
        }
      })
      .catch((err) => {
        console.log('===========', err);
        // toast.error(err, +'!');
      });
  };

  const getDataByEmail = async (val) => {
    const token = localStorage.getItem('token');
    Api(`resume/resume_by_email/${val}`, 'GET', '', token)
      .then((response) => {
        if (response.status === 200) {
          console.log('=========status 200======>>>>>>>', response);
          if (response.data.code === 200) {
            console.log('getDataByemail========', response.data);
            // const abc = response.data.result.city
            // getCity(abc)
            setValue({
              ...value,
              city: response.data.result.city,
              notes: '',
              jobid: response.data.result.jobId,
              reloc: response.data.result.reallocate,
              expectedCom: response.data.result.expectedCompensation,
              tax: response.data.result.taxTerm,
              visa: response.data.result.visaType,
              currCompany: response.data.result.currentCompany,
              phone: response.data.result.phone,
              jobtitle: response.data.result.jobTitle,
              email: response.data.result.email,
              name: response.data.result.fullName,
              zip: response.data.result.zipcode,
              linkedinUrl: response.data.result.linkedinUrl,
              country: response.data.result.country,
              userStatus: response.data.result.userStatus,
            });
            setCityy(response.data.result.city);
            setPdffile(response.data.result.resumeUrl);
            setCityandstate({ ...cityandstate, city: response.data.result.city, state: response.data.result.state });
            const arr = [];
            response.data.result.skill.forEach((item) => {
              arr.push({ title: item });
            });
            setFinallst(arr);
            if (response.data.result.resumeUrl) {
              setChoosefile(true);
            }
          } else {
            console.log('getDataByemail========', response.data);
          }
        }
      })
      .catch((err) => {
        console.log('===========', err);
        // toast.error(err, +'!');
      });
  };

  const getEmail = async (val) => {
    const token = localStorage.getItem('token');
    Api(`resume/check_existing_mail/${val}`, 'GET', '', token)
      .then((response) => {
        if (response.status === 200) {
          console.log('=========status 200======>>>>>>>', response);
          if (response.data.code === 200) {
            console.log('city========', JSON.stringify(response.data));
            getEmails(response.data.data);
          } else {
            getEmails(response.data.data);
            // toast.error(response.data.message, +'!');
          }
        }
      })
      .catch((err) => {
        console.log('===========', err);
        // toast.error(err, +'!');
      });
  };
 

  const childFuc = (e) => {
    // setCityy(e);
    setCityandstate({ ...cityandstate, city: e });

    if (e.length >= 3) {
      getCity(e);
    } else if (e.length === 0) {
      setCityy('');
      setCityAndZip([]);
      setCityandstate({});
    }
  };

  const fullVal = (val) => {
    console.log(val);
    setCityandstate(val);
    setCityy(val.city);
    setValue({ ...value, zip: val.zip });
    // setValue({ ...value, city: val.city });
    // setCityy(val.city);
  };

  const childFucEmail = (e) => {
    handleEmail(e);
    if (e.length >= 3) getEmail(e);
  };

  const fullEmail = (val) => {
    console.log(val);
    handleEmail(val);

    // setCityy(val.city);
  };

  const setContact = (e) => {
    const reg = /^[a-zA-Z]*$/;
    if (e.length) {
      const lst = e[e.length - 1];
      if (!lst.match(reg)) {
        setValue({
          ...value,
          phone: e,
        });
      }
    } else {
      setValue({
        ...value,
        phone: e,
      });
    }
  };

  return (
    <>
      <ToastContainer />
      <Stack flexDirection={'row'} alignItems={'flex-end'} justifyContent={'space-between'} m={1}>
        <Typography variant="h5" color={'text.secondary'}>
          Recruiter Worksheet
        </Typography>
        <Button color="custom" variant="contained" size="small" onClick={() => handleOpen1()}>
          Add New Prospect
        </Button>
      </Stack>
      <Stack sx={{ flexDirection: 'row', gap: 2, mb: 0.5, cursor: 'pointer', alignItems: 'center' }}>
        <Stack
          sx={[
            { flexDirection: 'row', alignItems: 'center' },
            valuee === 'worksheet' ? { borderBottom: 3, color: 'text.secondary' } : { color: 'text.textG' },
          ]}
          onClick={() => {
            navigate('/worksheet');
            setPageNumber(1)
            getResume();
            setValuee('worksheet');
          }}
        >
          <Iconify icon="mingcute:list-check-line" fontSize={17} />
          <Typography variant="tabs" sx={{ ml: 1 }}>
            Prospects
          </Typography>
        </Stack>
        <Stack
          sx={[
            { flexDirection: 'row', alignItems: 'center' },
            valuee === 'favourites' ? { borderBottom: 3, color: 'text.secondary' } : { color: 'text.textG' },
          ]}
          onClick={() => {
            // navigate('/worksheet',{ state: 'favourites' })
            setPageNumber(1);
            getFav();
            setValuee('favourites');
          }}
        >
          <Iconify
            icon="ph:heart"
            fontSize={17}
            color={valuee === 'favourites' ? { color: '#CB4335' } : { color: 'text.textG' }}
          />
          <Typography variant="tabs" sx={{ ml: 1 }}>
            Favourites
          </Typography>
        </Stack>
        <Stack
          sx={[
            { flexDirection: 'row', ml: 1, alignItems: 'center' },
            valuee === 'submitted' ? { borderBottom: 3, color: 'text.secondary' } : { color: 'text.textG' },
          ]}
          // onClick={() => handleChange('submitted')}
          onClick={() => {navigate('/worksheet', { state: 'submitted' });setPageNumber(1)}}
        >
          <Iconify
            icon="iconamoon:star-light"
            fontSize={17}
            color={valuee === 'submitted' ? '#B7950B' : 'text.textG'}
          />
          <Typography variant="tabs" sx={{ ml: 1 }}>
          Submissions 
          </Typography>
        </Stack>
        <Stack
          sx={[
            { flexDirection: 'row', ml: 1, alignItems: 'center' },
            valuee === 'interview' ? { borderBottom: 3, color: 'text.secondary' } : { color: 'text.textG' },
          ]}
          onClick={() => {navigate('/worksheet', { state: 'interview' });setPageNumber(1)}}
        >
          <Iconify icon="ps:check-box" fontSize={17} color={valuee === 'interview' ? '#F4D03F' : 'text.textG'} />
          <Typography variant="tabs" sx={{ ml: 1 }}>
          Interviews
          </Typography>
        </Stack>
        <Stack
          sx={[
            { flexDirection: 'row', ml: 1, alignItems: 'center' },
            valuee === 'placement' ? { borderBottom: 3, color: 'text.secondary' } : { color: 'text.textG' },
          ]}
          onClick={() => {navigate('/worksheet', { state: 'placement' });setPageNumber(1)}}
        >
          <Iconify
            icon="mingcute:target-line"
            sx={{ transform: 'rotate(270deg)' }}
            fontSize={17}
            color={valuee === 'placement' ? '#2ECC71' : 'text.textG'}
          />
          <Typography variant="tabs" sx={{ ml: 1 }}>
          Placements
          </Typography>
        </Stack>
        <Stack
          sx={[
            { flexDirection: 'row', ml: 1, alignItems: 'center' },
            valuee === 'backout' ? { borderBottom: 3, color: 'text.secondary' } : { color: 'text.textG' },
          ]}
          onClick={() =>{ navigate('/worksheet', { state: 'backout' });setPageNumber(1)}}
        >
          <Iconify icon="bx:error" fontSize={17} color={valuee === 'backout' ? '#CB4335' : 'text.textG'} />
          <Typography variant="tabs" sx={{ ml: 1 }}>
            Backouts
          </Typography>
        </Stack>
      </Stack>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ justifyContent: 'flex-start', backgroundColor: 'text.secondary' }}>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white',width:'12%' }}>Date</TableCell>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white',width:'18%' }}>Detail</TableCell>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white',width:'18%' }}>Contact Details</TableCell>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white' ,width:'12%' }}>Skills</TableCell>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white' ,width:'10%' }}>Visa & Terms</TableCell>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white' ,width:'10%' }}>Status</TableCell>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white' ,width:'10%' }}>Job Info</TableCell>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white' ,width:'10%' }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((item, index) => (
              <ResumeRow
                key={item._id}
                index={index}
                row={item}
                fun={funclick}
                fun2={funclick2}
                skillsett={skill}
                joblst1={joblst}
                filterTab={valuee}
                fun3={fun3}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {tableData.length < 1 && (
        <Stack sx={{ mt: 2, p: 2 }}>
          <Typography sx={{ alignSelf: 'center', color: 'red' }}>no data found</Typography>
        </Stack>
      )}
      {tableData.length ? (
        <Stack spacing={2} sx={{ alignItems: 'flex-end', width: '100%', mt: 2 }}>
          <Pagination  variant="outlined" shape="rounded" color="secondary" count={pages} page={pageNumber} onChange={handlePage} />
         </Stack>
      ) : null}

      <Modal open={open1} onClose={handleClose1} style={{ borderRadius: 10 }}>
        <Box sx={style}>
          <Typography variant="h5" color={'#3C6BA5'} textAlign={'center'}>
            New Candidate
          </Typography>
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
            <Divider sx={{mb:2}}/>
            <Grid display={'grid'} gridTemplateColumns={{ md: 'repeat(2, 1fr)', sm: 'repeat(1, 1fr)' }} py={2} gap={4}>
              <Stack gap={2}>
                <CustomComplete
                  err={emailerr}
                  req={'true'}
                  placeholder={'Email'}
                  option={emails}
                  propVal={value.email}
                  childFuc={childFucEmail}
                  fullVal={fullEmail}
                  fetch={'email'}
                />
                <FormControl fullWidth>
                  {/* <TextField
                    required
                    type="email"
                    id="outlined-basic"
                    label="Email"
                    placeholder="Email"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    value={value.email}
                    onChange={(e) => handleEmail(e)}
                    onFocus={(e) => setEvalid(false)}
                    onBlur={(e) => (evalid === true ? chkmail() : null)}
                    // onChange={(e) => setValue({...value, email:e.target.value})  }
                  /> */}
                  {evalid === false ? (
                    <Stack sx={{ ml: 1 }}>
                      <Typography color={'red'} variant="body">
                        This is not vaild mail
                      </Typography>
                    </Stack>
                  ) : null}
                  {dupemail === true ? (
                    <Stack sx={{ ml: 1, mt: 1, flexDirection: 'row' }}>
                      <Typography color={'red'} variant="body">
                        Duplicate Record Found
                      </Typography>
                      <Typography color={'red'} variant="body" sx={{ ml: 2 }}>
                        -
                      </Typography>
                      <Typography
                        color={'text.secondary'}
                        variant="body"
                        sx={{ ml: 2, cursor: 'pointer', textDecoration: 'underline' }}
                      >
                        Click here to view
                      </Typography>
                    </Stack>
                  ) : null}
                </FormControl>

                <TextField
                  id="outlined-basic"
                  required
                  // size='small'
                  label="Full Name"
                  placeholder="Full Name"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={value.name}
                  onChange={(e) => {
                    setValue({ ...value, name: e.target.value });
                    if (e.target.value.length) {
                      setNameerr(false);
                    } else {
                      setNameerr('true');
                    }
                  }}
                />
                <TextField
                  required
                  id="outlined-basic"
                  label="Job title"
                  placeholder="Job title"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={value.jobtitle}
                  onChange={(e) => {
                    setValue({ ...value, jobtitle: e.target.value });
                    if (e.target.value.length) {
                      setNameerr(false);
                    } else {
                      setNameerr('true');
                    }
                  }}
                />
                <TextField
                  id="outlined-basic"
                  label="Current Company"
                  placeholder="Current Company"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={value.currCompany}
                  onChange={(e) => setValue({ ...value, currCompany: e.target.value })}
                />

                <TextField
                  id="outlined-basic"
                  label="Phone"
                  placeholder="Phone"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={value.phone}
                  onChange={(e) => setContact(e.target.value)}
                  // type="text"
                  // inputProps={{
                  //   maxLength: 20,
                  // }}
                  // type="number"
                  // onChange={(e) => setValue({ ...value, phone: e.target.value })}
                />

                <Autocomplete
                  disablePortal
                  // id="combo-box-demo"
                  options={visalist}
                  value={value.visa}
                  sx={{ width: '100%' }}
                  onChange={(e) => {
                    setValue({ ...value, visa: e.target.innerText });
                    console.log(e.target.innerText);
                  }}
                  renderInput={(params) => <TextField {...params} label="Visa" />}
                />
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">Contract (Tax Term) / Fulltime</FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="c2c"
                    name="radio-buttons-group"
                    value={value.tax}
                    onChange={(e) => setValue({ ...value, tax: e.target.value })}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      ml: 1,
                    }}
                  >
                    <FormControlLabel value="c2c" control={<Radio />} label="C2C" />
                    <FormControlLabel value="w2" control={<Radio />} label="W2" />
                    <FormControlLabel value="1099" control={<Radio />} label="1099" />
                    <FormControlLabel value="T4" control={<Radio />} label="T4" />
                    <FormControlLabel value="INC" control={<Radio />} label="INC" />
                    <FormControlLabel value="fulltime" control={<Radio />} label="Full-Time" />
                  </RadioGroup>
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Compensation Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value.comType}
                    label="Compensation Type"
                    onChange={(e) => setValue({ ...value, comType: e.target.value })}
                  >
                    <MenuItem value={'hourly'}>Per hour Rate </MenuItem>
                    <MenuItem value={'Annually'}>Annual Compensation</MenuItem>;
                  </Select>
                </FormControl>

                <TextField
                  id="outlined-basic"
                  label="Expected Compensation( In Dollars )*"
                  placeholder="Expected Compensation"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={value.expectedCom}
                  // type="number"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    endAdornment: value.comType && (
                      <InputAdornment position="end">{value.comType === 'hourly' ? 'per/hour' : 'K'}</InputAdornment>
                    ),
                  }}
                  onChange={(e) => setValue({ ...value, expectedCom: e.target.value })}
                />
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Change Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value.userStatus}
                    label="Change Status"
                    onChange={(e) => setValue({ ...value, userStatus: e.target.value })}
                  >
                    <MenuItem value={'fake'}>Fake</MenuItem>
                    <MenuItem value={'blacklisted'}>Blacklisted</MenuItem>
                    <MenuItem value={'active'}>Active</MenuItem>;
                  </Select>
                </FormControl>
              </Stack>
              <Stack gap={2}>
                <FormControl fullWidth sx={{}}>
                  <TextField
                    id="outlined-basic"
                    label="Linkedin URL"
                    placeholder="Linkedin URL"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    value={value.linkedinUrl}
                    // type='number'
                    onChange={(e) => setValue({ ...value, linkedinUrl: e.target.value })}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Job Id</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value.jobid}
                    label="Job Id"
                    onChange={(e) => setValue({ ...value, jobid: e.target.value })}
                  >
                    <MenuItem value={'NA'}>NA</MenuItem>
                    {joblst.map((item,index) => {
                      return <MenuItem key={index} value={item.jobId}>{item.jobId}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Select Country</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value.country}
                    label="Select Country"
                    onChange={(e) => setValue({ ...value, country: e.target.value })}
                  >
                    <MenuItem value="USA">USA</MenuItem>;<MenuItem value="CANADA">CANADA</MenuItem>;
                  </Select>
                </FormControl>

                <CustomComplete
                  placeholder={'City'}
                  option={cityAndZip}
                  propVal={cityy}
                  childFuc={childFuc}
                  fullVal={fullVal}
                  fetch={'state'}
                />
                <FormControl fullWidth>
                  {cityandstate ? (
                    <Stack sx={{ ml: 1, mb: 2, mt: -1, flexDirection: 'row' }}>
                      <Typography color={'text.textG'} variant="body">
                        {cityandstate.city},{cityandstate.state}
                      </Typography>
                      {/* <Typography color={'text.textG'} variant="body" sx={{ ml: 1 }}>
                        {cityandstate.state}
                      </Typography> */}
                    </Stack>
                  ) : null}
                  <FormControl fullWidth>
                    <TextField
                      id="outlined-basic"
                      label="Zip code"
                      placeholder="Zip code"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      value={value.zip}
                      type="number"
                      onChange={(e) => setValue({ ...value, zip: e.target.value })}
                    />
                  </FormControl>
                </FormControl>
                <FormControl fullWidth>
                  <FormLabel id="demo-radio-buttons-group-label">Can Relocate </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="yes "
                    name="radio-buttons-group"
                    value={value.reloc}
                    onChange={(e) => setValue({ ...value, reloc: e.target.value })}
                    sx={{ display: 'flex', flexDirection: 'row', ml: 1 }}
                  >
                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                    <FormControlLabel value="no" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
                <Autocomplete
                  multiple
                  id="tags-outlined"
                  onChange={(event, newValue) => {
                    setFinallst([...newValue]);
                  }}
                  options={skill}
                  getOptionLabel={(option) => option.title}
                  value={finallst.map((item) => item)}
                  filterSelectedOptions
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some((option) => inputValue === option.title);
                    if (inputValue !== '' && !isExisting) {
                      filtered.push({
                        inputValue,
                        title: `${inputValue}`,
                      });
                    }
                    return filtered;
                  }}
                  renderInput={(params) => <TextField {...params} label="Skills" placeholder="Skills" />}
                />
                {/* <Stack sx={{ position: 'relative', width: '100%' }}>
                  <Autocomplete
                    value={askill}
                    onChange={(event, newValue) => {
                      if (typeof newValue === 'string') {
                        // setAskill({title: newValue });
                        addskill(newValue);
                        // console.log('first----- ', newValue);
                        setAskill('');
                      } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        // setAskill({title: newValue.inputValue});
                        // console.log('second------ ', newValue.inputValue);
                        addskill(newValue.inputValue);
                        setAskill('');
                      } else {
                        // console.log('third----- ', newValue.title);
                        setAskill('');
                        addskill(newValue.title);
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      const { inputValue } = params;
                      // Suggest the creation of a new value
                      const isExisting = options.some((option) => inputValue === option.title);
                      if (inputValue !== '' && !isExisting) {
                        filtered.push({
                          inputValue,
                          title: `Add "${inputValue}"`,
                        });
                      }

                      return filtered;
                    }}
                    id="free-solo-with-text-demo"
                    options={skill}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === 'string') {
                        // console.log('re 1-----', option);
                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        // console.log('re 2-----', option.inputValue);
                        return option.inputValue;
                      }
                      // Regular option

                      // console.log('re 3-----', option.title);
                      return option.title;
                    }}
                    renderOption={(props, option) => <li {...props}>{option.title}</li>}
                    sx={{}}
                    freeSolo
                    renderInput={(params) => <TextField {...params} label="Skill" />}
                  />

                  <Stack sx={{ marginTop: 1 }}>
                    <Grid container spacing={2}>
                      {finallst.map((item) => {
                        return (
                          <Grid item>
                            <Item>
                              {item}
                              <Iconify
                                icon="basil:cross-outline"
                                sx={{ color: 'white', width: 20, height: 20 }}
                                style={{ cursor: 'pointer' }}
                                onClick={() => del(item)}
                              />
                            </Item>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Stack>
                </Stack>*} */}
                <Stack sx={{ flexDirection: 'row' }}>
                  <Stack
                    sx={{
                      flexDirection: 'row',
                      // justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant="subtitle1">Upload Resume*</Typography>

                    <Stack sx={{ flexDirection: 'row' }}>
                      <Stack sx={{ position: 'relative' }}>
                        <Stack
                          sx={{
                            cursor: 'pointer',
                            m: 1,
                            border: 1,
                            p: 0.5,
                            borderRadius: 2,
                            width: 100,
                            borderColor: 'text.secondary',
                          }}
                        >
                          <Typography sx={{ alignSelf: 'center' }} color={'text.secondary'}>
                            choose file
                          </Typography>
                        </Stack>
                        <input
                          style={{ opacity: 0, position: 'absolute', margin: 5, width: 100 }}
                          type="file"
                          accept=".doc,.docx, application/pdf"
                          onChange={(e) => {
                            setDocfile(e.target.files[0]);
                            setChoosefile(false);
                            pdfUpload(e.target.files[0]);
                          }}
                        />
                      </Stack>
                    </Stack>
                  </Stack>
                  {docfile ? (
                    <Stack>
                      <Iconify
                        icon="basil:cross-outline"
                        sx={{ color: 'text.secondary', width: 30, height: 30 }}
                        style={{ cursor: 'pointer' }}
                        onClick={() => delfile()}
                      />
                    </Stack>
                  ) : null}

                  {/* {docfile && (
                    <Stack sx={{ mt: 0.5, flexDirection: 'row' }}>
                      <Button color="custom" variant="outlined" size="small" onClick={() => pdfUpload()}>
                        Upload File
                      </Button>
                    </Stack>
                  )} */}
                  {pdffile && (
                    <Stack sx={{ mt: 0.5, flexDirection: 'row', alignItems: 'center' }}>
                      <a href={pdffile} target="_foxearch">
                        <Button color="custom" variant="outlined">
                          View File
                        </Button>
                      </a>
                    </Stack>
                  )}
                  {choosefile === true ? (
                    <Stack sx={{ justifyContent: 'center', alignItems: 'center', ml: 2 }}>
                      <Typography color={'green'} onClick={(e) => window.open(pdffile, '_blank')}>
                        Success
                      </Typography>
                    </Stack>
                  ) : null}
                </Stack>
                {docfile ? (
                  <Stack sx={{ justifyContent: 'center' }}>
                    <Typography variant="body2" sx={{ width: 200 }}>
                      {docfile.name}
                    </Typography>
                  </Stack>
                ) : null}
                <TextField
                  id="outlined-multiline-static"
                  label="Note... (Max 2000 char)"
                  placeholder="Note..."
                  multiline
                  rows={5}
                  value={value.notes}
                  onChange={(e) => setValue({ ...value, notes: e.target.value })}
                />
                {/* <Stack sx={{ width: 500 }}>
                  <CKEditor
                    editor={ClassicEditor}
                    // data="<p>Typing&nbsp;5!<br><br></p>"
                    // onReady={ editor => {
                    //     // You can store the "editor" and use when it is needed.
                    //     console.log( 'Editor is ready to use!', editor );
                    // } }
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setValue({ ...value, notes: data });
                      console.log('ckeditor------data', data);
                    }}
                    // onBlur={ ( event, editor ) => {
                    //     console.log( 'Blur.', editor );
                    // } }
                    // onFocus={ ( event, editor ) => {
                    //     console.log( 'Focus.', editor );
                    // } }
                  />
                </Stack> */}
              </Stack>
            </Grid>
            <Stack flexDirection={'row'} gap={1} mt={1} sx={{ justifyContent: 'center' }}>
              <Button color="custom" variant="contained" onClick={(e) => addcv(e)}>
                Add CV
              </Button>
            </Stack>
          </Scrollbar>
        </Box>
      </Modal>
    </>
  );
}

export default Worksheet;

function ResumeRow({ row, fun, fun2, fun3, skillsett, joblst1, filterTab, index }) {
  useEffect(() => {
    console.log('=================date===>>>', filterTab);
    if (skillsett.length > 0) {
      setSkillset(skillsett);
    }
    if(row.skill){

      row.skill.map((item) => temparr.push({ title: item }))
    }
  }, []);

  const navigate = useNavigate();
  const [tmpskl, setTmpskl] = useState([]);

  const [status, setStatus] = useState('Pending');
  const [edit, setEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openpdf, setOpenpdf] = useState(false);

  const [openskill, setOpenskill] = useState(true);
  const skillclose = () => setOpenskill(false);
  const [open1, setOpen1] = useState(false);
  const handleEdit = () => {
    setOpen1(true);
  };
  const handleCloseEdit = () => setOpen1(false);

  const [esub, setSub] = useState(false);
  const openEsub = () => setSub(true);
  const closeEsub = () => setSub(false);
  const [subto, setSubto] = useState(row.type);
  const [esubdata, setEsubdata] = useState({
    subjobid: row.jobId,
    expectedCom:row.expectedCompensation,
    comType: row.chargeType,
    pname: row.positionName,
    cname:row.clientId,
    tax: row.taxTerm,
  });
const [clientlist ,setClientlst  ] = useState([])                                                                                                                                                                                                                                                                                                                                                                                                                                          
  const [eplace, setEplace] = useState(false);
  const openEplace = () => setEplace(true);
  const closeEplace = () => setEplace(false);
  const [eplacedata, setEplacedata] = useState({
    placestatus:"placed",
jobType:row.jobType,
offeredJob:row.offeredJob,
AnnualSalary:row.annualSalary,
JoiningBonus:row.joiningBonus,
AnnualBonus:row.annualBonus,
ReloAssistance:row.relocationAssistance,
hro:row.hourlyRateRateOffered,
TaxTerm:row.taxTerm,
OvertimeExpense:row.overTimeExpence,
Additional:row.additionals,
placenote:""
  });
  const [tsd, setTsd] = React.useState(dayjs(row.tentativeStartDate));
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [totalDays, setTotalDays] = useState(0);
  const [opens, setOpens] = useState(false);
  const handleOpens = () => setOpens(true);
  const handleCloses = () => setOpens(false);

  const [openn, setOpenn] = useState(false);
  const [his, setHis] = useState([]);
  const handleOpenn = () => {
    setOpenn(true);
    getHis();
  };
 const EditSubmitted = async () => {
    const token = localStorage.getItem('token');
    const body = {
      submissionId: row._id,
      resumeId: row.resumeId,
      type: subto,
      jobId:esubdata.subjobid,
      clientId: esubdata.cname,
      expectedCompensation:esubdata.expectedCom,
      chargeType:esubdata.comType,
      positionName:esubdata.pname,
      clientName:clientlist.find(item => item._id === esubdata.cname).clientName,
      taxTerm: esubdata.tax,
      oldJobId: row.jobId,
      oldClientId: row.clientId,
    };
    Api(`resume/update_any_step`, 'POST', body, token)
      .then((response) => {
        console.log('==========update_any_step=====>>>', response);
        if (response.status === 200) {
          if (response.data.code === 200) {
            // setLevelM(false);
            toast.success(response.data.message);
            console.log(row);
            fun2();
          } else {
            toast.warn(response.data.message);
          }
        }
      })
      .catch((err) => {});
  };
 const EditPlacement = async () => {
    const token = localStorage.getItem('token');
    const body = {
      submissionId: row._id,
      resumeId: row.resumeId,
      status: eplacedata.placestatus === 'placed' ? 'placement' : 'prospect',
      result: eplacedata.placestatus,
      note: eplacedata.placenote,
      jobType: eplacedata.jobType,
      offeredJob: eplacedata.offeredJob,
      chargeType: eplacedata.offeredJob === 'fulltime' ? 'K' : 'hourly',
      annualSalary: eplacedata.AnnualSalary,
      joiningBonus: eplacedata.JoiningBonus,
      annualBonus: eplacedata.AnnualBonus,
      relocationAssistance: eplacedata.ReloAssistance,
      additionals: eplacedata.Additional,
      tentativeStartDate: tsd,
      taxTerm: eplacedata.TaxTerm,
      hourlyRateRateOffered: eplacedata.hro,
      overTimeExpence: eplacedata.OvertimeExpense,
      startStatus: 'placement',
    };
    Api(`resume/update_any_step`, 'POST', body, token)
      .then((response) => {
        console.log('==========update_any_step=====>>>', response);
        if (response.status === 200) {
          if (response.data.code === 200) {
            // setLevelM(false);
            toast.success(response.data.message);
            console.log(row);
            fun2();
          } else {
            toast.warn(response.data.message);
          }
        }
      })
      .catch((err) => {});
  };
  const getHis = () => {
    const token = localStorage.getItem('token');
    Api(`resume/get_history/${filterTab === 'worksheet' ? row._id : row.resumeId}`, 'GET', '', token)
      .then((response) => {
        console.log('==========get_history=====>>>', response);
        if (response.status === 200) {
          if (response.data.code === 200) {
            setHis(response.data.data);
            // setJoblst(response.data.data);
          }
        }
      })
      .catch((err) => {});
  };
  const handleClosen = () => setOpenn(false);

  const [openinterview, setOpeninterview] = useState(false);
  const handleopeninterview = () => setOpeninterview(true);
  const handlecloseinterview = () => setOpeninterview(false);

  const [levelM, setLevelM] = useState(false);

  const [openclient, setOpenclient] = useState(false);
  const handleOpenclient = () => setOpenclient(true);
  const handleCloseclient = () => setOpenclient(false);

  const handleOpenpdf = () => setOpenpdf(true);
  const Closepdf = () => setOpenpdf(false);
  const [pdffile, setPdffile] = useState(row.resumeUrl);
  const [docfile, setDocfile] = useState();
  const [commentt, setCommentt] = useState('');
  const [statusNote , setStatusNote] = useState('')

  const [value, setValue] = useState({
    email: row.email,
    name: row.fullName,
    jobtitle: row.jobTitle,
    currCompany: row.currentCompany,
    phone: row.phone,
    visa: row.visaType,
    tax: row.taxTerm,
    expectedCom: row.expectedCompensation,
    jobid: row.jobId,
    city: filterTab === 'worksheet' ? row.city : row.cityOrZipcode,
    reloc: row.reallocate,
    notes: '',
    comType: row.chargeType,
    fcity: '',
    scode: row.stateCode,
    zip: row.zipcode,
    status: row.status,
    country: row.country,
    userStatus: row.userStatus,
    linkedinUrl: row.linkedinUrl,
  });
  const [tempskill, setTempskill] = useState([]);
  const temparr = [];
  const [skill, setSkill] = useState([{ title: 'zashi' }]);
  // console.log('new skill========>', row.skill);
  

  const [finallst, setFinallst] = useState(temparr);
  const [evalid, setEvalid] = useState(true);
  const [cityy, setCityy] = useState(filterTab === 'worksheet' ? row.city : row.cityOrZipcode || '');
  const [cityData, setcityData] = useState([]);
  // const [ city , setCity] = useState('')
  const [askill, setAskill] = React.useState(null);
  const [statee, setStatee] = useState('');
  const cities = [];
  const [skillset, setSkillset] = useState([{ title: 'item.skillName' }]);
  const [ client, setClients] = useState([]);
  const [cityandstate, setCityandstate] = useState({
    city: filterTab === 'worksheet' ? row.city : row.cityOrZipcode,
    state: row.stateCode,
  });
  const [choosefile, setChoosefile] = useState(false);
  const [leveltype, setLeveltype] = useState('');
  const [leveltimez, setLeveltimez] = useState('');
  const [leveltime, setLeveltime] = React.useState(dayjs());
  const [leveldate, setLeveldate] = React.useState(dayjs());
  const [subStatus, setSubStatus] = useState('');
  const [phoneCopy, setPhoneCopy] = useState(false);
  const [emailCopy, setEmailCopy] = useState(false);
  const [newcv ,setNewcv] = useState('no')

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: '500px',
    // height: '50%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    // flexDirection: 'row',
    display: 'flex',
    gap: 2,
  };
  const style2 = {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  };
  const style3 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 3,
  };
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#3366FF',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: 'white',
  }));
  const visalist = [
    { label: 'USC' },
    { label: 'GC' },
    { label: 'GCEAD' },
    { label: 'TN' },
    { label: 'H1B' },
    { label: 'H1b I 140 ' },
    { label: 'H4 EAD' },
    { label: 'OPT EAD' },
    { label: 'CPT EAD' },
    { label: 'E2 Visa' },
    { label: 'B 1 Visa' },
    { label: 'Canadian citizen' },
    { label: 'Open work permit' },
  ];
  const getSkills = async () => {
    const token = localStorage.getItem('token');
    Api(`resume/get_skills`, 'GET', '', token)
      .then((response) => {
        // console.log('=========getSkills======>>>>>>>', response.data);
        if (response.data.code === 200) {
          if (response.data.result.length !== 0) {
            response.data.result.map((item) => {
              return tempskill.push({ title: item.skillName });
            });
            setSkill(tempskill);
            console.log('===value skilll========', JSON.stringify(skill));
          } else {
            setSkill('');
          }
        } else {
          toast.warn(response.data.message, +'!');
        }
      })
      .catch((err) => {
        console.log('===========', err);
        toast.error(err, +'!');
      });
  };
  function fixCity(e) {
    setValue({ ...value, city: e.target.value });
    if (e.target.value.length > 2) {
      getCity();
      console.log('city set');
    }
  }
  const getCity = async (val) => {
    setcityData([])
    const token = localStorage.getItem('token');
    const body = {
      country: value.country,
      query: val,
    };
    Api(`resume/get_city_states`, 'POST', body, token)
      .then((response) => {
        if (response.status === 200) {
          console.log('=========status 200======>>>>>>>', response);
          if (response.data.code === 200) {
            console.log('city========', JSON.stringify(response.data));
            setcityData(response.data.data);
            // setCityandstate(response.data.data[0]);
            // if (response.data.data[0].zip) {
            //   setValue({ ...value, zip: response.data.data[0].zip, scode: response.data.data[0].stateCode });
            // } else {
            //   setValue({ ...value, zip: '' });
            // }
          } else {
            // toast.error(response.data.message, +'!');
          }
        }
      })
      .catch((err) => {
        console.log('===========', err);
        // toast.error(err, +'!');
      });
  };


  const pdfUpload = async (pdf) => {
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('document', pdf);
    formData.append("directoryName", "resume");
    formData.append("resumeName", value.name);
    UploadFile(`resume/upload_resume`, formData, token).then((Response) => {
      console.log('=====upload Response========>', Response.data);
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          setNewcv('yes')
          setChoosefile(true);
          setDocfile('');
          toast.success(Response.data.status, +'!');
          //  console.log('=====404=gst===', Response.data.message);
          setPdffile(Response.data.filePath);
        } else {
          console.log('=====404=gst===', Response.data.message);
          toast.warn(Response.data.status, +'!');
        }
      } else {
        toast.warn(Response.data.message, +'!');
      }
    });
  };

  const setContact = (e) => {
    const reg = /^[a-zA-Z]*$/;
    if (e.length) {
      const lst = e[e.length - 1];
      if (!lst.match(reg)) {
        setValue({
          ...value,
          phone: e,
        });
      }
    } else {
      setValue({
        ...value,
        phone: e,
      });
    }
  };
  const editResume = async (row) => {
    console.log('update skill', finallst);
    const skillLst = [];
    finallst.map((item) => {
      return skillLst.push(item.title);
    });
    console.log('after map skill', skillLst);
    const id = row._id;
    const token = localStorage.getItem('token');
    const body = {
      "newCV": newcv,
      resumeId: id,
      fullName: value.name,
      email: value.email,
      jobTitle: value.jobtitle,
      phone: value.phone,
      currentCompany: value.currCompany,
      skill: skillLst,
      visaType: value.visa,
      taxTerm: value.tax,
      expectedCompensation: value.expectedCom,
      cityOrZipcode: cityy,
      zipcode: value.zip,
      stateCode: value.scode,
      // cityOrZipcode: "90291",
      reallocate: value.reallocate,
      resumeUrl: pdffile,
      jobId: value.jobid,
      note: value.notes,
      status: value.status,
      userStatus: value.userStatus,
      country: value.country,
      linkedinUrl: value.linkedinUrl,
    };
    Api(`resume/update_resume`, 'POST', body, token)
      .then((Response) => {
        console.log('======getUser==response=====>', Response);
        if (Response.status === 200) {
          if (Response.data.code === 200) {
            // setValue({...value,
            //   email: "",
            //   name: "",
            //   jobtitle: "",
            //   currCompany: "",
            //   phone: "",
            //   visa: "",
            //   tax: "",
            //   expectedCom: "",
            //   jobid:"" ,
            //   city: "",
            //   reloc: "",
            //   notes: "",
            //   comType: "",
            //   fcity: '',
            //   scode: '',
            //   zip: "",
            //   status: "",
            //   country: "",
            //   userStatus: "",
            // })
            handleCloseEdit();
            toast.success(Response.data.message);
            fun();
            setcityData([]);
            // setFinallst([]);
            setDocfile();
            setChoosefile(false);
            console.log('======getUser===data.data pdata====>', Response);
          } else {
            console.log('======getUser=======>', +Response);
            toast.warn(Response.data.message);
            // enqueueSnackbar(Response.data.message);
          }
        }
      })
      .catch((err) => {
        toast.error(err, +'!');
        console.log('===========', err);
      });
  };

  const delfile = () => {
    console.log('doc file', docfile);
    setDocfile(null);
    // setDocfile('')
    setPdffile('');
    console.log('doc file------', docfile);
  };
  const handleEmail = (e) => {
    console.log('======================true=====1=============>>>>>>>>>', evalid);

    setValue({ ...value, email: e.target.value });
    const reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEvalid(reg.test(e.target.value));
    // setTimeout(() => {
    if (evalid === true) {
      console.log('=======email valid', evalid);
    } else {
      console.log('=======email unvalid', evalid);
    }
    // }, 1000);
    console.log('======================true==================>>>>>>>>>', evalid);
  };
  // const chkmail = async (e) => {
  //   const token = localStorage.getItem('token');
  //   const body = {
  //     email: value.email,
  //   };
  //   Api(`resume/duplicate_check`, 'POST', body, token)
  //     .then((response) => {
  //       console.log('===============>>>>>>>', response);
  //       if (response.status === 200) {
  //       } else {
  //         toast.warn(response.data.message, +'!');
  //       }
  //     })
  //     .catch((err) => {
  //       console.log('===========', err);
  //     });
  // };

  const getClients = async () => {
    const token = localStorage.getItem('token');
    Api(`resume/get_history/${row._id}`, 'GET', '', token)
      .then((response) => {
        if (response.status === 200) {
          console.log('=========get_client_for_resume======>>>>>>>', response);
          if (response.data.code === 200) {
            setClients(response.data.data);
            console.log('client========', JSON.stringify(response.data));
            handleOpenclient();
          } else {
            // toast.error(response.data.message, +'!');
          }
        }
      })
      .catch((err) => {
        console.log('===========', err);
        // toast.error(err, +'!');
      });
  };
  const getClientSub = async () => {
    const token = localStorage.getItem('token');
    Api(`company/active_clients`, 'GET', '', token)
      .then((response) => {
        console.log('==========resume_submission=====>>>', response);
        if (response.status === 200) {
          if (response.data.code === 200) {
              setClientlst(response.data.data);
          }
        }
      })
      .catch((err) => {
        console.log('===========', err);
        // toast.error(err, +'!');
      });
  };
  // const getClients = async () => {
  //   const token = localStorage.getItem('token');
  //   Api(`resume/get_client_for_resume/${row._id}`, 'GET', '', token)
  //     .then((response) => {
  //       if (response.status === 200) {
  //         console.log('=========get_client_for_resume======>>>>>>>', response);
  //         if (response.data.code === 200) {
  //           setClients(response.data.data);
  //           console.log('client========', JSON.stringify(response.data));
  //           handleOpenclient();
  //         } else {
  //           // toast.error(response.data.message, +'!');
  //         }
  //       }
  //     })
  //     .catch((err) => {
  //       console.log('===========', err);
  //       // toast.error(err, +'!');
  //     });
  // };
  const styleSkill = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '95%', sm: '70%' },
    bgcolor: 'background.paper',
    border: '3px solid #3C6BA5',
    boxShadow: 24,
    p: 3,
    borderRadius: 3,
  };
  const styleSkillset = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '60%', sm: '80%' },
    bgcolor: 'background.paper',
    border: '3px solid #3C6BA5',
    boxShadow: 24,
    p: 3,
    borderRadius: 3,
  };
  const styleClients = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '60%', sm: '80%' },
    bgcolor: 'background.paper',
    border: '3px solid #3C6BA5',
    boxShadow: 24,
    p: 3,
    borderRadius: 3,
  };
  const childFuc = (e) => {
    setCityy(e);
    if (e.length >= 3) {
      getCity(e);
    } else if (e.length === 0) {
      setCityy(e);
      setcityData([]);
      setCityandstate({});
    }
  };

  const fullVal = (val) => {
    console.log(val);
    setCityandstate(val);
    setCityy(val.city);
    setValue({ ...value, zip: val.zip });
  };

  const setLevel = (subId) => {
    const token = localStorage.getItem('token');
    const body = {
      submissionId: subId,
      level: leveltype,
      timeZone: leveltimez,
      date: leveldate,
      time: leveltime,
    };
    console.log('body', body);

    Api(`resume/set_level`, 'POST', body, token)
      .then((response) => {
        console.log('====update Notes====', response);
        if (response.status === 200) {
          if (response.data.code === 200) {
            fun2();
            setLevelM(false);
            toast.success(response.data.message);
            // setEditable(!editable);
          }
        }
      })
      .catch((err) => {
        toast.error(err, +'!');
      });
  };

  const Submitted = async (row) => {
    const token = localStorage.getItem('token');
    const body = {
      submissionId: row._id,
      resumeId: row.resumeId,                                                                                                      
      tempSubmitStatus: subStatus,
      note:`Marked ${subStatus} ${statusNote}`
      // stepWiseStatus: subStatus,
    };
    Api(`resume/update_sub_status`, 'POST', body, token)
      .then((response) => {
        console.log('==========update_any_step=====>>>', response);
        if (response.status === 200) {
          if (response.data.code === 200) {
            setLevelM(false);
            toast.success(response.data.message);
            console.log(row);
            fun2();
          } else {
            toast.warn(response.data.message);
          }
        }
      })
      .catch((err) => {});
  };
  const setFav = async (data) => {
    // alert(data)
    const token = localStorage.getItem('token');
    const body = {
      resumeId: row._id,
      // favStatus: data,
    };
    Api(`resume/favourite`, 'POST', body, token)
      .then((response) => {
        console.log('==========resume/favourite=====>>>', response);
        if (response.status === 200) {
          if (response.data.code === 200) {
            fun3();
            // toast.success(response.data.message);
            //  if(data === 'favourite'){
            //    fun3()
            //  }
          } else {
            toast.warn(response.data.message);
          }
        }
      })
      .catch((err) => {});
  };
  const setUnFav = async (data) => {
    // alert(data)
    const token = localStorage.getItem('token');
    const body = {
      resumeId: row._id,
      // favStatus: data,
    };
    Api(`resume/unfavourite`, 'POST', body, token)
      .then((response) => {
        console.log('==========resume/favourite=====>>>', response);
        if (response.status === 200) {
          if (response.data.code === 200) {
            fun3();
            // toast.success(response.data.message);
            //  if(data === 'favourite'){
            //    fun3()
            //  }
          } else {
            toast.warn(response.data.message);
          }
        }
      })
      .catch((err) => {});
  };

  const handleCopy = () => {
    toast.success('Copied to clipboard!', {
      position: toast.POSITION.TOP_CENTER
    });
  };

  return (
    <>
      <TableRow
        key={row._id}
        sx={[
          { background: index % 2 === 0 ? 'text.background' : tablebg },
          { '&:last-child td, &:last-child th': { border: 0 } },
          { '&:hover': { boxShadow: 5 } },
        ]}
      >
        {filterTab === 'worksheet' && (
          <TableCell sx={{ py: 0 }}>
            <Typography sx={{}} >
              {/* Updated {moment().diff(row.updatedAt, 'days')} days ago */}
              Updated {moment(row.updatedAt).format('ll')}
            </Typography>
            <Typography sx={{}} >
              Since {moment().diff(row.createdAt, 'months')} months
            </Typography>
            <Stack sx={{ flexDirection: 'row' }}>
              <Typography
                sx={{ color: 'text.secondary', textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => handleEdit()}
              >
                Edit
              </Typography>
              {row.favouriteStatus === true ? (
                
                <Iconify
                  icon="ph:heart-fill"
                  color={'text.secondary'}
                  sx={{ m: 0.3, height: 15, ml: 1, width: 15, cursor: 'pointer' }}
                  hover
                  onClick={() => setUnFav()}
                />
              ) : (
                <Iconify
                  icon="ph:heart"
                  color={'text.secondary'}
                  sx={{ m: 0.3, height: 15, ml: 1, width: 15, cursor: 'pointer' }}
                  hover
                  onClick={() => setFav('favourite')}
                />
              )}
            </Stack>
          </TableCell>
        )}
        {filterTab === 'favourites' && (
          <TableCell sx={{ py: 0 }}>
            <Typography sx={{}} >
              Updated {moment(row.updatedAt).format('ll')}
            </Typography>
            <Typography sx={{}} >
              Since {moment().diff(row.createdAt, 'months')} months
            </Typography>
            <Stack sx={{ flexDirection: 'row' }}>
              <Typography
              sx={{ color: 'text.secondary', textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => handleEdit()}
            >
              Edit
            </Typography>
             
                <Iconify
                  icon="ph:heart-fill"
                  color={'text.secondary'}
                  sx={{ m: 0.3, ml: 1, height: 15, width: 15, cursor: 'pointer' }}
                  hover
                  onClick={() => setUnFav()}
                />
              
            </Stack>
          </TableCell>
        )}
        {filterTab === 'submitted' && (
          <TableCell sx={{ py: 0 }}>
            <Typography sx={{}} >
              {moment(`${row.updatedAt}`).format('ll')}
            </Typography>
            <Typography sx={{}} >
              Since {moment().diff(row.createdAt, 'months')} months
            </Typography>
            <Typography
              sx={{ color: 'text.secondary', textDecoration: 'underline', cursor: 'pointer' }}
              onClick={()=>{getClientSub();openEsub()}}
            >
               Edit
            </Typography>
          </TableCell>
        )}
        {filterTab === 'interview' && (
          <TableCell sx={{ py: 0 }}>
            <Typography sx={{ color: 'text.secondary', cursor: 'pointer' }} onClick={handleopeninterview}>
              {moment(`${row.interviewDate}`).format('ll')}
            </Typography>
            <Typography sx={{}} >
              Since {moment().diff(row.createdAt, 'months')} months
            </Typography>
            <Typography>&nbsp;</Typography>
            {/* <Typography
            sx={{ color: 'text.secondary', textDecoration: 'underline', cursor: 'pointer', }}
            onClick={handleOpen1}
          >
            Edit
          </Typography> */}
          </TableCell>
        )}
        {filterTab === 'placement' && (
          <TableCell sx={{ py: 0 }}>
            <Typography
              sx={{  cursor: 'pointer' }}
              //  onClick={handleopeninterview}
            >
              {row.startStatus === ''
                ? moment(`${row.tentativeStartDate}`).format('ll')
                : moment(`${row.joiningDate}`).format('ll')}
            </Typography>
            {row.terminatedAt === null ? (
              <Typography sx={{}} >
                Since {moment().diff(row.createdAt, 'months')} months
              </Typography>
            ) : (
              <Typography sx={{}} >
                To
              </Typography>
            )}
            {row.terminatedAt !== null ? (
              <Typography>{moment(`${row.terminatedAt}`).format('ll')}</Typography>
            ) : (
              <Typography sx={{ color: 'text.secondary', textDecoration: 'underline', cursor: 'pointer' }} onClick={()=>openEplace()}>edit</Typography>
            )}
          </TableCell>
        )}
        {filterTab === 'backout' && (
          <TableCell sx={{ py: 0 }}>
            <Typography sx={{ color: 'text.secondary', cursor: 'pointer' }} onClick={handleopeninterview}>
              {moment(`${row.updatedAt}`).format('ll')}
            </Typography>
            <Typography sx={{}} >
              Since {moment().diff(row.createdAt, 'months')} months
            </Typography>
            <Typography>&nbsp;</Typography>
            {/* <Typography
            sx={{ color: 'text.secondary', textDecoration: 'underline', cursor: 'pointer', }}
            onClick={handleOpen1}
          >
            Edit
          </Typography> */}
          </TableCell>
        )}
        {filterTab === 'placement' ? (
          <TableCell sx={{ py: 0, maxWidth: 170, overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <Stack sx={{ flexDirection: 'row' }}>
              {row.userStatus === 'fake' || row.userStatus === 'blacklisted' ? (
                <Typography
                  sx={{ fontWeight: '600', maxWidth: 160, overflow: 'hidden', textOverflow: 'ellipsis' }}
                  color={'red'}
                >
                  {row.fullName}
                </Typography>
              ) : (filterTab === 'placement' && row.status === 'placement' && row.startStatus === 'started') ||
                row.startStatus === '' ? (
                <Typography
                  sx={{
                    cursor: 'pointer',
                    fontWeight: '600',
                    maxWidth: 160,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  color={'#2ECC71'}
                  onClick={() =>
                    navigate('/resumeDetail', {
                      state: {
                        row,
                        mtab: 'Resume',
                        fTab:
                          filterTab === 'worksheet'
                            ? 'submitted'
                            : '' ||filterTab === 'favourites'
                            ? 'submitted'
                            : ''|| filterTab === 'interview'
                            ? 'placement'
                            : '' || filterTab === 'placement'
                            ? 'start'
                            : '',
                      },
                    })
                  }
                >
                  {row.fullName}
                </Typography>
              ) : (
                <Typography
                  sx={{
                    cursor: 'pointer',
                    fontWeight: '600',
                    maxWidth: 160,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  color={'text.secondary'}
                  onClick={() =>
                    navigate('/resumeDetail', {
                      state: {
                        row,
                        mtab: 'Resume',
                        fTab:
                          filterTab === 'worksheet'
                            ? 'submitted'
                            : '' ||filterTab === 'favourites'
                            ? 'submitted'
                            : ''|| filterTab === 'interview'
                            ? 'placement'
                            : '' || filterTab === 'placement'
                            ? 'start'
                            : '',
                      },
                    })
                  }
                >
                  {row.fullName}
                </Typography>
              )}
              <Iconify
                icon="devicon:linkedin"
                color={'text.textG'}
                sx={{ ml: 1, height: 18, width: 18, cursor: 'pointer' }}
                hover
                onClick={() => window.open(row.linkedinUrl, '_blank')}
              />
            </Stack>
            <Typography sx={{ maxWidth: 180, overflow: 'hidden', textOverflow: 'ellipsis' }} >
              {row.jobTitle}
            </Typography>
            <Typography sx={{ maxWidth: 180, overflow: 'hidden', textOverflow: 'ellipsis' }} color={'text.textG'}>
              {row.currentCompany ? row.currentCompany : '-'}
            </Typography>
          </TableCell>
        ) : (
          <TableCell sx={{ py: 0}}>
            <Stack sx={{ flexDirection: 'row' }}>
              {row.userStatus === 'fake' || row.userStatus === 'blacklisted' ? (
                <Typography
                  sx={{ fontWeight: '600', maxWidth: 160, overflow: 'hidden', textOverflow: 'ellipsis',fontSize:'0.9rem' }}
                  color={'red'}
                >
                  {row.fullName}
                </Typography>
              ) : (filterTab === 'worksheet' && row.status === 'started') || row.status === 'placement' ? (
                <Typography
                  sx={{
                    cursor: 'pointer',
                    fontWeight: '600',
                    maxWidth: 160,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontSize:'0.9rem'
                  }}
                  color={'#2ECC71'}
                  onClick={() =>
                    navigate('/resumeDetail', {
                      state: {
                        row,
                        mtab: 'Resume',
                        fTab:
                          filterTab === 'worksheet'
                            ? 'submitted'
                            : '' ||filterTab === 'favourites'
                            ? 'submitted'
                            : ''|| filterTab === 'interview'
                            ? 'placement'
                            : '' || filterTab === 'placement'
                            ? 'start'
                            : '',
                      },
                    })
                  }
                >
                  {row.fullName}
                </Typography>
              ) : (
                <Typography
                  sx={{
                    cursor: 'pointer',
                    fontWeight: '600',
                    maxWidth: 160,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontSize:'0.9rem'
                  }}
                  color={'text.secondary'}
                  onClick={() =>
                    navigate('/resumeDetail', {
                      state: {
                        row,
                        mtab: 'Resume',
                        fTab:
                          filterTab === 'worksheet'
                            ? 'submitted'
                            : '' ||filterTab === 'favourites'
                            ? 'submitted'
                            : ''|| filterTab === 'interview'
                            ? 'placement'
                            : '' || filterTab === 'placement'
                            ? 'start'
                            : '',
                      },
                    })
                  }
                >
                  {row.fullName}
                </Typography>
              )}
              <Iconify
                icon="devicon:linkedin"
                color={'text.textG'}
                sx={{ m: 0.3, height: 15, width: 15, cursor: 'pointer' }}
                hover
                onClick={() => window.open(row.linkedinUrl, '_blank')}
              />
            </Stack>
            <Typography sx={{ width: 160, overflow: 'hidden', textOverflow: 'ellipsis' }} >
              {row.jobTitle}
            </Typography>
            <Typography sx={{ width: 160, overflow: 'hidden', textOverflow: 'ellipsis' }} color={'text.textG'}>
              {row.currentCompany ? row.currentCompany : '-'}
            </Typography>
          </TableCell>
        )}
        <TableCell sx={{ py: 0}}>
          {row.phone ? (
            <Stack sx={{ flexDirection: 'row' }}>
              <Typography sx={{ flexDirection: 'row', maxWidth: 160, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {row.phone}
              </Typography>
              <CopyToClipboard text={row.phone} onCopy={()=>setPhoneCopy(true)}>
                <Iconify
                  icon="radix-icons:copy"
                  color={phoneCopy?'text.secondary':'text.textG'}
                  sx={{ ml: 1, height: 17, width: 17, cursor: 'pointer' }}
                  hover
                />
              </CopyToClipboard>
            </Stack>
          ) : (
            <Typography>-</Typography>
          )}
          {row.email ? (
            <Stack sx={{ flexDirection: 'row' }}>
              <Typography
                sx={{ textTransform: 'lowercase', maxWidth: 160, overflow: 'hidden', textOverflow: 'ellipsis' }}
              >
                {row.email}
              </Typography>
              <CopyToClipboard text={row.email} onCopy={()=>setEmailCopy(true)}>
                <Iconify
                  icon="radix-icons:copy"
                  color={emailCopy?'text.secondary':'text.textG'}
                  sx={{ ml: 1, height: 17, width: 17, cursor: 'pointer' }}
                />
              </CopyToClipboard>
            </Stack>
          ) : (
            <Typography>-</Typography>
          )}
          {row.city || row.stateCode || row.reallocate === 'yes' ? (
            <Stack sx={{ flexDirection: 'row', gap: 0.5 ,maxWidth:160,overflow:'hidden',textOverflow:'ellipsis'}}>
              <Typography sx={{}} color={'text.textG'}>
                {row.city ? row.city : null}
              </Typography>
              <Typography sx={{}} color={'text.textG'}>
                {row.stateCode ? row.stateCode : null}
              </Typography>

              {row.reallocate === 'yes' ? (
                <Iconify icon="mdi:aeroplane" fontSize={20} color={'text.secondary'} />
              ) : null}
            </Stack>
          ) : (
            <Typography color={'text.textG'}>-</Typography>
          )}
        </TableCell>
        <TableCell sx={{ py: 0, width: 170 }}>
        {row.skill?(
          <Grid container sx={{ maxWidth: 160, height: 44, overflow: 'hidden', textOverflow: 'ellipsis', gap: 0.3 }}>
          {row.skill.map((item) => {
                return (
                  <Typography container sx={{}}>
                    {item} |
                  </Typography>
                )})}   
          </Grid>
        ):(
          <Typography>-</Typography>
        )}
          {row.skill ? (
            <Typography
              sx={{ color: 'text.secondary', textDecoration: 'underline', cursor: 'pointer' }}
              onClick={handleOpens}
            >
              view all
            </Typography>
          ) : null}
        </TableCell>
        <TableCell sx={{ py: 0, width: 120, overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <Typography sx={{}}>{row.visaType ? row.visaType : '-'}</Typography>
          {filterTab === 'placement' ? (
            <Typography sx={{}} >
              ${row.offeredJob === 'fulltime' ? row.annualSalary : row.hourlyRateRateOffered}
             {' '}{row.chargeType === 'hourly' ? 'P/H' : 'K'}
            </Typography>
          ) : (
            <Typography sx={{}} >
              ${row.expectedCompensation} {' '}
              {row.chargeType === 'hourly' ? 'P/H' : 'K'}
            </Typography>
          )}
          <Typography sx={{ textTransform: 'uppercase' }} color={'text.textG'}>
            {row.taxTerm ? row.taxTerm : '-'}
          </Typography>
        </TableCell>
        <TableCell sx={{ py: 0, width: 130, overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {filterTab === 'interview' ? (
            <Typography
              sx={{ color: 'text.secondary', textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() =>
                navigate('/resumeDetail', {
                  state: {
                    row: {
                      _id: row._id,
                      resumeId: row.resumeId,
                      status: 'submitted',
                      fullName: row.fullName,
                      resumeUrl: row.resumeUrl,
                      clientName:row.clientName,
                      interviewStatus: row.stepWiseStatus,
                    },
                    fTab: 'interview',
                  },
                })
              }
            >
              {row.stepWiseStatus} interview
            </Typography>
          ) : null}
          {filterTab === 'placement' ? (
            <Typography sx={{}}>{row.startStatus !== '' ? row.startStatus : row.status} </Typography>
          ) : null}
          {filterTab !== 'placement' && filterTab !== 'interview' && filterTab !== 'submitted' ? (
            <Typography sx={{}}>{row.status}</Typography>
          ) : null}
          {filterTab === 'submitted' ? (
            <Typography sx={{ color: 'text.secondary', textDecoration: 'underline' }} onClick={() => setLevelM(true)}>
              {/* {row.stepWiseStatus} */}
              {row.tempSubmitStatus!==""?row.tempSubmitStatus:row.status}
            </Typography>
          ) : null}
          {filterTab === 'worksheet' || filterTab === 'favourites' ? (
            <Stack sx={{ flexDirection: 'row', gap: 0.5 }}>
              <Typography sx={{}} >
                {row.jobId}
              </Typography>
              <Stack sx={{ flexDirection: 'row' }} onClick={(e) => getClients()}>
                <Iconify icon="tabler:activity" fontSize={20} color={'text.secondary'} />
                <Typography sx={{ color: 'text.secondary', cursor: 'pointer' }}>
                  {' +'}
                  {row.clients ? row.clients : '0'}
                </Typography>
              </Stack>
            </Stack>
          ) : (
            <Typography sx={{}}>
              {row.jobId ? row.jobId : '-'}
            </Typography>
          )}

          <Typography sx={{}} color={'text.textG'}>
            {row.contactPersonName ? row.contactPersonName : '-'}
          </Typography>
        </TableCell>
        {/* <TableCell sx={{ maxWidth: 120, overflow: 'hidden', textOverflow: 'ellipsis' }}> */}
        <TableCell sx={{ width: 120, overflow: 'hidden', textOverflow: 'ellipsis'}}>
          <Typography sx={{}} >
            {/* {row.clientName ? row.clientName : row.submitTo} */}
            {row.clientName || '-'}
          </Typography>
          {filterTab === 'worksheet' || filterTab === 'favourites' ? (
 <Typography sx={{}} >
 {row.jobIdTitle || '-'}
</Typography>
          ):(
            <Typography sx={{}} >
 {row.type=== 'position'?row.jobIdTitle:row.positionName || '-'}
</Typography>
          )}
         

          <Typography
            sx={{ color: 'text.secondary', textDecoration: 'underline', cursor: 'pointer' }}
            onClick={handleOpenn}
          >
            notes
          </Typography>
        </TableCell>
        <TableCell sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
          <Stack sx={{ flexDirection: 'row', color: 'text.secondary', cursor: 'pointer' }}>
            <Iconify icon="ic:baseline-email" fontSize={10} />
            <Typography sx={{}}>Email</Typography>
          </Stack>
          <Stack sx={{ flexDirection: 'row', color: 'text.secondary', cursor: 'pointer' }}>
            <Iconify icon="bx:support" fontSize={10} />
            <Typography sx={{}}>Contact</Typography>
          </Stack>
          {row.userStatus !== 'fake' && row.userStatus !== 'blacklisted' ? (
            <Stack sx={{ flexDirection: 'row', color: 'text.secondary', cursor: 'pointer' }}>
              {row.startStatus !== 'quit' && row.startStatus !== 'terminated' && row.startStatus !== 'project ended' ? (
                <Iconify icon="icon-park-solid:check-one" fontSize={10} />
              ) : (
                <Typography sx={{ color: 'transparent' }}>cdcd</Typography>
              )}
              {filterTab === 'worksheet' ? (
                <Typography onClick={() => navigate('/resumeDetail', { state: { row, fTab: 'submitted' } })}>
                  {row.status === 'prospect' ? 'Submit' : 'Resubmit'}
                </Typography>
              ) : null}
              {filterTab === 'favourites' ? (
                <Typography onClick={() => navigate('/resumeDetail', { state: { row, fTab: 'submitted' } })}>
                  {row.status === 'prospect' ? 'Submit' : 'Resubmit'}
                </Typography>
              ) : null}
              {filterTab === 'submitted' ? (
                <Typography onClick={() => navigate('/resumeDetail', { state: { row, fTab: 'interview' } })}>
                  {/* {row.status === 'submitted' ? 'Interview' : ''} */}
                  Interview
                </Typography>
              ) : null}
              {filterTab === 'interview' ? (
                <Typography onClick={() => navigate('/resumeDetail', { state: { row, fTab: 'placement' } })}>
                  {row.status === 'interview' ? 'Offer/Reject' : ''}
                </Typography>
              ) : null}
              {filterTab === 'placement' ? (
                <>
                  <Typography
                    onClick={() =>
                      navigate('/resumeDetail', {
                        state: { row, fTab: 'start', q: row.startStatus === 'started' ? 'quit' : '' },
                      })
                    }
                  >
                    {row.startStatus === '' && row.status === 'placement' ? 'Start/Backout' : ''}
                  </Typography>
                  <Typography
                    onClick={() =>
                      navigate('/resumeDetail', {
                        state: { row, fTab: 'start', q: row.startStatus === 'started' ? 'quit' : '' },
                      })
                    }
                  >
                    {row.startStatus === 'started' && row.status === 'placement' ? 'Quit/Pro.End' : ''}
                  </Typography>
                </>
              ) : null}
              {filterTab === 'backout' ? (
                <Typography onClick={() => navigate('/resumeDetail', { state: { row, fTab: 'submitted' } })}>
                  {row.status === 'backout' ? 'Resubmit' : ''}
                </Typography>
              ) : null}
            </Stack>
          ) : (
            <Typography sx={{ color: 'transparent' }}>cdcd</Typography>
          )}
        </TableCell>
      </TableRow>
      <Modal open={open1} onClose={handleCloseEdit} style={{ borderRadius: 10 }}>
        <Box sx={styleSkill}>
          <Typography variant="h4" color={'#3C6BA5'} textAlign={'center'}>
            Edit Candidate
          </Typography>
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
            <Divider />
            <Grid display={'grid'} gridTemplateColumns={{ md: 'repeat(2, 1fr)', sm: 'repeat(1, 1fr)' }} py={2} gap={4}>
              <Stack gap={2}>
                <FormControl fullWidth>
                  <TextField
                    disabled
                    type="email"
                    id="outlined-basic"
                    label="Email"
                    placeholder="Email"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    value={value.email}
                  />
                </FormControl>

                <TextField
                  id="outlined-basic"
                  label="Full Name"
                  placeholder="Full Name"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={value.name}
                  onChange={(e) => setValue({ ...value, name: e.target.value })}
                />
                <TextField
                  id="outlined-basic"
                  label="Job title"
                  placeholder="Job title"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={value.jobtitle}
                  onChange={(e) => setValue({ ...value, jobtitle: e.target.value })}
                />
                <TextField
                  id="outlined-basic"
                  label="Current Company"
                  placeholder="Current Company"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={value.currCompany}
                  onChange={(e) => setValue({ ...value, currCompany: e.target.value })}
                />

                <TextField
                  id="outlined-basic"
                  label="Phone"
                  placeholder="Phone"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={value.phone}
                  onChange={(e) => setContact(e.target.value)}
                  // type="text"
                  // inputProps={{
                  //   maxLength: 20,
                  // }}
                  // onChange={(e) => setValue({ ...value, phone: e.target.value })}
                />
                <Autocomplete
                  disablePortal
                  // id="combo-box-demo"
                  options={visalist}
                  value={value.visa}
                  sx={{ width: '100%' }}
                  onChange={(e) => {
                    setValue({ ...value, visa: e.target.innerText });
                    console.log(e.target.innerText);
                  }}
                  renderInput={(params) => <TextField {...params} label="Visa" />}
                />
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">Contract (Tax Term) / Fulltime</FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="c2c"
                    name="radio-buttons-group"
                    value={value.tax}
                    onChange={(e) => setValue({ ...value, tax: e.target.value })}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      ml: 1,
                    }}
                  >
                    <FormControlLabel value="c2c" control={<Radio />} label="C2C" />
                    <FormControlLabel value="w2" control={<Radio />} label="W2" />
                    <FormControlLabel value="1099" control={<Radio />} label="1099" />
                    <FormControlLabel value="T4" control={<Radio />} label="T4" />
                    <FormControlLabel value="INC" control={<Radio />} label="INC" />
                    <FormControlLabel value="fulltime" control={<Radio />} label="Full-Time" />
                  </RadioGroup>
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Compensation Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value.comType}
                    label="Compensation Type"
                    onChange={(e) => setValue({ ...value, comType: e.target.value })}
                  >
                    <MenuItem value={'hourly'}>Per hour Rate </MenuItem>
                    <MenuItem value={'Annually'}>Annual Compensation</MenuItem>;
                  </Select>
                </FormControl>
                <TextField
                  id="outlined-basic"
                  label="Expected Compensation( In Dollars )*"
                  placeholder="Expected Compensation"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={value.expectedCom}
                  // type="number"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    endAdornment: value.comType && (
                      <InputAdornment position="end">{value.comType === 'hourly' ? 'per/hour' : 'K'}</InputAdornment>
                    ),
                  }}
                  onChange={(e) => setValue({ ...value, expectedCom: e.target.value })}
                />
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Job Id</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value.jobid}
                    label="Job Id"
                    onChange={(e) => setValue({ ...value, jobid: e.target.value })}
                  >
                    <MenuItem value={''}>NA</MenuItem>
                    {joblst1.map((item,index) => {
                      return <MenuItem key={index} value={item.jobId}>{item.jobId}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Stack>
              <Stack gap={2}>
                <FormControl fullWidth sx={{}}>
                  <TextField
                    id="outlined-basic"
                    label="Linkedin URL"
                    placeholder="Linkedin URL"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    value={value.linkedinUrl}
                    // type='number'
                    onChange={(e) => setValue({ ...value, linkedinUrl: e.target.value })}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Select Country</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value.country}
                    label="Select Country"
                    onChange={(e) => setValue({ ...value, country: e.target.value })}
                  >
                    <MenuItem value="USA">USA</MenuItem>;<MenuItem value="CANADA">CANADA</MenuItem>;
                  </Select>
                </FormControl>

                <CustomComplete
                  placeholder={'City & Pincode'}
                  option={cityData}
                  propVal={cityy}
                  childFuc={childFuc}
                  fullVal={fullVal}
                  fetch={'state'}
                />
                <FormControl>
                  {cityandstate ? (
                    <Stack sx={{ ml: 1, mt: -1, flexDirection: 'row' }}>
                      <Typography color={'text.textG'} variant="body">
                        {cityandstate.city},
                      </Typography>
                      <Typography color={'text.textG'} variant="body" sx={{ ml: 1 }}>
                        {cityandstate.state}
                      </Typography>
                    </Stack>
                  ) : null}
                </FormControl>

                <FormControl fullWidth>
                  <TextField
                    id="outlined-basic"
                    label="Zip code"
                    placeholder="Zip code"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    value={value.zip}
                    type="number"
                    onChange={(e) => setValue({ ...value, zip: e.target.value })}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <FormLabel id="demo-radio-buttons-group-label">Can Relocate </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="yes "
                    name="radio-buttons-group"
                    value={value.reloc}
                    onChange={(e) => setValue({ ...value, reloc: e.target.value })}
                    sx={{ display: 'flex', flexDirection: 'row', ml: 1 }}
                    // defaultValue={row}
                  >
                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                    <FormControlLabel value="no" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>

                <Autocomplete
                  multiple
                  id="tags-outlined"
                  onChange={(event, newValue) => {
                    setFinallst([...newValue]);
                  }}
                  options={skill}
                  getOptionLabel={(option) => option.title}
                  value={finallst.map((item) => item)}
                  filterSelectedOptions
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some((option) => inputValue === option.title);
                    if (inputValue !== '' && !isExisting) {
                      filtered.push({
                        inputValue,
                        title: `${inputValue}`,
                      });
                    }
                    return filtered;
                  }}
                  renderInput={(params) => <TextField {...params} label="Skills" placeholder="Skills" />}
                />
                {/* <Stack sx={{ position: 'relative', width: '100%' }}>
                  <Autocomplete
                    value={askill}
                    onChange={(event, newValue) => {
                      if (typeof newValue === 'string') {
                        addskill(newValue);
                        console.log('first----- ', newValue);
                      } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        // setAskill({title: newValue.inputValue});
                        console.log('second------ ', newValue.inputValue);
                        addskill(newValue.inputValue);
                      } else {
                        console.log('third----- ', newValue.title);
                        addskill(newValue.title);
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      const { inputValue } = params;
                      // Suggest the creation of a new value
                      const isExisting = options.some((option) => inputValue === option.title);
                      if (inputValue !== '' && !isExisting) {
                        filtered.push({
                          inputValue,
                          title: `Add "${inputValue}"`,
                        });
                      }

                      return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="free-solo-with-text-demo"
                    options={skillset}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === 'string') {
                        // console.log("re 1-----",option);
                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.title;
                    }}
                    renderOption={(props, option) => <li {...props}>{option.title}</li>}
                    sx={{}}
                    freeSolo
                    renderInput={(params) => <TextField {...params} label="Skill" />}
                  />

                  {finallst.length ? (
                    <Stack sx={{ marginTop: 1 }}>
                      <Grid container spacing={2}>
                        {finallst.map((item) => {
                          return (
                            <Grid item>
                              <Item>
                                {item}
                                <Iconify
                                  icon="basil:cross-outline"
                                  sx={{ color: 'white', width: 20, height: 20 }}
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => del(item)}
                                />
                              </Item>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Stack>
                  ) : null}
                </Stack> */}
                <Stack sx={{ flexDirection: 'row' }}>
                  <Stack
                    sx={{
                      flexDirection: 'row',
                      // justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant="subtitle1">Upload Resume*</Typography>

                    <Stack sx={{ flexDirection: 'row' }}>
                      <Stack sx={{ position: 'relative' }}>
                        <Stack
                          sx={{
                            cursor: 'pointer',
                            m: 1,
                            border: 1,
                            p: 0.5,
                            borderRadius: 2,
                            width: 100,
                            borderColor: 'text.secondary',
                          }}
                        >
                          <Typography sx={{ alignSelf: 'center' }} color={'text.secondary'}>
                            choose file
                          </Typography>
                        </Stack>
                        <input
                          style={{ opacity: 0, position: 'absolute', margin: 5, width: 100 }}
                          type="file"
                          accept=".doc,.docx, application/pdf"
                          onChange={(e) => {
                            setDocfile(e.target.files[0]);
                            setChoosefile(false);
                            pdfUpload(e.target.files[0]);
                          }}
                        />
                      </Stack>
                      {pdffile && (
                        <Stack sx={{ mt: 0.5, flexDirection: 'row', alignItems: 'center' }}>
                          <a href={pdffile} target="_foxearch">
                            <Button color="custom" variant="outlined">
                              View File
                            </Button>
                          </a>
                        </Stack>
                      )}
                      {docfile ? (
                        <Stack sx={{ justifyContent: 'center' }}>
                          <Typography variant="body2" sx={{ width: 200 }}>
                            {docfile.name}
                          </Typography>
                        </Stack>
                      ) : null}
                    </Stack>
                  </Stack>
                  {docfile ? (
                    <Stack>
                      <Iconify
                        icon="basil:cross-outline"
                        sx={{ color: 'text.secondary' }}
                        fontSize={20}
                        style={{ cursor: 'pointer' }}
                        onClick={() => delfile()}
                      />
                    </Stack>
                  ) : null}

                  {/* {docfile && (
                    <Stack sx={{ mt: 0.5, flexDirection: 'row' }}>
                      <Button color="custom" variant="outlined" size="small" onClick={() => pdfUpload()}>
                        Upload File
                      </Button>
                    </Stack>
                  )} */}
                  {choosefile === true ? (
                    <Stack sx={{ justifyContent: 'center', alignItems: 'center', ml: 2 }}>
                      <Typography color={'green'}>Success</Typography>
                    </Stack>
                  ) : null}
                </Stack>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Change Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value.userStatus}
                    label="Change Status"
                    onChange={(e) => setValue({ ...value, userStatus: e.target.value })}
                  >
                    <MenuItem value={'fake'}>Fake</MenuItem>
                    <MenuItem value={'blacklisted'}>Blacklisted</MenuItem>
                    <MenuItem value={'active'}>Active</MenuItem>;
                  </Select>
                </FormControl>
                <TextField
                  sx={{}}
                  id="outlined-multiline-static"
                  label="Notes"
                  multiline
                  rows={4}
                  value={value.notes}
                  onChange={(e) => setValue({ ...value, notes: e.target.value })}
                  // defaultValue="Default Value"
                />
                {/* <Stack sx={{ width: 500 }}>
                  <CKEditor
                    editor={ClassicEditor}
                    // data="<p>{value.notes}<br><br></p>"
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setValue({ ...value, notes: data });
                      console.log('ckeditor------data', { event, editor, data });
                    }}
                    onBlur={(event, editor) => {
                      console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                      console.log('Focus.', editor);
                    }}
                  />
                </Stack> */}
              </Stack>
            </Grid>
            <Stack flexDirection={'row'} gap={1} mt={1} sx={{ justifyContent: 'center' }}>
              <Button color="custom" variant="contained" onClick={(e) => editResume(row)}>
                Save CV
              </Button>
            </Stack>
          </Scrollbar>
        </Box>
      </Modal>

      <Modal open={opens} onClose={handleCloses} style={{ borderRadius: 10 }}>
        <Box sx={styleSkillset}>
          <Typography variant="h5" color={'#3C6BA5'} textAlign={'center'}>
            {/* {row.fullName}'s Skills */}
            {/* {row.fullName.split(" ",1)}'s Skills */}
            {/\s/.test(row.fullName)?row.fullName.split(" ",1):row.fullName}'s Skills
          </Typography>
          <Scrollbar sx={{ maxHeight: 600, Scrollbar: 'thin' }}>
            <Divider />
            <Stack sx={{ flexDirection: 'row', justifyContent: 'center', gap: 0.5 }}>
              {row.skill?.map((item, index) => {
                return (
                  <Typography key={index} variant="h6" color={'text.textG'} textAlign={'center'} sx={{ mt: 2 }}>
                    {item} |{/* {index < row.skill.length - 1&&'|'} */}
                  </Typography>
                );
              })}
            </Stack>
          </Scrollbar>
        </Box>
      </Modal>
      <Modal open={openn} onClose={handleClosen} style={{ borderRadius: 10 }}>
        <Box sx={styleClients}>
          <Stack sx={{ width: '100%', alignItems: 'flex-end' }}>
            <Button
              // sx={{width:'13%'}}
              color="custom"
              variant="contained"
              size="small"
              onClick={() =>
                navigate('/resumeDetail', {
                  state: {
                    row,
                    mtab: 'Detail',
                    fTab:
                      filterTab === 'worksheet'
                        ? 'submitted'
                        : '' || filterTab === 'interview'
                        ? 'placement'
                        : '' || filterTab === 'placement'
                        ? 'start'
                        : '',
                  },
                })
              }
            >
              Add Note
              {/* <Iconify icon="simple-line-icons:note" sx={{ml:1}} fontSize={22} fontWeight={'bold'} /> */}
            </Button>
          </Stack>
          <Typography variant="h5" color={'#3C6BA5'} sx={{ textTransform: 'capitalize', mt: -3 }} textAlign={'center'}>
            {/* {row.fullName.split(" ",1)}'s Notes */}
            {/* {row.fullName}'s Notes */}
            {/\s/.test(row.fullName)?row.fullName.split(" ",1):row.fullName}'s Notes
          </Typography>
            <Divider sx={{mb:2}}/>
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
            <Stack sx={{ backgroundColor: 'text.secondary', flexDirection: 'row',  p: 1,py:0.5,borderRadius: 1}}>
              <Typography color={'white'} sx={{ width: '11%', fontSize: '0.976rem', fontWeight: '600' }}>
                Date
              </Typography>
              <Typography color={'white'} sx={{ width: '10%', fontSize: '0.976rem', fontWeight: '600' }}>
                User
              </Typography>
              <Typography color={'white'} sx={{ width: '80%', fontSize: '0.976rem', fontWeight: '600' }}>
                Note
              </Typography>
            </Stack>
            {his.length
              ? his.map((item, index) => {
                  return (
                    <Stack
                      sx={[
                        { background: index % 2 === 0 ? 'text.background' : tablebg },
                        { flexDirection: 'row', p: 1 },
                      ]}
                    >
                      <Typography sx={{ width: '11%' }}>{moment(`${item.createdAt}`).format('ll')}</Typography>
                      <Typography sx={{ width: '10%', textTransform: 'capitalize',fontWeight: '800',color:'text.secondary' }}>{item.actionBy}</Typography>
                      <Typography sx={{ width: '80%' }}>
                      {item.note}
                        {/* <div dangerouslySetInnerHTML={{ __html: item.note }} /> */}
                      </Typography>
                    </Stack>
                  );
                })
              : null}
          </Scrollbar>
        </Box>
      </Modal>
      <Modal open={levelM} onClose={() => setLevelM(false)} style={{ borderRadius: 10 }}>
        <Box sx={[styleSkillset, { width: { xs: '60%', sm: '50%' } }]}>
          <Typography variant="h5" color={'#3C6BA5'} textAlign={'center'}>
            Change Status
          </Typography>
          <Divider />
          <Stack sx={{ justifyContent: 'center', gap: 2, mb: 2, mt: 2, width: '100%' }}>
            <Stack sx={{ alignSelf: 'center', gap: 3, width: '60%' }}>
              <FormControl>
                <InputLabel id="demo-simple-select-label">Select New Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  InputLabelProps={{ shrink: true }}
                  label="Select New Status"
                  placeholder="Select New Status"
                  value={subStatus}
                  onChange={(e) => setSubStatus(e.target.value)}
                >
                  <MenuItem value={'client Submission'}>Client Submission</MenuItem>
                  <MenuItem value={'unqualified'}>Unqualified</MenuItem>
                  <MenuItem value={'shortlisted'}>Shortlisted</MenuItem>
                  <MenuItem value={'job On Hold'}>Job On Hold</MenuItem>
                  <MenuItem value={'out Of Market'}>Out of Market</MenuItem>
                  <MenuItem value={'Duplicate Submission'}>Duplicate Submission</MenuItem>
                </Select>
              </FormControl>

              <FormControl sx={{}}>
                <TextField
                  id="outlined-multiline-static"
                  label="Note... (Max 2000 char)"
                  placeholder="Note..."
                  multiline
                  rows={5}
                  value={statusNote}
                  onChange={(e) => setStatusNote(e.target.value)}
                />
              </FormControl>
              <Button
                color="custom"
                variant="contained"
                sx={{ width: '15%', alignSelf: 'center' }}
                onClick={(e) => Submitted(row)}
              >
                Submit
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
      <Modal open={openinterview} onClose={handlecloseinterview} style={{ borderRadius: 10 }}>
        <Box sx={styleSkillset}>
          <Typography variant="h5" color={'#3C6BA5'} textAlign={'center'} sx={{ textTransform: 'capitalize' }}>
            {row.fullName} Interview Schedule
          </Typography>
          <Scrollbar sx={{ maxHeight: 600, Scrollbar: 'thin' }}>
            <Divider />
            <Stack
              sx={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
                backgroundColor: 'text.secondary',
                borderRadius:1,
                p:0.5
              }}
            >
              <Typography color={'white'} sx={{ width: '30%', fontWeight: '600', fontSize: '0.976rem' }}>
                Date
              </Typography>
              <Typography color={'white'} sx={{ width: '30%', fontWeight: '600', fontSize: '0.976rem' }}>
                Time
              </Typography>
              <Typography color={'white'} sx={{ width: '30%', fontWeight: '600', fontSize: '0.976rem' }}>
                Mode
              </Typography>
            </Stack>
            {filterTab === 'interview' ? (
              <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%', p: 1 }}>
                <Typography sx={{ width: '30%' }}>{moment(row.interviewDate).format('ll')}</Typography>
                <Typography sx={{ width: '30%', textTransform: 'uppercase' }}>
                  {moment(row.interviewStartTime).format('LT')} - {moment(row.interviewEndTime).format('LT')}{' '}
                  {row.timezone}
                </Typography>
                <Stack sx={{ flexDirection: 'row', width: '30%', textTransform: 'capitalize', overflow: 'hidden' }}>
                  {row.interviewMode === 'phoneic' && (
                    <>
                      <Typography sx={{ textTransform: 'capitalize' }}>Phone - ({row.contactNo})</Typography>
                      <CopyToClipboard
                        text={`
                          Date: ${moment(row.interviewDate).format('ll')}
                          Time:  ${moment(row.interviewStartTime).format('LT')} - ${moment(row.interviewEndTime).format(
                          'LT'
                        )} ${row.timezone}                       
                          Mode: ${'Phone'} - ${
                          row.contactNo
                        },                                                              
                          `}
                      >
                        <Iconify
                          icon="radix-icons:copy"
                          color={'text.textG'}
                          sx={{ ml: 1, height: 17, width: 17, cursor: 'pointer' }}
                          hover
                        />
                      </CopyToClipboard>
                    </>
                  )}
                  {row.interviewMode === 'video' && (
                    <>
                      <Typography sx={{ textTransform: 'capitalize' }}>{row.videoPlatform} - </Typography>
                      <Typography sx={{ textTransform: 'capitalize' ,color:'text.secondary',cursor:'pointer'}} 
                        onClick={()=>window.open(row.meetingLink, '_blank')}>
                          &nbsp; Meeting url
                          </Typography>
                      {/* <a href={row.meetingLink} target="_blank" rel="noreferrer">
                        &nbsp; Meeting link
                      </a> */}
                      <CopyToClipboard
                        text={`
                          Date: ${moment(row.interviewDate).format('ll')}
                          Time:  ${moment(row.interviewStartTime).format('LT')} - ${moment(row.interviewEndTime).format(
                          'LT'
                        )} ${row.timezone}                     
                          Mode: ${row.videoPlatform} - ${row.meetingLink},
                                                                                         
                          `}
                      >
                        <Iconify
                          icon="radix-icons:copy"
                          color={'text.textG'}
                          sx={{ ml: 1, height: 17, width: 17, cursor: 'pointer' }}
                          hover
                        />
                      </CopyToClipboard>
                    </>
                  )}
                  {row.interviewMode === 'onsite' && (
                    <>
                      <Typography sx={{ textTransform: 'capitalize', maxWidth: '90%' }}>
                        face to face - {row.companyAddress}
                      </Typography>
                      <CopyToClipboard
                        text={`
                          Date: ${moment(row.interviewDate).format('ll')}
                          Time:  ${moment(row.interviewStartTime).format('LT')} - ${moment(row.interviewEndTime).format(
                          'LT'
                        )} ${row.timezone}                       
                          Mode: ${'Face To Face'} - ${
                          row.companyAddress
                        }                                                              
                          `}
                      >
                        <Iconify
                          icon="radix-icons:copy"
                          color={'text.textG'}
                          sx={{ ml: 1, height: 17, width: 17, cursor: 'pointer' }}
                          hover
                        />
                      </CopyToClipboard>
                    </>
                  )}
                </Stack>{' '}
              </Stack>
            ) : null}
          </Scrollbar>
        </Box>
      </Modal>
      <Modal open={openclient} onClose={handleCloseclient} style={{ borderRadius: 10 }}>
        <Box sx={styleClients}>
          <Typography variant="h5" color={'#3C6BA5'} textAlign={'center'}>
            Activities
          </Typography>
            <Divider sx={{mb:2}}/>
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
            <Stack sx={{ alignItems: 'center' }}>
              <Stack sx={{ backgroundColor: 'text.secondary', width: '100%', alignItems: 'center', p: 0.5,borderRadius: 1}}>
                <Stack
                  sx={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}
                >
                  <Typography sx={{ width: '25%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Date
                  </Typography>
                  <Typography sx={{ width: '20%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    User Name
                  </Typography>
                  <Typography sx={{ width: '20%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Job ID
                  </Typography>
                  <Typography sx={{ width: '20%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                  Client
                  </Typography>
                  <Typography sx={{ width: '20%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Job Title
                  </Typography>
                  <Typography sx={{ width: '20%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Status
                  </Typography>
                </Stack>
              </Stack>

              {client
                ? client.map((item, index) => {
                    console.log('client list----------------------', item);
                    return (
                      <Stack
                        sx={[
                          { background: index % 2 === 0 ? 'text.background' : tablebg },
                          { width: '100%', flexDirection: 'row', justifyContent: 'space-between', p: 1 },
                        ]}
                      >
                        <Typography sx={{ width: '25%' }}>
                          {moment(`${item.createdAt}`).format('ll')}
                        </Typography>
                        <Typography sx={{ width: '20%', textTransform: 'capitalize',fontWeight:'800',color:'text.secondary' }}>
                          {item.actionBy}
                        </Typography>
                        <Typography sx={{ width: '20%', textTransform: 'capitalize' }}>
                        {item.jobId ? item.jobId : '-'}
                        </Typography>
                        <Typography sx={{ width: '20%', textTransform: 'capitalize' }}>
                          {item.clientName ? item.clientName : '-'}
                        </Typography>
                        <Typography sx={{ width: '20%', textTransform: 'capitalize' }}>
                          {item.jobIdTitle || item.jobTitle}
                        </Typography>
                        <Typography
                          sx={[
                            {
                              color:
                                item.actionTo === 'started' || item.actionTo === 'placement'
                                  ? 'green'
                                  : item.actionTo === 'Backout from Job offer'
                                  ? 'red'
                                  : '',
                            },
                            { width: '20%', textTransform: 'capitalize' },
                          ]}
                        >
                          {item.actionTo}
                        </Typography>
                      </Stack>
                    );
                  })
                : null}
            </Stack>
          </Scrollbar>
        </Box>
      </Modal>

      <Modal open={esub} onClose={closeEsub} style={{ borderRadius: 10 }}>
        <Box sx={[styleSkillset, { width: { xs: '60%', sm: '50%' } }]}>
          <Typography variant="h5" color={'#3C6BA5'} textAlign={'center'}>
            Edit Submission
          </Typography>
          <Divider />
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
            <Stack gap={2}>
                  <FormControl>
                    <Typography fontWeight={500}>Submit to</Typography>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="position"
                      name="radio-buttons-group"
                      value={subto}
                      onChange={(e) => setSubto(e.target.value)}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        ml: 1,
                      }}
                    >
                      <FormControlLabel value="position" control={<Radio />} label="Position" />
                      <FormControlLabel value="client" control={<Radio />} label="Client" />
                    </RadioGroup>
                  </FormControl>
                  {subto === 'position' && (
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Job Id</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={esubdata.subjobid}
                        label="Job Id"
                        onChange={(e) => setEsubdata({ ...esubdata, subjobid: e.target.value })}
                        // onChange={(e) => alert(e.target.value)}
                      >
                        <MenuItem value={'NA'}>NA</MenuItem>
                        {joblst1.map((item,index) => {  
                          return <MenuItem key={index} value={item.jobId}>{item.jobId} : {item.jobTitle}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  )}
                  {subto === 'client' && (
                    <>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select Client</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={esubdata.cname}
                          label="Select Client"
                          onChange={(e) => setEsubdata({ ...esubdata, cname: e.target.value })}
                        >
                          {clientlist.map((item) => {
                            return <MenuItem value={item._id}>{item.clientCompanyId} : {item.companyName}</MenuItem>;
                          })}
                        </Select>
                      </FormControl>
                      <FormControl>
                      <TextField
                        sx={{ mt: 2 }}
                        id="outlined-basic"
                        label="Position Name"
                        placeholder="Position Name"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        value={esubdata.pname}
                        onChange={(e) => setEsubdata({ ...esubdata, pname: e.target.value })}
                      />
                      </FormControl>
                    </>
                  )}
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Compensation Type</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={esubdata.comType}
                      label="Compensation Type"
                      onChange={(e) => setEsubdata({ ...esubdata, comType: e.target.value })}
                    >
                      <MenuItem value={'hourly'}>Per hour Rate </MenuItem>
                      <MenuItem value={'Annually'}>Annual Compensation</MenuItem>;
                    </Select>
                  </FormControl>
                  <FormControl>
                  <TextField
                    id="outlined-basic"
                    label="Expected Compensation( In Dollars )*"
                    placeholder="Expected Compensation"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    value={esubdata.expectedCom}
                    // type="number"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      endAdornment: esubdata.comType && (
                        <InputAdornment position="end">
                          {esubdata.comType === 'hourly' ? 'per/hour' : 'K'}
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => setEsubdata({ ...esubdata, expectedCom: e.target.value })}
                  />
                  </FormControl>
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">Contract (Tax Term) / Fulltime</FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="c2c"
                      name="radio-buttons-group"
                      value={esubdata.tax}
                      onChange={(e) => setEsubdata({ ...esubdata, tax: e.target.value })}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        ml: 1,
                      }}
                    >
                      <FormControlLabel value="c2c" control={<Radio />} label="C2C" />
                      <FormControlLabel value="w2" control={<Radio />} label="W2" />
                      <FormControlLabel value="1099" control={<Radio />} label="1099" />
                      <FormControlLabel value="T4" control={<Radio />} label="T4" />
                      <FormControlLabel value="INC" control={<Radio />} label="INC" />
                      <FormControlLabel value="fulltime" control={<Radio />} label="Full-Time" />
                    </RadioGroup>
                  </FormControl>
                  <Button
                    type="submit"
                    variant="contained"
                    color="custom"
                    size="small"
                    sx={{ width:'fit-content',mb:2 ,alignSelf:'center' }}
                    onClick={() => EditSubmitted()}
                  >
                    Submit
                  </Button>
               
             
          
         </Stack>
          </Scrollbar>
        </Box>
      </Modal>
      <Modal open={eplace} onClose={closeEplace} style={{ borderRadius: 10 }}>
        <Box sx={[styleSkillset, { width: { xs: '60%', sm: '50%' } }]}>
          <Typography variant="h5" color={'#3C6BA5'} textAlign={'center'}>
            Edit Placement
          </Typography>
          <Divider />
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
          <Stack  sx={{}}>
                  <FormControl fullWidth>
                    <Typography  fontWeight={500} sx={{ my: 2 }}>
                      Placement Status
                    </Typography>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="c2c"
                      name="radio-buttons-group"
                      value={eplacedata.placestatus}
                      onChange={(e) => setEplacedata({...eplacedata,placestatus:e.target.value})}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        ml: 1,
                      }}
                    >
                      <FormControlLabel value="placed" control={<Radio />} label="Placed" />
                      <FormControlLabel value="rejected" control={<Radio />} label="Rejected" />
                    </RadioGroup>
                  </FormControl>
                  {eplacedata.placestatus === 'placed' && (
                    <Stack gap={2}>
                      <FormControl sx={{ }}>
                        <InputLabel id="demo-simple-select-label">Job Type</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Job Type"
                          value={eplacedata.jobType}
                          onChange={(e) => setEplacedata({ ...eplacedata, jobType: e.target.value })}
                        >
                          <MenuItem value="onsite">On Site</MenuItem>
                          <MenuItem value="hybrid">Hybrid</MenuItem>
                          <MenuItem value="wfh">WFH</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl sx={{ }}>
                        <InputLabel id="demo-simple-select-label">Offered Job</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Offered Job"
                          value={eplacedata.offeredJob}
                          onChange={(e) => setEplacedata({ ...eplacedata, offeredJob: e.target.value })}
                        >
                          <MenuItem value="fulltime">Fulltime</MenuItem>
                          <MenuItem value="contract">Contract</MenuItem>
                          <MenuItem value="cth">CTH</MenuItem>
                        </Select>
                      </FormControl>
                      {eplacedata.offeredJob === 'fulltime' ? (
                        <>
                        <FormControl sx={{ }}>
                          <TextField
                          sx={{}}
                            id="outlined-multiline-static"
                            label="Annual Salary"
                            value={eplacedata.AnnualSalary}
                            type="number"
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              endAdornment: <InputAdornment position="end">K</InputAdornment>
                            }}
                            onChange={(e) => setEplacedata({ ...eplacedata, AnnualSalary: e.target.value })}
                          />
                          </FormControl>
                          <FormControl>
                          <TextField
                            id="outlined-multiline-static"
                            label="Joining Bonus"
                            value={eplacedata.JoiningBonus}
                            onChange={(e) => setEplacedata({ ...eplacedata, JoiningBonus: e.target.value })}
                          />
                          </FormControl>
                          <FormControl>
                          <TextField
                            id="outlined-multiline-static"
                            label="Annual Bonus"
                            value={eplacedata.AnnualBonus}
                            onChange={(e) => setEplacedata({ ...eplacedata, AnnualBonus: e.target.value })}
                          />
                          </FormControl>
                          <FormControl>
                          <TextField
                            id="outlined-multiline-static"
                            label="Relocation Assistance"
                            value={eplacedata.ReloAssistance}
                            onChange={(e) => setEplacedata({ ...eplacedata, ReloAssistance: e.target.value })}
                          />
                          </FormControl>
                        </>
                      ) : null}

                      {eplacedata.offeredJob !== 'fulltime' ? (
                        <>
                        <FormControl>
                          <TextField
                          sx={{}}
                            id="outlined-multiline-static"
                            label="Hourly Rate Offered"
                            value={eplacedata.hro}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              endAdornment: <InputAdornment position="end">per/hour</InputAdornment>
                            }}
                            onChange={(e) => setEplacedata({ ...eplacedata, hro: e.target.value })}
                          />
                          </FormControl>
                          <FormControl>
                          <TextField
                            id="outlined-multiline-static"
                            label="Tax Term"
                            value={eplacedata.TaxTerm}
                            onChange={(e) => setEplacedata({ ...eplacedata, TaxTerm: e.target.value })}
                          />
                          </FormControl>
                          <FormControl>
                          <TextField
                            id="outlined-multiline-static"
                            label="Overtime Expense"
                            value={eplacedata.OvertimeExpense}
                            onChange={(e) => setEplacedata({ ...eplacedata, OvertimeExpense: e.target.value })}
                          />
                          </FormControl>
                        </>
                      ) : null}

                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer sx={{  mt: -0.8 }} components={['DatePicker', 'DatePicker']}>
                          <DatePicker  
                          disablePast
                            label="Tentative Start Date"
                            value={tsd}
                            onChange={(newValue) => setTsd(newValue)}
                            slotProps={{
                              textField: {
                                  readOnly: true,
                              },
                          }}
                            // console.log("uncontrol date",newValue)}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                      <TextField
                        id="outlined-multiline-static"
                        label="Additional"
                        value={eplacedata.Additional}
                        onChange={(e) =>  setEplacedata({ ...eplacedata, Additional: e.target.value })}
                      />
                    </Stack>
                  )}

                  <TextField
                    sx={{ mt: 2 }}
                    id="outlined-multiline-static"
                    label="Notes"
                    multiline
                    value={eplacedata.placenote}
                    rows={4}
                    onChange={(e) => setEplacedata({ ...eplacedata, placenote: e.target.value })}
                    // defaultValue="Default Value"
                  />

                  <Button
                    type="submit"
                    variant="contained"
                    color="custom"
                    size="small"
                    sx={{ width: 230, mt: 3 }}
                    onClick={() => EditPlacement()}
                  >
                    Update Placement
                  </Button>
                </Stack>  
          </Scrollbar>
        </Box>
      </Modal>
    </>
  );
}
