// @mui
import { styled } from '@mui/material/styles';
import { ListItemIcon, ListItemButton } from '@mui/material';

// ----------------------------------------------------------------------

export const StyledNavItem = styled((props,navwd) =>
 <ListItemButton disableGutters {...props} />)(({ theme,navwd }) => ({
  ...theme.typography.body2,
  height:navwd===100?'100%':48,
  position: 'relative',
  textTransform: 'capitalize',
  color: theme.palette.text.primary,
  borderRadius: theme.shape.borderRadius,
  flexDirection:navwd===100?"column":'row'
}));

export const StyledNavItemIcon = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
