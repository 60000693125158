// ----------------------------------------------------------------------

export function remToPx(value) {
  return Math.round(parseFloat(value) * 16);
}

export function pxToRem(value) {
  return `${value / 16}rem`;
}

export function responsiveFontSizes({ sm, md, lg }) {
  return {
    '@media (min-width:600px)': {
      fontSize: pxToRem(sm),
    },
    '@media (min-width:900px)': {
      fontSize: pxToRem(md),
    },
    '@media (min-width:1200px)': {
      fontSize: pxToRem(lg),
    },
  };
}

// ----------------------------------------------------------------------

const FONT_PRIMARY = 'Roboto, sans-serif'; // Google Font
// const FONT_SECONDARY = 'CircularStd, sans-serif'; // Local Font

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  h1: {
    fontWeight: 400,
    lineHeight: 80 / 64,
    fontSize: pxToRem(40),
    ...responsiveFontSizes({ sm: 72, md: 84, lg: 96 }),
  },
  h2: {
    fontWeight: 300,
    lineHeight: 64 / 48,
    fontSize: pxToRem(32),
    ...responsiveFontSizes({ sm: 48, md: 54, lg: 60 }),
  },
  h3: {
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: pxToRem(24),
    ...responsiveFontSizes({ sm: 40, md: 44, lg: 48 }),
  },
  h4: {
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: pxToRem(20),
    ...responsiveFontSizes({ sm: 26, md: 30, lg: 34 }),
  },
  h5: {
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: pxToRem(18),
    // fontSize: pxToRem(10),
    ...responsiveFontSizes({ sm: 20, md: 22, lg: 24 }),
  },
  h6: {
    fontWeight: 400,
    lineHeight: 28 / 18,
    fontSize: pxToRem(22),
    // fontSize: '1.35rem',
    ...responsiveFontSizes({ sm: 19, md: 20, lg: 20 }),
  },
  subtitle1: {
    fontWeight: 600,
    lineHeight: 1.5,
    // fontSize: pxToRem(16),
    fontSize: pxToRem(13),
    // fontSize: '0.87rem',
  },
  subtitle2: {
    fontWeight: 600,
    lineHeight: 22 / 14,
    // fontSize: pxToRem(14),
    fontSize: pxToRem(11),
    // fontSize: '0.90rem',
  },
  body1: {
    lineHeight: 1.5,
    fontSize: '0.80rem',
    // fontSize: pxToRem(16),
    // fontSize: pxToRem(12),
  },
  body2: {
    
    lineHeight: 22 / 14,
    // fontSize: pxToRem(14),
    fontSize: '0.8125rem',
  },
  tabs: {
    lineHeight: 22 / 14,
    // fontSize: pxToRem(14),
    fontSize: '0.85rem',
  },
  caption: {
    lineHeight: 1.5,
    fontSize: pxToRem(12),
  },
  overline: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(10),
    textTransform: 'uppercase',
  },
  button: {
    fontWeight: 600,
    // lineHeight: 24 / 14,
    lineHeight: 1.5,
    // fontSize: pxToRem(20),
    fontSize: '0.80rem',
    textTransform: 'capitalize',
  },
};

export default typography;
