import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, Link } from 'react-router-dom';
import {
  Typography,
  Table,
  Button,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Modal,
  Box,
  TextField,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar/Scrollbar';
import { Api } from '../webservices';

function UserManagement() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [edit, setEdit] = useState(false);
  const [status, setStatus] = useState('active');
  const [role, setRole] = React.useState('Admin');
  const [editTrue, setEditTrue] = React.useState(true);

  useEffect(() => {
    getCompany();
  }, []);
  // modal 0
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // modal 1
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  // modal 2
  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
  const [tableData, setTableData] = useState([]);
  const tableLabel = [
    { id: 1, label: 'Company Name' },
    { id: 2, label: 'Role' },
    { id: 3, label: 'Email' },
    { id: 4, label: 'Official Domain' },
    { id: 5, label: 'Contact Person Name' },
    { id: 6, label: 'Mobile Number' },
    // { id: 7, label: 'Address' },
    { id: 8, label: 'Action' },
  ];

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { md: '50%', xs: '95%' },
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 3,
  };

  const [formValuesAddComapnny, setFormValuesAddCompany] = useState({
    cName: '',
    role: 'recruiterManager',
    email: '',
    domain: '',
    ContactPersonName: '',
    contactNumber: '',
    password: '',
    address: '',
  });

  const setContact = (e) => {
    const reg = /^[a-zA-Z]*$/
    if(e.length){
      const lst = e[e.length - 1]  
      if(!lst.match(reg)){
        setFormValuesAddCompany({
          ...formValuesAddComapnny,
          contactNumber: e,
        })
      }
    } else{
      setFormValuesAddCompany({
        ...formValuesAddComapnny,
        contactNumber: e,
      })
    }
    
  }

  const getCompany = async () => {
    const token = localStorage.getItem('token');
    Api(`company/get_company_list`, 'GET', '', token)
      .then((response) => {
        console.log('=========getCompany======>>>>>>>', response.data.message);
        console.log('=========getCompany======>>>>>>>', response);
        if (response.status === 200) {
          // toast.success(response.data.message, +'!');
          setTableData(response.data.result);
        } else {
          // toast.warn(response.data.message, +'!');
        }
      })
      .catch((err) => {
        console.log('===========', err);
        toast.error(err, +'!');
      });
  };
  const addCompany = async (e) => {
    // alert('ashish')
    e.preventDefault();
    const token = await localStorage.getItem('token');
    // let token = localStorage.getItem('token');
    const body = {
      email: formValuesAddComapnny.email,
      role: formValuesAddComapnny.role,
      password: formValuesAddComapnny.password,
      contactPersonName: formValuesAddComapnny.ContactPersonName,
      contactNo: formValuesAddComapnny.contactNumber,
      officialDomain: formValuesAddComapnny.domain,
      companyAddress: formValuesAddComapnny.address,
      companyName: formValuesAddComapnny.cName,
    };
    Api(`company/add_user`, 'POST', body, token)
      .then((response) => {
        console.log('===============>>>>>>>', response);
        if (response.status === 200) {
          handleClose2();
          toast.success(response.data.message, +'!');
          getCompany();
          setFormValuesAddCompany({
            ...formValuesAddComapnny,
            cName: '',
            // role: '',
            email: '',
            domain: '',
            ContactPersonName: '',
            contactNumber: '',
            password: '',
            address: '',
          });
          // setTableData(response.data.result)
        } else {
          toast.warn(response.data.message, +'!');
        }
      })

      .catch((err) => {
        toast.error(err, +'!');
        console.log('===========', err);
      });
  };

  const funclick = () => {
    getCompany();
  };
  return (
    <>
      <ToastContainer />
      <Stack flexDirection={'row'} alignItems={'flex-end'} justifyContent={'space-between'} m={1}>
        <Typography variant="h5" mb={2} color={'text.secondary'}>
          Manager Management
        </Typography>
        <Stack flexDirection={'row'} gap={1}>
          <Button color="custom" variant="contained" size="small" onClick={handleOpen2}>
            Add Manager
          </Button>
        </Stack>
      </Stack>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 750 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{backgroundColor:'text.secondary'}}>
              {tableLabel.map((item) => {
                return <TableCell sx={{p:1,py:0.5,color:'white'}} key={item.id}>{item.label}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row, index) => (
              <ManagementRow key={row._id} data={row} fun={funclick} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Scrollbar sx={{ maxHeight: 400 }}>
            <Divider />
            <Stack sx={{ border: '1px solid #dadada', borderRadius: '5px', p: 1, mt: 1 }}>
              <Typography id="modal-modal-description" variant="body1" sx={{ mt: 2 }}>
                It is a long established fact that a reader will be distracted by the readable content of a page when
                looking at its layout. The point of using Lorem.
              </Typography>
              <Typography variant="caption">19-06-2023 10.30am</Typography>
              <Typography variant="caption">
                by <span>User Name</span>
              </Typography>
            </Stack>
            <Stack sx={{ border: '1px solid #dadada', borderRadius: '5px', p: 1, mt: 1 }}>
              <Typography id="modal-modal-description" variant="body1" sx={{ mt: 2 }}>
                It is a long established fact that a reader will be distracted by the readable content of a page when
                looking at its layout. The point of using Lorem.
              </Typography>
              <Typography variant="caption">19-06-2023 10.30am</Typography>
              <Typography variant="caption">
                by <span>User Name</span>
              </Typography>
            </Stack>
            <Stack sx={{ border: '1px solid #dadada', borderRadius: '5px', p: 1, mt: 1 }}>
              <Typography id="modal-modal-description" variant="body1" sx={{ mt: 2 }}>
                It is a long established fact that a reader will be distracted by the readable content of a page when
                looking at its layout. The point of using Lorem.
              </Typography>
              <Typography variant="caption">19-06-2023 10.30am</Typography>
              <Typography variant="caption">
                by <span>User Name</span>
              </Typography>
            </Stack>
            <Stack sx={{ border: '1px solid #dadada', borderRadius: '5px', p: 1, mt: 1 }}>
              <Typography id="modal-modal-description" variant="body1" sx={{ mt: 2 }}>
                It is a long established fact that a reader will be distracted by the readable content of a page when
                looking at its layout. The point of using Lorem.
              </Typography>
              <Typography variant="caption">19-06-2023 10.30am</Typography>
              <Typography variant="caption">
                by <span>User Name</span>
              </Typography>
            </Stack>
            <Divider />
          </Scrollbar>
          <Stack flexDirection={'row'} gap={1} mt={2}>
            <TextField size="small" placeholder="Comment..." sx={{ width: '100%' }} />
            <Button color="custom" variant="contained" onClick={handleOpen}>
              Post
            </Button>
          </Stack>
        </Box>
      </Modal>

      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form onSubmit={(e) => addCompany(e)}>
            <Scrollbar sx={{ maxHeight: { sm: 500, xs: 400 } }}>
              <Grid
                display={'grid'}
                py={1}
                pr={{ xs: 1 }}
                gap={2}
                gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }}
              >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Role</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Role"
                    placeholder="Role"
                    value={formValuesAddComapnny.role}
                    onChange={(e) =>
                      setFormValuesAddCompany({
                        ...formValuesAddComapnny,
                        role: e.target.value,
                      })
                    }
                  >
                    {/* <MenuItem value="admin">NA</MenuItem> */}
                    <MenuItem value="recruiterManager">Recruiter Manager</MenuItem>
                    <MenuItem value="salesManager">Sales Manager</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  id="outlined-basic"
                  type="email"
                  name="email"
                  label="Email"
                  placeholder="Email"
                  variant="outlined"
                  value={formValuesAddComapnny.email}
                  onChange={(e) =>
                    setFormValuesAddCompany({
                      ...formValuesAddComapnny,
                      email: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-basic"
                  name="domain"
                  label="Official Domain"
                  placeholder="Official Domain"
                  variant="outlined"
                  value={formValuesAddComapnny.domain}
                  onChange={(e) =>
                    setFormValuesAddCompany({
                      ...formValuesAddComapnny,
                      domain: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-basic"
                  name="ContactPersonName"
                  label="Contact Person Name"
                  placeholder="Contact Person Name"
                  variant="outlined"
                  value={formValuesAddComapnny.ContactPersonName}
                  onChange={(e) =>
                    setFormValuesAddCompany({
                      ...formValuesAddComapnny,
                      ContactPersonName: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-basic"
                  name="contactNumber"
                  label="Contact Number"
                  placeholder="Contact Number"
                  variant="outlined"
                  value={formValuesAddComapnny.contactNumber}
                  // type="number"
                  onChange={(e) =>setContact(e.target.value)}
                  // onChange={(e) =>
                  //   setFormValuesAddCompany({
                  //     ...formValuesAddComapnny,
                  //     contactNumber: e.target.value,
                  //   })
                  // }
                />
                <Stack className="passwordIcon">
                  <TextField
                    id="outlined-basic"
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    label="Password"
                    placeholder="Password"
                    variant="outlined"
                    value={formValuesAddComapnny.password}
                    onChange={(e) =>
                      setFormValuesAddCompany({
                        ...formValuesAddComapnny,
                        password: e.target.value,
                      })
                    }
                  />
                  <Iconify
                    icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                    onClick={() => setShowPassword(!showPassword)}
                  />
                </Stack>
              </Grid>
            </Scrollbar>
            <Stack flexDirection={'row'} gap={1} mt={2}>
              <Button color="custom" type="submit" variant="contained">
                Add
              </Button>
            </Stack>
          </form>
        </Box>
      </Modal>
    </>
  );
}

function ManagementRow({ data, fun }) {
  const [formValuesAddComapnny, setFormValuesAddCompany] = useState({
    cName: '',
    role: data.role,
    email: data.email,
    domain: data.officialDomain,
    ContactPersonName: data.contactPersonName,
    contactNumber: data.contactNo,
    password: '',
    address: data.companyAddress,
  });
  const [edit, setEdit] = useState(false);
  const [status, setStatus] = useState('active');
  const navigate = useNavigate();

  const setContact = (e) => {
    const reg = /^[a-zA-Z]*$/
    if(e.length){
      const lst = e[e.length - 1]  
      if(!lst.match(reg)){
        setFormValuesAddCompany({
          ...formValuesAddComapnny,
          contactNumber: e,
        })
      }
    } else{
      setFormValuesAddCompany({
        ...formValuesAddComapnny,
        contactNumber: e,
      })
    }
    
  }
  async function editFun(data) {
    const token = await localStorage.getItem('token');
    if (edit === false) {
      setEdit(true);
    } else if (edit === true) {
      const body = {
        companyId: data._id,
        email: formValuesAddComapnny.email ? formValuesAddComapnny.email : data.email,
        role: formValuesAddComapnny.role ? formValuesAddComapnny.role : data.role,
        password: formValuesAddComapnny.password ? formValuesAddComapnny.password : data.password,
        contactPersonName: formValuesAddComapnny.ContactPersonName
          ? formValuesAddComapnny.ContactPersonName
          : data.contactPersonName,
        contactNo: formValuesAddComapnny.contactNumber ? formValuesAddComapnny.contactNumber : data.contactNo,
        officialDomain: formValuesAddComapnny.domain ? formValuesAddComapnny.domain : data.domain,
        companyAddress: formValuesAddComapnny.address ? formValuesAddComapnny.address : data.companyAddress,
      };
      Api(`company/edit_company_details`, 'POST', body, token).then((Response) => {
        console.log('======edit_company_details======>', JSON.stringify(Response));
        if (Response.status === 200) {
          setEdit(false);
          fun();
        }
      });
    }
  }

  async function statuss(data) {
    const token = await localStorage.getItem('token');
    let sts = '';
    if (data.status === 'active') {
      sts = 'blocked';
    } else {
      sts = 'active';
    }
    const body = {
      companyId: data._id,
      status: sts,
    };
    Api(`company/company_status`, 'POST', body, token).then((Response) => {
      console.log('======getUser==response=====>', Response);
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          // toast.success(Response.data.message);
          fun();
          console.log('======getUser===data.data pdata====>', Response.data.data);
        } else {
          console.log('======getUser=======>', +Response);
        }
      }
    });
  }
  return (
    <TableRow key={data._id}>
      <TableCell sx={{ pl: 1 }}>Foxearch</TableCell>
      <TableCell sx={{ pl: 1 }}>{data.role}</TableCell>
      <TableCell sx={{ pl: 1,textTransform:'lowercase'  }}>{data.email}</TableCell>
      <TableCell sx={{ pl: 1 }}>
        {edit === true ? (
          <TextField
            id="outlined-basic"
            label="Official Domain"
            placeholder="Official Domain"
            variant="outlined"
            value={formValuesAddComapnny.domain}
            onChange={(e) =>
              setFormValuesAddCompany({
                ...formValuesAddComapnny,
                domain: e.target.value,
              })
            }
          />
        ) : (
          <span>{data.officialDomain}</span>
        )}
      </TableCell>
      <TableCell sx={{ pl: 2 }}>
        {edit === true ? (
          <TextField
            id="outlined-basic"
            label="Contact Person Name"
            placeholder="Contact Person Name"
            variant="outlined"
            value={formValuesAddComapnny.ContactPersonName}
            onChange={(e) =>
              setFormValuesAddCompany({
                ...formValuesAddComapnny,
                ContactPersonName: e.target.value,
              })
            }
          />
        ) : (
          <span>{data.contactPersonName}</span>
        )}
      </TableCell>

      <TableCell sx={{ pl: 2 }}>
        {edit === true ? (
          <TextField
            id="outlined-basic"
            label="Contact No"
            placeholder="Contact No"
            variant="outlined"
            value={formValuesAddComapnny.contactNumber}
            onChange={(e) => setContact(e.target.value)}
            // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 10, minLength: 10 }}
            // onChange={(e) =>
            //   setFormValuesAddCompany({
            //     ...formValuesAddComapnny,
            //     contactNumber: e.target.value,
            //   })
            // }
          />
        ) : (
          <span>{data.contactNo}</span>
        )}
      </TableCell>
      <TableCell sx={{ pl: 2 }}>
        <Stack flexDirection={'row'} gap={1}>
          <Button color="custom" type="submit" variant="contained" size="small" onClick={() => editFun(data)}>
            {edit === false ? 'edit' : 'save'}
          </Button>
          <Button color="custom" type="submit" variant="contained" size="small" onClick={() => statuss(data)}>
            {data.status}
          </Button>
          {/* <Button color="custom" type="submit" variant="contained" size="small" > */}
          {data.role === 'recruiterManager' || data.role === 'salesManager' ? (
            <Iconify
              icon="maki:arrow"
              sx={{ color: 'text.secondary', width: 30, height: 30 }}
              style={{ cursor: 'pointer' }}
              onClick={() =>
                navigate(data.role === 'recruiterManager' ? '/recruiterTeamLead' : '/salesTeamLead', {
                  state: { id: data._id },
                })
              }
            />
          ) : (
            <Iconify
              icon="maki:arrow"
              sx={{ color: 'text.secondary', width: 30, height: 30 }}
              style={{ cursor: 'pointer' }}
              onClick={() => navigate('/salesManagement', { state: { id: data._id } })}
            />
          )}
        </Stack>
      </TableCell>
    </TableRow>
  );
}

export default UserManagement;
