import React, { Fragment, useEffect, useState } from 'react';
import {
  Button,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Tabs,
  Tab,
  Box,
  Modal,
  Divider,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  TextField,
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
  ListItemText,
  OutlinedInput,
  Pagination

} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Icon } from '@iconify/react';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ToastContainer, toast } from 'react-toastify';
import Iconify from '../components/iconify';

import Scrollbar from '../components/scrollbar/Scrollbar';
import { Api } from '../webservices';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const styleSkillset = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: { xs: '60%', sm: '50%' },
  width: '50%',
  bgcolor: 'background.paper',
  border: '3px solid #3C6BA5',
  boxShadow: 24,
  p: 3,
  borderRadius: 3,
};

const tablebg = 'rgba(82, 130, 190, 0.08)';
function Jobs() {
  const navigate = useNavigate();
  const role = useSelector((state) => state.userData.data.role);
  const [currentTab, setCurrentTab] = React.useState('Active');
  const [jobList, setJobList] = React.useState([]);
  const [tempRole , setTempRole] = useState('')
  const [pageNumber,setPageNumber]=useState(1)
  const [pages,setPages]=useState(null)

  const handlePage = (event, value) => {
    console.log(value)
    setPageNumber(value);
    getJobs(currentTab,value)
  };

  useEffect(() => {
    getJobs('Active');
  }, []);

  const getJobs = (val,value) => {
    const body = {
      status: val,
      "page":value || 1,
      "limit":"35"
    };
    const token = localStorage.getItem('token');
    Api('company/jobs', 'POST', body, token).then((Response) => {
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          setJobList(Response.data.data);
          const totalPage = Math.ceil(Response.data.count/35)
          setPages(totalPage)
        }
      }
    });
  };
  const getJobsFun = (val) => {
    getJobs(val);
  };

  const handleTabs = (val) => {
    setCurrentTab(val);
    getJobs(val);
    // getJobs(val);
  }
  return (
    <>
      <ToastContainer />

      <Stack flexDirection={'row'} alignItems={'flex-end'} justifyContent={'space-between'} m={1}>
        <Typography variant="h5" color={'text.secondary'}>
          All Jobs
        </Typography>
        {role === 'admin' || role === 'salesHead' || role === "salesManager"|| role === 'recruiterHead' || role === 'recruiterManager' || role === 'recruiterTeamLead' ?(  
        <Button color="custom" variant="contained" size="small" onClick={() => navigate('/addjob', { state: { row: '' } })}>
          
          Add New Job
        </Button>
        ):null}
      </Stack>
     
       <Stack sx={{ flexDirection: 'row', gap: 2, mb: 0.5, cursor: 'pointer', alignItems: 'center' }}>
        <Stack
          sx={[
            { flexDirection: 'row' },
            currentTab === '' ? { borderBottom: 3, color: 'text.secondary' } : { color: 'text.textG' },
          ]}
          onClick={() => {handleTabs('')
           
          }}
        >
          <Iconify icon="mingcute:list-check-line" fontSize={17} />
          <Typography variant="tabs" sx={{ ml: 1 }}>
            All
          </Typography>
        </Stack>
        <Stack
          sx={[
            { flexDirection: 'row'},
            currentTab === 'Active' ? { borderBottom: 3, color: 'text.secondary' } : { color: 'text.textG' },
          ]}
          onClick={() => {handleTabs('Active')
           
          }}
        >
          <Iconify icon="iconamoon:star-thin" fontSize={17} color={currentTab ==="Active"?"#2ECC71":"text.textG"} />
          <Typography variant="tabs" sx={{ ml: 1 }}>
            Active Job
          </Typography>
        </Stack>
        <Stack
          sx={[
            { flexDirection: 'row' },
            currentTab === 'On Hold' ? { borderBottom: 3, color: 'text.secondary' } : { color: 'text.textG' },
          ]}
          onClick={() => {  handleTabs('On Hold')
          }}
        >
          <Iconify icon="mynaui:pause" fontSize={17} color={currentTab ==='On Hold'? '#F4D03F' : 'text.textG'}/>
          <Typography variant="tabs" sx={{ ml: 1 }}>
            On Hold
          </Typography>
        </Stack>
        <Stack
          sx={[
            { flexDirection: 'row' },
            currentTab === 'Closed' ? { borderBottom: 3, color: 'text.secondary' } : { color: 'text.textG' },
          ]}
          onClick={() => {  handleTabs('Closed')
          }}
        >
          <Iconify icon="material-symbols:block" fontSize={17} color={currentTab ==="Closed"? '#CB4335' : 'text.textG'}/>
          <Typography variant="tabs" sx={{ ml: 1 }}>
            Closed Jobs
          </Typography>
        </Stack>
        <Stack
          sx={[
            { flexDirection: 'row' },
            currentTab === 'Cancelled Jobs' ? { borderBottom: 3, color: 'text.secondary' } : { color: 'text.textG' },
          ]}
          onClick={() => {  handleTabs('Cancelled Jobs')
          }}
        >
          <Iconify icon="jam:triangle-danger" fontSize={17} color={currentTab ==="Cancelled Jobs"? '#CB4335' : 'text.textG'} />
          <Typography variant="tabs" sx={{ ml: 1 }}>
            Cancelled Jobs
          </Typography>
        </Stack>
        </Stack>
      <TableContainer component={Paper} >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ justifyContent: 'flex-start', backgroundColor: 'text.secondary' }}>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white' ,width:'12%'}}>Date</TableCell>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white',width:'18%' }}>Role</TableCell>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white',width:'14%' }}>Compensation</TableCell>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white',width:'12%' }}>Job Info</TableCell>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white',width:'12%' }}>Activity</TableCell>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white',width:'10%' }}>Search</TableCell>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white',width:'10%' }}>Assignee</TableCell>
              <TableCell sx={{ p: 1, py: 0.5, color: 'white',width:'12%' }}>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {jobList.map((item, index) => (
              <JobRow key={item._id} row={item} index={index} cTab={currentTab} role={role}getJobsFun={getJobsFun} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {jobList.length < 1 && (
        <Stack sx={{ mt: 1 }}>
          <Typography sx={{ alignSelf: 'center', color: 'red' }}>no data found</Typography>
        </Stack>
      )}
       {jobList.length ? (
        <Stack spacing={2} sx={{ alignItems: 'flex-end', width: '100%', mt: 2 }}>
          <Pagination  variant="outlined" shape="rounded" color="secondary" count={pages} page={pageNumber} onChange={handlePage}/>
         </Stack>
      ) : null}
    </>
  );
}

const JobRow = ({ row, index, cTab, getJobsFun,role }) => {
  const navigate = useNavigate();
  const styleClients = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '60%', sm: '80%' },
    bgcolor: 'background.paper',
    border: '3px solid #3C6BA5',
    boxShadow: 24,
    p: 3,
    borderRadius: 3,
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openSub, setOpenSub] = useState(false);
  const subCloses = () => setOpenSub(false);
  const subOpen = () => setOpenSub(true);

  const [openInt, setOpenInt] = useState(false);
  const intCloses = () => setOpenInt(false);
  const intOpen = () => setOpenInt(true);

  const [openPla, setOpenPla] = useState(false);
  const plaCloses = () => setOpenPla(false);
  const plaOpen = () => setOpenPla(true);

  const [client, setClient] = useState([]);

  const [openstatus, setOpenstatus] = useState(false);
  const statusCloses = () => setOpenstatus(false);
  const statusOpen = () => setOpenstatus(true);

  const [openP, setOpenP] = useState(false);
  const pCloses = () => setOpenP(false);
  const pOpen = () => setOpenP(true);

  const [openAss, setOpenAss] = useState(false);
  const assCloses = () => setOpenAss(false);
  const assOpen = () => setOpenAss(true);

  const [statuss, setStatus] = useState(row.status);
  const [priorityy, setPriority] = useState('');
  const [member, setMember] = useState('individual');

  const [actionData, setActionData] = useState([]);
  const [assignee, setAssignee] = useState([]);
  const [unAssignee, setUnAssignee] = useState([]);
  const [allUsers, setAllUsers] = useState([])

  const [openJobM, setOpenJobM] = useState(false);
  const closeJobM = () => setOpenJobM(false);
  const openJobMm = () => setOpenJobM(true);
  const [jobUserList, setJobUserList] = useState([])

  const [tempRole , setTempRole] = useState('')
  const [personName, setPersonName] = React.useState([]);
  const [personId, setPersonId] = React.useState([]);
  const [roleUsers,setRoleUsers] = useState([])
  const [underRole,setUnderRole] = useState([])
  const [selectUser,setSelectUser] = useState([])
  const [selectUserId,setSelectUserId] = useState([])
  const [allAssign,setAllAssign] = useState(false)
  const [editAssign,setEditAssign] = useState(false)
  

  const handleChange = (event) => {
    console.log('event',event.target.value) 
    setPersonName(event.target.value);
    setSelectUser([...selectUser,roleUsers[event.target.value]])
    setSelectUserId([...selectUserId,roleUsers[event.target.value]._id] )
    // alert(roleUsers[event.target.value]._id)
    userUnderRole(roleUsers[event.target.value]._id)
    // const {target: { value }, } = event.target.value[0].contactPersonName;
    // setPersonName(
    //   // On autofill we get a stringified value.
    //   typeof value === 'string' ? value.split(',') : value,
    // );
  };

  const changeStatus = () => {
    const token = localStorage.getItem('token');
    const body = {
      id: row._id,
      status: statuss,
    };
    Api('company/newjob', 'POST', body, token).then((Response) => {
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          statusCloses();
          getJobsFun(cTab);
        }
      } else {
        toast.warn(Response.data.error, +'!');
      }
    });
  };
  const changePriority = () => {
    const token = localStorage.getItem('token');
    const body = {
      jobId: row._id,
      priority: priorityy,
    };
    Api('company/set_priority', 'POST', body, token).then((Response) => {
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          pCloses();
          getJobsFun(cTab);
        }
      } else {
        toast.warn(Response.data.error, +'!');
      }
    });
  };

  const jobAction = (val) => {
    const token = localStorage.getItem('token');
    const body = {
      jobId: row.jobId,
      status: val,
    };
    Api('company/actionsbyjob', 'POST', body, token).then((Response) => {
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          // toast.success(Response.data.message);
          setActionData(Response.data.data);
        }
      } else {
        toast.warn(Response.data.error, +'!');
      }
    });
  };

  const getAllUser =async()=>{
    const token = localStorage.getItem('token');
     Api('company/all_users', 'GET', '', token).then((Response) => {
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          // toast.success(Response.data.message);
          setAllUsers(Response.data.data);
        }
      } else {
        toast.warn(Response.data.error, +'!');
      }
    });
  }

  const asignData = (val) => {
      if(val.target.checked){
        setAssignee([...assignee,val.target.value])
      }else{
        assignee.forEach((item,index)=>{
      if(item === val.target.value) {
        assignee.splice(index,1)
       setAssignee(assignee)
        }
       }) 
      }
    };

  const unAsignData = (val) => {
      if(val.target.checked){
        setUnAssignee([...unAssignee,val.target.value])
      }else{
        unAssignee.forEach((item,index)=>{
      if(item === val.target.value) {
        unAssignee.splice(index,1)
       setUnAssignee(unAssignee)
        }
       }) 
      }
    };

    const AllAsignData = (val) => {
      const tempData = []
        setAllAssign(true)
        allUsers.forEach((item)=>  {
          tempData.push(item._id)
          console.log('all-',item._id)
        } )
        setAssignee(tempData)
     
    }

    const assignJob = async() => {

      const token = localStorage.getItem('token');
      const body = {
        "jobId": row._id,
        "assignedTo": member === 'team'?selectUserId:assignee,
        "assignType": member === 'team'? 'team':'individual'
      };
      Api('company/assignjob', 'POST', body, token).then((Response) => {
        if (Response.status === 200) {
          if (Response.data.code === 200) {
            assCloses()
            toast.success(Response.data.message);
            // setActionData(Response.data.data);
          }
        } else {
          toast.warn(Response.data.error, +'!');
        }
      });
    };

    const unAssignJob = async() => {
      const token = localStorage.getItem('token');
      const body = {
        "jobId": row._id,
        "usersToUnassign": unAssignee,
      };
      Api('company/remove_job', 'POST', body, token).then((Response) => {
        if (Response.status === 200) {
          if (Response.data.code === 200) {
          setEditAssign(false)
           closeJobM()
            toast.success(Response.data.message);
            // setActionData(Response.data.data);
          }
        } else {
          toast.warn(Response.data.error, +'!');
        }
      });
    };

    const jobUsers =async()=>{
      openJobMm()      
      const token = localStorage.getItem('token');
       Api(`company/users_for_job/${row._id}`, 'GET', '', token).then((Response) => {
        if (Response.status === 200) {
          if (Response.data.code === 200) {    
            if(Response.data.data.length){            
            setJobUserList(Response.data.data);
            
          }else{
            toast.warn(Response.data.message);
          }
        }
        } else {
          toast.error(Response.data.error, +'!');
        }
      });
    }

   const  handleRole =(val)=>{
    setPersonName([])
    const token = localStorage.getItem('token');
    Api(`company/userbyrole/${val}`, 'GET', '', token).then((Response) => {
     if (Response.status === 200) {
       if (Response.data.code === 200) {    
         if(Response.data.data.length){            
          setRoleUsers(Response.data.data);      
       }else{
         toast.warn(Response.data.message);
       }
     }
     } else {
       toast.error(Response.data.error, +'!');
     }
   });
   }
   
   const  userUnderRole =(val)=>{
    const token = localStorage.getItem('token');
    Api(`company/underusers/${val}`, 'GET', '', token).then((Response) => {
     if (Response.status === 200) {
       if (Response.data.code === 200) {    
         if(Response.data.data.length){            
          setUnderRole([...underRole,Response.data.data]);      
       }else{ 
         toast.warn(Response.data.message);
       }
     }
     } else {
       toast.error(Response.data.error, +'!');
     }
   });
   }

   const handleRoleUser=(index)=>{
      selectUser.splice(index,1)
      setSelectUser([...selectUser])
      selectUserId.splice(index,1)
      setSelectUserId([...selectUserId])
      console.log('selectUserId',selectUserId)
   }

  return (
    <>
      <TableRow
        key={row._id}
        sx={[
          { background: index % 2 === 0 ? 'text.background' : tablebg },
          { '&:last-child td, &:last-child th': { border: 0 } },
          { '&:hover': { boxShadow: 5 } },
        ]}
      >
        <TableCell sx={{}}>
          <Typography sx={{}}>Updated {moment(row.createdAT).format('ll')}</Typography>
          <Typography sx={{}}>Since {moment().diff(row.createdAT, 'months')} months</Typography>
          {role === 'admin' || role === 'salesHead' || role === "salesManager"|| role === 'recruiterHead' || role === 'recruiterManager' || role === 'recruiterTeamLead' ?(  
          <Typography
            sx={{ color: 'text.secondary', textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => navigate('/addJob', { state: { row } })}
          >
            Edit
          </Typography>
          ):(
            <Typography>&nbsp;</Typography>
          )}
        </TableCell>
        <TableCell sx={{}}>
          <Typography
            sx={{ fontWeight: '600',fontSize: '0.90rem', cursor: 'pointer', maxWidth:160,overflow:'hidden',textOverflow:'ellipsis'}}
            color={'#286ACC'}
            onClick={() => navigate('/jobDetail', { state: { row } })}
          >
            {row?.jobTitle || '-'}
          </Typography>
          <Typography sx={{maxWidth:160,overflow:'hidden',textOverflow:'ellipsis'}} >
            {row.endClientOrImpPartner || '-'}
          </Typography>
          <Stack sx={{ flexDirection: 'row', gap: 0.5 }}>
          <Typography sx={{maxWidth:160,overflow:'hidden',textOverflow:'ellipsis'}} color={'text.textG'}>
            {row.location || '-'}
          </Typography>
          {row.outOfAreaCandidate === "Yes"&&(
            <Iconify icon="mdi:aeroplane" fontSize={20} color={'text.secondary'} />
          )}      
          </Stack>
        </TableCell>
      
        <TableCell sx={{}}>
          {row.compensationType === 'annual' ? (
            <Typography sx={{}} >
             ${row.annualMinCompensation} K{' - '}
             ${row.annualMaxCompensation} K
            </Typography>
          ) : null}

          {row.compensationType === 'per hour' ? (
            <Typography sx={{textTransform:'none'}} >
              ${row.payRateOn.minPayRate} P/H{' - '}
              ${row.payRateOn.maxPayRate} P/H
            </Typography>
          ) : null}
          <Stack sx={{ flexDirection: 'row',gap:0.5,maxWidth:130,overflow:'hidden',textOverflow:'ellipsis' }}>
        {row.taxTerm.length?row.taxTerm.map((item,index)=>{
         return <Typography key={index} sx={{ textTransform: 'none' }}>{item} {index < row.taxTerm.length - 1&&'|'}</Typography>
        }): <Typography sx={{ flexDirection: 'row' }}>-</Typography>}
        </Stack>
          
          <Typography sx={{ flexDirection: 'row',color:'text.textG'}}>{row.duration || '-'}</Typography>
        </TableCell>
        <TableCell sx={{}}>
          <Typography sx={{ flexDirection: 'row' ,color:'text.secondary',fontWeight:'600'}}>{row.jobId || '-'}</Typography>
          <Typography sx={{ flexDirection: 'row' }}>{row.jobType || '-' }</Typography>
         {row.openings ==='1'?(
          <Typography sx={{ flexDirection: 'row',color:'text.textG' }}>{row.openings} opening</Typography>
         ):(
          <Typography sx={{ flexDirection: 'row',color:'text.textG' }}>{row.openings} openings</Typography>
         )} 
        </TableCell>
        <TableCell sx={{}}>
          <Typography
            sx={{ color: '#286ACC', cursor: 'pointer',fontWeight: '600' }}
            onClick={() => {
              handleOpen();
              jobAction('submitted');
            }}
          >
            submissions - {row.submittedCount || 0}
          </Typography>
          <Typography
            sx={{ color: '#AE8D18', cursor: 'pointer',fontWeight: '600' }}
            onClick={() => {
              intOpen();
              jobAction('interview');
            }}
          >
            Interviews - {row.interviewCount || 0}
          </Typography>
          <Typography
            sx={{ color: '#399648', cursor: 'pointer',fontWeight: '600' }}
            onClick={() => {
              plaOpen();
              jobAction('placement');
            }}
          >
            Placements - {row.placementCount || 0}
          </Typography>
        </TableCell>

        <TableCell sx={{}}>
          <Typography sx={{ fontWeight: '600' }} color={'text.secondary'}>
            Foxearch
          </Typography>
        </TableCell>

        <TableCell sx={{}}>
          <Stack sx={{ flexDirection: 'row' }} >
            <Typography sx={{color:'text.secondary',cursor: 'pointer' }} onClick={()=>jobUsers()}>Assignees</Typography>
            {role === 'admin' || role === 'salesHead' || role === 'recruiterHead'|| role === 'recruiterManager' ?( 
            <Iconify
              icon="bx:edit"
              // color={'text.secondary'}
              sx={{ height: 15, width: 15,ml:0.3 ,cursor: 'pointer',alignSelf:'center' }}
              onClick={() => {assOpen();getAllUser()}}
            />
            ):null}
          </Stack>
            {row.status === "Active"?( 
          <Stack sx={{ flexDirection: 'row', cursor: 'pointer' }}>
            <Typography sx={[{color:row.priority=== 'Top Priority'?'#399648':row.priority=== 'Mid Priority'?'#AE8D18':row.priority=== 'Low Priority'?'#E08787':null}]}>{row.priority?row.priority:'Priority'}</Typography>
            {role === 'admin' || role === 'salesHead' || role === 'recruiterHead' || role === 'recruiterManager' ?( 
            <Iconify
              icon="bx:edit"
              onClick={() => pOpen()}
              // color={'text.secondary'}
              sx={{ height: 15, width: 15,alignSelf:'center',ml:0.3  }}
            />
            ):null}
          </Stack>
            ):(
              <Typography   >
          Priority
          </Typography>
            )}
          <Typography sx={{ color:'text.textG',maxWidth:130,overflow:'hidden',textOverflow:'ellipsis'}}  >
            {row.addedByName?row.addedByName:'-'}
          </Typography>
        </TableCell>
        <TableCell sx={{}}>
          <Stack sx={{ flexDirection: 'row', cursor: 'pointer' }}>
            <Typography sx={{}}>{row.status ||'-'}</Typography>
            {role === 'admin' || role === 'salesHead' || role === 'recruiterHead' || role === 'recruiterManager' ?( 
              <Iconify
              icon="bx:edit"
              onClick={() => statusOpen()}
              // color={'text.secondary'}
              sx={{  height: 15, width: 15,alignSelf:'center',ml:0.3 }}
              />
            ):null}
        
          </Stack>
          <Typography sx={{maxWidth:130,overflow:'hidden',textOverflow:'ellipsis'}} >
            {row.client || '-'}
          </Typography>
          <Typography sx={{maxWidth:130,overflow:'hidden',textOverflow:'ellipsis'}} color={'text.textG'}>
            {row.lead || '-'}
          </Typography>
        </TableCell>
      </TableRow>

      <Modal open={open} onClose={handleClose} style={{ borderRadius: 10 }}>
        <Box sx={[styleClients,{width: { xs: '60%', sm: '90%' }}]}>
          <Typography variant="h5" color={'#3C6BA5'} textAlign={'center'}>
            Submissions
          </Typography>
            <Divider sx={{mb:2}}/>
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
            <Stack sx={{ alignItems: 'center' }}>
              <Stack
                sx={{ backgroundColor: 'text.secondary', width: '100%', alignItems: 'center', p: 0.5, borderRadius: 1 }}
              >
                <Stack sx={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Typography sx={{ width: '15%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Date
                  </Typography>
                  <Typography sx={{ width: '25%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Name
                  </Typography>
                  <Typography sx={{ width: '25%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Email
                  </Typography>
                  <Typography sx={{ width: '20%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Compensation
                  </Typography>
                  <Typography sx={{ width: '20%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Tax Term
                  </Typography>
                  <Typography sx={{ width: '20%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Status
                  </Typography>
                  <Typography sx={{ width: '20%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Presented By
                  </Typography>
                </Stack>
              </Stack>

              {actionData
                ? actionData.map((item, index) => {
                    console.log('client list----------------------', item);
                    return (
                      <Stack
                        sx={[
                          { background: index % 2 === 0 ? 'text.background' : tablebg },
                          { '&:last-child td, &:last-child th': { border: 0 } },
                          { '&:hover': { boxShadow: 5 } },{flexDirection:'row',justifyContent:'space-between',width:'100%',p:1}
                        ]}
                      >
                        <Typography sx={{ width: '15%' }}>{moment(row.updatedAt).format('ll')}</Typography>
                  
                        <Typography
                          sx={{width: '25%', textTransform: 'capitalize', fontWeight: '600', color: 'text.secondary' }}
                        >
                          {item.fullName ? item.fullName : '-'}
                        </Typography>
                       
                        <Typography sx={{ width: '25%', textTransform: 'capitalize',overflow:'hidden',textOverflow:'ellipsis' }}>
                          {item.prospectEmail ? item.prospectEmail : '-'}
                        </Typography>
                        <Typography sx={{ width: '20%', textTransform: 'capitalize' }}>
                          ${item.submissionData.expectedCompensation}{" "}
                          {item.submissionData.chargeType === 'Annually' ? 'K' : 'P/H'}
                        </Typography>
                        <Typography sx={{ width: '20%', textTransform: 'uppercase' }}>{item.submissionData.taxTerm ||"-"}</Typography>
                        <Typography sx={{ width: '20%', textTransform: 'capitalize' }}>{item.submissionData.tempSubmitStatus ||"Submitted"}</Typography>
                        <Typography sx={{ width: '20%', textTransform: 'capitalize' }}>
                          {item.presentedBy ? item.presentedBy : '-'}
                        </Typography>
                      </Stack>
                    );
                  })
                : null}
            </Stack>
          </Scrollbar>
        </Box>
      </Modal>
      <Modal open={openInt} onClose={intCloses} style={{ borderRadius: 10 }}>
        <Box sx={[styleClients,{width: { xs: '60%', sm: '90%' }}]}>
          <Typography variant="h5" color={'#3C6BA5'} textAlign={'center'}>
            Interviews
          </Typography>
            <Divider sx={{mb:2}} />
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
            <Stack sx={{ alignItems: 'center' }}>
              <Stack
                sx={{ backgroundColor: 'text.secondary', width: '100%', alignItems: 'center', p: 0.5, borderRadius: 1 }}
              >
                <Stack sx={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Typography sx={{ width: '10%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Date
                  </Typography>
                  <Typography sx={{ width: '15%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Name
                  </Typography>
                  <Typography sx={{ width: '15%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Email
                  </Typography>
                  <Typography sx={{ width: '10%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Mode
                  </Typography>
                  <Typography sx={{ width: '10%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Location
                  </Typography>
                  <Typography sx={{ width: '15%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Hiring Manager
                  </Typography>
                  <Typography sx={{ width: '10%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Status
                  </Typography>
                  <Typography sx={{ width: '15%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Presented By
                  </Typography>
                </Stack>
              </Stack>

              {actionData
                ? actionData.map((item, index) => {
                    console.log('client list----------------------', item);
                    return (
                      <Stack
                        sx={[
                          { background: index % 2 === 0 ? 'text.background' : tablebg },
                          { '&:last-child td, &:last-child th': { border: 0 } },
                          { '&:hover': { boxShadow: 5 } },{width:'100%',flexDirection:'row',justifyContent:'space-between',p:1}
                        ]}
                      >
                        <Typography sx={{ width: '10%' }}>{moment(row.interviewDate).format('ll')}</Typography>
                        <Typography
                          sx={{ width: '15%', textTransform: 'capitalize', fontWeight: '600', color: 'text.secondary' }}
                        >
                          {item.fullName ? item.fullName : '-'}
                        </Typography>
                        <Typography sx={{ width: '15%', textTransform: 'none' }}>
                          {item.prospectEmail ? item.prospectEmail : '-'}
                        </Typography>
                        <Typography sx={{ width: '10%', textTransform: 'capitalize' }}>{item.submissionData.interviewMode}</Typography>
                        {item.submissionData.interviewMode === 'phoneic' && (
                          <Typography sx={{ width: '10%', textTransform: 'capitalize' }}>
                            {item.submissionData.contactNo ? item.submissionData.contactNo : '-'}
                            {item.submissionData.interviewMode === '' ? item.submissionData.contactNo : ''}
                            {item.submissionData.interviewMode === 'onsite' ? item.submissionData.contactNo : ''}
                          </Typography>
                        )}
                        {item.submissionData.interviewMode === 'video' && (
                          <Typography
                            sx={{ width: '10%', color: 'text.secondary' }}
                            onClick={() => window.open(item.submissionData.meetingLink, '_blank')}
                          >
                            {item.submissionData.videoPlatform ? item.submissionData.videoPlatform : '-'}
                          </Typography>
                        )}
                        {item.submissionData.interviewMode === 'onsite' && (
                          <Typography sx={{ width: '10%', textTransform: 'capitalize' }}>
                            {item.submissionData.companyAddress ? item.submissionData.companyAddress : '-'}
                          </Typography>
                        )}
                        <Typography sx={{ width: '15%', textTransform: 'capitalize' }}>
                          {item.submissionData.hiringManagerName ? item.submissionData.hiringManagerName : '-'}
                        </Typography>
                        <Typography sx={{ width: '10%', textTransform: 'capitalize' }}>
                          {item.submissionData.stepWiseStatus ? item.submissionData.stepWiseStatus : '-'} Interview
                        </Typography>
                        <Typography sx={{ width: '15%', textTransform: 'capitalize' }}>
                          {item.presentedBy ? item.presentedBy : '-'}
                        </Typography>
                      </Stack>
                    );
                  })
                : null}
            </Stack>
          </Scrollbar>
        </Box>
      </Modal>
      <Modal open={openPla} onClose={plaCloses} style={{ borderRadius: 10 }}>
        <Box sx={[styleClients,{width: { xs: '60%', sm: '95%' }}]}>
          <Typography variant="h5" color={'#3C6BA5'} textAlign={'center'}>
            Placements
          </Typography>
            <Divider sx={{mb:2}} />
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
            <Stack sx={{ alignItems: 'center' }}>
              <Stack
                sx={{ backgroundColor: 'text.secondary', width: '100%', alignItems: 'center', p: 0.5, borderRadius: 1 }}
              >
                <Stack sx={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Typography sx={{width:'10%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>Date</Typography>
                  <Typography sx={{width:'10%',color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Placements No
                  </Typography>
                  <Typography sx={{width:'10%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>Name</Typography>
                  <Typography sx={{width:'10%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    Employement Type
                  </Typography>
                  <Typography sx={{width:'10%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>Tax Term</Typography>
                  <Typography sx={{width:'10%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>Compensation</Typography>
                  <Typography sx={{width:'10%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>Start Date</Typography>
                  <Typography sx={{width:'10%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                    End Date / Expected ED
                  </Typography>
                  <Typography sx={{ width:'10%',color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>Status</Typography>
                  <Typography sx={{width:'10%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>Presented By</Typography>
                </Stack>
              </Stack>

              {actionData
                ? actionData.map((item, index) => {
                    console.log('client list----------------------', item);
                    return (
                      <Stack
                        sx={[
                          { background: index % 2 === 0 ? 'text.background' : tablebg },
                          { '&:last-child td, &:last-child th': { border: 0 } },
                          { '&:hover': { boxShadow: 5 } },{width:'100%',flexDirection:'row',justifyContent:'space-between',p:1}
                        ]}
                      >
                        <Typography sx={{width:'10%'}}>{moment(row.updatedAt).format('MMM YY')}</Typography>
                        <Typography sx={{width:'10%'}}>NA</Typography>
                        <Typography sx={{width:'10%', textTransform: 'capitalize', fontWeight: '600', color: 'text.secondary' }}>
                          {item.fullName ? item.fullName : '-'}
                        </Typography>
                        <Typography sx={{width:'10%', textTransform: 'capitalize' }}>
                          {item.submissionData.offeredJob ? item.submissionData.offeredJob : '-'}
                        </Typography>
                        <Typography sx={{width:'10%', textTransform: 'capitalize' }}>
                          {item.submissionData.taxTerm ? item.submissionData.taxTerm : '-'}
                        </Typography>
                        <Typography sx={{width:'10%', textTransform: 'capitalize' }}>
                          $ {item.submissionData.expectedCompensation} {item.submissionData.chargeType === 'Annually' ? 'K' : 'P/H'}
                        </Typography>
                        <Typography sx={{width:'10%',}}>{moment(row.joiningDate).format('ll')}</Typography>
                        <Typography sx={{width:'10%',}}>{item.submissionData.tentativeStartDate?moment(item.submissionData.tentativeStartDate).format('ll'):"-"}</Typography>
                        <Typography sx={{width:'10%', textTransform: 'capitalize' }}>{item.submissionData.status ? item.submissionData.status : '-'}</Typography>
                        <Typography sx={{width:'10%', textTransform: 'capitalize', fontWeight: '600', color: 'text.secondary' }}>
                          {item.presentedBy ? item.presentedBy : '-'}
                        </Typography>
                      </Stack>
                    );
                  })
                : null}
            </Stack>
          </Scrollbar>
        </Box>
      </Modal>

      <Modal open={openstatus} onClose={statusCloses} style={{ borderRadius: 10 }}>
        <Box sx={[styleSkillset]}>
          <Typography variant="h5" color={'#3C6BA5'} textAlign={'center'}>
            Change Status
          </Typography>

          <Divider />
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
            <Grid
              container
              sx={{ mt: 2, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
              gap={2}
            >
              <FormControl sx={{ width: '100%' }}>
                <InputLabel id="demo-simple-select-label">Select Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Select New Status"
                  value={statuss}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <MenuItem value={'Active'}>Active</MenuItem>;<MenuItem value={'Closed'}>Closed</MenuItem>;
                  <MenuItem value={'On Hold'}>On Hold</MenuItem>;<MenuItem value={'Cancelled'}>Cancelled</MenuItem>;
                </Select>
              </FormControl>
            </Grid>
            <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
              <Button color="custom" variant="contained" sx={{ width: '10%', mt: 2 }} onClick={() => changeStatus()}>
                Submit
              </Button>
            </Stack>
          </Scrollbar>
        </Box>
      </Modal>
      <Modal open={openP} onClose={pCloses} style={{ borderRadius: 10 }}>
        <Box sx={[styleSkillset]}>
          <Typography variant="h5" color={'#3C6BA5'} textAlign={'center'}>
            Set Priority
          </Typography>

          <Divider />
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin' }}>
            <Grid
              container
              sx={{ mt: 2, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
              gap={2}
            >
              <FormControl sx={{ width: '100%' }}>
                <InputLabel id="demo-simple-select-label">Select Priority</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Select Priority"
                  InputLabelProps={{ shrink: true }}
                  value={priorityy}
                  onChange={(e) => setPriority(e.target.value)}
                >
                  <MenuItem value={'Top Priority'}>Top Priority</MenuItem>
                  <MenuItem value={'Mid Priority'}>Mid Priority</MenuItem>;
                  <MenuItem value={'Low Priority'}>Low Priority</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
              <Button color="custom" variant="contained" sx={{ width: '10%', mt: 2 }} onClick={() => changePriority()}>
                Submit
              </Button>
            </Stack>
          </Scrollbar>
        </Box>
      </Modal>
      <Modal open={openAss} onClose={assCloses} style={{ borderRadius: 10 }}>
        <Box sx={[styleSkillset, { p: 1,width:'60%' }]}>
          <Typography variant="h5" color={'#3C6BA5'} textAlign={'center'}>
          Assign Professionals  
          </Typography>

          <Divider />
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin', p: 2 }}>
            <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Select Resources</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select Resources"
                placeholder="Select Resources"
                value={member}
                onChange={(e) => {setMember(e.target.value);
                 if(e.target.value === 'all'){
                  AllAsignData()
                 }else{
                  setAssignee([])
                 }
                  }}
              >       
              <MenuItem value={"all"}>All</MenuItem>                
              <MenuItem value={"individual"}>Individual</MenuItem>                
              <MenuItem value={"team"}>Team</MenuItem>
              </Select>
            </FormControl>
            {member === 'team'&&(
              <>
            <FormControl fullWidth sx={{mt:1}}>
              <InputLabel id="demo-simple-select-label">Select Role</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select Resources"
                placeholder="Select Resources"
                value={tempRole}
                onChange={(e) => {setTempRole(e.target.value);handleRole(e.target.value)}}
              >       
              <MenuItem value={"recruiterHead"}>RH</MenuItem>
              <MenuItem value={"recruiterManager"}>RM</MenuItem>                
              <MenuItem value={"recruiterTeamLead"}>RL</MenuItem>                
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mt: 1 }}>
        <InputLabel id="demo-multiple-checkbox-label">Users</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          // multiple    
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput label="Tag" />}
          // renderValue={(selected) => selected.join(', ')}
          // MenuProps={MenuProps}
        >                                 
          {roleUsers.map((name,index) => (
            <MenuItem key={name._id} value={index}>
              {/* <Checkbox checked={personName.indexOf(name.contactPersonName) > -1} value={name._id}  /> */}
              <ListItemText primary={name.contactPersonName} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {selectUser.length>0&&(
        <Grid container gridTemplateColumns={{ sm: 'repeat(8, 1fr)', xs: 'repeat(8, 1fr)' }} sx={{mt:1,gap:1}}>
         { selectUser.map((item,index)=>{
            return <Stack sx={{ flexDirection: 'row',border:1,borderRadius:1,p:0.5,borderColor:'text.secondary'}}>
            <Typography sx={{color:'text.secondary',textAlign:'center'}}>{item.contactPersonName}</Typography>
            <Icon icon="jam:close" color={'red'} fontSize={16} sx={{ cursor: 'pointer',mt:-1 }}
                onClick={() => handleRoleUser(index)}
                        />
            </Stack>
          })}
        </Grid>
      )}
      
       <Grid display={'grid'} gridTemplateColumns={{ sm: 'repeat(4, 1fr)', xs: 'repeat(4, 1fr)' }}>
            {underRole.map((item, index) => {
            return <Stack key={index} sx={{ flexDirection: 'row', alignItems: 'center' }}>
           {/* return<Grid display={'grid'} gridTemplateColumns={{ sm: 'repeat(2, 1fr)', xs: 'repeat(2, 1fr)' }} py={1} gap={2}> */}
              <FormControlLabel
                control={<Checkbox defaultChecked disabled />}
                value={item._id}
                // onChange={(e) => asignData(e)}
              />
              <Typography sx={{ml:-3}}>{item.contactPersonName} {" "}{item.USERID}</Typography>
              {/* <Typography sx={{ml:1}}>{item.USERID}</Typography> */}
            </Stack>
            })}
            </Grid>
      </>
            )}
            {member=== 'individual'&&(
            <Grid display={'grid'} gridTemplateColumns={{ sm: 'repeat(4, 1fr)', xs: 'repeat(4, 1fr)' }}>
            {allUsers.map((item, index) => {
            return <Stack key={index} sx={{ flexDirection: 'row', alignItems: 'center' }}>
           {/* return<Grid display={'grid'} gridTemplateColumns={{ sm: 'repeat(2, 1fr)', xs: 'repeat(2, 1fr)' }} py={1} gap={2}> */}
             <FormControlLabel
                control={<Checkbox  />}
                value={item._id}
                onChange={(e) => asignData(e)}
              />
              <Typography sx={{ml:-3}}>{item.contactPersonName} {" "}{item.USERID}</Typography>
              {/* <Typography sx={{ml:1}}>{item.USERID}</Typography> */}
            </Stack>
            })}
            </Grid>
            )}

        {member=== 'all'&&(
           <Grid display={'grid'} gridTemplateColumns={{ sm: 'repeat(4, 1fr)', xs: 'repeat(4, 1fr)' }}>
            {allUsers.map((item, index) => {
            return <Stack key={index} sx={{ flexDirection: 'row', alignItems: 'center' }}>
           {/* return<Grid display={'grid'} gridTemplateColumns={{ sm: 'repeat(2, 1fr)', xs: 'repeat(2, 1fr)' }} py={1} gap={2}> */}
             <FormControlLabel
                control={<Checkbox checked={allAssign} />}
                value={item._id}
                // onChange={(e) => asignData(e)}
              />
              <Typography sx={{ml:-3}}>{item.contactPersonName} {" "}{item.USERID}</Typography>
              {/* <Typography sx={{ml:1}}>{item.USERID}</Typography> */}
            </Stack>
            })}
            </Grid>
            )}
            {/* <Grid container sx={{ mt: 2 ,flexDirection:'column',justifyContent:'center',alignItems:'center'}}  gap={2}>
            
            
          </Grid> */}
            <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
              <Button color="custom" variant="contained" sx={{ width: '10%', mt: 2 }}
              onClick={()=> assignJob()}>
                Submit
              </Button>
            </Stack>
          </Scrollbar>
        </Box>
      </Modal>

      <Modal open={openJobM} onClose={closeJobM} style={{ borderRadius: 10 }}>
        <Box sx={[styleSkillset, { p: 1,width:'90%' }]}>
          <Stack sx={{ width: '100%', alignItems: 'flex-end'}}>
          <Icon icon="bx:edit"  fontSize={16} sx={{ cursor: 'pointer',mt:-1 }} onClick={()=>setEditAssign(true)}/>
          </Stack>
          <Typography variant="h5" color={'#3C6BA5'} sx={{ mt: -3}} textAlign={'center'}>
          {!editAssign?"Assigned":"Unassigned"} Professionals
          </Typography>
          <Divider />
          <Scrollbar sx={{ maxHeight: 400, Scrollbar: 'thin', p: 2 }}>
            {!editAssign?(
              <>
               <Grid display={'grid'} gap={1} gridTemplateColumns={{ sm: 'repeat(3, 1fr)', xs: 'repeat(3, 1fr)' }}>
               <Stack sx={{flexDirection:'row', backgroundColor: 'text.secondary', width: '100%', alignItems: 'center', p: 0.5,borderRadius: 1}}>
                 <Typography sx={{ width: '50%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                 User ID
                 </Typography>
                 <Typography sx={{  width: '50%',color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                   Name
                 </Typography>
                 </Stack>
                 <Stack sx={{flexDirection:'row', backgroundColor: 'text.secondary', width: '100%', alignItems: 'center', p: 0.5,borderRadius: 1}}>
                 <Typography sx={{  width: '50%',color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                 User ID
                 </Typography>
                 <Typography sx={{  width: '50%',color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                   Name
                 </Typography>
                 </Stack>
                 <Stack sx={{flexDirection:'row', backgroundColor: 'text.secondary', width: '100%', alignItems: 'center', p: 0.5,borderRadius: 1}}>
                 <Typography sx={{ width: '50%', color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                 User ID
                 </Typography>
                 <Typography sx={{  width: '50%',color: 'white', fontSize: '0.976rem', fontWeight: '600' }}>
                   Name
                 </Typography>
                  </Stack>
          </Grid>
           <Grid gap={1} display={'grid'} gridTemplateColumns={{ sm: 'repeat(3, 1fr)', xs: 'repeat(3, 1fr)' }}>
           {jobUserList.map((item, index) => {
           return  <Stack
             sx={[
               { background: index % 2 === 0 ? 'text.background' : tablebg },
               { width: '100%', flexDirection: 'row', justifyContent: 'space-between', p: 1 },
             ]}
         >
           <Typography sx={{width: '50%'  }}>
             {item.USERID}
           </Typography>
           <Typography sx={{ width: '50%', textTransform: 'capitalize',fontWeight:'600',color:'text.secondary' }}>
             {item.contactPersonName}
           </Typography>
           </Stack>
           })}
           </Grid>
           </>
            ):
            <>
            <Grid display={'grid'} gridTemplateColumns={{ sm: 'repeat(4, 1fr)', xs: 'repeat(4, 1fr)' }}>
            {jobUserList.map((item, index) => {
            return <Stack key={index} sx={{ flexDirection: 'row', alignItems: 'center' }}>
             <FormControlLabel
                control={<Checkbox />}
                value={item._id}
                onChange={(e) => unAsignData(e)}
              />
              <Typography sx={{ml:-3}}>{item.contactPersonName} {" "}{item.USERID}</Typography>
            </Stack>
            })}
            </Grid>
            <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
              <Button color="custom" variant="contained" sx={{ width: '10%', mt: 2 }}
              onClick={()=> unAssignJob()}>
                Unassign 
              </Button>
            </Stack>
            </>}
         
          </Scrollbar>
        </Box>
      </Modal>
    </>
  );
};

export default Jobs;
