import React, { useEffect, useRef, useState } from 'react';
import {
  Grid,
  Stack,
  Typography,
  Box,
  Button,
  Modal,
  Divider,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  Tab,
  Tabs,
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import moment from 'moment';
import Scrollbar from '../components/scrollbar/Scrollbar';
import Iconify from '../components/iconify';
import { Api, UploadFile } from '../webservices';

function ClientDetail() {
  const styleClients = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '60%', sm: '80%' },
    bgcolor: 'background.paper',
    border: '3px solid #3C6BA5',
    boxShadow: 24,
    p: 3,
    borderRadius: 3,
  };
  const navigate = useNavigate();
  const locationData = useLocation();
  const row = locationData.state?.row;

  useEffect(() => {
    getContract()
  }, []);

  const [currentTab, setCurrentTab] = useState('1');
  const suru = 'https://docs.google.com/viewer?url=';
  const lll = '&embedded=true';

  const [openN, setOpenN] = useState(false);
  const openNote = () => {
    setOpenN(true);
  };
  const closeNote = () => {
    setOpenN(false);
  };

  const [openS, setOpenS] = useState(false);
  const openScreening = () => {
    setOpenS(true);
  };
  const closeScreening = () => {
    setOpenS(false);
  };

  const [openC, setOpenC] = useState(false);
  const openCompensation = () => {
    setOpenC(true);
  };
  const closeCompensation = () => {
    setOpenC(false);
  };

  const [openR, setOpenR] = useState(false);
  const openResume = () => {
    setOpenR(true);
  };
  const closeResume = () => {
    setOpenR(false);
  };

  const [note, setNote] = useState('');
  const [firstEdit, setFirstEdit] = useState(false);
  const [edit1, setEdit1] = useState(true);

  const [tabs,setTabs] = useState('Agreement')
  const [upload, setUpload] = useState(false);
  const [ resumeArry, setResumeArry] = useState([]);
  const [contractUrl, setContractUrl] = useState(row.contractUrl);
  const [uploadLoader, setUploadLoader] = useState(false);
  const [docfile, setDocfile] = useState('');
  const [box1, setBox1] = useState({
    name: row.clientName ||'',
    currentStatus: row.status || '',
    source: row.clientSource || '',
    sPerson: row.salesPerson || '',
    started: row.startDate,
    ended:row.clientEnded,
    feeType: row.amountType || '',
    amount: row.amountValue || '',
    placements: row.placementCount || '',
    revenue: row.totalRevenue||'',
  });
  const editSource = () => {
    setEdit1(false);
    if (firstEdit === false) {
      setFirstEdit(true);
    } else if (firstEdit === true) {
      console.log('hgscgj');
      UpdateClient()
    }
  };
  const [fourEdit, setFourEdit] = useState(false);
  const [edit4, setEdit4] = useState(true);
  const editSource4 = () => {
    setEdit4(false);
    if (fourEdit === false) {
      setFourEdit(true);
    } else if (fourEdit === true) {
      console.log('hgscgj');
      UpdateClient()
    }
  };

  const [sEdit, setSEdit] = useState(false);
  const [edit2, setEdit2] = useState(true);

  const [box2, setBox2] = useState({
    cId: row.clientCompanyId || '',
    cName: row.companyName || '',
    year: row.yearfounded ||'',
    domain: row.domain || '',
    address: row.address || '',
    founder: row.founderName || '',
    contactName: row.mainContactName || '',
    pNumber: row.phoneNo || '',
    email: row.email || '',
    fax: row.fax ||'',
    web: row.websiteUrl ||'',
    empoyees: row.employeeNo || '',
    annualRev: row.annualRevenue ||   '',
    leads: '',
    pCompany:row.parentCompany ||'',
    sCompany: row.sisterCompany ||'',
    field1: row.field1||'',
    field2: row.field2||'',
    field3: row.field3 ||'',
  });

  const editSource2 = () => {
    setEdit2(false);
    if (sEdit === false) {
      setSEdit(true);
    } else if (sEdit === true) {
      console.log('hgscgj');
      UpdateClient()
    }
  };

  const [box3, setBox3] = useState({ cDes: row.companyDescription ||'' });
  const [thirdEdit, setThirdEdit] = useState(false);
  const [edit3, setEdit3] = useState(true);

  const editSource3 = () => {
    setEdit3(false);
    if (thirdEdit === false) {
      setThirdEdit(true);
    } else if (thirdEdit === true) {
      console.log('hgscgj');
      UpdateClient()
    }
  };

  const UpdateClient = () => {
    const token = localStorage.getItem('token');
    const body = {
      clientId: row._id,
      companyID: row.companyID,
      email: box2.email,
      startDate: box1.started,
      linkedinUrl: row.linkedinUrl,
      mainContactName: box2.contactName,
      websiteUrl: box2.web,
      domain: box2.domain,
      phoneNo: box2.pNumber,
      RPO_Staffing: row.RPO_Staffing,
      status: box1.currentStatus,
      amountType: box1.feeType,
      totalRevenue: box1.revenue,
      yearfounded: box2.year,
      founderName: box2.founder,
      fax: box2.fax,
      clientEnded:box1.ended,
      annualRevenue: box2.annualRev,
      parentCompany: box2.pCompany,
        sisterCompany: box2.sCompany,
        field1: box2.field1,
        field2: box2.field2,
        field3: box2.field3,
        companyDescription: box3.cDes,
    };
    Api('company/edit_converted_client', 'POST', body, token).then((Response) => {
      console.log(Response);
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          toast.success(Response.data.message);
          setFirstEdit(false);
          setSEdit(false)
          setThirdEdit(false);
          setFourEdit(false);
        } else {
          toast.warn(Response.data.error, +'!');
        }}
    });
  };
  const cardbg = '';

  const pdfUpload = async (file) => {
    setUploadLoader(true);
    setDocfile(file.name);
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('document', file);
    formData.append('directoryName', 'contract');
    UploadFile(`resume/upload_resume`, formData, token).then((Response) => {
      console.log('=====upload Response========>', Response.data);
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          setUploadLoader(false);
          toast.success(Response.data.status, +'!');
          // setFormValues({ ...formValues, contractUrl: Response.data.filePath });
        } else {
          console.log('=====404=gst===', Response.data.message);
          toast.warn(Response.data.status, +'!');
        }
      } else {
        toast.warn(Response.data.message, +'!');
      }
    });
  };

  const deleteContract =async(cId)=>{
    const token = localStorage.getItem('token');
    const body ={
      "clientId":row._id,
      "contractUrlId":cId
    }
     Api(`company/del_contract`, 'POST', body, token).then((Response) => {
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          getContract()
          // toast.success(Response.data.message);
          // setResumeArry(Response.data.data);
        }
      } else {
        toast.warn(Response.data.error, +'!');
      }
    });
  }
  const getContract =async()=>{
    const token = localStorage.getItem('token');
     Api(`company/contracts_by_candidate/${row._id}`, 'GET', '', token).then((Response) => {
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          // toast.success(Response.data.message);
          setResumeArry(Response.data.data);
        }
      } else {
        toast.warn(Response.data.error, +'!');
      }
    });
  }
  return (
    <>
     <ToastContainer />
     <Stack sx={{ flexDirection: 'row', p: 1, justifyContent: 'space-between' }}>
        <Stack sx={{ flexDirection: 'row' }}>
        <Iconify icon="ion:arrow-back" fontSize={25} sx={{ cursor: 'pointer', color: 'text.secondary',fontSize:'0.9rem',fontWeight:'600',mr:1 }} onClick={() => navigate(-1)} />
          <Typography
            color={'text.secondary'}
            style={{ textDecoration: 'underline', cursor: 'pointer',fontSize:'0.9rem',fontWeight:'600' }}
            onClick={() => navigate(-1)}
          >
            Client
          </Typography>
          <Iconify icon="ic:round-greater-than" sx={{ color: 'text.secondary',fontSize:'0.9rem',fontWeight:'600'}} />
          <Typography color={'text.textG'} sx={{ mx: 1,fontSize:'0.9rem',fontWeight:'600',textTransform:'capitalize' }}>
            {row.companyName}
          </Typography>
          <Iconify icon="ic:round-greater-than" sx={{ color: 'text.textG',fontSize:'0.9rem',fontWeight:'600',textTransform:'capitalize'}}  />
          <Typography color={'text.textG'} sx={{ mx: 1,fontSize:'0.9rem',fontWeight:'600',textTransform:'capitalize' }}>
            {row.status}
          </Typography>
        </Stack>
        {/* <Button variant="contained" size="small" color="custom" onClick={() => window.open(row.resumeUrl, '_blank')}>
          Download
        </Button> */}
      </Stack>
    <Stack sx={{ }}>
      {/* <Typography variant="h5" sx={{ color: 'text.secondary', textAlign: 'center' }}>
        Details
      </Typography> */}
  
            <Stack sx={{ flexDirection: 'row' }}>
              <Stack sx={{ p: 1, width: '50%' }}>
                <iframe title="resume" src={suru +contractUrl+ lll} width="100%" height="650px" />
              </Stack>
              <Stack sx={{ p: 1, width: '50%' }}>
              <Stack sx={{ flexDirection: 'row', gap: 2, mb: 2, cursor: 'pointer' }}>
            <Stack
              sx={[
                { flexDirection: 'row' },
                tabs === 'Agreement' ? { borderBottom: 2, color: 'text.secondary' } : { color: 'text.textG' },
              ]}
              onClick={() => {
                setTabs('Agreement');
              }}
            >
              <Iconify icon="bi:filetype-pdf" sx={{ mb: 0.5 }} fontSize={20} />
              <Typography  sx={{ ml: 0.5, mb: 0.5,fontSize:'0.9rem',fontWeight:'600' }}>
                Agreement
              </Typography>
            </Stack>
            <Stack
              sx={[
                { flexDirection: 'row' },
                tabs === 'Detail' ? { borderBottom: 2, color: 'text.secondary' } : { color: 'text.textG' },
              ]}
              onClick={() => {
                setTabs('Detail');
              }}
            >
              <Iconify icon="mingcute:user-4-fill" sx={{ mb: 0.5 }} fontSize={20} />
              <Typography  sx={{ ml: 0.5, mb: 0.5,fontSize:'0.9rem',fontWeight:'600' }} >
                Detail
              </Typography>
            </Stack>
            </Stack>
                 {tabs === 'Agreement' ? (
                  <Stack sx={{ backgroundColor: 'text.background', borderRadius: 2, boxShadow: 3, p: 2, gap: 2 }}>
                    <Stack sx={{ flexDirection: 'row', gap: 1 }}>
                      <Stack>
                        <Button
                          type="file"
                          variant="contained"
                          size="small"
                          color="custom"
                          sx={{ width: 'fit-content' }}
                          // onClick={(e) => updateNote()}
                        >
                          Add New Agreement
                        </Button>
                        <input
                          style={{ opacity: 0, position: 'absolute', width: 120 }}
                          type="file"
                          accept=".doc,.docx, application/pdf"
                          onChange={(e) => {
                            pdfUpload(e.target.files[0]);
                          }}
                        />
                      </Stack>
                      {upload && <Typography sx={{ color: 'text.secondary', p: 0.5 }}>Success</Typography>}
                    </Stack>
                    <Scrollbar sx={{ maxHeight: 300, Scrollbar: 'thin' }}>
                      {resumeArry.length ? resumeArry.map((item,index)=>{
                       return <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                      <Stack sx={{flexDirection:'row'}}> 
                      <Typography
                        color={'text.secondary'}
                        sx={{ textDecoration: 'underline',cursor: 'pointer'  }}
                        onClick={() => setContractUrl(item.contractUrl)}
                      >
                        Agreement #{index+1}
                      </Typography>
                      <Iconify
                        icon="mdi:open-in-new"
                        fontSize={20}
                        sx={{ cursor: 'pointer',ml:3 }}
                        onClick={() => window.open(suru+item.contractUrl+lll, '_blank')}
                        color={'text.secondary'}
                      />
                      </Stack>
                      <Typography sx={{}}>Uploaded on : {moment(item.createdAt).format('ll')}</Typography>
                      
                      <Stack sx={{flexDirection:'row'}}>
                      <Typography color={'text.secondary'} sx={{ cursor: 'pointer' }} onClick={()=>deleteContract(item._id)}>
                        Delete
                      </Typography>
                      <Typography
                        color={'text.secondary'}
                        sx={{ cursor: 'pointer',ml:3}}
                        onClick={() => window.open(item.contractUrl, '_blank')}
                      >
                        Download
                      </Typography>
                      </Stack>
                    </Stack>
                    }):null}
                    </Scrollbar>
                    <TextField
                  sx={{ mt: 2 }}
                  id="outlined-multiline-static"
                  label="Note... (Max 2000 char)"
                  placeholder="Note..."
                  multiline
                  rows={5}
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                />
                <Button
                  variant="contained"
                  size="small"
                  color="custom"
                  sx={{ width: 'fit-content', height: 30, mt: 2 }}
                  //   onClick={() => openScreening()}
                >
                  Save
                </Button>
                  </Stack>
                ) : null}
              {tabs === 'Detail' ? (
                 <Stack sx={{ backgroundColor: 'text.background', borderRadius: 2, boxShadow: 3 }}>
                 <Stack sx={{ width: '100%', alignItems: 'flex-end' }}>
                   {firstEdit === false ? (
                     <Iconify
                       icon="bx:edit"
                       sx={{ width: 25, height: 25, mr: 3, mt: 1, mb: 1 }}
                       onClick={() => editSource()}
                     />
                   ) : (
                     <Iconify
                       icon="carbon:save"
                       sx={{ color: 'text.secondary', width: 25, height: 25, mr: 3, mt: 1, mb: 1 }}
                       onClick={() => editSource()}
                     />
                   )}
       
                   {/* <Button sx={{ width: 100, mr: 3 }} variant="contained" onClick={() => editSource()}>
                     {edit1 === false ? 'edit' : 'save'}
                   </Button> */}
                 </Stack>
       
                 <Stack sx={{ flexDirection: 'row', px: 1 }}>
                   <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                     Name
                   </Typography>
                   {edit1 ? (
                     <Typography fontWeight={500} sx={{ p: 1 }}>
                       {box1.name}
                     </Typography>
                   ) : (
                     <TextField
                       disabled
                       size="small"
                       value={box1.name}
                       sx={{ py: 0.5, width: '60%', background: 'text.background' }}
                       onChange={(e) => setBox1({ ...box1, name: e.target.value })}
                       // label='Source'
                     />
                   )}
                 </Stack>
                 <Divider />
                 <Stack sx={{ flexDirection: 'row', px: 1, backgroundColor: cardbg }}>
                   <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                     Status
                   </Typography>
                   {edit1 ? (
                     <Typography fontWeight={500} sx={{ p: 1 }}>
                       {box1.currentStatus}
                     </Typography>
                   ) : (
                     <TextField
                       size="small"
                       disabled
                       sx={{ py: 0.5, width: '60%' }}
                       value={box1.currentStatus}
                       onChange={(e) => setBox1({ ...box1, currentStatus: e.target.value })}
                       // label='Source'
                     />
                   )}
                 </Stack>
                 <Divider />
                 <Stack sx={{ flexDirection: 'row', px: 1 }}>
                   <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                     Source
                   </Typography>
                   {edit1 ? (
                     <Typography fontWeight={500} sx={{ p: 1 }}>
                       {box1.source}
                     </Typography>
                   ) : (
                     <TextField
                       size="small"
                       disabled
                       value={box1.source}
                       sx={{ py: 0.5, width: '60%', background: 'text.background' }}
                       onChange={(e) => setBox1({ ...box1, source: e.target.value })}
                       // label='Source'
                     />
                   )}
                 </Stack>
                 <Divider />
                 <Stack sx={{ flexDirection: 'row', px: 1, backgroundColor: cardbg }}>
                   <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                     Sales Person
                   </Typography>
                   {edit1 ? (
                     <Typography fontWeight={500} sx={{ p: 1 }}>
                       {box1.sPerson}
                     </Typography>
                   ) : (
                     <TextField
                       size="small"
                       disabled
                       value={box1.sPerson}
                       sx={{ py: 0.5, width: '60%', background: 'text.background' }}
                       onChange={(e) => setBox1({ ...box1, sPerson: e.target.value })}
                       // label='Source'
                     />
                   )}
                 </Stack>
                 <Divider />
                 <Stack sx={{ flexDirection: 'row', px: 1 }}>
                   <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                     Client Started
                   </Typography>
                   {edit1 ? (
                     <Typography fontWeight={500} sx={{ p: 1 }}>
                       {moment(box1.started).format('ll')}
                     </Typography>
                   ) : (
                    //  <TextField
                    //    size="small"
                    //    disabled
                    //    value={moment(box1.started).format('ll')}
                    //    sx={{ py: 0.5, width: '60%', background: 'text.background' }}
                    //   //  onChange={(e) => setBox1({ ...box1, started: e.target.value })}
                    //    // label='Source'
                    //  />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker  
                    disablePast
                    sx={{ py: 0.5, width: '60%', background: 'text.background' }}
                      value={dayjs(box1.started)}
                      onChange={(newValue) => setBox1({ ...box1, started: newValue })}
                      // console.log("uncontrol date",newValue)}
                    />
                </LocalizationProvider>
                   )}
                 </Stack>
                 <Divider />
                 <Stack sx={{ flexDirection: 'row', px: 1, backgroundColor: cardbg }}>
                   <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                     Client Ended
                   </Typography>
                   {edit1 ? (
                     <Typography fontWeight={500} sx={{ p: 1 }}>
                       {moment(box1.ended).format('ll')}
                     </Typography>
                   ) : (
                   //   <TextField
                   //     size="small"
                   //     value={box1.ended}
                   //     sx={{ py: 0.5, width: '60%', background: 'text.background' }}
                   //     onChange={(e) => setBox1({ ...box1, ended: e.target.value })}
                   //     // label='Source'
                   //   />
               
                   <LocalizationProvider dateAdapter={AdapterDayjs}>
                     <DatePicker  
                     disablePast
                     sx={{ py: 0.5, width: '60%', background: 'text.background' }}
                       value={dayjs(box1.ended)}
                       onChange={(newValue) => setBox1({ ...box1, ended: newValue })}
                       // console.log("uncontrol date",newValue)}
                     />
                 </LocalizationProvider>
                   )}
                 </Stack>
                 <Divider />
                 <Stack sx={{ flexDirection: 'row', px: 1 }}>
                   <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                     Fee Type
                   </Typography>
                   {edit1 ? (
                     <Typography fontWeight={500} sx={{ p: 1 }}>
                       {box1.feeType}
                     </Typography>
                   ) : (
                     <TextField
                       size="small"
                       disabled
                       value={box1.feeType}
                       sx={{ py: 0.5, width: '60%', background: 'text.background' }}
                       onChange={(e) => setBox1({ ...box1, feeType: e.target.value })}
                       // label='Source'
                     />
                   )}
                 </Stack>
                 <Divider />
                 <Stack sx={{ flexDirection: 'row', px: 1, backgroundColor: cardbg }}>
                   <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                     Amount
                   </Typography>
                   {edit1 ? (
                     <Typography fontWeight={500} sx={{ p: 1 }}>
                       {box1.amount}
                     </Typography>
                   ) : (
                     <TextField
                       size="small"
                       disabled
                       sx={{ py: 0.5, width: '60%', background: 'text.background' }}
                       value={box1.amount}
                       onChange={(e) => setBox1({ ...box1, amount: e.target.value })}
                       // label='Source'
                     />
                   )}
                 </Stack>
                 <Divider />
                 <Stack sx={{ flexDirection: 'row', px: 1 }}>
                   <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                     Placement
                   </Typography>
                   {edit1 ? (
                     <Typography fontWeight={500} sx={{ p: 1 }}>
                       {box1.placements}
                     </Typography>
                   ) : (
                     <TextField
                       size="small"
                       disabled
                       sx={{ py: 0.5, width: '60%', background: 'text.background' }}
                       value={box1.placements}
                       onChange={(e) => setBox1({ ...box1, placements: e.target.value })}
                       // label='Source'
                     />
                   )}
                 </Stack>
                 <Divider />
                 <Stack sx={{ flexDirection: 'row', px: 1, backgroundColor: cardbg}}>
                   <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
                     Total Revenue
                   </Typography>
                   {edit1 ? (
                     <Typography fontWeight={500} sx={{ p: 1 }}>
                       {box1.revenue}
                     </Typography>
                   ) : (
                     <TextField
                       size="small"
                       sx={{ py: 0.5, width: '60%', background: 'text.background' }}
                       value={box1.revenue}
                       onChange={(e) => setBox1({ ...box1, revenue: e.target.value })}
                       // label='Source'
                     />
                   )}
                 </Stack>
                 <Divider />
                 <Stack sx={{ flexDirection: 'row', px: 1 }}>
            <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
              Client ID :
            </Typography>
            {edit1 ? (
              <Typography fontWeight={500} sx={{ p: 1 }}>
                {box2.cId}
              </Typography>
            ) : (
              <TextField
                size="small"
                disabled
                value={box2.cId}
                sx={{ py: 0.5, width: '60%', background: 'text.background' }}
                onChange={(e) => setBox2({ ...box2, cId: e.target.value })}
                // label='Source'
              />
            )}
          </Stack>
          <Divider />
          <Stack sx={{ flexDirection: 'row', px: 1, backgroundColor: cardbg }}>
            <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
              Client Name
            </Typography>
            {edit1 ? (
              <Typography fontWeight={500} sx={{ p: 1 }}>
                {box2.cName}
              </Typography>
            ) : (
              <TextField
              disabled
                size="small"
                sx={{ py: 0.5, width: '60%' }}
                value={box2.cName}
                onChange={(e) => setBox2({ ...box2, cName: e.target.value })}
                // label='Source'
              />
            )}
          </Stack>
          <Divider />
          <Stack sx={{ flexDirection: 'row', px: 1 }}>
            <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
              Year Founded
            </Typography>
            {edit1 ? (
              <Typography fontWeight={500} sx={{ p: 1 }}>
                {box2.year}
              </Typography>
            ) : (
              <TextField
                size="small"
                value={box2.year}
                sx={{ py: 0.5, width: '60%', background: 'text.background' }}
                onChange={(e) => setBox2({ ...box2, year: e.target.value })}
                // label='Source'
              />
            )}
          </Stack>
          <Divider />
          <Stack sx={{ flexDirection: 'row', px: 1, backgroundColor: cardbg }}>
            <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
              Domain / Industry
            </Typography>
            {edit1 ? (
              <Typography fontWeight={500} sx={{ p: 1 }}>
                {box2.domain}
              </Typography>
            ) : (
              <TextField
                size="small"
                disabled
                value={box2.domain}
                sx={{ py: 0.5, width: '60%', background: 'text.background' }}
                onChange={(e) => setBox2({ ...box2, domain: e.target.value })}
                // label='Source'
              />
            )}
          </Stack>
          <Divider />
          <Stack sx={{ flexDirection: 'row', px: 1,mb:2 }}>
            <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
              Website
            </Typography>
            {edit1 ? (
              <Typography fontWeight={500} sx={{ p: 1 }}>
                {box2.web}
              </Typography>
            ) : (
              <TextField
                size="small"
                disabled
                value={box2.web}
                sx={{ py: 0.5, width: '60%', background: 'text.background' }}
                onChange={(e) => setBox2({ ...box2, web: e.target.value })}
                // label='Source'
              />
            )}
          </Stack>
          
        
               </Stack>
              ):null}
              </Stack>
            </Stack>
            {tabs === 'Detail' ? (
              <>
      <Stack sx={{ flexDirection: 'row', width: '100%', gap: 1 }}>
        <Stack sx={{ backgroundColor: 'text.background', borderRadius: 2, boxShadow: 3, width: '49%' }}>
          <Stack sx={{ width: '100%', alignItems: 'flex-end' }}>
            {fourEdit === false ? (
              <Iconify
                icon="bx:edit"
                sx={{ width: 25, height: 25, mr: 3, mt: 1, mb: 1 }}
                onClick={() => editSource4()}
              />
            ) : (
              <Iconify
                icon="carbon:save"
                sx={{ color: 'text.secondary', width: 25, height: 25, mr: 3, mt: 1, mb: 1 }}
                onClick={() => editSource4()}
              />
            )}

            {/* <Button sx={{ width: 100, mr: 3 }} variant="contained" onClick={() => editSource()}>
              {edit1 === false ? 'edit' : 'save'}
            </Button> */}
          </Stack>
          <Stack sx={{ flexDirection: 'row', px: 1, backgroundColor: cardbg }}>
            <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
              Address
            </Typography>
            {edit4 ? (
              <Typography fontWeight={500} sx={{ p: 1 }}>
                {box2.address}
              </Typography>
            ) : (
              <TextField
                size="small"
                disabled
                value={box2.address}
                sx={{ py: 0.5, width: '60%', background: 'text.background' }}
                onChange={(e) => setBox2({ ...box2, address: e.target.value })}
                // label='Source'
              />
            )}
          </Stack>
          <Divider />
          <Stack sx={{ flexDirection: 'row', px: 1 }}>
            <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
              Founder / Owners
            </Typography>
            {edit4 ? (
              <Typography fontWeight={500} sx={{ p: 1 }}>
                {box2.founder}
              </Typography>
            ) : (
              <TextField
                size="small"
                value={box2.founder}
                sx={{ py: 0.5, width: '60%', background: 'text.background' }}
                onChange={(e) => setBox2({ ...box2, founder: e.target.value })}
                // label='Source'
              />
            )}
          </Stack>
          <Divider />
          <Stack sx={{ flexDirection: 'row', px: 1, backgroundColor: cardbg }}>
            <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
              Main Contact Name
            </Typography>
            {edit4 ? (
              <Typography fontWeight={500} sx={{ p: 1 }}>
                {box2.contactName}
              </Typography>
            ) : (
              <TextField
                size="small"
                disabled
                sx={{ py: 0.5, width: '60%', background: 'text.background' }}
                value={box2.contactName}
                onChange={(e) => setBox2({ ...box2, contactName: e.target.value })}
                // label='Source'
              />
            )}
          </Stack>
          <Divider />
          <Stack sx={{ flexDirection: 'row', px: 1 }}>
            <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
              Main Phone Contact
            </Typography>
            {edit4 ? (
              <Typography fontWeight={500} sx={{ p: 1 }}>
                {box2.pNumber}
              </Typography>
            ) : (
              <TextField
                size="small"
                disabled
                sx={{ py: 0.5, width: '60%', background: 'text.background' }}
                value={box2.pNumber}
                onChange={(e) => setBox2({ ...box2, pNumber: e.target.value })}
                // label='Source'
              />
            )}
          </Stack>
          <Divider />
          <Stack sx={{ flexDirection: 'row', px: 1, backgroundColor: cardbg }}>
            <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
              Email
            </Typography>
            {edit4 ? (
              <Typography fontWeight={500} sx={{ p: 1 }}>
                {box2.email}
              </Typography>
            ) : (
              <TextField
                size="small"
                disabled
                sx={{ py: 0.5, width: '60%', background: 'text.background' }}
                value={box2.email}
                onChange={(e) => setBox2({ ...box2, email: e.target.value })}
                // label='Source'
              />
            )}
          </Stack>
          <Divider />
          <Stack sx={{ flexDirection: 'row', px: 1, backgroundColor: cardbg }}>
            <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
              Fax
            </Typography>
            {edit4 ? (
              <Typography fontWeight={500} sx={{ p: 1 }}>
                {box2.fax}
              </Typography>
            ) : (
              <TextField
                size="small"
                sx={{ py: 0.5, width: '60%', background: 'text.background' }}
                value={box2.fax}
                onChange={(e) => setBox2({ ...box2, fax: e.target.value })}
                // label='Source'
              />
            )}
          </Stack>
          <Divider />
          <Stack sx={{ flexDirection: 'row', px: 1, backgroundColor: cardbg }}>
            <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
              No. of Employee
            </Typography>
            {edit4 ? (
              <Typography fontWeight={500} sx={{ p: 1 }}>
                {box2.empoyees}
              </Typography>
            ) : (
              <TextField
                size="small"
                disabled
                sx={{ py: 0.5, width: '60%', background: 'text.background' }}
                value={box2.empoyees}
                onChange={(e) => setBox2({ ...box2, empoyees: e.target.value })}
                // label='Source'
              />
            )}
          </Stack>
          <Divider />
        </Stack>

        <Stack sx={{ backgroundColor: 'text.background', borderRadius: 2, boxShadow: 3, width: '49%' }}>
          <Stack sx={{ width: '100%', alignItems: 'flex-end' }}>
            {sEdit === false ? (
              <Iconify
                icon="bx:edit"
                sx={{ width: 25, height: 25, mr: 3, mt: 1, mb: 1 }}
                onClick={() => editSource2()}
              />
            ) : (
              <Iconify
                icon="carbon:save"
                sx={{ color: 'text.secondary', width: 25, height: 25, mr: 3, mt: 1, mb: 1 }}
                onClick={() => editSource2()}
              />
            )}

            {/* <Button sx={{ width: 100, mr: 3 }} variant="contained" onClick={() => editSource()}>
              {edit1 === false ? 'edit' : 'save'}
            </Button> */}
          </Stack>

       
        
        
     
          <Stack sx={{ flexDirection: 'row', px: 1, backgroundColor: cardbg }}>
            <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
              Annual Revenue
            </Typography>
            {edit2 ? (
              <Typography fontWeight={500} sx={{ p: 1 }}>
                {box2.annualRev}
              </Typography>
            ) : (
              <TextField
                size="small"
                sx={{ py: 0.5, width: '60%', background: 'text.background' }}
                value={box2.annualRev}
                onChange={(e) => setBox2({ ...box2, annualRev: e.target.value })}
                // label='Source'
              />
            )}
          </Stack>
          <Divider />
          <Stack sx={{ flexDirection: 'row', px: 1, backgroundColor: cardbg }}>
            <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
              Leads
            </Typography>
            {edit2 ? (
              <Typography fontWeight={500} sx={{ p: 1 }}>
                {box2.leads}
              </Typography>
            ) : (
              <TextField
                size="small"
                sx={{ py: 0.5, width: '60%', background: 'text.background' }}
                value={box2.leads}
                onChange={(e) => setBox2({ ...box2, leads: e.target.value })}
                // label='Source'
              />
            )}
          </Stack>
          <Divider />
          <Stack sx={{ flexDirection: 'row', px: 1, backgroundColor: cardbg }}>
            <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
              Parent Companies
            </Typography>
            {edit2 ? (
              <Typography fontWeight={500} sx={{ p: 1 }}>
                {box2.pCompany}
              </Typography>
            ) : (
              <TextField
                size="small"
                sx={{ py: 0.5, width: '60%', background: 'text.background' }}
                value={box2.pCompany}
                onChange={(e) => setBox2({ ...box2, pCompany: e.target.value })}
                // label='Source'
              />
            )}
          </Stack>
          <Divider />
          <Stack sx={{ flexDirection: 'row', px: 1, backgroundColor: cardbg }}>
            <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
              Sister Companies
            </Typography>
            {edit2 ? (
              <Typography fontWeight={500} sx={{ p: 1 }}>
                {box2.sCompany}
              </Typography>
            ) : (
              <TextField
                size="small"
                sx={{ py: 0.5, width: '60%', background: 'text.background' }}
                value={box2.sCompany}
                onChange={(e) => setBox2({ ...box2, sCompany: e.target.value })}
                // label='Source'
              />
            )}
          </Stack>
          <Divider />
          <Stack sx={{ flexDirection: 'row', px: 1, backgroundColor: cardbg }}>
            <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
              Field 1
            </Typography>
            {edit2 ? (
              <Typography fontWeight={500} sx={{ p: 1 }}>
                {box2.field1}
              </Typography>
            ) : (
              <TextField
                size="small"
                sx={{ py: 0.5, width: '60%', background: 'text.background' }}
                value={box2.field1}
                onChange={(e) => setBox2({ ...box2, field1: e.target.value })}
                // label='Source'
              />
            )}
          </Stack>
          <Divider />
          <Stack sx={{ flexDirection: 'row', px: 1, backgroundColor: cardbg }}>
            <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
              Field 2
            </Typography>
            {edit2 ? (
              <Typography fontWeight={500} sx={{ p: 1 }}>
                {box2.field2}
              </Typography>
            ) : (
              <TextField
                size="small"
                sx={{ py: 0.5, width: '60%', background: 'text.background' }}
                value={box2.field2}
                onChange={(e) => setBox2({ ...box2, field2: e.target.value })}
                // label='Source'
              />
            )}
          </Stack>
          <Divider />
          <Stack sx={{ flexDirection: 'row', px: 1, backgroundColor: cardbg }}>
            <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
              Field 3
            </Typography>
            {edit2 ? (
              <Typography fontWeight={500} sx={{ p: 1 }}>
                {box2.field3}
              </Typography>
            ) : (
              <TextField
                size="small"
                sx={{ py: 0.5, width: '60%', background: 'text.background' }}
                value={box2.field3}
                onChange={(e) => setBox2({ ...box2, field3: e.target.value })}
                // label='Source'
              />
            )}
          </Stack>
          <Divider />
        </Stack>
      </Stack>
      <Stack sx={{ backgroundColor: 'text.background', borderRadius: 2, boxShadow: 3, width: '99%', mt: 2 }}>
        <Stack sx={{ width: '100%', alignItems: 'flex-end' }}>
          {thirdEdit === false ? (
            <Iconify icon="bx:edit" sx={{ width: 25, height: 25, mr: 3, mt: 1, mb: 1 }} onClick={() => editSource3()} />
          ) : (
            <Iconify
              icon="carbon:save"
              sx={{ color: 'text.secondary', width: 25, height: 25, mr: 3, mt: 1, mb: 1 }}
              onClick={() => editSource3()}
            />
          )}
        </Stack>
        <Stack sx={{ flexDirection: 'row', p: 1, py: 2 }}>
          <Typography fontWeight={500} sx={{ p: 1, width: 200, color: 'text.secondary' }}>
            Company Description
          </Typography>
          {edit3 ? (
            <Typography fontWeight={500} sx={{ p: 1 }}>
              {box3.cDes}
            </Typography>
          ) : (
            <TextField
              size="small"
              multiline
              value={box3.cDes}
              sx={{ py: 0.5, background: 'text.background', width: '100%' }}
              onChange={(e) => setBox3({ ...box3, cDes: e.target.value })}
              // label='Source'
            />
          )}
        </Stack>
      </Stack>
</>
            ):null}
    </Stack>
    </> );
}

export default ClientDetail;
