import { createSlice } from '@reduxjs/toolkit'

const userSlice = createSlice({
  name: 'userData',
  initialState: {
    data: {}
  },
  reducers: {
    userdetail: (state, action) => {
      state.data = action.payload
      console.log("redux data=====", state.data);
    }
  },
})

// Action creators are generated for each case reducer function
export default userSlice.reducer
export const { userdetail } = userSlice.actions
