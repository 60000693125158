// ----------------------------------------------------------------------

export default function Table(theme) {
  return {
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: theme.palette.text.secondary,
          
          // backgroundColor: '#ff7fac14',
          backgroundColor: theme.palette.text.hover,
        },
        body: {
          color: theme.palette.text.main,
          whiteSpace: 'nowrap',
          textTransform: 'capitalize',
          padding: '8px 5px',
          // backgroundColor: theme.palette.background.neutral,
        },
      },
    },
  };
}
