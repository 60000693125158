import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Typography,
  Table,
  Button,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Modal,
  Box,
  TextField,
  Divider,
  Grid,
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar/Scrollbar';
import { Api } from '../webservices';

function SalesManagement() {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const tableLabel = [
    // { id: 1, label: 'Company Name' },
    { id: 2, label: 'User Id' },
    { id: 3, label: 'Role' },
    { id: 4, label: 'Name' },
    { id: 5, label: 'Phone' },
    { id: 6, label: 'Email' },
    { id: 7, label: 'Password' },
    { id: 8, label: 'Action' },
  ];
  // alert(location.state)
  const [editTrue, setEditTrue] = React.useState(true);
  const [tableData, setTableData] = useState([]);
  const [formValuesAddComapnny, setFormValuesAddCompany] = useState({
    cName: '',
    role: 'sales',
    email: '',
    domain: '',
    ContactPersonName: '',
    contactNumber: '',
    password: '',
    address: '',
    linkedin:""
  });
  // modal 0
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // modal 1
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [id, setId] = useState('');
  const role = localStorage.getItem('role');
  const [temprole, setTemprole] = useState('');
  // const [role, setRole] = useState('')

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }


  useEffect(() => {
    console.log('dependency', location.state);
    const userId = localStorage.getItem('userid');
    console.log('userId------useeffect',userId)
    if (location.state) {
      setId(location.state.id);
      // setRole(location.state.rolee);
      getCompanyDetail(location.state.id);
      console.log('temp role location', location.state.id);
    } else {
      // alert("state")
      // setRole(localStorage.getItem('role'));
      getCompanyDetail(userId);
      setId(userId);
    }
  }, [location.state]);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { md: '50%', xs: '95%' },
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 3,
  };

  const getCompanyDetail = async (idd) => {
    const token = localStorage.getItem('token');
    Api(`company/get_users_list/${idd}`, 'GET', '', token)
      .then((response) => {
        console.log('=========getCompany======>>>>>>>', response.data.message);
        console.log('=========getCompany======>>>>>>>', response);
        if (response.status === 200) {
          if (response.data.data.length !== 0) {
            // toast.success(response.data.message, +'!');
            setTableData(response.data.data);
          } else {
            // toast.warn('Data Not Found!');
          }
        } else {
          // toast.warn(response.data.message, +'!');
        }
      })
      .catch((err) => {
        console.log('===========', err);
        toast.error(err, +'!');
      });
  };
  const addAgent = async (e) => {
    // alert(id)
    e.preventDefault();
    const token = await localStorage.getItem('token');
    // let token = localStorage.getItem('token');
    const body = {
      email: formValuesAddComapnny.email,
      role: formValuesAddComapnny.role,
      password: formValuesAddComapnny.password,
      contactPersonName: formValuesAddComapnny.ContactPersonName,
      contactNo: formValuesAddComapnny.contactNumber,
      // companyId: id || '',
      parentId: id,
    };
    Api(`company/add_user`, 'POST', body, token)
      .then((response) => {
        console.log('===============>>>>>>>', response);
        if (response.status === 200) {
          toast.success(response.data.message, +'!');
          setOpen(false);
          setFormValuesAddCompany({
            cName: '',
            role: '',
            email: '',
            domain: '',
            ContactPersonName: '',
            contactNumber: '',
            password: '',
            address: '',
          });
          getCompanyDetail(id);
          // setTableData(response.data.data)
        } else {
          toast.warn(response.data.message, +'!');
        }
      })
      .catch((err) => {
        console.log('===========', err);
      });
  };
  const funclick = () => {
    getCompanyDetail();
  };
  return (
    <>
      <ToastContainer />
      <Stack flexDirection={'row'} alignItems={'flex-end'} justifyContent={'space-between'} m={1}>
      <Stack flexDirection={'row'} gap={1} alignItems={'center'}  color={'text.secondary'}>
          <Iconify icon="ion:arrow-back" fontSize={25} sx={{ cursor: 'pointer'}} onClick={()=>navigate(-1)} />
           <Typography variant="h5"  color={'text.secondary'}>
          User Management
        </Typography>
        </Stack>
        <Stack flexDirection={'row'} gap={1}>
        {role === 'admin'&&(
        <Button color="custom" variant="contained" size="small" onClick={handleOpen}>
            Add
          </Button>
        )}
        </Stack>
      </Stack>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{backgroundColor:'text.secondary'}}>
            {tableLabel.map((item) => {
                return <TableCell sx={{p:1,py:0.5,color:'white'}} key={item.id}>{item.label}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row, index) => (
              <ManagementRow key={row._id} data={row} id={id} fun={funclick} rolee={role} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Scrollbar sx={{ maxHeight: { sm: 500, xs: 400 } }}>
            <Grid display={'grid'} gridTemplateColumns={{ sm: 'repeat(2, 1fr)', xs: 'repeat(1, 1fr)' }} py={1} gap={2}>
              <TextField
                id="outlined-basic"
                label="Contact Person Name"
                placeholder="Contact Person Name"
                variant="outlined"
                value={formValuesAddComapnny.ContactPersonName}
                onChange={(e) =>
                  setFormValuesAddCompany({
                    ...formValuesAddComapnny,
                    ContactPersonName: e.target.value,
                  })
                }
              />
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Role</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Role"
                  placeholder="Role"
                  onChange={(e) =>
                    setFormValuesAddCompany({
                      ...formValuesAddComapnny,
                      role: e.target.value,
                    })
                  }
                >
                  <MenuItem value="salesExecutive">Sales Executive</MenuItem>
                  <MenuItem value="salesTrainee">Sales Trainee</MenuItem>
                </Select>
              </FormControl>

              <TextField
                id="outlined-basic"
                label="Email Id"
                placeholder="Email Id"
                variant="outlined"
                value={formValuesAddComapnny.email}
                onChange={(e) =>
                  setFormValuesAddCompany({
                    ...formValuesAddComapnny,
                    email: e.target.value,
                  })
                }
              />
              <TextField
                id="outlined-basic"
                label="Contact Number "
                placeholder="Contact Number"
                variant="outlined"
                value={formValuesAddComapnny.contactNumber}
                inputProps={{ type: 'numeric', pattern: '[0-9]*', maxLength: 10, minLength: 10 }}
                onChange={(e) =>
                  setFormValuesAddCompany({
                    ...formValuesAddComapnny,
                    contactNumber: e.target.value,
                  })
                }
              />
              {/* <TextField
                id="outlined-basic"
                label="LinkedIn Url"
                placeholder="LinkedIn Url"
                variant="outlined"
                value={formValuesAddComapnny.linkedin}
                inputProps={{ type: 'numeric', pattern: '[0-9]*', maxLength: 10, minLength: 10 }}
                onChange={(e) =>
                  setFormValuesAddCompany({
                    ...formValuesAddComapnny,
                    linkedin: e.target.value,
                  })
                }
              /> */}
              <Stack className="passwordIcon">
                <TextField
                  id="outlined-basic"
                  label="Password"
                  placeholder="Password"
                  type={showPassword ? 'text' : 'password'}
                  variant="outlined"
                  value={formValuesAddComapnny.password}
                  onChange={(e) =>
                    setFormValuesAddCompany({
                      ...formValuesAddComapnny,
                      password: e.target.value,
                    })
                  }
                />
                <Iconify
                  icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                  onClick={() => setShowPassword(!showPassword)}
                />
              </Stack>
            </Grid>
          </Scrollbar>
          <Stack flexDirection={'row'} gap={1} mt={2}>
            <Button color="custom" variant="contained" onClick={(e) => addAgent(e)}>
              Add
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
}

function ManagementRow({ data, id, fun, rolee }) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { md: '50%', xs: '95%' },
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 3,
    border: '3px solid #3C6BA5',
    borderRadius: 3,
  };
  const [formValuesAddComapnny, setFormValuesAddCompany] = useState({
    cName: '',
    role: '',
    email: data.email,
    domain: '',
    ContactPersonName: data.contactPersonName,
    contactNumber: data.contactNo,
    password: data.password,
    address: '',
  });
  const [edit, setEdit] = useState(false);
  const [status, setStatus] = useState('active');
  const navigate = useNavigate();
  const role = localStorage.getItem('role')
  const [accesss,setAccesss]= useState(false)
  const  closeaccess =()=>{setAccesss(false)}
  const  openaccess =()=>{setAccesss(true)}
  const [access,setAccess] = useState({
    rworksheet: '',rdatabase:"", client: '', lead: '', jobs: '', placement: '' 
  })
  useEffect(() => {
    setFormValuesAddCompany({
      ...formValuesAddComapnny,
      ContactPersonName: data.contactPersonName,
      contactNumber: data.contactNo,
    });
  }, []);

  async function editFun(data) {
    const token = await localStorage.getItem('token');
    console.log('=====id=====>>>', id);
    if (edit === false) {
      setEdit(true);
    } else if (edit === true) {
      const body = {
        parentId: data.parentId,
        userId: data._id,
        email: formValuesAddComapnny.email ? formValuesAddComapnny.email : data.email,
        // role: formValuesAddComapnny.role ? formValuesAddComapnny.role : data.role,
        password: formValuesAddComapnny.password ? formValuesAddComapnny.password : data.password,
        contactPersonName: formValuesAddComapnny.ContactPersonName
          ? formValuesAddComapnny.ContactPersonName
          : data.contactPersonName,
        contactNo: formValuesAddComapnny.contactNumber ? formValuesAddComapnny.contactNumber : data.contactNo,
      };
      Api(`company/edit_user`, 'POST', body, token)
        .then((Response) => {
          console.log('======getUser==response=====>', Response);
          if (Response.status === 200) {
            if (Response.data.code === 200) {
              setEdit(false);
              toast.success(Response.data.message);
              fun();
              console.log('======getUser===data.data pdata====>', Response);
            } else {
              console.log('======getUser=======>', +Response);
              toast.warn(Response.data.message);
              // enqueueSnackbar(Response.data.message);
            }
          }
        })
        .catch((err) => {
          toast.error(err, +'!');
          console.log('===========', err);
        });
    }
  }

  async function statuss(data) {
    const token = await localStorage.getItem('token');
    let sts = '';
    if (data.status === 'active') {
      sts = 'blocked';
    } else {
      sts = 'active';
    }
    const body = {
      companyId: id,
      userId: data.userId,
      status: sts,
    };
    Api(`company/end_user_status`, 'POST', body, token).then((Response) => {
      console.log('======getUser==response=====>', Response);
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          toast.success(Response.data.message);
          fun();
          // enqueueSnackbar('Scheme update Successfull !');
          console.log('======getUser===data.data pdata====>', Response.data.data);
        } else {
          console.log('======getUser=======>', +Response);
          toast.warn(Response.data.message);
          // enqueueSnackbar(Response.data.message);
        }
      }
    });
  }
  const goto=(data)=>{
    if(data.role === 'recruiterHead'){
      navigate('/recuiterManager', { state: { id: data._id }}) 
    }else if(data.role === 'salesHead'){
      navigate('/salesManager', { state: { id: data._id }}) 
    }else if(data.role === 'recruiterManager'){
      navigate('/recruiterTeamLead', { state: { id: data._id }}) 
    }else if(data.role === 'salesManager'){
      navigate('/salesTeamLead', { state: { id: data._id }}) 
    }else if(data.role === 'recruiterTeamLead'){
      navigate('/recruiterManagement', { state: { id: data._id }})
    }else if(data.role === 'salesTeamLead'){
      navigate('/salesManagement', { state: { id: data._id }})
    }else if(data.role === 'recruiter'){
      navigate('/recruitertrainee', { state: { id: data._id }})
    }else if(data.role === 'salesExecutive'){
      navigate('/salestrainee', { state: { id: data._id }})
    }

  }
  const getRights = async (data) => {
    const token = await localStorage.getItem('token');
    Api(`company/getrights/${data._id}`, 'GET',"", token).then((Response) => {
      console.log('======getUser=getrights=response=====>', Response);
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          setAccess({
            rworksheet: Response.data.data.worksheet,
            rdatabase: Response.data.data.resumes,
            client: Response.data.data.clients,
            lead: Response.data.data.leads,
            jobs: Response.data.data.jobs,
            placement: Response.data.data.placements,
          });
          console.log('======getUser===data.data pdata====>', Response.data.data);
        } else {
          console.log('======getUser=======>', +Response);
        }
      }
    }); 
  }
  const Rights = async (data) => {
    const token = await localStorage.getItem('token');
    const body ={
      "userId": data._id,
      "worksheet": access.rworksheet,
      "clients": access.client,
      "jobs": access.jobs,
      "resumes": access.rdatabase,
      "leads": access.lead,
      "placements": access.placement
    }
    Api(`company/access_rights`, 'POST',body, token).then((Response) => {
      console.log('======getUser=getrights=response=====>', Response);
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          toast.success(Response.data.message, +'!');
          closeaccess()
          setAccess({
            rworksheet: '',
            rdatabase: '',
            client: '',
            lead: '',
            jobs: '',
            placement: '',
          });
          console.log('======getUser===data.data pdata====>', Response.data.data);
        } else {
          toast.error(Response.data.message, +'!');
          console.log('======getUser=======>', +Response);
        }
      }
    }); 
  }
  return (
    <>
    <TableRow key={data._id}>
       {/* <TableCell sx={{ pl: 1 }}>
        <Typography>{data.companyName}</Typography>
        </TableCell> */}
      <TableCell sx={{ pl: 1 }}>
        <Stack sx={{flexDirection:'row'}}>
        <Typography sx={{ maxWidth: 90, overflow: 'hidden', textOverflow: 'ellipsis' }}>{data.USERID}</Typography>
        <CopyToClipboard text={data.USERID}>
                <Iconify
                  icon="radix-icons:copy"
                  color={'text.textG'}
                  sx={{ height: 17, width: 17, cursor: 'pointer',ml:1}}
                />
              </CopyToClipboard>
              </Stack>
        </TableCell>
      <TableCell sx={{ pl: 2 }}>{data.role.replace(/([A-Z])/g, ' $&')}</TableCell>
      <TableCell sx={{ pl: 2 }}>
        {edit === true ? (
          <TextField
            id="outlined-basic"
            label="Name"
            placeholder="Name"
            variant="outlined"
            value={formValuesAddComapnny.ContactPersonName}
            onChange={(e) =>
              setFormValuesAddCompany({
                ...formValuesAddComapnny,
                ContactPersonName: e.target.value,
              })
            }
          />
        ) : (
          <span>{formValuesAddComapnny.ContactPersonName}</span>
        )}
      </TableCell>
      <TableCell sx={{ pl: 2 }}>
        {edit === true ? (
          <TextField
            id="outlined-basic"
            label="Phone"
            placeholder="Phone"
            variant="outlined"
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 10, minLength: 10 }}
            value={formValuesAddComapnny.contactNumber}
            onChange={(e) =>
              setFormValuesAddCompany({
                ...formValuesAddComapnny,
                contactNumber: e.target.value,
              })
            }
          />
        ) : (
          <span>{formValuesAddComapnny.contactNumber}</span>
        )}
      </TableCell>
      <TableCell sx={{ pl: 2 }}>
        {edit === true ? (
          <TextField
            id="outlined-basic"
            label="Email"
            placeholder="Email"
            variant="outlined"
            value={formValuesAddComapnny.email}
            // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 10, minLength: 10 }}
            onChange={(e) =>
              setFormValuesAddCompany({
                ...formValuesAddComapnny,
                email: e.target.value,
              })
            }
          />
        ) : (
          <Typography sx={{textTransform:'lowercase'}}>{data.email}</Typography>
        )}
      </TableCell>
      <TableCell sx={{ pl: 2 }}>
        {edit === true ? (
          <TextField
            id="outlined-basic"
            label="Password"
            placeholder="Password"
            variant="outlined"
            value={formValuesAddComapnny.password}
            // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 10, minLength: 10 }}
            onChange={(e) =>
              setFormValuesAddCompany({
                ...formValuesAddComapnny,
                password: e.target.value,
              })
            }
          />
        ) : (
          <Typography sx={{maxWidth: 90, overflow: 'hidden', textOverflow: 'ellipsis'}}>{data.password}</Typography>
        )}
      </TableCell>
      <TableCell sx={{ pl: 1 }}>
        <Stack flexDirection={'row'} gap={1}>
        {role === 'admin' &&(
           <Button color="custom" type="submit" variant="contained" size="small" onClick={() => {getRights(data); openaccess()}}>
            access
          </Button>
          )}
          <Button color="custom" type="submit" variant="contained" size="small" onClick={() => editFun(data)}>
            {edit === false ? 'edit' : 'save'}
          </Button>
          <Button color="custom" type="submit" variant="contained" size="small" onClick={() => statuss(data)}>
            {data.status}
          </Button>
          {data.role!== "salesTrainee" && data.role!== "recruiterTrainee"?(
            <Iconify
              icon="maki:arrow"
              sx={{ color: 'text.secondary', width: 30, height: 30 }}
              style={{ cursor: 'pointer' }}
              onClick={() => goto(data) } 
            />
        ):null}
        </Stack>
      </TableCell>
    </TableRow>
    <Modal
    open={accesss}
    onClose={closeaccess}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={style}>
      <Scrollbar sx={{ maxHeight: 400 }}>
        <Typography id="modal-modal-description" variant="h6" sx={{ textAlign: 'center', color: 'text.secondary' }}>
          Access Management
        </Typography>
        <Divider />
        <Stack gap={2} sx={{ mt: 2 ,flexDirection:'row'}}>
          <Stack sx={{width:'100%',gap:2}}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Recruiter Worksheet</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={access.rworksheet}
              label="Recruiter Worksheet"
              onChange={(e) => setAccess({ ...access, rworksheet: e.target.value })}
            >
              <MenuItem value={'noaccess'}>No Access</MenuItem>
              <MenuItem value={'limited'}>Limited Access</MenuItem>
              <MenuItem value={'fullaccess'}>Full Access</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Client</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={access.client}
              label="Client"
              onChange={(e) => setAccess({ ...access, client: e.target.value })}
            >
              <MenuItem value={'noaccess'}>No Access</MenuItem>
              <MenuItem value={'limited'}>Limited Access</MenuItem>
              <MenuItem value={'fullaccess'}>Full Access</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Jobs</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={access.jobs}
              label="Jobs"
              onChange={(e) => setAccess({ ...access, jobs: e.target.value })}
            >
              <MenuItem value={'noaccess'}>No Access</MenuItem>
              <MenuItem value={'limited'}>Limited Access</MenuItem>
              <MenuItem value={'fullaccess'}>Full Access</MenuItem>
            </Select>
          </FormControl>
          
          </Stack>
          <Stack sx={{width:'100%',gap:2}}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Resume Database</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={access.rdatabase}
              label="Lead"
              onChange={(e) => setAccess({ ...access, rdatabase: e.target.value })}
            >
              <MenuItem value={'noaccess'}>No Access</MenuItem>
              <MenuItem value={'limited'}>Limited Access</MenuItem>
              <MenuItem value={'fullaccess'}>Full Access</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Lead</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={access.lead}
              label="Lead"
              onChange={(e) => setAccess({ ...access, lead: e.target.value })}
            >
              <MenuItem value={'noaccess'}>No Access</MenuItem>
              <MenuItem value={'limited'}>Limited Access</MenuItem>
              <MenuItem value={'fullaccess'}>Full Access</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Placement</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={access.placement}
              label="Placement"
              onChange={(e) => setAccess({ ...access, placement: e.target.value })}
            >
              <MenuItem value={'noaccess'}>No Access</MenuItem>
              <MenuItem value={'limited'}>Limited Access</MenuItem>
              <MenuItem value={'fullaccess'}>Full Access</MenuItem>
            </Select>
          </FormControl>
          </Stack>
        </Stack>
      </Scrollbar>
      <Stack sx={{ alignItems: 'center' }} gap={1} mt={2}>
        <Button color="custom" variant="contained" sx={{ width: 100 }} onClick={()=>Rights(data)} >
          save
        </Button>
      </Stack>
    </Box>
  </Modal>
  </>
  );
}
export default SalesManagement;
