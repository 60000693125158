import React, { useEffect, useState } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import Iconify from '../iconify/Iconify';

function CustomComplete({ placeholder, propVal, option, childFuc, fetch, fullVal,helperText,err,req}) {
  const [selectedVal, setSelectedVal] = useState('');
  const [dropdown, setdropdown] = useState(false);
  const [localOption, setLocalOption] = useState([]);

  useEffect(() => {
    if (localOption.length > 0) setdropdown(true);
    else setdropdown(false);
    setSelectedVal(propVal);
  }, []);

  useEffect(() => {
    setLocalOption(option);
  }, [option]);

  useEffect(() => {
    console.log('propval', propVal);

    setSelectedVal(propVal);
  }, [propVal]);

  const fetchValue = (e) => {
    setdropdown(true);
    setSelectedVal(e.target.value);
    childFuc(e.target.value);
    if (e.target.value.length === 0) {
      // setSelectedVal('')
      setdropdown(false);
    }
  };

  const setValue = (e) => {
    if (fetch === 'state') {
      const csc = `${e.item.city} ${e.item.state}`
      setdropdown(false);
      // setSelectedVal(e.item.city);
      setSelectedVal(csc);
      childFuc(e.item.city);
      fullVal(e.item);
    } else {
      setdropdown(false);
      childFuc(e.item);
      fullVal(e.item);
      setSelectedVal(e.item);
    }
  };

  return (
    <>
      <div style={{ position: 'relative', maxHeight: 300, width: '100%' }}>
        <TextField
          required={req}
          error={err}
          helperText={helperText}
          id="outlined-multiline-static"
          label={placeholder || ''}
          rows={4}
          autoComplete='off'
          onChange={(e) => fetchValue(e)}
          sx={{ width: '100%' }}
          InputLabelProps={{ shrink: true }}
          value={selectedVal}
        />
        {/* {selectedVal !== '' && (
          <Iconify
            icon="iconamoon:close-light"
            sx={{ position: 'absolute', top: 10, right: 10, height: 30, width: 30, zIndex: 999, cursor: 'pointer' }}
            onClick={() => setSelectedVal('')}
          />
        )} */}
      </div>

      {dropdown && (
        <div style={{ height: 'fit-content', maxHeight: 100, overflowY: 'scroll', cursor: 'pointer' }}>
          {localOption.map((item) => {
            return (
              <Typography
                key={fetch === 'state' ? item.zip : item}
                sx={{
                  '& > img': {
                    mx: 2,
                    my: 1,
                    position: 'absolute',
                    bottom: 0,
                    zIndex: 999,
                    background: 'white',
                  },
                }}
                onClick={() => setValue({ item })}
              >
                {fetch === 'state' ? `${item.city} (${item.state}) (${item.zip})` : item}
              </Typography>
            );
          })}
        </div>
      )}
    </>
  );
}

export default CustomComplete;
