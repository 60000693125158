import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Typography,
  Table,
  Button,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Modal,
  Box,
  TextField,
  Divider,
  Grid,
} from '@mui/material';
import moment from 'moment';
import Iconify from '../components/iconify';
import { Api, UploadFile } from '../webservices';

const tablebg = 'rgba(82, 130, 190, 0.08)';
function History() {
  const navigate = useNavigate();
  const location = useLocation();
  const hisId = location.state.Id;
  const name = location.state.name;
  const [hisData, setHisData] = React.useState([]);
  useEffect(() => {
    getHis();
  }, []);
  const getHis = () => {
    const token = localStorage.getItem('token');
    Api(`resume/get_history/${hisId}`, 'GET', '', token)
      .then((response) => {
        console.log('==========get_history=====>>>', response);
        if (response.status === 200) {
          if (response.data.code === 200) {
            setHisData(response.data.data);
            // setJoblst(response.data.data);
          }
        }
      })
      .catch((err) => {});
  };
  return (
    <>
      <Stack flexDirection={'row'} alignItems={'flex-end'} justifyContent={'space-between'} m={1}>
        <Stack flexDirection={'row'} gap={1} alignItems={'center'}  color={'text.secondary'}>
          <Iconify icon="ion:arrow-back" fontSize={25} sx={{ cursor: 'pointer'}} onClick={()=>navigate(-1)} />
          <Typography variant="h5" >
            History of {name}
          </Typography>
          {/* <Button color="custom" variant="contained" size="small" onClick={handleOpen}>
            Add Company
          </Button> */}
        </Stack>
      </Stack>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ backgroundColor: 'text.secondary' }}>
              <TableCell sx={{  p: 1, color: 'white' }}>Date</TableCell>
              <TableCell sx={{  p: 1, color: 'white' }}>User Name</TableCell>
              <TableCell sx={{  p: 1, color: 'white' }}>Job ID</TableCell>
              <TableCell sx={{  p: 1, color: 'white' }}>Client</TableCell>
              <TableCell sx={{  p: 1, color: 'white' }}>Job Title</TableCell>
              <TableCell sx={{  p: 1, color: 'white' }}>Status</TableCell>
              <TableCell sx={{  p: 1, color: 'white' }}>Note</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {hisData.map((item, index) => {
              return <HistoryRow key={item._id} index={index} row={item} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
export default History;
function HistoryRow({ row, index }) {
  return (
    <TableRow
      key={''}
      sx={[
        { background: index % 2 === 0 ? 'text.background' : tablebg },
        { '&:last-child td, &:last-child th': { border: 0 } },
      ]}
    >
      <TableCell sx={{ pl: 1 }}>{moment(`${row.createdAt}`).format('ll')}</TableCell>
      <TableCell sx={{ pl: 1, textTransform: 'capitalize',fontWeight:'600',color:'text.secondary' }}>{row.actionBy}</TableCell>
      <TableCell sx={{ pl: 1 }}>{row.jobId}</TableCell>
      <TableCell sx={{ pl: 1 }}>{row.clientName}</TableCell>
      <TableCell sx={{ pl: 1 }}>{row.jobTitle}</TableCell>
      <TableCell sx={{ pl: 1 }}>{row.actionTo}</TableCell>
      <TableCell sx={{ pl: 1 }}>
        <div dangerouslySetInnerHTML={{ __html: row.note }} />
      </TableCell>
    </TableRow>
  );
}
