import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import './style.css';
// @mui
import { Link, Stack, Button, InputAdornment, TextField, Checkbox, Typography, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Iconify from '../../../components/iconify';
import { Api } from '../../../webservices';
import { userdetail } from '../../../redux/slice/userSlice';

// ----------------------------------------------------------------------

export default function LoginForm(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { func } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [value, setValue] = useState({ email: '', pass: '', role: '' });

  const logIn = async (e) => {
    localStorage.removeItem('token');
    localStorage.removeItem('userid');
    localStorage.removeItem('role');
    localStorage.removeItem('name');
    // alert('ashish')
    e.preventDefault();
    const body = {
      email: value.email,
      password: value.pass,
      // "role": value.role
    };
    Api(`company/company_login`, 'POST', body, '')
      .then((response) => {
        console.log('==========login=====>>>>>>>', JSON.stringify(response.data.data));
        if (response.status === 200) {
          if (response.data.data) {
            dispatch(userdetail(response.data.data));
            // toast.success(response.data.message);
            if (response.data.data.role === 'superadmin') {
              // navigate('/usermanagement', { replace: true });
              navigate('/addCompany', { replace: true });
            } else if (response.data.data.role === 'admin') {
              navigate(
                '/management',
                { state: { id: response.data.data._id, rolee: response.data.data.role } },
                { replace: true }
              );
            } else if (response.data.data.role === 'recruiterHead') {
              navigate('/worksheet' );
              // navigate(
              //   '/recuiterManager',
              //   { state: { id: response.data.data._id, rolee: response.data.data.role } },
              //   { replace: true }
              // );
            } else if (response.data.data.role === 'salesHead') {
              navigate(
                // '/salesManager',
                '/salesworksheet',
                { state: { id: response.data.data._id, rolee: response.data.data.role } },
                { replace: true }
              );
            } else if (response.data.data.role === 'recruiterManager') {
              navigate('/worksheet' );
              // navigate(
              //   '/recruiterTeamLead',
              //   { state: { id: response.data.data._id, rolee: response.data.data.role } },
              //   { replace: true }
              // );
            } else if (response.data.data.role === 'salesManager') {
              navigate(
                // '/salesTeamLead',
                '/salesworksheet',
                { state: { id: response.data.data._id, rolee: response.data.data.role } },
                { replace: true }
              );
            } else if (response.data.data.role === 'salesExecutive') {
              navigate(
                '/salesworksheet',
                { state: { id: response.data.data._id, rolee: response.data.data.role } },
                { replace: true }
              );
            } else if (response.data.data.role === 'recruiter') {
              navigate(
                '/worksheet',
                // { state: { id: response.data.data._id, rolee: response.data.data.role } },
                { replace: true }
              );
            } else if (response.data.data.role === 'recruiterTrainee') {
              navigate(
                '/worksheet',
                // { state: { id: response.data.data._id, rolee: response.data.data.role } },
                { replace: true }
              );
            } else if (response.data.data.role === 'salesTrainee') {
              navigate(
                '/salesworksheet',
                { state: { id: response.data.data._id, rolee: response.data.data.role } },
                { replace: true }
              );
            } else if (response.data.data.role === 'recruiterTeamLead') {
              // navigate('/recruiterManagement', { state: { id: response.data.data._id } });
              navigate('/worksheet' );
            } else if (response.data.data.role === 'salesTeamLead') {
              navigate(
                // '/salesManagement',
                '/salesworksheet',
                 { state: { id: response.data.data._id } });
            }
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('userid', response.data.data._id);
            localStorage.setItem('role', response.data.data.role);
            localStorage.setItem('username', response.data.data.contactPersonName);
            localStorage.setItem('email', response.data.data.email);
            // alert(response.data.data.role)
            localStorage.setItem('name', response.data.data.companyName);
            // window.location.reload()
          }
          else{
            toast.warn(response.data.message, +'!');
          }
        } else {
          toast.warn(response.data.message, +'!');
        }
      })
      .catch((err) => {
        console.log('===========', err);
        toast.warn(err);
      });
  };

  return (
    <>
    <Stack sx={{flexDirection:'row',justifyContent:'flex-end',mb:1}}>
    <Typography variant="subtitle1" >
    Contact Sales :&nbsp;
  </Typography>
    <Typography color="#e3398b" variant="subtitle1">
     (386) 465 2764
  </Typography>
  </Stack>
    <Stack bgcolor={'white'} sx={{p:3}} border={'2px solid #3C6BA5'} borderRadius={3}>
      <ToastContainer />
      <Typography variant="h5" sx={{color:'text.secondary',px:5,fontWeight:'600'}}  gutterBottom mb={4} >
        Welcome to Foxearch
      </Typography>
      <Stack spacing={2} className="email_field" >
        <input
          label="User Name"
          color="secondary"
          placeholder="User Name"
          onChange={(e) => setValue({ ...value, email: e.target.value })}
        />
        <Stack className="passwordIcon">
          <input
            label="Password"
            color="secondary"
            placeholder="Password"
            type={showPassword ? 'text' : 'password'}
            onChange={(e) => setValue({ ...value, pass: e.target.value })}
          />
          <Iconify
            icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}
            onClick={() => setShowPassword(!showPassword)}
          />
        </Stack>
        <Typography
            sx={{ letterSpacing: '1.25px', cursor: 'pointer',marginLeft:'2px !important' }}
            color="#e3398b"
            variant="subtitle2"
            onClick={func}
          >
            FORGOT PASSWORD?
          </Typography>
        <Stack alignItems={'center'}>
          <button className="login_btn" onClick={(e) => logIn(e)}>
            LOGIN
          </button> 
        </Stack>
      </Stack>
      

      {/* <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={(e)=>logIn(e)}>
        Login
      </LoadingButton> */}
    </Stack>
    <Stack sx={{flexDirection:'row',m:1}}>
      <Typography variant="subtitle2">New to Foxearch ?&nbsp;</Typography>
      <Typography color="#e3398b"
            variant="subtitle2">Signup</Typography>
    </Stack>
    {/* <Button variant="contained"  className="newacc_btn">
        CREATE NEW ACCOUNT
      </Button> */}
    </>
  );
}
